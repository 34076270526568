import { AppActionTypes } from '../actions';
import { Enums } from '../../helpers'

const initState = {
	feedId: null,
	feedName: null,
	match: null,
	serverTime: null,
	device: null,
	mobileMenuOpen: false,
	mobileBetslipOpen: false,
	avoidClick: false,
	isDark:false,

};

const app = (state = initState, action) => {

	switch (action.type) {
		case AppActionTypes.Switch_mode:
			return{
				...state,
				isDark: !state.isDark
				
			}

		case AppActionTypes.Start:
			return {
				...state
			}

		case AppActionTypes.ChangeFeed:
			return {
				...state,
				feedId: action.feedId,
				feedName: action.feedName
			}

		case AppActionTypes.PropsMatch:
			return {
				...state,
				match: action.match
			}

		case AppActionTypes.SetServerTime:
			return {
				...state,
				serverTime: action.serverTime
			}

		case AppActionTypes.WindowResizeSet:
			return {
				...state,
				device: action.device
			}

		case AppActionTypes.OpenMobileMenu:
			return {
				...state,
				mobileMenuOpen: action.open
			}

		case AppActionTypes.OpenMobileBetslip:
			return {
				...state,
				mobileBetslipOpen: action.open
			}
		case AppActionTypes.Set_Avoid_Click:
			return {
				...state,
				avoidClick: action.value
			}


		default:
			return state;

	}
}

export default app;
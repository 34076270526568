import React from 'react';

class StatisticsPage extends React.Component {

	async componentDidMount() {
		// get the new path from url
		var params = window.location.search.split("=");;
		let path = '';
		if (params) {
			path = params[1];
			window.location = path;
		}
	}

	render() {
		return (
				<div>Loading statistics...</div>
		)
	}
}

export default StatisticsPage;


import { SignalRTypes,SportTypesActionTypes } from '.'


export function loadSportTypes(request) {
	return {
		type: SignalRTypes.SportTypes_Request,
		request
	}
}

export function setSport(item) {
	return {
		type: SportTypesActionTypes.Set_Sport,
		item
	}
}

export function init() {
	return {
		type: SportTypesActionTypes.Init
	}
}

export function removeDisabledSportTypes() {
	return {
		type: SportTypesActionTypes.SportTypes_Remove_Disabled
	}
}
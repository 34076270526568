import { SignalRTypes, AppActionTypes } from '../actions'


export function init(route) {
	return {
		type: AppActionTypes.Start,
	}
}

export function navigate(route) {
	return {
		type: AppActionTypes.Navigate,
		route
	}
}

export function changeFeed(feedId, feedName) {
	return {
		type: AppActionTypes.ChangeFeed,
		feedId,
		feedName
	}
}

export function setPropsMath(match) {
	return {
		type: AppActionTypes.PropsMatch,
		match
	}
}

export function openMobileMenu(open) {
	return {
		type: AppActionTypes.OpenMobileMenu,
		open
	}
}

export function openMobileBetslip(open) {
	return {
		type: AppActionTypes.OpenMobileBetslip,
		open
	}
}

export function setAvoidClick(value) {
	return {
		type: AppActionTypes.Set_Avoid_Click,
		value
	}
}

export function switchMode() {

	return {
		type:AppActionTypes.Switch_mode,
	}
	
}
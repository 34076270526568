import { ContactUsActionTypes } from './actionTypes';

export function getSubjects() {
    return {
        type: ContactUsActionTypes.Subjects_Request
    }
}

export function SendContactData(data) {
    return {
        type: ContactUsActionTypes.Contact_Data_Request,
        data
    }
}
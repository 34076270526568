import { Button, Form, Input, Select, DatePicker, Icon } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Alert, PhoneNumber } from "../../../components";
import { Cookies, CountryCodes } from "../../../helpers";
import {
  getMemberDetails,
  sendVerifyEmail,
  updateMemberDetails,
} from "../../../redux/actions/membersActions";
import { getRegisterEntities } from "../../../redux/actions/registerActions";
import { getCounties } from "../../../redux/actions/generalActions";
import AddPhoneNumber from "./AddPhoneNumber";
import Moment from "moment";

class EditPersonalDetails extends React.Component {
  state = {
    showAddPhone: false,
  };

  componentDidMount() {
    this.props.getRegisterEntities();
    this.props.getCounties();
    this.props.getMemberDetails();
  }

  onClickAddPhone = () => {
    this.setState({ showAddPhone: true });
  };

  onCloseAddPhone = () => {
    this.setState({ showAddPhone: false });
  };

  sendVerifyEmail = () => {
    let form = this.props.form;
    this.props.sendVerifyEmail({ name: form.getFieldValue("email") });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //update member
        let data = {
          memberId: Cookies.get("memberId"),
          email: values.email,
          genderId: values.gender,
          firstName: values.firstName,
          lastName: values.lastName,
          securityQuestionId: values.question,
          answer: values.securityAnswer,
          countryId: values.country,
          countyId: values.county,
          city: values.city,
          address: values.address,
          postalCode: values.postalCode,
          titleId: values.title,
          birthDate: values.birthDate.format("YYYY-MM-DD"),
        };
        window.log("recieved ", data);
        this.props.updateMemberDetails(data);
      }
    });
  };

  verifyEmail = () => {
    const { userData } = this.props;

    return userData.emailValid ? (
      <Button className="success-check" type="primary">
        <Icon type="check" /> Email verified
      </Button>
    ) : (
      <Button
        type="primary"
        disabled={this.props.form.getFieldError("email")}
        onClick={this.sendVerifyEmail}
        htmlType="submit"
      >
        Verify Email
      </Button>
    );
  };

  getCountryFlag = (name) => {
    let result = CountryCodes.Codes.filter((item) => {
      return item.name.toLowerCase() == name.toLowerCase();
    });

    return result && result.length > 0 ? result[0].code.toLowerCase() : "";
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      message,
      success,
      validateAccount,
      userData,
      genders,
      memberTitles,
      countries,
      counties,
      securityQuestions,
      loading,
    } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <div className="personal-form">
        <div className="form-container">
          <AddPhoneNumber
            onCloseAddPhone={this.onCloseAddPhone}
            visible={this.state.showAddPhone}
          ></AddPhoneNumber>
          <Form colon={false} layout="horizontal" className="form">
            <Form.Item {...formItemLayout} label="Member Number">
              {getFieldDecorator("memberNumber", {
                initialValue: userData.memberNumber
                  ? userData.memberNumber
                  : "",
              })(<Input disabled />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Phone Number">
              <PhoneNumber
                form={this.props.form}
                mode="view"
                value={
                  userData.memberPhones
                    ? userData.memberPhones[0].formattedPhoneNumber
                    : ""
                }
              />
            </Form.Item>

            <Form.Item {...formItemLayout} label="Email Address">
              {getFieldDecorator("email", {
                initialValue: userData.email || "",
              })(
                <div>
                  <Input
                    onChange={(e) => {
                      this.value = e.target.value;
                    }}
                    value={userData.email || this.value}
                    // addonAfter={this.verifyEmail()}
                  />
                  {validateAccount && !success && !message && (
                    <p className="text">
                      We sent a verification link to your email
                    </p>
                  )}
                </div>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Title">
              {getFieldDecorator("title", {
                initialValue:
                  userData.titleId !== 0 ? userData.titleId : this.placeholder,
                rules: [{ required: true, message: "Please select title" }],
              })(
                <Select placeholder="Title" disabled={userData.titleId != 0}>
                  {memberTitles
                    ? memberTitles.map((title, i) => (
                        <Select.Option
                          key={title.titleId}
                          value={title.titleId}
                        >
                          {title.titleName}
                        </Select.Option>
                      ))
                    : ""}
                </Select>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="First Name">
              {getFieldDecorator("firstName", {
                initialValue: userData.firstName
                  ? userData.firstName
                  : this.placeholder,
                rules: [
                  { required: true, message: "Please provide first name" },
                ],
              })(
                <Input
                  disabled={userData.firstName != null}
                  placeholder="First Name"
                />
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Last Name">
              {getFieldDecorator("lastName", {
                initialValue: userData.lastName
                  ? userData.lastName
                  : this.placeholder,
                rules: [
                  { required: true, message: "Please provide last name" },
                ],
              })(
                <Input
                  disabled={userData.lastName != null}
                  placeholder="Last Name"
                />
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Gender">
              {getFieldDecorator("gender", {
                initialValue:
                  userData.genderId !== 0
                    ? userData.genderId
                    : this.placeholder,
                rules: [{ required: true, message: "Please provide gender" }],
              })(
                <Select placeholder="Gender" disabled={userData.genderId !== 0}>
                  {genders
                    ? genders.map((gender, i) => (
                        <Select.Option
                          key={gender.genderId}
                          value={gender.genderId}
                        >
                          <span>{gender.genderName}</span>
                        </Select.Option>
                      ))
                    : ""}
                </Select>
              )}
            </Form.Item>

            <Form.Item {...formItemLayout} label="Date of Birth">
              {getFieldDecorator("birthDate", {
                initialValue: userData
                  ? Moment(userData.birthDate, "YYYY-MM-DD")
                  : null,
                rules: [
                  { required: true, message: "Please provide birth date!" },
                ],
              })(
                <DatePicker
                  style={{ width: "100%" }}
                  size="large"
                  showTime
                  format="DD/MM/YYYY"
                  disabled
                />
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="County">
              {getFieldDecorator("county", {
                initialValue: userData.countyId
                  ? userData.countyId
                  : this.placeholder,
                rules: [
                  { required: true, message: "Please select your county" },
                ],
              })(
                <Select
                  placeholder="County"
                  //disabled={userData.countyId != 0}
                >
                  {counties
                    ? counties.map((county, i) => (
                        <Select.Option
                          key={county.countyName}
                          value={county.countyId}
                        >
                          {county.countyName}
                        </Select.Option>
                      ))
                    : ""}
                </Select>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Country">
              {getFieldDecorator("country", {
                initialValue: 121 || this.placeholder,
                rules: [
                  { required: true, message: "Please select your country" },
                ],
              })(
                <Select
                  placeholder="Please Select Country"
                  disabled
                >
                  {countries
                    ? countries.map((country, i) => (
                        <Select.Option
                          key={country.countryName}
                          value={country.countryId}
                        >
                          {
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            >
                              <div
                                className={
                                  "flag-icon flag-icon-" +
                                  this.getCountryFlag(country.countryName)
                                }
                              ></div>
                              <div
                                style={{
                                  paddingLeft: "10px",
                                  color: `${
                                    userData.countryId != 0
                                      ? "rgba(255, 255, 255, 0.44)"
                                      : "#ffff"
                                  }`,
                                }}
                              >
                                {country.countryName}
                              </div>
                            </div>
                          }
                        </Select.Option>
                      ))
                    : ""}
                </Select>
              )}
            </Form.Item>


            {/* <Form.Item  {...formItemLayout} label="Question" >
							{getFieldDecorator('question', {
								initialValue: userData.securityQuestionId != 0 ? userData.securityQuestionId : this.placeholder,
								rules: [{ required: true, message: 'Please select your security Question!' }]
							})(<Select placeholder="Security Question" disabled={userData.securityQuestionId != 0}>
								{securityQuestions ? securityQuestions.map((question, i) => <Select.Option key={question.questionId} value={question.questionId}>{question.questionName}</Select.Option>) : ''}
							</Select>)}
						</Form.Item>
						<Form.Item {...formItemLayout} label="Security Answer" >
							{getFieldDecorator('securityAnswer', {
								initialValue: userData.answer ? userData.answer : '',
								rules: [{ required: true, message: 'Please fiil your Security Answer' }],
							})(<Input placeholder="Security Answer"  disabled={userData.answer != null}/>)}
						</Form.Item> */}
            {/* <Form.Item {...formItemLayout} label="City" >
							{getFieldDecorator('city', {
								initialValue: userData.city ? userData.city : ''
							})(<Input />)}
						</Form.Item> */}
            {/* <Form.Item {...formItemLayout} label="Address" >
							{getFieldDecorator('address', {
								initialValue: userData.address ? userData.address : ''
							})(<Input />)}
						</Form.Item>
						<Form.Item {...formItemLayout} label="Postal Code" >
							{getFieldDecorator('postalCode', {
								initialValue: userData.postalCode ? userData.postalCode : ''
							})(<Input type="number" />)}
						</Form.Item>

						{
							userData.memberPhones && userData.memberPhones.length > 1 &&
							<Form.Item  {...formItemLayout} label="Additional Phone Number">
								<PhoneNumber form={this.props.form} mode='view' value={userData.memberPhones ? userData.memberPhones[1].formattedPhoneNumber : ''} />
							</Form.Item>
						} */}
          </Form>

          {userData.isAllowUpdate && (
            <div
              className="buttons"
              style={{ textAlign: "center", marginBottom: 19, marginTop: 5 }}
            >
              {/* {
								userData.memberPhones && userData.memberPhones.length <= 1 &&
								<Button className="success" type="primary" onClick={this.onClickAddPhone} loading={loading} >Add Phone Number</Button>
							} */}

              <Button
                className="info"
                type="primary"
                onClick={this.handleOnSubmit}
                loading={loading}
                htmlType="submit"
                style={{ height: 49, width: 150 }}
              >
                Save
              </Button>
            </div>
          )}
          {loading
            ? ""
            : message && <Alert message={message} type="error"></Alert>}
          {loading
            ? ""
            : success && <Alert message={success} type="success"></Alert>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    countries: state.register.countries,
    genders: state.register.genders,
    memberTitles: state.register.memberTitles,
    securityQuestions: state.register.securityQuestions,
    userData: state.member.userData,
    validateAccount: state.member.validateAccount,
    success: state.member.editDetailsSuccessMessage,
    message: state.member.editDetailsErrorMessage,
    loading: state.member.loading,
    counties: state.general.counties,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRegisterEntities: () => dispatch(getRegisterEntities()),
  updateMemberDetails: (data) => dispatch(updateMemberDetails(data)),
  getMemberDetails: (data) => dispatch(getMemberDetails(data)),
  sendVerifyEmail: (data) => dispatch(sendVerifyEmail(data)),
  getCounties: () => dispatch(getCounties()),
});

const wrappedEditPersonalDetails = Form.create({ name: "editPersonalDetails" })(
  EditPersonalDetails
);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(wrappedEditPersonalDetails);

import { SearchActionTypes } from '../actions';

const initState = {
    searchResult: [],
    eventsResult: [],
    loading: false
};

const search = (state = initState, action) => {

    switch (action.type) {
        case SearchActionTypes.GetSearchedData_Request: {
            return {
                ...state,
                loading: true
            }
        }
        case SearchActionTypes.GetSearchedData_Response: {
            return {
                ...state,
                loading: true
            }
        }
        case SearchActionTypes.GetSearchedData_Success:
            return {
                ...state,
                searchResult: action.results,
                eventsResult: action.response,
                loading: false
            }
        case SearchActionTypes.GetSearchedData_Error:
            return {
                ...state,
                searchResult: [],
                loading: false
            }

        default:
            return { ...state };

    }
}

export default search;
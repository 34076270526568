import React from 'react';
import { connect } from 'react-redux';
import { Images } from '../';
import { Enums } from '../../helpers';
import { changeFeed } from '../../redux/actions/appActions';

class BannerMobile extends React.Component {

	async componentDidMount() {
	}

	render() {

		return (
			<div className="container mobile">
				<div className="content">
					<img src={Images.Banner} />
					<div className="size">
						{/* <div className="text">
							They Play, You Win...
						</div> */}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		feedId: state.app.feedId
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		changeFeed: (feedId) => {
			dispatch(changeFeed(feedId));
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BannerMobile);

import { all, fork, put, takeEvery, select } from "redux-saga/effects";
import { BetSlipActionTypes, GeneralActionTypes, MembersActionTypes, SignalRTypes } from '../actions/actionTypes';
import { SessionStorage, Cookies, Enums, Constants } from '../../helpers';
import { membersApi, generalApi } from '../../api';

export function* init() {

    yield takeEvery(BetSlipActionTypes.Update_BetSlip_List_Request, function* ({ field }) {
        const { general, betSlip, app } = yield select();
        const { betsRestrictions, betSlipConstants } = general;
        betSlip.amount = betSlip.amount || 100;
        let betTab = SessionStorage.betTab || 2;
        let errors = [];
        let totalOdds = 0;
        let possibleWinnings = 0;
        let totalStake = 0;
        let list = SessionStorage.betSlip ? { ...SessionStorage.betSlip } : {};
        yield put({ type: BetSlipActionTypes.Place_Bet_Error });
        let fieldCopy = { ...field }
        fieldCopy.oddValueStatus = 0;

        if (Object.values(list).filter((bet) => bet.response).length > 0) {
            // delete succeded bets if they exist
            Object.values(list).forEach((betList) => {
                if (betList.response && betList.response.type === 'success') {
                    delete list[betList.activeOddFieldId]
                }
            });
            // remove resonse for bets 
            Object.values(list).forEach((betList) => {
                if (betList.response) {
                    delete betList.response
                }
            });

        }

        if (list && Object.values(list).length > 0) {
            if (list[fieldCopy.activeOddFieldId]) {//if exist delete
                delete list[fieldCopy.activeOddFieldId];
                if (!app.match.params.eventId || (app.match.params.eventId.toString() !== fieldCopy.eventId.toString())) {// if current screen is not the oddfield eventId then part
                    yield put({
                        type: SignalRTypes.Part_Request,
                        groupName: `F${fieldCopy.feed.feedId}S${fieldCopy.sport.sportId}E${fieldCopy.eventId}`,
                    })
                }
            } else {
                //check if same betTypeId exist
                let found;
                Object.values(list).map((item) => {
                    if (item.feed.feedId === fieldCopy.feed.feedId
                        && item.sport.sportId === fieldCopy.sport.sportId
                        && item.eventId === fieldCopy.eventId
                        && item.betType.betTypeId === fieldCopy.betType.betTypeId
                        && betTab == 2) {
                        found = item.activeOddFieldId;
                        return
                    }
                })
                if (found) {//replace
                    delete list[found]
                    list[fieldCopy.activeOddFieldId] = fieldCopy

                } else {//add   
                    list[fieldCopy.activeOddFieldId] = fieldCopy
                }
                yield put({
                    type: SignalRTypes.Join_Request,
                    groupName: `F${fieldCopy.feed.feedId}S${fieldCopy.sport.sportId}E${fieldCopy.eventId}`,
                })
            }
        } else { // if empty add first 
            list[fieldCopy.activeOddFieldId] = fieldCopy
            yield put({
                type: SignalRTypes.Join_Request,
                groupName: `F${fieldCopy.feed.feedId}S${fieldCopy.sport.sportId}E${fieldCopy.eventId}`,
            })
        }

        totalOdds = calcTotaOdds(list);
        SessionStorage.betSlip = list;
        if (betTab == 1) {
            totalStake = calcTotalStake(list);
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Total_Stake,
                totalStake
            })
            possibleWinnings = calacTotalPossibleWin(list);
            validateSingleBetSlip(list, betSlipConstants)
            yield put({ type: BetSlipActionTypes.Handle_Hide_Stake })
        }
        else {
            possibleWinnings = calcPossibleWinning(betSlip.amount, totalOdds);
            errors = validateMultipleBetSlip(list, betsRestrictions, betSlipConstants, totalOdds, possibleWinnings)
        }


        let result = Object.values(list).find((item) => item.oddValueStatus !== 0);

        yield put({ type: GeneralActionTypes.Bet_Code_Clear })

        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Value_Change_Warning,
            valueChangeWarning: result ? "Some of your bets values changed" : ""
        })
        if (!SessionStorage.betTab) {
            yield put({ type: BetSlipActionTypes.Set_Tab, tab: 2 })
        }
        yield put({
            type: BetSlipActionTypes.Update_BetSlip_List_Done,
            betSlipList: list,
        })
        yield put({
           type: MembersActionTypes.CheckDepositBonusConditions_Error
        })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Total_Odds,
            totalOdds,
        })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Possible_Winnings,
            possibleWinnings
        })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Errors,
            errors
        })

    })

    yield takeEvery(BetSlipActionTypes.Update_BetSlip_From_Storage, function* () {
        const { general, betSlip } = yield select();
        const { betsRestrictions, betSlipConstants } = general;
        let errors = [];
        let betTab = SessionStorage.betTab;
        let list = SessionStorage.betSlip ? { ...SessionStorage.betSlip } : {};
        let totalOdds = calcTotaOdds(list);
        let possibleWinnings = 0

        if (betTab == 1) {
            let totalStake = calcTotalStake(list);
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Total_Stake,
                totalStake,
            })
            possibleWinnings = calacTotalPossibleWin(list);
            validateSingleBetSlip(list, betSlipConstants)
        }
        else if (betTab == 2) {
            let selectedCurrency = Cookies.get('selectedCurrency');
            let betSlipAmount = (general.betSlipConstants && general.betSlipConstants.betSlipAmountLD && general.betSlipConstants.betSlipAmountUSD) ?
            ((selectedCurrency.currencyId === Enums.Currency.LiberianDollar) ?
                general.betSlipConstants.betSlipAmountLD : general.betSlipConstants.betSlipAmountUSD) :100;          
            yield put({ type: BetSlipActionTypes.On_Amount_Change, amount: betSlipAmount })
            yield put({
                type: MembersActionTypes.CheckDepositBonusConditions_Error
            })
            possibleWinnings = calcPossibleWinning(betSlipAmount, totalOdds);
            errors = validateMultipleBetSlip(list, betsRestrictions, betSlipConstants, totalOdds, possibleWinnings)
        }

        yield Object.values(list).map((item) => {
            item.oddValueStatus = 0;
            return put({
                type: SignalRTypes.Join_Request,
                groupName: `F${item.feed.feedId}S${item.sport.sportId}E${item.eventId}`,
            })
        })

        yield put({
            type: BetSlipActionTypes.Update_BetSlip_List_Done,
            betSlipList: list,
        })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Errors,
            errors
        })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Total_Odds,
            totalOdds,
        })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Possible_Winnings,
            possibleWinnings
        })

    })

    yield takeEvery(SignalRTypes.Get_Updated_OddField_Details_Response, function* ({ response }) {
        window.console.response("betslip fields", response)

        const { general, betSlip } = yield select();
        const { betsRestrictions, betSlipConstants } = general;
        let betTab = SessionStorage.betTab;
        let errors = [];
        betSlip.amount = betSlip.amount || 100
        let sessionList = response.data ? response.data : { ...SessionStorage.betSlip };
        // if response list exists - calculate only the not succeded bets
        if (Object.values(betSlip.betSlipList).filter((bet) => bet.response).length > 0) {
            let list = {};
            Object.values(betSlip.betSlipList).forEach((bet) => {
                Object.values(sessionList).map((betList) => {
                    if (bet.response && bet.response.type !== 'success') {
                        return list[betList.activeOddFieldId] = sessionList[betList.activeOddFieldId]
                    }
                })
            })
            sessionList = list;
        }

        let responseList = response.data;
        let possibleWinnings = 0;
        let totalOdds = calcTotaOdds(sessionList);

        Object.keys(responseList).map((key) => {
            if (betTab == 1) {
                responseList[key].amount = Object.keys(sessionList).length > 0 && sessionList[key] && sessionList[key].amount ? sessionList[key].amount : 100;
                sessionList[key] = responseList[key];
                possibleWinnings = calacTotalPossibleWin(sessionList);
                validateSingleBetSlip(sessionList, betSlipConstants);
            }
            else if (betTab == 2) {
                sessionList[key] = responseList[key];
                possibleWinnings = calcPossibleWinning(betSlip.amount, totalOdds);
                errors = validateMultipleBetSlip(sessionList, betsRestrictions, betSlipConstants, totalOdds, possibleWinnings);
            }
        })

        Object.keys(sessionList).map((key) => {
            if (Object.keys(responseList).indexOf(key) === -1) {
                let fieldCopy = sessionList[key]
                fieldCopy.notAvailable = true;
                sessionList[key] = fieldCopy;
            }
        })
        if (betTab == 1) {
            let totalStake = calcTotalStake(sessionList);
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Total_Stake,
                totalStake,
            })
        }


        SessionStorage.betSlip = sessionList;

        yield Object.values(sessionList).map((item) => {
            item.oddValueStatus = 0;
            return put({
                type: SignalRTypes.Join_Request,
                groupName: `F${item.feed.feedId}S${item.sport.sportId}E${item.eventId}`,
            })
        })

        yield put({
            type: BetSlipActionTypes.Update_BetSlip_List_Done,
            betSlipList: sessionList,
        })
        yield put({
            type: MembersActionTypes.CheckDepositBonusConditions_Error
         })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Errors,
            errors
        })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Total_Odds,
            totalOdds,
        })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Possible_Winnings,
            possibleWinnings
        })

    })

    yield takeEvery(BetSlipActionTypes.Update_BetSlip_From_FeedChange, function* ({ list }) {
        const { general, betSlip } = yield select();
        const { betsRestrictions, betSlipConstants } = general;

        let betTab = SessionStorage.betTab;
        let errors = [];
        let totalOdds = calcTotaOdds(list);
        Object.values(list).forEach((item) => item.errors = []);
        let possibleWinnings = 0;
        if (betTab == 1) {
            if (Object.values(betSlip.betSlipList).filter((bet) => bet.response).length > 0) {
                let responseList = {}
                // we calculate only the fields that were not succeded 
                Object.values(betSlip.betSlipList).forEach((bet) => {
                    if (bet.response && bet.response.type !== 'success') {
                        return responseList[bet.activeOddFieldId] = list[bet.activeOddFieldId]
                    }
                })
                if (Object.keys(responseList).length > 0) {
                    let totalStake = calcTotalStake(responseList);
                    possibleWinnings = calacTotalPossibleWin(responseList)
                    yield put({
                        type: BetSlipActionTypes.Set_BetSlip_Total_Stake,
                        totalStake
                    })
                    validateSingleBetSlip(list, betSlipConstants)
                }
                else {
                    possibleWinnings = 0;
                    yield put({
                        type: BetSlipActionTypes.Set_BetSlip_Total_Stake,
                        totalStake: 0
                    })
                }
            }
            else {
                let totalStake = calcTotalStake(list);
                possibleWinnings = calacTotalPossibleWin(list)
                yield put({
                    type: BetSlipActionTypes.Set_BetSlip_Total_Stake,
                    totalStake
                })
                validateSingleBetSlip(list, betSlipConstants);
            }

        }
        else if (betTab == 2) {
            possibleWinnings = calcPossibleWinning(betSlip.amount, totalOdds);
            errors = validateMultipleBetSlip(list, betsRestrictions, betSlipConstants, totalOdds, possibleWinnings);
        }
        SessionStorage.betSlip = list;
        let result = Object.values(list).find((item) => item.oddValueStatus !== 0);
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Value_Change_Warning,
            valueChangeWarning: result ? "Some of your bets values changed" : ""
        })
        yield put({
            type: BetSlipActionTypes.Update_BetSlip_List_Done,
            betSlipList: list,
        })
        yield put({
            type: MembersActionTypes.CheckDepositBonusConditions_Error
         })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Errors,
            errors
        })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Total_Odds,
            totalOdds,
        })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Possible_Winnings,
            possibleWinnings
        })

    })

    yield takeEvery(BetSlipActionTypes.BetSlip_Remove_Field, function* ({ field }) {
        const { general, betSlip, app } = yield select();
        const { betsRestrictions, betSlipConstants } = general;
        let errors = [];
        let list = SessionStorage.betSlip ? { ...SessionStorage.betSlip } : {};
        delete list[field.activeOddFieldId];
        SessionStorage.betSlip = list;
        let betTab = SessionStorage.betTab;
        let totalOdds = calcTotaOdds(list);
        let possibleWinnings = 0;
        let responseListSuccess = Object.values(betSlip.betSlipList).filter((bet) => bet.response && bet.response.type === 'success')
        if (betTab == 1) {
            // calculate only failed bets
            if (responseListSuccess.length > 0) {
                let newList = Object.values(betSlip.betSlipList).filter((bet) => bet.response && bet.response.betLine !== field.eventBetLineNo && bet.response.type === 'error');
                let totalStake = calcTotalStake(newList);
                possibleWinnings = calacTotalPossibleWin(newList)
                yield put({
                    type: BetSlipActionTypes.Set_BetSlip_Total_Stake,
                    totalStake
                })
                validateSingleBetSlip(list, betSlipConstants);
            }
            else {
                possibleWinnings = calacTotalPossibleWin(list)
                let totalStake = calcTotalStake(list);
                yield put({
                    type: BetSlipActionTypes.Set_BetSlip_Total_Stake,
                    totalStake
                })
                validateSingleBetSlip(list, betSlipConstants);
            }
            yield put({ type: BetSlipActionTypes.Handle_Hide_Stake })
        }
        else if (betTab == 2) {
            possibleWinnings = calcPossibleWinning(betSlip.amount, totalOdds);
            errors = validateMultipleBetSlip(list, betsRestrictions, betSlipConstants, totalOdds, possibleWinnings);
        }
        if (!app.match.params.eventId || (app.match.params.eventId.toString() !== field.eventId.toString())) {// if current screen is not the oddfield eventId then part

            yield put({
                type: SignalRTypes.Part_Request,
                groupName: `F${field.feed.feedId}S${field.sport.sportId}E${field.eventId}`,
            })
        }
        yield put({ type: GeneralActionTypes.Bet_Code_Clear })


        let result = Object.values(list).find((item) => item.oddValueStatus !== 0);


        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Value_Change_Warning,
            valueChangeWarning: result ? "Some of your bets values changed" : ""
        })

        yield put({
            type: BetSlipActionTypes.Update_BetSlip_List_Done,
            betSlipList: list,
        })
        yield put({
            type: MembersActionTypes.CheckDepositBonusConditions_Error
         })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Total_Odds,
            totalOdds,
        })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Possible_Winnings,
            possibleWinnings
        })
        yield put({
            type: BetSlipActionTypes.Set_BetSlip_Errors,
            errors
        })

    })

    yield takeEvery(BetSlipActionTypes.Place_Multiple_Bet_Request, function* () {
        const { general, betSlip } = yield select();
        let user = Cookies.get('user');
        let list = SessionStorage.betSlip ? { ...SessionStorage.betSlip } : {};
        let errors = [];



        let currency = Cookies.get('selectedCurrency');
        if (!currency || !currency.currencyId) {
            Cookies.set('selectedCurrency', general.currencies.LiberianDollar)
            currency = Cookies.get('selectedCurrency');
        }

        errors = validateMultipleBetSlipAmount(list, general.minimumBetsAmount, betSlip.amount);
        if (user) {
            let balanceError = validateBalanceToBetSlip(betSlip.amount);
            if (balanceError) {
                return yield put({
                    type: BetSlipActionTypes.Set_BetSlip_Errors,
                    errors: [{ type: "validation", text: "User balance is not enough" }]
                })
            }
        }
        if (errors.length > 0) {
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Errors,
                errors
            })
        } else {
            let virtualResult = Object.values(list).filter((item) => Enums.VirtualFeedIds.includes(item.feed.feedId));
            let request = {};
            let response = '';

            request.betLines = [];
            request.amount = betSlip.amount;
            request.betCode = SessionStorage.betCode.toString();
            request.currencyId = currency.currencyId;
            Object.values(list).map((item) => {
                request.betLines.push({
                    feedId: item.feed.feedId,
                    sportId: item.sport.sportId,
                    eventId: item.eventId,
                    oddId: item.oddId,
                    oddFieldNumber: item.oddFieldNumber,
                    betLineNo: item.eventBetLineNo,
                    betTypeId: item.betType.betTypeId,
                })
            })
            response = virtualResult && virtualResult.length > 0 ?
                yield membersApi.placeBetVirtual(request)
                :
                yield membersApi.placeBetStaticLive(request)

            switch (response.status) {
                case 1:
                    yield put({ type: BetSlipActionTypes.Place_Bet_Success });
                    let user = Cookies.get('user');
                    let tokenExpiredMinutes = Cookies.get('tokenExpiresMinutes');
                    let selectedCurrency = Cookies.get('selectedCurrency');
                    let balances = response.data.member.memberBalances.reduce((acc, current) => {
                        let currencyName = current.currency.currencyName.split(' ').join('');
                        if (!acc[currencyName]) {
                            acc[currencyName] = {
                                balance: current.balance,
                                currencySymbol: current.currency.currencySymbol,
                                defaultCurrency: current.currency.currencyIsDefault,
                                currencyId: current.currency.currencyId,
                                currencyName: current.currency.currencyName
                            }
                        }
                        return acc;
                    }, {});
                    Cookies.set('user', {
                        userName: user.userName,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        balance: balances,
                        bonus: response.data.member.memberDepositBonusBalance,
                        memberPhones: user.memberPhones
                    }, tokenExpiredMinutes)
                    let currentCurrency = Object.keys(balances).find((item) => {
                        return balances[item].currencySymbol == selectedCurrency.currencySymbol
                    })

                    Cookies.set('selectedCurrency', balances[currentCurrency]);
                    yield put({ type: MembersActionTypes.Member_Update_Balance, balance: balances[currentCurrency].balance });
                    yield put({ type: BetSlipActionTypes.BetSlip_Clear });
                    yield put({ type: GeneralActionTypes.Bet_Code_Clear })

                    break;
                case 3:
                    yield put({ type: BetSlipActionTypes.Place_Bet_Error, serverError: response.message });
                    break;
                case 4:
                    yield put({ type: BetSlipActionTypes.Set_Login_Error, loginError: true })
                    break;
                default:
            }
        }
    })

    yield takeEvery(BetSlipActionTypes.Place_Single_Bet_Request, function* () {
        const { general, betSlip } = yield select();
        let user = Cookies.get('user');
        let tokenExpiredMinutes = Cookies.get('tokenExpiresMinutes');
        let list = { ...SessionStorage.betSlip } || {};
        let betResponseExist = Object.values(betSlip.betSlipList).filter((bet) => bet.response).length > 0;
        // if we have response list we send only the bets that were not succeeded first time
        if (betResponseExist) {
            Object.values(list).map((betList) => {
                if (betSlip.response && betSlip.response.type === 'success') {
                    delete list[betList.activeOddFieldId]
                }
            })
        }


        let currency = Cookies.get('selectedCurrency');
        if (!currency || !currency.currencyId) {
            Cookies.set('selectedCurrency', general.currencies.LiberianDollar)
            currency = Cookies.get('selectedCurrency');
        }

        let errors = validateSingleBetSlipAmount(list, general.minimumBetsAmount);
        if (!errors) {
            if (user) {
                let balanceError = validateBalanceToBetSlip(betSlip.totalStake);
                if (balanceError) {
                    return yield put({
                        type: BetSlipActionTypes.Set_BetSlip_Errors,
                        errors: [{ type: "validation", text: "User balance is not enough" }]
                    })
                }
            }

            let balance = '';
            let tokenExpired = false;
            let betLines = [];

            Object.values(list).map((item) => {
                betLines.push(
                    {
                        amount: item.amount,
                        feedId: item.feed.feedId,
                        sportId: item.sport.sportId,
                        eventId: item.eventId,
                        oddId: item.oddId,
                        oddFieldNumber: item.oddFieldNumber,
                        betLineNo: item.eventBetLineNo,
                        betTypeId: item.betType.betTypeId,

                    })
            });

            let request = {
                currencyId: currency.currencyId,
                betLines: betLines
            }

            let response = yield membersApi.placeBetSingles(request);

            switch (response.status) {
                case 4:
                    tokenExpired = true;
                    break;
                case 1:
                    let user = Cookies.get('user');
                    let selectedCurrency = Cookies.get('selectedCurrency');
                    let balances = response.data.member.memberBalances.reduce((acc, current) => {
                        let currencyName = current.currency.currencyName.split(' ').join('');
                        if (!acc[currencyName]) {
                            acc[currencyName] = {
                                balance: current.balance,
                                currencySymbol: current.currency.currencySymbol,
                                defaultCurrency: current.currency.currencyIsDefault,
                                currencyId: current.currency.currencyId,
                                currencyName: current.currency.currencyName
                            }
                        }
                        return acc;
                    }, {});
                    Cookies.set('user', {
                        userName: user.userName,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        balance: balances,
                        bonus: response.data.member.memberDepositBonusBalance,
                        memberPhones: user.memberPhones
                    }, tokenExpiredMinutes)
                    let currentCurrency = Object.keys(balances).find((item) => {
                        return balances[item].currencySymbol == selectedCurrency.currencySymbol
                    })
                    Cookies.set('selectedCurrency', balances[currentCurrency]);
                    balance = balances[currentCurrency].balance;
                    let responses = response.data.singlesBetFormDetails;
                    responses.forEach((item) => {
                        Object.values(list).map((bet) => {
                            if (bet.activeOddFieldId === item.activeOddFieldId && item.betSucceed) {
                                bet.response = { type: 'success', message: 'Bet succeeded', betLine: item.betLineNo, fieldId: item.activeOddFieldId };
                            }
                            else if (bet.activeOddFieldId === item.activeOddFieldId && !item.betSucceed) {
                                let errorMessage = item.messages.map((mes) => mes.message)

                                bet.response = { type: 'error', message: errorMessage[0], betLine: item.betLineNo, fieldId: item.activeOddFieldId };
                            }
                        })
                    })
                    break;
                case 3:
                    yield put({
                        type: BetSlipActionTypes.Set_BetSlip_Errors,
                        errors: [`Server Error (${response.message})`]
                    })
                    break;
                default:
            }

            if (balance) {
                yield put({ type: MembersActionTypes.Member_Update_Balance, balance: balance });
            }

            if (Object.values(list).filter((bet) => bet.response).length > 0) {
                // calculate only the fields that were not succeeded
                let newList = Object.values(list).filter((bet) => bet.response && bet.response.type === 'error')
                let totalStake = calcTotalStake(newList);
                let possibleWinnings = calacTotalPossibleWin(newList)
                if (possibleWinnings == 0) {
                    yield put({
                        type: BetSlipActionTypes.Set_Hide_Stake,
                        hideStake: true
                    })
                }
                yield put({
                    type: BetSlipActionTypes.Set_BetSlip_Possible_Winnings,
                    possibleWinnings
                })
                yield put({
                    type: BetSlipActionTypes.Set_BetSlip_Total_Stake,
                    totalStake
                })

                yield put({ type: BetSlipActionTypes.Place_Single_Bets_Response, betSlipList: list })
                SessionStorage.betSlip = list

            }
            if (tokenExpired) {
                yield put({ type: BetSlipActionTypes.Set_Login_Error, loginError: true })
            }
        }
        else {
            yield put({
                type: BetSlipActionTypes.Update_BetSlip_List_Done,
                betSlipList: list,
            })
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Errors,
                errors: []
            })
        }
    })
    yield takeEvery(BetSlipActionTypes.Clear_Succeeded_Bets, function* () {
        const { betSlip, app } = yield select();
        let list = { ...SessionStorage.betSlip } || {};;

        let succededResponse = Object.values(list)
            .filter((betResponse) => betResponse.response && betResponse.response.type === 'success');

        if (succededResponse.length > 0) {
            yield succededResponse.forEach((bet) => {
                Object.values(list).map((betList) => {
                    if (bet.eventBetLineNo === betList.eventBetLineNo) {
                        if (!app.match.params.eventId || (app.match.params.eventId.toString() !== betList.eventId.toString())) {// if current screen is not the oddfield eventId then part

                            put({
                                type: SignalRTypes.Part_Request,
                                groupName: `F${list[betList.activeOddFieldId].feed.feedId}S${list[betList.activeOddFieldId].sport.sportId}E${list[betList.activeOddFieldId].eventId}`
                            });
                        }
                        delete list[betList.activeOddFieldId]
                    }
                })
            })
            SessionStorage.betSlip = list;
            yield put({ type: BetSlipActionTypes.Update_BetSlip_List_Done, betSlipList: list })
        }
    })

    yield takeEvery(BetSlipActionTypes.BetSlip_Clear, function* () {
        const { betSlip, app } = yield select();
        yield Object.values(SessionStorage.betSlip).map((item) => {
            if (!app.match.params.eventId || (app.match.params.eventId.toString() !== item.eventId.toString())) {// if current screen is not the oddfield eventId then part

                return put({
                    type: SignalRTypes.Part_Request,
                    groupName: `F${item.feed.feedId}S${item.sport.sportId}E${item.eventId}`
                });
            }
            return;
        })
        if (SessionStorage.betCode) {
            yield put({ type: GeneralActionTypes.Bet_Code_Clear })
        }
        SessionStorage.betSlip = {};
        SessionStorage.betSlipAmount = '';
        betSlip.amount = 0;
    });

    yield takeEvery(GeneralActionTypes.Bet_Slip_Configuration_Request, function* () {
        //call server
        try {
            yield put({ type: BetSlipActionTypes.Clear_Succeeded_Bets })
            let response = yield generalApi.betSlipConfiguration();
            let data = response.data;

            switch (response.status) {
                case 1:
                    yield put({
                        type: GeneralActionTypes.Bet_Slip_Configuration_Success,
                        betSlipConstants: data.betSlipConstants,
                        betsRestrictions: data.betsRestrictions,
                        minimumBetsAmount: data.minimumBetsAmount,
                    });
                    //yield put({ type: BetSlipActionTypes.Update_BetSlip_From_Storage });
                    let list = SessionStorage.betSlip ? { ...SessionStorage.betSlip } : {};
                    let request = [];
                    if (list && Object.keys(list).length > 0) {
                        Object.values(list).map((item) => {
                            request.push({
                                feed: { feedId: item.feed.feedId },
                                sport: { sportId: item.sport.sportId },
                                eventId: item.eventId,
                                betType: { betTypeId: item.betType.betTypeId },
                                oddSpecialField: item.oddSpecialField,
                                activeOddFieldId: item.activeOddFieldId,
                            })
                        });

                    }
                    if (request && request.length > 0) {
                        yield put({ type: SignalRTypes.Get_Updated_OddField_Details, request });
                    }

                    break;
                case 3 || 4:
                    yield put({ type: GeneralActionTypes.Bet_Slip_Configuration_Error, message: [{ text: response.message }] });
                    break;
            }
        }
        catch (error) {
            window.log('client =>> could not get BetSlip Configuration: ', error);
        }
    });

    yield takeEvery(BetSlipActionTypes.On_Amount_Change, function* ({ amount }) {
        try {
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Errors,
                errors: []
            })

            const { general, betSlip } = yield select();
            const { betsRestrictions, betSlipConstants } = general;
            let errors = [];
            let list = SessionStorage.betSlip ? { ...SessionStorage.betSlip } : {};
            SessionStorage.betSlipAmount = amount;
            let totalOdds = calcTotaOdds(list);
            let possibleWinnings = 0;
            let totalStake = calcTotalStake(list);
            let betTab = SessionStorage.betTab;
            if (betTab == 1) {
                possibleWinnings = calacTotalPossibleWin(list);
                validateSingleBetSlip(list, betSlipConstants);
            }
            else if (betTab == 2) {
                possibleWinnings = calcPossibleWinning(betSlip.amount, totalOdds);
                errors = validateMultipleBetSlip(list, betsRestrictions, betSlipConstants, totalOdds, possibleWinnings);
            }

            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Total_Odds,
                totalOdds,
            })
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Possible_Winnings,
                possibleWinnings
            })
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Total_Stake,
                totalStake
            })
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Errors,
                errors
            })
            yield put({
                type: MembersActionTypes.CheckDepositBonusConditions_Error
             })

        }
        catch (error) {
            window.log('client =>> could not get BetSlip Configuration: ', error);
        }
    });
    yield takeEvery(BetSlipActionTypes.On_Field_Amount_Change, function* ({ amount, fieldId }) {
        try {
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Errors,
                errors: []
            })

            const { general, betSlip } = yield select();
            const { betsRestrictions, betSlipConstants } = general;
            let errors = [];
            let list = SessionStorage.betSlip ? { ...SessionStorage.betSlip } : {};
            list[fieldId].amount = amount;
            list[fieldId].errors = [];
            let totalOdds = calcTotaOdds(list);
            let totalStake = calcTotalStake(list);

            let possibleWinnings = calacTotalPossibleWin(list);
            validateSingleBetSlip(list, betSlipConstants);
            SessionStorage.betSlip = list;
            yield put({
                type: BetSlipActionTypes.Update_BetSlip_List_Done,
                betSlipList: list,
            })
            yield put({
                type: MembersActionTypes.CheckDepositBonusConditions_Error
             })
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Total_Odds,
                totalOdds,
            })
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Possible_Winnings,
                possibleWinnings
            })
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Total_Stake,
                totalStake
            })
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Errors,
                errors
            })

        }
        catch (error) {
            window.log('client =>> could not get BetSlip Configuration: ', error);
        }
    });

    yield takeEvery(BetSlipActionTypes.Set_Tab, function* ({ tab }) {
        const { betSlip, general } = yield select();
        const { betsRestrictions, betSlipConstants } = general;
        try {


            SessionStorage.betTab = parseInt(tab);
            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Errors,
                errors: []
            })
            let list = { ...SessionStorage.betSlip } || {};;
            let possibleWinnings = 0;
            if (Object.values(list).filter((bet) => bet.response).length > 0) {
                // if we switch tabs we delete the responses 
                Object.values(list).map((bet) => {
                    if (bet.response.type === 'success') {
                        return delete list[bet.activeOddFieldId]
                    }

                    else if (bet.response) {
                        return delete bet.response
                    }

                })
            }

            if (tab == 2) {
                Object.values(list).forEach((item) => {
                    delete item.amount;
                    delete item.errors;
                });
                let totalOdds = calcTotaOdds(list);
                possibleWinnings = calcPossibleWinning(betSlip.amount, totalOdds)
                let errors = validateMultipleBetSlip(list, betsRestrictions, betSlipConstants, totalOdds, possibleWinnings)
                if (errors) {
                    yield put({
                        type: BetSlipActionTypes.Set_BetSlip_Errors,
                        errors
                    })
                }
                yield put({
                    type: BetSlipActionTypes.Set_BetSlip_Total_Odds,
                    totalOdds,
                })
            }
            else if (tab == 1) {

                SessionStorage.betCode = '';
                Object.values(list).forEach((item) => {
                    item.amount = parseInt(item.amount) || parseInt(sessionStorage.betSlipAmount) || 100;
                    delete item.errors;
                });
                possibleWinnings = calacTotalPossibleWin(list);
                let totalStake = calcTotalStake(list);
                validateSingleBetSlip(list, betSlipConstants)
                yield put({
                    type: BetSlipActionTypes.Set_BetSlip_Total_Stake,
                    totalStake
                })

                yield put({
                    type: BetSlipActionTypes.Set_Hide_Stake,
                    hideStake: false
                })
            }


            SessionStorage.betSlip = list;

            yield put({
                type: BetSlipActionTypes.Set_BetSlip_Possible_Winnings,
                possibleWinnings
            })
            yield put({
                type: BetSlipActionTypes.Update_BetSlip_List_Done,
                betSlipList: list,
            })


        }
        catch (error) {
            window.log('client =>> could not set tab: ', error);
        }
    });

    yield takeEvery(BetSlipActionTypes.Handle_Hide_Stake, function* ({ forceHideStake }) {
        try {
            if (forceHideStake !== undefined) {
                yield put({
                    type: BetSlipActionTypes.Set_Hide_Stake,
                    hideStake: forceHideStake
                })
            }
            else {
                let betSlip = SessionStorage.betSlip;
                let amount = SessionStorage.betSlipAmount;
                let betSlipNumbers = Object.keys(betSlip);
                let isBetSlipsEqual = true;

                for (let i = 0; i < betSlipNumbers.length; i++) {
                    for (let j = 0; j < betSlipNumbers.length; j++) {
                        if (betSlip[betSlipNumbers[i]].amount !== betSlip[betSlipNumbers[j]].amount) {
                            isBetSlipsEqual = false;
                        }
                    }
                }


                let hideStake = betSlipNumbers.some((key) => {
                    return betSlip[key].amount !== amount
                });

                if (isBetSlipsEqual && hideStake && betSlipNumbers.length > 0) {
                    yield put({ type: BetSlipActionTypes.On_Amount_Change, amount: betSlip[betSlipNumbers[0]].amount })
                    hideStake = false;
                }

                yield put({
                    type: BetSlipActionTypes.Set_Hide_Stake,
                    hideStake: hideStake
                })
            }
        }


        catch (error) {
            window.log('client =>> could not set hide stake: ', error);
        }
    });
}

const validateMultipleBetSlip = (list, betsRestrictions, betSlipConstants, totalOdds, possibleWinning) => {
    let errors = [];
    let selectedCurrency = Cookies.get('selectedCurrency');
    let { Currency } = Enums;
    if (list && Object.values(list).length > 0) {

        checkRestrictions(list, betsRestrictions) && errors.push({ type: "validation", text: "Some of your bets cannot be combined" });
        checkVirtualError(list) && errors.push({ type: "validation", text: `Can't combine virtual with static or live` });
        checkLocked(list) && errors.push({ type: "validation", text: `Some of your bets locked` });
        checkAvailable(list) && errors.push({ type: "validation", text: `Some of your bets are no longer available` });
        checkVirtualTypes(list) && errors.push({ type: "validation", text: `Different virtual bet types cannot be combined` });

        Object.values(list).length > betSlipConstants.maxBetLines && errors.push({ type: "validation", text: `Maximum ${betSlipConstants.maxBetLines} bet lines allowed` });
        totalOdds > betSlipConstants.maxOddsMultiplier && errors.push({ type: "validation", text: `Maximum ${betSlipConstants.maxOddsMultiplier.format(2)} Total odds allowed` });
        totalOdds < betSlipConstants.minOddsMultiplier && errors.push({ type: "validation", text: `Minimum ${betSlipConstants.minOddsMultiplier.format(2)} Total odds is required` });
        if (selectedCurrency && selectedCurrency.currencyId === 1) {
            possibleWinning > betSlipConstants.maxPossibleWinning && errors.push({ type: "validation", text: `Maximum ${betSlipConstants.maxPossibleWinning.format(0)} Possible winnings allowed` });
        }
        else {
            possibleWinning > betSlipConstants.maxPossibleWinningUSD && errors.push({ type: "validation", text: `Maximum ${betSlipConstants.maxPossibleWinningUSD.format(2)} Possible winnings allowed` });

        }
    }
    return errors;
}


const validateSingleBetSlip = (list, betSlipConstants) => {
    let selectedCurrency = Cookies.get('selectedCurrency');
    let { Currency } = Enums;
    if (list && Object.values(list).length > 0) {
        checkLocked(list);
        checkAvailable(list);
        Object.values(list).forEach((bet) => {
            bet.errors = bet.errors || [];
            bet.oddValue > betSlipConstants.maxOddsMultiplier && bet.errors.pushUnique(`Maximum ${betSlipConstants.maxOddsMultiplier.format(2)} Total odds allowed`);

            bet.oddValue < betSlipConstants.minOddsMultiplier && bet.errors.pushUnique(`Minimum ${betSlipConstants.minOddsMultiplier.format(2)} Total odds is required`);

            if (selectedCurrency && selectedCurrency.currencyId === 1) {
                bet.possibleFieldWin > betSlipConstants.maxPossibleWinning && bet.errors.push(`Maximum ${betSlipConstants.maxPossibleWinning.format(0)} Possible winnings allowed`);
            }
            else {
                bet.possibleFieldWin > betSlipConstants.maxPossibleWinningUSD && bet.errors.pushUnique(`Maximum ${betSlipConstants.maxPossibleWinningUSD.format(2)} Possible winnings allowed`);
            }
        })
    }
}

const validateBalanceToBetSlip = (totalStake) => {
    let user = Cookies.get('user');
    let currentUserCurrency = Object.values(user.balance).reduce((acc, balance) => {
        if (!acc[balance.currencySymbol] && balance.defaultCurrency) {
            acc[balance.currencySymbol] = balance
        }
        return acc
    }
        , {})

    let selectedCurrency = Cookies.get('selectedCurrency') || currentUserCurrency;
    return selectedCurrency.balance < totalStake;
}
const validateMultipleBetSlipAmount = (list, minimumBetsAmount, amount) => {
    let { Currency } = Enums;
    let result = minimumBetsAmount.find((item) => item.noOfBetLines === Object.values(list).length);
    let selectedCurrency = Cookies.get('selectedCurrency');
    if (selectedCurrency.currencyId !== Currency.USD && amount % 5) {
        return [{ type: "validation", text: `amount must be devided by 5` }];
    }
    else if (selectedCurrency.currencyId == Currency.LiberianDollar && result.amount > amount) {
        return [{ type: "validation", text: `Minimum ${result.amount.format(0)} amount allowed` }]
    }
    else if (selectedCurrency.currencyId == Currency.USD && result.usdAmount > amount) {
        return [{ type: "validation", text: `Minimum ${result.usdAmount.format(2)} amount allowed` }]
    }

    else {
        return [];
    }
}

const validateSingleBetSlipAmount = (list, minimumBetsAmount) => {
    let { Currency } = Enums;
    let result = minimumBetsAmount.find((item) => item.noOfBetLines === 1);
    let error = false;
    let selectedCurrency = Cookies.get('selectedCurrency');
    Object.values(list).forEach((bet) => {
        bet.errors = bet.errors || [];
        if (selectedCurrency.currencyId == Currency.LiberianDollar && result.amount > bet.amount) {
            bet.errors.pushUnique(`Minimum ${result.amount.format(0)} amount allowed`)
            error = true
        }
        else if (selectedCurrency.currencyId == Currency.USD && result.usdAmount > bet.amount) {
            bet.errors.pushUnique(`Minimum ${result.usdAmount.format(2)} amount allowed`)
            error = true
        }
        else if (selectedCurrency.currencyId !== Currency.USD && bet.amount % 5) {
            bet.errors.pushUnique(`amount must be devided by 5`)
            error = true
        }
    })
    return error;
}

const checkRestrictions = (list, restrictions) => {
    let combineError;
    Object.values(list).map((item) => {
        let temp = { ...item }
        temp.errors = [];
        list[temp.activeOddFieldId] = temp;
    })
    for (let i = 0; i < Object.values(list).length - 1; i++) {
        let item1 = { ...Object.values(list)[i] };
        item1.errors = [];
        for (let j = i + 1; j < Object.values(list).length; j++) {
            let result;
            let item2 = { ...Object.values(list)[j] };
            item2.errors = [];
            if (item1.eventId === item2.eventId) {
                result = restrictions && restrictions.filter((res) => {
                    return (res.feedId === item1.feed.feedId && res.sportId === item1.sport.sportId &&
                        ((res.betTypeId1 === item1.betTypeId && res.betTypeId2 === item2.betTypeId)
                            || (res.betTypeId1 === item2.betTypeId && res.betTypeId2 === item1.betTypeId))
                    )
                })
                if (!result || result.length === 0) {
                    let message1 = `${item1.betType.betTypeName} cannot combine with ${item2.betType.betTypeName}`
                    let message2 = `${item2.betType.betTypeName} cannot combine with ${item1.betType.betTypeName}`
                    item1.errors.pushUnique(message1);
                    item2.errors.pushUnique(message2);
                    combineError = true;
                }


                list[item1.activeOddFieldId] = item1;
                list[item2.activeOddFieldId] = item2;
            }


        }
    }

    return combineError;
}


const checkVirtualError = (list) => {

    let combineError;
    Object.values(list).map((item) => {
        let temp = { ...item }
        list[temp.activeOddFieldId] = temp;
    })
    for (let i = 0; i < Object.values(list).length - 1; i++) {
        let item1 = { ...Object.values(list)[i] };
        for (let j = i + 1; j < Object.values(list).length; j++) {
            let result;
            let item2 = { ...Object.values(list)[j] };
            if (item1.feed.feedId !== item2.feed.feedId) {
                result = Constants.feedRestrictions.filter((res) => {

                    return (((res.feedId1 === item1.feed.feedId && res.feedId2 === item2.feed.feedId)
                        || (res.feedId1 === item2.feed.feedId && res.feedId2 === item1.feed.feedId))
                    )
                })
                if (!result || result.length === 0) {
                    let message1 = `${item1.feed.feedName} cannot combine with ${item2.feed.feedName}`
                    let message2 = `${item2.feed.feedName} cannot combine with ${item1.feed.feedName}`
                    item1.errors.pushUnique(message1);
                    item2.errors.pushUnique(message2);
                    combineError = true;
                }


                list[item1.activeOddFieldId] = item1;
                list[item2.activeOddFieldId] = item2;
            }




        }
    }

    return combineError;

}

const checkVirtualTypes = (list) => {
    let error;

    let virtualResult = Object.values(list).filter((item) => Enums.VirtualFeedIds.includes(item.feed.feedId));
    let staticResult = Object.values(list).find((item) => item.feed.feedId === Enums.Feed.Static);
    let liveResult = Object.values(list).find((item) => item.feed.feedId === Enums.Feed.Live);

    if ((virtualResult && (!staticResult || !liveResult))) {
        if (virtualResult.length > 1) {
            let virtualTypes = virtualResult.map((item) => item.feed.feedId);
            virtualTypes = [...new Set(virtualTypes)];//distinct types
            if (virtualTypes && virtualTypes.length > 1) {
                Object.values(list).map((item) => item.feed.feedId !== 5 && item.feed.feedId !== 6 && item.errors.pushUnique("Cannot combine with different virtual types"));
                error = true;
            }

        }
    }

    return error;
}

const checkLocked = (list) => {
    let lockError;
    Object.values(list).map((item) => {
        if (!item.enableBet || !item.eventEnableBet || !item.oddEnableBet) {
            item.errors = item.errors || [];
            item.errors.pushUnique("Bet is locked");
            lockError = true;
        }
    })

    return lockError;
}
const checkAvailable = (list) => {
    let availableError;
    Object.values(list).map((item) => {
        if (item.notAvailable) {
            item.errors.pushUnique("Bet is no longer available");
            availableError = true;
        }
    })

    return availableError;
}

const calcTotaOdds = (list) => {
    let totalOdds = 0;
    if (Object.values(list).length > 0) {
        totalOdds = Object.values(list).map((item) => item.oddValue).reduce((total, value) => {
            return total * value
        })
    }
    return totalOdds;
}

const calcTotalStake = (list) => {
    let totalAmount = 0;
    if (Object.values(list).length > 0) {
        Object.values(list).map((item) =>
            totalAmount += item.amount
        )
    }
    return totalAmount;
}


const calcPossibleWinning = (amount, totalOdds) => {
    let selectedCurrency = Cookies.get('selectedCurrency');
    let pw = (amount * totalOdds);
    if (selectedCurrency.currencyId == 1) {
        let remain = pw % 5;
        pw = pw - remain;
    }
    return pw;

}

const calacTotalPossibleWin = (list) => {
    let selectedCurrency = Cookies.get('selectedCurrency');
    let totalPossibleWin = 0;
    if (selectedCurrency.currencyId == 1) {
        Object.values(list).map((item) => {
            let remain = (item.amount * item.oddValue) % 5;
            item.possibleFieldWin = (item.amount * item.oddValue) - remain;
            totalPossibleWin += item.possibleFieldWin;
        })

        let remain = totalPossibleWin % 5;
        totalPossibleWin = totalPossibleWin - remain;
    }
    else {
        Object.values(list).map((item) => {
            item.possibleFieldWin = (item.amount * item.oddValue);
            totalPossibleWin += item.possibleFieldWin;
        })
    }
    return totalPossibleWin;
}


export default function* appSaga() {
    yield all([
        fork(init)
    ])
}

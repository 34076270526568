import React from 'react';

const SportIcon = props => {
	const { feedId, sportId, className } = props;

	let imageName = null;
	let image = null;

	if (feedId && sportId) {
		imageName = `${feedId}${sportId}.webp`;
	}

	try {
		image = require('../../resources/sport-icons/' + imageName);
	}
	catch (e) {
		image = require('../../resources/sport-icons/0.webp');
	}

	return (
		<div className={className || 'icon'}><img src={image} /></div>
	)
}

export default SportIcon

import { delay } from 'redux-saga';
import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import { Constants, Utils } from '../../helpers';
import { SignalRTypes, SportEventsActionTypes } from '../actions';


export function* requests() {

	yield takeEvery(SignalRTypes.SportEvents_Response, function* ({ response }) {
		try {
			window.console.response("Events", response)
			const { app } = yield select();

			switch (response.status) {
				case 1:
					let betTypes = Utils.handleBetTypes(response.data.topBetTypes);

					let selectedValues = []

					if (betTypes && betTypes.optionsBetTypes && betTypes.optionsBetTypes.length > 0) {
						for (let i = 0; i < Constants.ColumnsNumber; i++) {
							selectedValues[i] = betTypes.optionsBetTypes[i] && betTypes.optionsBetTypes[i].key
						}
					}
					yield put({
						type: SportEventsActionTypes.SportEvents_Success,
						betTypes: betTypes,
						sportEvents: response.data.sportEvents,
						eventOdds: response.data.eventOdds,
						eventsIds: response.data.sportEventIds,
						upcomingEvents: response.data.upcomingEvents,
						upcomingEventIds: response.data.upcomingEventIds,
						selectedValues: selectedValues,
					})

					yield delay(50);

					yield put({
						type: SportEventsActionTypes.SportEvents_OddFields_Success,
						eventOddFields: response.data.eventOddFields,
					})

					break;

				case 3:

					yield put({
						type: SportEventsActionTypes.SportEvents_Error,
						error: response.message,
					})
					break;


			}

			let item = null;
			let eventId = app.match.params["eventId"];
			let newEventId = `${app.feedId}_${app.match.params.sport}_${eventId}`

			//Refresh page
			if (eventId) {
				item = response.data.sportEvents[newEventId]
			}

			//If no item, dont try load all bet types
			if (!item)
				return;

			//else Set the event item and get all Bettypes of sport event
			//yield put({ type: SportEventsActionTypes.Set_Event, item: item });
			//yield put({ type: SignalRTypes.AllBetTypes_Request })


		} catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.AllBetTypes_Response, function* ({ response }) {
		try {
			let events = {};
			yield put({ type: SportEventsActionTypes.Set_Event, item: response.data.sportEvent });
			if (response.data.sportEvent) {
				events[response.data.sportEvent.key] = response.data.sportEvent
			}
			window.console.response("All BetTypes", response)

			let drillDownTabs = [];
			Object.values(response.data.eventOddGroups).sort(function (a, b) {
				return a.betTypeGroupId - b.betTypeGroupId
			}).map(item => drillDownTabs.pushUnique(item.betTypeDrillDownGroupName))
			switch (response.status) {
				case 1:
					yield put({
						type: SportEventsActionTypes.SportEvents_Success,
						betTypes: {},
						sportEvents: events,
						eventOddGroups: response.data.eventOddGroups,
						eventOddGroupIds: response.data.eventOddGroupIds,
						eventOdds: response.data.eventOdds,
						eventsIds: response.data.sportEvent ? [response.data.sportEvent.key] : [],
						selectedValues: [],
						upcomingEvents: {},
						upcomingEventIds: [],
						drillDownTabs: drillDownTabs
					})

					yield delay(50);

					yield put({
						type: SportEventsActionTypes.SportEvents_OddFields_Success,
						eventOddFields: response.data.eventOddFields,
					})

					break;

				case 3:
					yield put({
						type: SportEventsActionTypes.SportEvents_Error,
						error: response.message,
					})
					break;

			}
		} catch (e) {
			window.log("ERROR", e)
		}
	})

}

export default function* sportSaga() {
	yield all([
		fork(requests),
	])
}

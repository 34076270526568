import { RegisterActionTypes, MembersActionTypes } from '../actions';

const initState = {
	countries: '',
	genders: '',
	memberTitles: '',
	securityQuestions: '',
	message: '',
	loading: false,
	current: 0,
	resendSmsMessage: '',
	registered: false,
	phoneValid: false,
	emailVerified: false,
	emailVerifiedError: null,
	formData: {
		phone: '',
		birthDate: '',
		password: '',
		termsAndConditions: '',
		smsCode: '',
		captcha: '',
		secondPhone: ''
	}
}

const register = (state = initState, action) => {
	switch (action.type) {
		case RegisterActionTypes.Init: {
			if (state !== initState) {
				return {
					...initState
				}
			}
			else {
				return {
					...state
				}
			}
		}
		case RegisterActionTypes.Validate_Email_Request:
			return {
				...state,
				loading: true
			}
		case RegisterActionTypes.Validate_Email_Success:
			return {
				...state,
				loading: false,
				emailVerified: true,
				message: ''
			}
		case RegisterActionTypes.Validate_Email_Error:
			return {
				...state,
				loading: false,
				emailVerified: false,
				emailVerifiedError: action.message
			}
		case RegisterActionTypes.Register_Entities_Success:
			return {
				...state,
				countries: action.data.countries,
				genders: action.data.genders,
				memberTitles: action.data.memberTitles,
				securityQuestions: action.data.securityQuestions
			}
		case RegisterActionTypes.Register_Entities_Error:
			return {
				...state,
				message: action.message
			}
		case RegisterActionTypes.Validate_Phone_Request:
			return {
				...state, loading: true
			}
		case RegisterActionTypes.Validate_Phone_Success:
			return {
				...state,
				message: null,
				resendSmsMessage: action.message,
				current: action.current,
				loading: false
			}
		case RegisterActionTypes.Send_Register_Data:
			return {
				...state,
				formData: action.data,
			}
		case RegisterActionTypes.Validate_Phone_Error:
			return {
				...state,
				message: action.message,
				resendSmsMessage: null,
				loading: false
			}
		case RegisterActionTypes.Validate_Add_Phone_Request:
			return {
				...state,
				loading: true
			}
		case RegisterActionTypes.Validate_Add_Phone_Success:
			return {
				...state,
				message: '',
				resendSmsMessage: action.message,
				loading: false,
				phoneValid: true
			}
		case RegisterActionTypes.Validate_Add_Phone_Error:
			return {
				...state,
				message: action.message,
				resendSmsMessage:null,
				loading: false
			}
		case RegisterActionTypes.Register_Member_Request:
			return {
				...state,
				loading: true
			}
		case RegisterActionTypes.Register_Member_Success:
			return {
				...state,
				current: action.current,
				message: '',
				loading: false
			}
		case RegisterActionTypes.Register_Member_Error:
			return {
				...state,
				message: action.message,
				loading: false
			}
		case RegisterActionTypes.Add_Member_Phone_Request:
			return {
				...state,
				loading: true
			}
		case RegisterActionTypes.Add_Member_Phone_Success:
			return {
				...state,
				message: '',
				current: action.current,
				loading: false
			}
		case RegisterActionTypes.Add_Member_Phone_Error:
			return {
				...state,
				message: action.message,
				loading: false
			}
		case MembersActionTypes.Member_Details_Request:
			return {
				...state,
				loading: true
			}
		case MembersActionTypes.Member_Details_Success:
			return {
				...state,
				registered: true,
				message: '',
				loading: false
			}
		case MembersActionTypes.Member_Details_Error:
			return {
				...state,
				message: action.message,
				loading: false
			}
		case MembersActionTypes.Skip:
			return {
				...state,
				current: state.current + 1
			}
		default:
			return state
	}
}
export default register;
/* eslint-disable jsx-a11y/alt-text */
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Images } from "../";
import { Enums, Utils, SessionStorage, Cookies } from "../../helpers";
import { changeFeed } from "../../redux/actions/appActions";
import { getCurrencies } from "../../redux/actions/generalActions";
import { switchMode } from "../../redux/actions/appActions";
import { setJackpotWonMessages } from "../../redux/actions/jackpotActions";
import { setMemberApprovedJackpotWinnings } from "../../redux/actions/membersActions";
import Connection from "../common/Connection";
import ModeSwitcher from "../common/ModeSwitcher";
import Search from "../common/Search";
import Signin from "./Signin";

class Header extends React.Component {
  state = {
    time: moment(),
    messageCount: 0,
  };

  async componentDidMount() {
    if (this.props.serverTime) {
      this.setState({ time: moment(this.props.serverTime) });
    }
    this.props.getCurrencies();
  }

  componentDidUpdate(prevProps) {
    let {
      winningMessage,
      jackpotWinningApproved,
      setMemberApprovedJackpotWinnings,
    } = this.props;
    let jackpotId = Cookies.get("jackpotId");

    if (
      this.props.serverTime &&
      this.props.serverTime !== prevProps.serverTime
    ) {
      this.setState({ time: moment(this.props.serverTime) });
      clearInterval(this.interval);
      this.interval = setInterval(
        () =>
          this.setState({ time: moment(this.state.time).add(1, "minutes") }),
        60000
      );
    }
    if (
      winningMessage.length > 0 &&
      this.state.messageCount !== winningMessage.length
    ) {
      let iframeElement = document.getElementById("slot-frame");
      const message = {
        name: "gdk:ui",
        args: {
          name: "autogame",
          data: {
            active: false,
            rounds: Infinity,
          },
        },
      };
      iframeElement &&
        iframeElement.contentWindow &&
        iframeElement.contentWindow.postMessage(message, "*");
      winningMessage.map((message, index) => {
        this.setState({ messageCount: this.state.messageCount + 1 });
        let modal = {
          key: `gameWin${index}`,
          type: "text",
          text: message,
          visible: true,
          onOK: () => {
            Utils.closeModal({ id: `gameWin${index}` });
            winningMessage.splice(index, 1);
            this.props.setJackpotWonMessages(winningMessage);
            this.setState({ messageCount: this.state.messageCount - 1 });
          },
          okLabel: "OK",
        };
        Utils.openModal(modal);
      });
    }

    if (
      jackpotWinningApproved &&
      jackpotWinningApproved !== prevProps.jackpotWinningApproved
    ) {
      let modal = {
        key: "winningApproved",
        type: "text",
        text: "Your Jackpot Winning Is Approved",
        visible: true,
        onOK: () => {
          Utils.closeModal({ id: "winningApproved" });
          setMemberApprovedJackpotWinnings(jackpotId);
        },
        okLabel: "OK",
      };
      Utils.openModal(modal);
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  navigate = (
    route,
    feedId = Enums.Feed.Static,
    feedName = Enums.FeedNames.Static
  ) => {
    const { changeFeed, history } = this.props;
    if (history.location.pathname != route) {
      history.push(`${route}`);
      changeFeed(feedId.toString(), feedName);
    }
  };

  render() {
    const {
      feedName,
      feedId,
      jackpotWinningApproved,
      winningMessage,
      setMemberApprovedJackpotWinnings,
      switchMode
     
    } = this.props;
    return (
      <div className="container desktop">
        <div className="top">
          <div className="size">
            <ModeSwitcher switchMode={switchMode} />
            {/* <div
              className="link light-on"
              onClick={() => {
                this.navigate("/contact", Enums.Feed.ContactUs);
              }}
            >
              Contact Us
            </div> */}
            <Connection  />
            <div className="time">
              {Utils.formatDate(this.state.time, "HH:mm a").toUpperCase()}
            </div>
          </div>
          <div className="top-search">
            <Search />
          </div>
        </div>
        <div className="top-menu">
          <div className="size">
            <div
              className="logo"
              onClick={() =>
                this.navigate(
                  `/sport/${Enums.Feed.Static}/1/Soccer`,
                  Enums.Feed.Home,
                  Enums.FeedNames.Home
                )
              }
            >
              <img style={{height:"70px" , width:"170px"}}  alt="Winners" src={Images.LogoWinners} />
            </div>
            <div className="title-wrapper">
              <div
                className={`title light-on ${
                  feedId === Enums.Feed.Static.toString() ? "selected" : ""
                }`}
                onClick={() =>
                  this.navigate(
                    `/sport/${Enums.Feed.Static}/1/Soccer`,
                    Enums.Feed.Static,
                    Enums.FeedNames.Static
                  )
                }
              >
                Sports
              </div>
              <div
                className={`title light-on ${
                  feedId === Enums.Feed.Live.toString() ? "selected" : ""
                }`}
                onClick={() =>
                  this.navigate(
                    `/live/${Enums.Feed.Live}/1/Soccer`,
                    Enums.Feed.Live,
                    Enums.FeedNames.Live
                  )
                }
              >
                Live
              </div>
              <div
                className={`title light-on ${
                  feedId === Enums.Feed.VFL.toString() ? "selected" : ""
                }`}
                onClick={() =>
                  this.navigate(
                    "/virtual/7/1",
                    Enums.Feed.VFL,
                    Enums.FeedNames.VFL
                  )
                }
              >
                Virtual
              </div>
              <div
                className={`title light-on ${
                  feedName === Enums.FeedNames.Slots ? "selected" : ""
                }`}
                onClick={() =>
                  this.navigate(
                    "/slots",
                    Enums.Feed.Slots,
                    Enums.FeedNames.Slots
                  )
                }
              >
                Slots
              </div>
              <div
                className={`title light-on ${
                  feedId === Enums.Feed.Fixture.toString() ? "selected" : ""
                }`}
                onClick={() => this.navigate("/fixture", Enums.Feed.Fixture)}
              >
                Spin & Win
              </div>
            </div>

            <Signin history={this.props.history} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    feedId: state.app.feedId,
    serverTime: state.app.serverTime,
    currentGame: state.slots.currentGame,
    winningMessage: state.jackpot.winningMessage,
    currentWin: state.jackpot.currentWin,
    jackpotWinningApproved: state.jackpot.jackpotWinningApproved,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeFeed: (feedId, feedName) => dispatch(changeFeed(feedId, feedName)),
  getCurrencies: () => dispatch(getCurrencies()),
  setJackpotWonMessages: (messages) =>
    dispatch(setJackpotWonMessages(messages)),
  setMemberApprovedJackpotWinnings: (jackpotId) =>
    dispatch(setMemberApprovedJackpotWinnings(jackpotId)),
    switchMode:()=>    dispatch(switchMode())

});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import moment from "moment";
import { Constants, Events } from "./";
var deflate = require("deflate-js");

window.log = (...params) => {
  if (Utils.InDev) {
    const datetime = `[${moment().format("DD/MM/YYYY HH:mm:ss.SSS")}] `;
  }
};

window.console.request = (...args) => {
  if (Utils.InDev) {
    const datetime = `[${moment().format("DD/MM/YYYY HH:mm:ss.SSS")}]`;

    let params = "";
    let objs = {};

    for (let i = 0; i < args.length; i++) {

      if (typeof args[i] == "string") {
        params += args[i];
      }

      if (typeof args[i] == "object") {
        objs[i] = args[i];
      }
    }
  }
};

window.console.response = (...args) => {
  if (Utils.InDev) {
    const datetime = `[${moment().format("DD/MM/YYYY HH:mm:ss.SSS")}]`;

    let params = "";
    let objs = {};

    for (let i = 0; i < args.length; i++) {

      if (typeof args[i] == "string") {
        params += args[i];
      }

      if (typeof args[i] == "object") {
        objs[i] = args[i];
      }
    }
  }
};

/**
 * Number.prototype.format(n, x)
 *
 * @param integer n: length of decimal
 * @param integer x: length of sections
 */
Number.prototype.format = function (n, x) {
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
};

Array.prototype.pushUnique = function (item) {
  if (this.indexOf(item) == -1) {
    this.push(item);
    return true;
  }
  return false;
};

export default class Utils {
  static cloneObjectDiffLock = false;

  static get InDev() {
    return (
      document.location.search.indexOf("ddd=1") > -1 ||
      document.location.host.indexOf("localhost") > -1
    );
  }

  static get IsMobile() {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  static inflate = (data) => {
    try {
      const buffer = Buffer.from(data, "base64");

      let str = deflate
        .inflate(buffer)
        .map((byte) => {
          return String.fromCharCode(byte);
        })
        .join("");

      return JSON.parse(str);
    } catch (e) {
      window.log("INFLATE", e);
    }
    return null;
  };

  static cloneObjectDiff = (o1, objectWithNewChanges) => {
    if (!Utils.cloneObjectDiffLock) {
      //	Utils.cloneObjectDiffLock = true;
      for (var prop in o1) {
        if (
          objectWithNewChanges[prop] != undefined &&
          objectWithNewChanges[prop] != o1[prop]
        ) {
          o1[prop] = objectWithNewChanges[prop];
        }
      }
    }
  };

  static getMaxObjByAttribute = (object, attrName) => {
    return Math.max.apply(
      Math,
      Object.keys(object).map((key) => {
        return object[key][attrName];
      })
    );
  };

  static sortArrayByAttribute = (array, attrName) => {
    let clonedArr = Utils.deepClone(array);
    return clonedArr.sort((a, b) => a[attrName] - b[attrName]);
  };

  static deepClone = (array) => {
    return array && JSON.parse(JSON.stringify(array));
  };

  static formatDate(date, format) {
    return moment.utc(date).format(format || `hh: mm A DD/MM/YYYY`);
  }
  static formatSecondsToMins(seconds) {
    return moment.utc(seconds * 1000).format("mm:ss");
  }

  static getGameTimeBySeconds(time) {
    let minutes = Math.floor(time / 60).toString();
    let seconds = (time - minutes * 60).toString();
    minutes = minutes.length == 1 ? `0${minutes}` : minutes;
    seconds = seconds.length == 1 ? `0${seconds}` : seconds;
    return `${minutes}: ${seconds}`;
  }

  static formatSecondsToTime(time) {
    let duration = moment.duration(time, "seconds");
    let hours = duration.get("hours").toString();
    hours = hours.length == 1 ? `0${hours}` : hours;
    let minutes = duration.get("minutes").toString();
    minutes = minutes.length == 1 ? `0${minutes}` : minutes;
    let seconds = duration.get("seconds").toString();
    seconds = seconds.length == 1 ? `0${seconds}` : seconds;

    return `${hours}: ${minutes}: ${seconds}`;
  }

  static handleBetTypes(betTypes) {
    const match =
      betTypes &&
      betTypes.length > 0 &&
      betTypes.filter((item, index) => {
        return item.betTypeId == 1;
      });

    const filteredBetTypes =
      betTypes &&
      betTypes.length > 0 &&
      betTypes.filter((item, index) => {
        return item.betTypeId != 1;
      });

    return {
      optionsBetTypes: filteredBetTypes,
      match: match && match.length > 0 ? match[0] : null,
    };
  }

  static generateGroupKeys(data) {
    let key = "";
    let keyList = [];
    if (data.Feed) {
      key += data.Feed.FeedId ? `F${data.Feed.FeedId}` : "";
      keyList.push(key);
    }
    if (data.Sport) {
      key += data.Sport.SportId ? `S${data.Sport.SportId}` : "";
      keyList.push(key);
    }
    if (
      data.Feed &&
      data.Sport &&
      !data.SportCategory &&
      !data.EventId &&
      !data.IsLastMin &&
      !data.Day
    ) {
      key += data.Sport.SportId ? "TopBets" : "";
      keyList.push(key);
    }
    if (data.SportCategory) {
      key += data.SportCategory.SportCategoryId
        ? `C${data.SportCategory.SportCategoryId}`
        : "";
      key += data.TournamentId ? `T${data.TournamentId}` : "";
      keyList.push(key);
    }

    if (data.EventId) {
      key += `E${data.EventId}`;
      keyList.push(key);
    }

    if (data.IsLastMin) {
      key += data.IsLastMin ? `L1` : "";
      keyList.push(key);
    } else {
      if (data.Day) {
        key += data.Day ? `D${data.Day}` : "";
        keyList.push(key);
      }
    }

    return keyList;
  }

  static generateDectionary(arr, id) {
    let dec = {};

    arr.map((item) => {
      dec[item[id]] = { ...item };
    });
    return dec;
  }

  static generateIdsList(arr, id) {
    let ids = [];

    arr.map((item) => {
      ids.push(item[id]);
    });

    return ids;
  }

  static generateEventsDectionary(arr) {
    let dec = {};

    arr.map((item) => {
      dec[`${item.feed.feedId}, ${item.sport.sportId}_${item.eventId}`] = {
        ...item,
      };
    });
    return dec;
  }

  static generateEventsIdsList(arr) {
    let ids = [];

    arr.map((item) => {
      ids.push(`${item.feed.feedId}_${item.sport.sportId}, ${item.eventId}`);
    });

    return ids;
  }

  static generateOddsDectionary(arr) {
    let dec = {};

    arr.map((item) => {
      dec[`${item.betType.betTypeId}_${item.oddSpecialField}`] = { ...item };
    });
    return dec;
  }

  static generateOddsIdsList(arr) {
    let ids = [];

    arr &&
      arr.map((item) => {
        ids.push(`${item.betType.betTypeId}_${item.oddSpecialField}`);
      });

    return ids;
  }

  static getEventId(event) {
    return `${event.feed.feedId}_${event.sport.sportId}_${event.eventId}`;
  }

  static getOddId(odd) {
    return `${odd.betType.betTypeId}_${odd.oddSpecialField}`;
  }
  static getGroupId(group) {
    return `${group.betTypeGroupId}_${group.betTypeGroupName}`;
  }

  static memorySizeOf = (obj) => {
    var bytes = 0;

    function sizeOf(obj) {
      if (obj !== null && obj !== undefined) {
        switch (typeof obj) {
          case "number":
            bytes += 8;
            break;
          case "string":
            bytes += obj.length * 2;
            break;
          case "boolean":
            bytes += 4;
            break;
          case "object":
            var objClass = Object.prototype.toString.call(obj).slice(8, -1);
            if (objClass === "Object" || objClass === "Array") {
              for (var key in obj) {
                if (!obj.hasOwnProperty(key)) continue;
                sizeOf(obj[key]);
              }
            } else bytes += obj.toString().length * 2;
            break;
        }
      }
      return bytes;
    }

    function formatByteSize(bytes) {
      if (bytes < 1024) return bytes + " bytes";
      else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + " KiB";
      else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + " MiB";
      else return (bytes / 1073741824).toFixed(3) + " GiB";
    }

    return formatByteSize(sizeOf(obj));
  };

  static openModal = (args) => {
    args.visible = args.visible == null ? true : args.visible;
    Events.publish(Constants.modal, args);
  };

  static closeModal = (args) => {
    args.visible = false;
    Events.publish(Constants.modal, args);
  };
}

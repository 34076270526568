import { ContactUsActionTypes } from '../actions';

const initState = {
	subjects: null,
	text: null,
    message: null,
    loading: false,
    resetFields: false
}

const contactUs = (state = initState, action) => {
    switch (action.type) {
        case ContactUsActionTypes.Contact_Data_Request:
            return {
                ...state,
                loading: true,
                message: false
            }
        case ContactUsActionTypes.Contact_Data_Success:
            return {
                ...state,
                message: action.message,
                loading: false,
                resetFields: action.resetFields
            }
        case ContactUsActionTypes.Contact_Data_Error:
            return {
                ...state,
                loading: false,
                message: action.message,
                resetFields: action.resetFields
            }
		case ContactUsActionTypes.Subjects_Success:
            //todo - set message from server 
            return {
                ...state,
				subjects: action.data,
				text: action.text
            }
        default:
            return state
    }
}
export default contactUs;
import { SignalRTypes, SportEventsActionTypes, SearchActionTypes } from '../actions';

const initState = {
	error: null,
	loadingSportEvents: true,
	sportEvents: {},
	eventsIds: [],
	eventOdds: {},
	eventOddFields: {},
	eventData: null,
	betTypes: null,
	selectedValues: [],
	eventOddGroups: {},
	eventOddGroupIds: [],
	upcomingEvents: {},
	upcomingEventIds: [],
	drillDownTabs: [],
	searchResult: []
};

const sportEvents = (state = initState, action) => {

	switch (action.type) {

		case SportEventsActionTypes.Init:
			return {
				...state,
				error: null,
				loadingSportEvents: true,
				sportEvents: {},
				eventsIds: [],
				eventOdds: {},
				eventOddFields: {},
				eventData: null,
				betTypes: null,
				selectedValues: [],
				eventOddGroups: {},
				eventOddGroupIds: [],
				upcomingEvents: {},
				upcomingEventIds: [],
				drillDownTabs: []

			}

		case SignalRTypes.SportEvents_Clear:
			return {
				...state,
				error: null,
				loadingSportEvents: false,
				sportEvents: {},
			}

		case SignalRTypes.SportEvents_Request:
		case SignalRTypes.TopBets_Request:
		case SignalRTypes.SportEvents_By_Date_Request:
			return {
				...state,
				error: null,
				loadingSportEvents: true,
				sportEvents: {},
				eventsIds: [],
				eventOdds: {},
				eventOddFields: {},
				eventData: null,
				betTypes: null,
				selectedValues: [],
				eventOddGroups: {},
				eventOddGroupIds: [],
				upcomingEvents: {},
				upcomingEventIds: [],
			}

		case SignalRTypes.AllBetTypes_Request:
			return {
				...state,
				sportEvents: {},
				eventsIds: [],
				eventOdds: {},
				eventOddFields: {},
				betTypes: null,
				selectedValues: [],
				eventOddGroups: {},
				eventOddGroupIds: [],
				upcomingEvents: {},
				upcomingEventIds: [],
				error: null,

			}

		case SignalRTypes.SportEvents_By_Date_Request:
			return {
				...state,
				sportEvents: {},
				eventsIds: [],
				eventOdds: {},
				eventOddFields: {},
				betTypes: null,
				eventData: null,
				selectedValues: [],
				eventOddGroups: {},
				eventOddGroupIds: [],
				upcomingEvents: {},
				upcomingEventIds: [],
				error: null,
				loadingSportEvents: true,


			}


		case SportEventsActionTypes.SportEvents_Success:
			return {
				...state,
				loadingSportEvents: false,
				sportEvents: action.sportEvents,
				eventOdds: action.eventOdds,
				betTypes: action.betTypes,
				eventsIds: action.eventsIds,
				selectedValues: action.selectedValues,
				eventOddGroups: action.eventOddGroups,
				eventOddGroupIds: action.eventOddGroupIds,
				upcomingEvents: action.upcomingEvents,
				upcomingEventIds: action.upcomingEventIds,
				error: null,
				drillDownTabs: action.drillDownTabs

			}

		case SportEventsActionTypes.SportEvents_Events_FeedChange:
			{
				return {
					...state,
					sportEvents: action.sportEvents,
					eventsIds: action.eventsIds,

				}
			}
			
		case SportEventsActionTypes.SportEvents_OddFields_Success:
			{
				return {
					...state,
					eventOddFields: action.eventOddFields,
				}
			}

		case SportEventsActionTypes.SportEvents_Odds_Success:
			{
				return {
					...state,
					eventOdds: action.eventOdds,
				}
			}
		case SportEventsActionTypes.SportEvents_Events_Success:
			{
				return {
					...state,
					sportEvents: action.sportEvents,
					eventsIds: action.eventsIds,

				}
			}
		case SportEventsActionTypes.SportEvents_Upcoming_Events_Success:
			{
				return {
					...state,
					upcomingEvents: action.upcomingEvents,
					upcomingEventIds: action.upcomingEventIds
				}
			}
		case SportEventsActionTypes.SportEvents_Groups_Success:
			{
				return {
					...state,
					eventOddGroups: action.eventOddGroups,
					eventOddGroupIds: action.eventOddGroupIds,
					drillDownTabs: action.drillDownTabs

				}
			}
		case SportEventsActionTypes.SportEvents_BetTypes_Success:
			{
				return {
					...state,
					betTypes: action.betTypes,

				}
			}


		case SportEventsActionTypes.SportEvents_Error:
			return {
				...state,
				loadingSportEvents: false,
				sportEvents: {},
				eventOdds: {},
				betTypes: [],
				eventsIds: [],
				selectedValues: [],
				eventOddGroups: {},
				eventOddGroupIds: [],
				upcomingEvents: {},
				upcomingEventIds: [],
				eventOddFields: {},
				error: action.error,
			}

		case SportEventsActionTypes.Set_Event:
			return {
				...state,
				eventData: action.item
			}


		case SportEventsActionTypes.SportEvents_Update:
			return {
				...state,
				sportEvents: action.sportEvents,
			}

		case SportEventsActionTypes.SportEvents_OnDropDownsChange:
			return {
				...state,
				selectedValues: action.selectedValues,
			}
		case SearchActionTypes.GetSearchedData_Response:
			return {
				...state,
				searchResult: action.response
			}

		default:
			return { ...state };

	}
}

export default sportEvents;
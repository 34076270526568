import React from 'react'
import { Tabs } from 'antd';

const TabPane = Tabs.TabPane;

class MyBetsTab extends React.Component {

	render() {
		return (
			<div className="container my-bets-tab">
				My Bets
			</div>
		)
	}
}

export default MyBetsTab
import { Spin, Icon } from "antd";
import React from "react";

const Loading = (props) => {
  const { text, className } = props;
  const SpinIcon = <Icon type="loading" spin />;

  return (
    <div className={className || "loading"}>
      <div className="spiner">
        <Spin indicator={SpinIcon} />
      </div>
      <div className="text">{text || "Loading..."}</div>
    </div>
  );
};

export default Loading;

import React, { Component } from "react";
import { Input, Form, Button, Tooltip, Popconfirm, Label } from "antd";
import { Images } from "../../components";
import { AppConfig } from "../../helpers";
import { connect } from "react-redux";
import { init } from "../../redux/actions/authActions";
import {
  browserName,
  fullBrowserVersion,
  osName,
  osVersion,
  isMobile,
  isTablet,
} from "react-device-detect";
import {
  signIn,
  signOut,
  setShowLoginPopConfirm,
} from "../../redux/actions/authActions";
import { navigate } from "../../redux/actions/appActions";

class LoginForm extends Component {
  state = {
    validUsername: true,
    tooltipUsername: null,
    validPassword: true,
    tooltipPassword: null,
    showRegister: false,
    showForgotPassword: false,
    loginPopoverOpen: false,
    showLoginError: false,
    showCurrencyPopup: false,
    setCurrency: "",
    bounce: false,
    accountVisible: false,
    showBalance: true,
    navigate: false,
  };
  

  handleSubmit =  (e, isLoginConfirm) => {
    const { form, signIn , message ,  history } = this.props;
    this.setState({
      showLoginError: true,
    });
    e.preventDefault();
    e.stopPropagation();
    form.validateFields(["userName", "password"], (err, values) => {
      if (!err) {
        this.setState({
          validUsername: true,
          validPassword: true,
          tooltipUsername: null,
          tooltipPassword: null,
        });

        let data = {
          userName: values.userName,
          password: values.password,
          platform: isTablet ? "Tablet" : isMobile ? "Mobile" : "Desktop",
          os: `${osName} ${osVersion}`,
          browser: `${browserName} ${fullBrowserVersion}`,
        };
        if (isLoginConfirm) {
          data.isSignedInConfirm = true;
          this.props.setShowLoginPopConfirm(false);
        }

        signIn(data);
        console.log("message2" , message);
     
      } else {
        if (err.userName && err.userName.errors.length > 0) {
          this.setState({
            validUsername: false,
            tooltipUsername: err.userName.errors[0].message,
          });

          setTimeout(() => {
            this.setState({
              validUsername: true,
              tooltipUsername: null,
            });
          }, 4000);
        }

        if (err.password && err.password.errors.length > 0) {
          this.setState({
            validPassword: false,
            tooltipPassword: err.password.errors[0].message,
          });

          setTimeout(() => {
            this.setState({
              validPassword: true,
              tooltipPassword: null,
            });
          }, 4000);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { message, loading, showLoginPopConfirm , signedIn , history } = this.props;
    console.log("message" , message);

    const {
      validUsername,
      validPassword,
      tooltipUsername,
      tooltipPassword,
      showLoginError,
      loginPopoverOpen,
    } = this.state;
    {
      console.log("showLoginError", showLoginError);
    }
    signedIn && this.setState({navigate:true}) 
    if(this.state.navigate){

      console.log("hiiiiiiiiiiiiii");
      console.log("history" , history);
      history.push("/");
    }
    return (
      <div>
        <div className="login-form-container">
          <p className="login-form-headline">Login</p>
          {AppConfig.ShowSignInAndRegistersBtn ? (
            <div className="login">
              <Form
                layout="inline"
                onSubmit={this.handleSubmit}
                autoComplete="off"
              >
                <div className="login-form-items-container">
                  <Form.Item
                    label="Email or Phone Number"
                    className="form-inputs-container"
                  >
                    {getFieldDecorator("userName", {
                      rules: [{}],
                    })(
                      <div>
                        <Tooltip
                          overlayClassName="error"
                          trigger={["focus"]}
                          placement="topLeft"
                          // title={tooltipUsername}
                          visible={!validUsername}
                        ></Tooltip>

                        <Input
                          onChange={() => {
                            this.props.init();
                            this.setState({
                              showLoginError: false,
                            });
                          }}
                          className="username"
                          placeholder="Email or Phone or Number"
                        />
                      </div>
                    )}
                  </Form.Item>
                  <Form.Item label="Password" className="form-inputs-container">
                    {getFieldDecorator("password", {
                      rules: [{}],
                    })(
                      <div className="login-password-container">
                        <Tooltip
                          overlayClassName="error"
                          trigger={["focus"]}
                          placement="topLeft"
                          title={tooltipPassword}
                          visible={!validPassword}
                        ></Tooltip>
                        <Input
                          onChange={() => {
                            this.props.init();
                            this.setState({
                              showLoginError: false,
                            });
                          }}
                          id="password-input"
                          type="password"
                          className="password"
                          placeholder="Password"
                        />
                        <img
                          id="password-icon"
                          className="show-password-icon"
                          src={Images.ShowPasswordIcon}
                          onClick={() =>
                            this.props.showPassword("password-input", "password-icon")
                          }
                        />
                      </div>
                    )}
                  </Form.Item>
                </div>
                <div className="login-form-button-container">
                  {showLoginPopConfirm ? (
                    <Form.Item>
                      <Popconfirm
                        overlayClassName="login-confirm-popover"
                        placement="bottomLeft"
                        title={
                          "You are already signed in on different machine, do you want to continue?"
                        }
                        onConfirm={(e) => this.handleSubmit(e, true)}
                        onCancel={() =>
                          this.props.setShowLoginPopConfirm(false)
                        }
                        okText="Yes"
                        cancelText="No"
                        visible={showLoginPopConfirm}
                      >
                        <Button
                          className="info"
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                        >
                          {loading ? "" : "Login"}
                          {message && (
                            <Tooltip
                              overlayClassName="error"
                              //trigger={['focus']}
                              placement="bottom"
                              title={message}
                              visible={showLoginError}
                            ></Tooltip>
                          )}
                        </Button>
                      </Popconfirm>
                    </Form.Item>
                  ) : (
                    <Form.Item>
                      <Button
                        className="info"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        {loading ? "" : "Login"}
                        {message && (
                          <Tooltip
                            overlayClassName="error"
                            //trigger={['focus']}
                            placement="bottom"
                            title={message}
                            visible={showLoginError}
                          ></Tooltip>
                        )}
                      </Button>
                    </Form.Item>
                  )}
                </div>
              </Form>
              <div className="login-forget-password-container">
                <span
                  className="forgot light-on"
                  onClick={this.props.onClickForgotPassword}
                >
                  Forgot Password?
                </span>
              </div>
            </div>
          ) : (
            "Coming soon"
          )}
        </div>
      </div>
    );
  }
}
const WrappedSigninForm = Form.create({ name: "Signin_Form" })(LoginForm);
const mapStateToProps = (state, props) => {
  return {
    signedIn: state.auth.signedIn,
    message: state.auth.message,
    loading: state.auth.loading,
    balance: state.member.balance,
    selectedCurrency: state.member.selectedCurrency,
    showLoginPopConfirm: state.auth.showLoginPopConfirm,
  };
};

const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(init()),
  signOut: () => dispatch(signOut()),
  signIn: (data) => dispatch(signIn(data)),
  setShowLoginPopConfirm: (value) => dispatch(setShowLoginPopConfirm(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WrappedSigninForm);

// message: state.auth.message,
// loading: state.auth.loading,
// showLoginPopConfirm: state.auth.showLoginPopConfirm,

import { Badge, Drawer, Menu, Icon, SubMenuSubMenu } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Loading, SportIcon } from "../";
import { CountryCodes, Enums, AppConfig } from "../../helpers";
import { openMobileMenu, changeFeed } from "../../redux/actions/appActions";
import {
  init,
  loadSportCategories,
  removeDisabledCategoriesTournaments,
  setTounament,
} from "../../redux/actions/categoriesActions";
import {
  loadSportTypes,
  removeDisabledSportTypes,
  setSport,
} from "../../redux/actions/sportTypesActions";
import Images from "./Images";
import Search from "./Search";
import SecondarySearchBar from "./SecondarySearchBar";

const SubMenuTitle = (props) => {
  const { item } = props;
  return (
    <div>
      <span
        className={
          "flag-icon flag-icon-" +
          getCountryCode(item.categoryDisplayName || "")
        }
      />
      <span className="nav-country">{item.categoryDisplayName}</span>
    </div>
  );
};

const Title = (props) => {
  const { feedId, sportId, text } = props;

  return (
    <div className="title">
      <SportIcon feedId={feedId} sportId={sportId} />
      <div className="text">{text}</div>
    </div>
  );
};

const getCountryCode = (name) => {
  let result = CountryCodes.Codes.filter((item) => {
    return item.name.toLowerCase() == name.toLowerCase();
  });

  return result && result.length > 0 ? result[0].code.toLowerCase() : "eu";
};

const MenuItem = (props) => {
  const { item, onClick, title, showFlag } = props;

  return (
    <div onClick={onClick} className="nav-div">
      {showFlag && (
        <div
          className={
            "flag-icon flag-icon-" +
            getCountryCode(item.sportCategory.categoryDisplayName)
          }
        />
      )}
      <div className="nav-text">{title}</div>
      <Badge
        count={item.tournamentActiveEvents}
        className="nav-badge"
        style={{ backgroundColor: "#52c41a" }}
      />
    </div>
  );
};

class MenuTree extends React.Component {
  state = {
    openKeys: [],
    selectedKeys: [],
    searchText: "",
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.appMatch &&
      prevProps.appMatch.url !== this.props.appMatch.url
    ) {
      this.handleOpenTree();
    }
    if (
      (prevProps.sportTypes.length > 0 && this.props.sportTypes.length === 0) ||
      (!prevProps.connected && this.props.connected)
    ) {
      let request = {
        Data: {
          Feed: {
            FeedId: this.props.appMatch.url.includes("account")
              ? 6
              : Number(this.props.match.params.feed),
          },
        },
      };
      this.props.loadSportTypes(request);
    }
  }

  componentDidMount() {
    const { loadSportTypes, match, connected } = this.props;

    if (connected) {
      let request = {
        Data: {
          Feed: {
            FeedId: match.url.includes("account")
              ? 6
              : Number(match.params.feed),
          },
        },
      };
      loadSportTypes(request);
    }
    this.handleOpenTree();
  }

  handleOpenTree() {
    const { tournamentData, appMatch, match } = this.props;

    let tempMatch = appMatch ? appMatch : match;
    let openKeys = [];
    let selectedKeys = [];
    let sport = tempMatch.params.sport;
    let feed = tempMatch.params.feed;
    let section = tempMatch.params.section;
    let country = tempMatch.params.country;
    let tournament = tempMatch.params.tournament;
    let day = tempMatch.params.day;
    openKeys.push(`${sport}${feed}`);
    openKeys.push(section);
    openKeys.push(`C${country}`);
    day !== undefined && selectedKeys.push(`today`);
    tournament && selectedKeys.push(`${country}${tournament}`);

    tournamentData &&
      openKeys.push(
        `${tournamentData.sportCategory.sportCategoryId}${tournamentData.sportCategory.sportCategoryDisplayName}`
      );

    this.setState({
      openKeys,
      selectedKeys,
    });
  }

  navigate = (
    route,
    feedId = Enums.Feed.Static,
    feedName = Enums.FeedNames.Static
  ) => {
    const { changeFeed, history } = this.props;
    if (history.location.pathname != route) {
      history.push(`${route}`);
      changeFeed(feedId.toString(), feedName);
    }
  };

  onSearchChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  handleFilter = (category, searchText) => {
    const itemName = category.categoryDisplayName.toLowerCase();
    const sT = searchText.toLowerCase();
    if (sT.length === 0) return true;
    if (itemName.indexOf(sT) > -1) {
      return true;
    } else {
      return false;
    }
  };

  onMenuClick = (section, item) => {
    const { openMobileMenu, history, setTounament, match } = this.props;
    openMobileMenu(false);
    if (match.url.includes("account")) {
      history.push(
        `/sport/${item.feed.feedId}/${item.sport.sportId}/${item.sport.sportDisplayName}/events/${section}/${item.sportCategory.sportCategoryId}/${item.sportCategory.categoryDisplayName}/${item.tournamentId}/${item.tournamentDisplayName}`
      );
    } else {
      history.push(
        `${match.url}/events/${section}/${item.sportCategory.sportCategoryId}/${item.sportCategory.categoryDisplayName}/${item.tournamentId}/${item.tournamentDisplayName}`
      );
    }

    setTounament(item);
    removeDisabledCategoriesTournaments();
  };

  onTodayClick = (day = "0", isLastMin = false) => {
    const { openMobileMenu, history, match } = this.props;
    openMobileMenu(false);

    history.push(`${match.url}/today/${day}`);
    removeDisabledCategoriesTournaments();
  };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    ); //get last open key
    if (
      this.props.sportTypes.findIndex(
        (item) => `${item.sport.sportId}${item.feed.feedId}` == latestOpenKey
      ) === -1
    ) {
      // check if not exist in sportTypes keys
      this.setState({ openKeys }); //kep openKeys
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [], // init openKeys with the newsport
      });
    }
  };

  onTitleClick(input, sport) {
    const { sportData } = this.props;
    if (sport.sport.sportId != sportData.sport.sportId) {
      this.onSportTypeClick(sport);
    }
    this.setState({ selectedSport: sport.sport.sportId });
  }

  onSportTypeClick = (item) => {
    const {
      history,
      setSport,
      feedName,
      loadSportCategories,
      removeDisabledSportTypes,
      feedId,
      match,
    } = this.props;
    if (
      (item.feed.feedId == 6 && item.sport.sportId == 1) ||
      item.feed.feedId == 5
    ) {
      history.push(
        `/${feedName}/${feedId}/${item.sport.sportId}/${item.sport.sportDisplayName}`
      );
    }
    //Go to today
    else {
      history.push(
        `/${feedName}/${feedId}/${item.sport.sportId}/${item.sport.sportDisplayName}/today/0`
      );
    }

    setSport(item);
    removeDisabledSportTypes();

    const request = {
      Data: {
        Feed: { FeedId: Number(item.feed.feedId) },
        Sport: { SportId: Number(item.sport.sportId) },
      },
    };
    loadSportCategories(request);
  };

  onOpenStats = () => {
    window.open(
      //"https://s5.sir.sportradar.com/sportbookcloud/en",
      `${AppConfig.StatisticsAddress.Domain}?path=${AppConfig.StatisticsAddress.Menu}`,
      "_blank",
      "toolbar=no,scrollbars=no,resizable=no,top=100,left=100,width=1000,height=1000"
    );
  };

  renderCategories = (
    categories,
    topCategories,
    sportTypes,
    categoriesError
  ) => {
    const { openKeys, selectedKeys } = this.state;
    const { loadingSportCategories, sportData, feedName } = this.props;
    let sportId;
    if (sportData) sportId = sportData.sport.sportId;
    return (
      <div id="side-menu">
      <Menu
        className="menu"
        mode="inline"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        onOpenChange={this.onOpenChange}
      >
        {/* <div className="item">
          <div className="title">{feedName}</div>
        </div> */}
        {sportTypes
          .filter((da) => da.sport.sportId === sportId)
          .map((data) => {
            return (
              <>
                {loadingSportCategories &&
                  sportData.sport.sportId === data.sport.sportId && (
                    <div className="today">
                      <Loading />
                    </div>
                  )}
                {/* {this.props.feedId != Enums.Feed.Live && (
                <div
                  className="today"
                  onClick={() => this.onTodayClick()}
                  key={"today"}
                >
                  <div>Today</div>
                </div>
              )} */}
                <div className="menu-banner">Top Leagues</div>
                {topCategories && (
                  //&& topCategories[0].tournamentId > 0
                  <div className="sub-menu" title="Top Leagues" key="top">
                    {topCategories &&
                      topCategories.map((category) => {
                        return (
                          <div
                            className="item"
                            title={`${category.tournamentDisplayName}`}
                            key={`${category.sportCategory.sportCategoryId}${category.tournamentId}`}
                          >
                            <MenuItem
                              className="item"
                              showFlag
                              item={category}
                              title={`${category.tournamentDisplayName}`}
                              onClick={() => this.onMenuClick("top", category)}
                            />
                          </div>
                        );
                      })}
                  </div>
                )}

                {/* all counties */}
                <div className="menu-banner">All Countries</div>
                {
                  <div id="sub-menu-items" className="sub-menu" key="countries" title="Countries">
                    <SecondarySearchBar onChange={this.onSearchChange} />
                    {categories &&
                      categories
                        .filter((item) =>
                          this.handleFilter(item, this.state.searchText)
                        )
                        
                        .map((category) => {
                          const children = category.sportTournaments;
                          const hasChildren = children && children.length > 0;
                          if (categoriesError) {
                            return <div>{categoriesError}</div>;
                          }
                          if (hasChildren) {
                            return (
                              <div className="sub-menu-divs">
                              <Menu mode="inline">
                                <Menu.SubMenu
                                  className="submenu"
                                  title={
                                    <div>
                                      <span
                                        style={{ width: "30px" }}
                                        className={
                                          "flag-icon flag-icon-" +
                                          getCountryCode(
                                            category.categoryDisplayName || ""
                                          )
                                        }
                                      />
                                      {category.categoryDisplayName}
                                    </div>
                                  }
                                >
                                  {children.map((child) => {
                                    return (
                                      <div
                                        className="item"
                                        title={child.tournamentDisplayName}
                                        key={`${child.sportCategory.sportCategoryId}${child.tournamentId}`}
                                      >
                                        <MenuItem
                                          className="item"
                                          showFlag
                                          item={child}
                                          title={child.tournamentDisplayName}
                                          onClick={() =>
                                            this.onMenuClick("countries", child)
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </Menu.SubMenu>
                              </Menu>
                              </div>
                              // <div
                              //   className="sub-menu"
                              //   key={`C${category.sportCategoryId}`}
                              //   title={<SubMenuTitle item={category} />}
                              // >
                              //   {children.map((child) => {
                              //     return (
                              //       <div
                              //         className="item"
                              //         title={child.tournamentDisplayName}
                              //         key={`${child.sportCategory.sportCategoryId}${child.tournamentId}`}
                              //       >
                              //         <MenuItem
                              //           className="item"
                              //           item={child}
                              //           title={child.tournamentDisplayName}
                              //           onClick={() =>
                              //             this.onMenuClick("countries", child)
                              //           }
                              //         />
                              //       </div>
                              //     );
                              //   })}
                              // </div>
                            );
                          }
                        })}
                  </div>
                }
              </>
            );
          })}
        <div  className="menu-banner">Bets Services</div>
        <div id="menu-tree-bet-services" className="sub-menu">
          <div id="bet-services-statistics-container" onClick={() => this.onOpenStats()} className="menu-item">
            <div style={{display:"flex" }}>
              {" "}
              <img
                src={"/images/statistics.svg"}
                style={{ marginLeft: "5px" }}
                height={"18px"}
                width={"20px"}
              />{" "}
            </div>{" "}
            <span style={{ marginLeft: "6px" }}> Statistics</span>
          </div>
          <div
          style={{paddingBottom:"20px"  , paddingTop:"20px"}}
            onClick={() => this.navigate("/fixture", Enums.Feed.Fixture)}
            className="menu-item"
          >
            <div style={{display:"flex"}}>
              <img
                style={{ marginLeft: "5px" }}
                src={"/images/fixtures.svg"}
                height={"18px"}
                width={"20px"}
              />
            </div>{" "}
            <span style={{ marginLeft: "6px" }}> Fixture & Results</span>
          </div>
        </div>

      </Menu>
      </div>
    );
  };

  render() {
    const {
      mobileMenuOpen,
      openMobileMenu,
      sportCategories,
      sportTopLeagues,
      sportTypes,
      sportTypesError,
      categoriesError,
      loadingSportTypes,
    } = this.props;

    if (loadingSportTypes) {
      return <Loading />;
    }
    if (sportTypesError) {
      return <div>{sportTypesError}</div>;
    }

    return (
      <div>
        {sportTypes &&
          sportTypes.length > 0 &&
          this.renderCategories(
            sportCategories,
            sportTopLeagues,
            sportTypes,
            categoriesError
          )}

        <Drawer
          width={220}
          className="mobile"
          placement="left"
          closable={false}
          onClose={() => openMobileMenu(false)}
          visible={mobileMenuOpen}
        >
          {mobileMenuOpen &&
            sportTypes &&
            sportTypes.length > 0 &&
            this.renderCategories(
              sportCategories,
              sportTopLeagues,
              sportTypes,
              categoriesError
            )}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sportCategories: state.categories.sportCategories,
    sportTopLeagues: state.categories.sportTopLeagues,
    tournamentData: state.categories.tournamentData,
    loadingSportCategories: state.categories.loadingSportCategories,
    categoriesError: state.categories.error,
    sportTypesError: state.sportTypes.error,
    loadingSportTypes: state.sportTypes.loadingSportTypes,
    sportData: state.sportTypes.sportData,
    sportTypes: state.sportTypes.sportTypes,
    appMatch: state.app.match,
    mobileMenuOpen: state.app.mobileMenuOpen,
    connected: state.signalr.connected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeFeed: (feedId, feedName) => dispatch(changeFeed(feedId, feedName)),
    openMobileMenu: (open) => dispatch(openMobileMenu(open)),
    loadSportCategories: (request) => {
      dispatch(loadSportCategories(request));
    },
    setTounament: (item) => {
      dispatch(setTounament(item));
    },
    init: () => {
      dispatch(init());
    },
    setSport: (item) => {
      dispatch(setSport(item));
    },
    loadSportTypes: (request) => {
      dispatch(loadSportTypes(request));
    },
    removeDisabledSportTypes: () => {
      dispatch(removeDisabledSportTypes());
    },
    removeDisabledCategoriesTournaments: () => {
      dispatch(removeDisabledCategoriesTournaments());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuTree);

import { SearchActionTypes } from '.'

export function getSearchedData(request) {
    return {
        type: SearchActionTypes.GetSearchedData_Request,
        request
    }
}

export function navigateSearchItem(path) {
    return {
        type: SearchActionTypes.Navigate_Search_Item,
        path
    }
}
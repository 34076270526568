import React from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import moment from 'moment';
import { memberForms, formsDetails, openMobileTableDetails, openBetDetailsModal } from '../../redux/actions/membersActions';
import { formStatuses } from '../../redux/actions/generalActions';
import { Table, DatePicker, Select, Tooltip, Alert, Modal } from 'antd';
import MobileTableDrawer from './MobileTableDrawer';
import { isMobile } from 'react-device-detect';
import ModalBetDetails from './ModalBetDetails';
import {Images} from '../../components'
import {Tabs } from "antd";
const TabPane = Tabs.TabPane;
const { RangePicker } = DatePicker;

const columns = [{
	title: 'Date Sent',
	dataIndex: 'date',
	defaultSortOrder: 'descend',
}, {
	title: 'Form Number',
	dataIndex: 'formNumber',
	render(text, record) {
		return {
			children: <div style={{  color: '#2C8AF7' }}>{text}</div>,
		};
	}
}, {
	title: 'Form Amount',
	dataIndex: 'amount'
}, {
	title: 'Win Amount',
	dataIndex: 'winAmount'
}, {
	title: 'Status',
	dataIndex: 'status',
	className: 'status-type',
	// fixed: 'right',

	render(text, record) {
		return {
			children: <div style={{ color: style(record.status) }}>{text}</div>,
		};
	}
}];
const mobileColumns = [{
	title: 'title',
	dataIndex: 'tHead',
	// defaultSortOrder: 'descend',
	render(text, record) {
		{console.log("thead text================////" , text);}
		return {
			children: <div style={{fontWeight:"500"}}> {text.split(',').map(item=><p className='mobile-table-row-data'>{item}</p>)}</div>,
		};
	}
}, {
	title: 'Data',
	dataIndex: 'data',
	render(text, record) {
		return {
			children:  <div>
				 <p  className='mobile-table-row-data'>{text.date}</p>
				 <p style={{  color: '#2C8AF7' }} className='mobile-table-row-data'>{text.formNumber}</p>
				 <p  className='mobile-table-row-data'>{text.amount}</p>
				 <p  className='mobile-table-row-data'>{text.winAmount}</p>
				 <p style={{color: (text.status == "Won - Not Claimed" || text.status == "Claimed") && 'green' }} className='mobile-table-row-data'>{text.status}</p>
			</div>,
		};
	}
}];



const style = (status) => {
	if (status === "Won - Not Claimed" || status === "Claimed") {
		return 'green'
	}
}

class Bet extends React.Component {
	state = {
		expandedRowKeys: [],
		// fromDate: moment().startOf('day'),
		// toDate: moment().endOf('day'),
		status: 0,
		pagination: {
			current: 1,
		},
		index: 0,
		rendered: false,
		expendedDataSource: [],
		expandedSNWRowKeys: [],
	}

	// rowRenderer = (index) => {
	// 	if (!this.props.loading) {
	// 		const tableElement = ReactDOM.findDOMNode(this.tableRef.current);
	// 		const rowExpandElement = tableElement.querySelectorAll(`[data-row-key="${index}-extra-row"]`)[0];
	// 		const extraRowElement = tableElement.querySelectorAll(`[data-row-key="${index}-extra-row"]`)[1];
	// 		const { height } = rowExpandElement.getBoundingClientRect();
	// 		extraRowElement.style.height = `${height}px`;
	// 		this.setState({ rendered: true })
	// 	}
	// }

	// getSNWFormDetails = (expanded, record) => {
	// 	var keys = [];
	// 	let data = []
	// 	if (expanded) {
	// 		keys.push(record.formId);
	// 		let dataSource = this.props.betLines.find((row) => row.formId == record.formId);

	// 		data.push(
	// 			{
	// 				betNo: dataSource.betLineNo,
	// 				eventName: dataSource.eventName,
	// 				feedName: dataSource.feedName,
	// 				sportDisplayName: dataSource.sportName,
	// 				betTypeName: dataSource.betType.betTypeName,
	// 				odd: dataSource.odd,
	// 				formMark: dataSource.formMark,
	// 				finalMark: dataSource.finalMark,
	// 				gameScore: dataSource.gameScore,
	// 				winStatus: dataSource.winStatus
	// 			})
	// 		this.setState({ expandedSNWRowKeys: keys, expendedDataSource: data });
	// 	}
	// 	else {
	// 		this.setState({ expandedSNWRowKeys: [], expendedDataSource: [] });
	// 	}
	// }

	// expendedRowRender = () => {
	// 	const SpinAndWinColumns = [
	// 		{
	// 			title: 'Form No.',
	// 			dataIndex: 'formNumber',
	// 			key: 'formNumber',
	// 		},
	// 		{
	// 			title: 'Issue Date',
	// 			dataIndex: 'issueDate',
	// 			key: 'issueDate',
	// 			render: (text, record) => {
	// 				return moment(record.issueDate).format("DD/MM/YYYY HH:mm:ss")
	// 			}
	// 		},
	// 		{
	// 			title: 'Winning Odds',
	// 			dataIndex: 'winningOdds',
	// 			key: 'winningOdds',
	// 		},
	// 		{
	// 			title: 'Sum Of Bet',
	// 			dataIndex: 'sumOfBet',
	// 			key: 'sumOfBet',
	// 		},
	// 		{
	// 			title: 'Winning Possible',
	// 			dataIndex: 'winningPossible',
	// 			key: 'winningPossible',
	// 		},
	// 		{
	// 			title: 'Status',
	// 			dataIndex: 'formStatus',
	// 			key: 'formStatus',
	// 		},
	// 	]

	// 	const columns = [{
	// 		title: 'Bet No.', dataIndex: 'bet', key: 'bet'
	// 	}, {
	// 		title: 'Feed', dataIndex: 'feed', key: 'feed'
	// 	}, {
	// 		title: 'Sport', dataIndex: 'sport', key: 'sport'
	// 	}, {
	// 		title: 'Match', dataIndex: 'match', key: 'match'
	// 	}, {
	// 		title: 'Note', dataIndex: 'note', key: 'note'
	// 	}, {
	// 		title: 'Odd', dataIndex: 'odd', key: 'odd'
	// 	}, {
	// 		title: 'Mark', dataIndex: 'mark', key: 'mark'
	// 	}, {
	// 		title: 'Correct mark', dataIndex: 'correctMark', key: 'correctMark'
	// 	}, {
	// 		title: 'Game Score', dataIndex: 'gameScore', key: 'gameScore'
	// 	}, {
	// 		title: 'Status', dataIndex: 'status', key: 'status'
	// 	}];

	// 	const table = this.props.isGroup ? <Table
	// 		columns={SpinAndWinColumns}
	// 		dataSource={this.props.formDetails}
	// 		pagination={false}
	// 		onExpand={this.getSNWFormDetails}
	// 		expandedRowKeys={this.state.expandedSNWRowKeys}
	// 		expandedRowRender={this.state.expandedSNWRowKeys.length > 0 ? () => this.expendedSNWRowRender() : () => { }}
	// 		rowKey={(item, index) => item.formId}


	// 	/>
	// 		:
	// 		<Table
	// 			columns={columns}
	// 			dataSource={this.props.formDetails}
	// 			pagination={false}
	// 		/>
	// 	return table
	// }

	// expendedSNWRowRender = () => {

	// 	const columns = [{

	// 		title: 'Bet No.', dataIndex: 'betNo', key: 'betNo'
	// 	}, {
	// 		title: 'Event', dataIndex: 'eventName', key: 'eventName'
	// 	}, {
	// 		title: 'Feed', dataIndex: 'feedName', key: 'feedName'
	// 	}, {
	// 		title: 'Sport', dataIndex: 'sportDisplayName', key: 'sportDisplayName'
	// 	}, {
	// 		title: 'bet Type', dataIndex: 'betTypeName', key: 'betTypeName'
	// 	}, {
	// 		title: 'Odd', dataIndex: 'odd', key: 'odd'
	// 	}, {
	// 		title: 'Mark', dataIndex: 'formMark', key: 'formMark'
	// 	}, {
	// 		title: 'Final mark', dataIndex: 'finalMark', key: 'finalMark'
	// 	}, {
	// 		title: 'Game Score', dataIndex: 'gameScore', key: 'gameScore'
	// 	}, {
	// 		title: 'Status', dataIndex: 'winStatus', key: 'winStatus'
	// 	}];


	// 	return <Table
	// 		columns={columns}
	// 		dataSource={this.state.expendedDataSource}
	// 		pagination={false}
	// 	/>
	// }


	componentDidMount() {
		let data = {
			statusId: this.state.status,
			fromDate: this.state.fromDate,
			toDate: this.state.toDate,
			pageIndex: this.state.pagination.current - 1
		}
		console.log("data" ,  data);
		this.props.memberForms(data)
		this.props.formStatuses({ data: { formStatusId: 0 } })
		this.tableRef = React.createRef();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.formDateRange && prevProps.formDateRange !== this.props.formDateRange) {
			this.setState({ fromDate: moment(this.props.formDateRange.fromDate), toDate: moment(this.props.formDateRange.toDate) })
		}
	}

	handleTableChange = (pagination, filters, sorter) => {
		this.setState({ pagination: { ...pagination, current: pagination.current }, expandedRowKeys: [] }, () => {
			let data = {
				fromDate: this.state.fromDate,
				toDate: this.state.toDate,
				pageIndex: this.state.pagination.current - 1,
				statusId: this.state.status
			}
			this.props.memberForms(data)
		})
	};

	setStatusFilter = (value) => {
		console.log("value",value);
		this.setState({ status: value, expandedRowKeys: [] }, () => {
			let data = {
				statusId: this.state.status,
				fromDate: this.state.fromDate,
				toDate: this.state.toDate,
				pageIndex: 0
			}
			this.props.memberForms(data)
		})
	}

	setDateSort = (values) => {
		if (!values[0]) {
			this.setState({ expandedRowKeys: [], status: 0, fromDate: moment().startOf('day'), toDate: moment().endOf('day') }, () => {
				let data = {
					fromDate: this.state.fromDate,
					toDate: this.state.toDate,
					pageIndex: this.state.pagination.current - 1,
					statusId: this.state.status
				}
				this.props.memberForms(data)
			})
		}
		else {
			this.setState({ fromDate: values[0], toDate: values[1] }, () => {
				let data = {
					fromDate: this.state.fromDate,
					toDate: this.state.toDate,
					pageIndex: this.state.pagination.current - 1,
					statusId: this.state.status
				}
				this.props.memberForms(data)
			})
		}

	}

	getFormDetails = (record) => {
		const { openMobileTableDetails, openBetDetailsModal } = this.props;


		openBetDetailsModal(true)
		
		console.log("recordaaaaaaaaaaaaaaaaaaaaaaa" , record);
		if (record.item){
			let data = {
				formNumber: record.item.formNumber,
				formType: record.item.formType
			}
			this.props.formsDetails(data)
		}else{
			let data = {
				formNumber: record.formNumber,
				formType: record.formType
			}
			this.props.formsDetails(data)
		}

		//}
		//this.setState({ expandedRowKeys: keys, index: record.key, rendered: false });


	}

	itemRender = (current, type, originalElement) => {
		if (type === 'prev') {
			return <a>< img style={{transform: "rotate(90deg)"}} src={Images.DownArrow}/></a>;
		} if (type === 'next') {
			return <a>< img style={{transform: "rotate(-90deg)"}} src={Images.DownArrow}/></a>;
		}
		return originalElement;
	}
	disabledDate = (current) => {
		return current && (current < moment().day(-30) || current > moment())
	}
	render() {
		const { formDetails, betLines, betDetailsModalVisible, openBetDetailsModal } = this.props;
		const mobileTableData = []
		this.props.forms.map(item=>{
			mobileTableData.push({'tHead':`DATA SENT,fORM NUMBER,FORM AMOUNT,WIN AMOUNT,STATUS` , "data":{date:item.date,formNumber:item.formNumber,amount:item.amount,winAmount:item.winAmount,status:item.status},"item":item})
		})
		console.log("mobileTableData]]]]]]]]]]]]]]]]]]]" , mobileTableData);
		return (
			<div className="bets">
	
				{
					formDetails.length > 0 &&
					<Modal
						visible={betDetailsModalVisible}
						footer={null}
						className="bet-details-modal"
						destroyOnClose
						onCancel={() => openBetDetailsModal(false)}
					>
						<ModalBetDetails
							betLines={betLines}
							formDetails={formDetails}
						/>
					</Modal>

				}
				{/* {formDetails.length > 0 && isMobile &&
					<MobileTableDrawer
						betLines={betLines}
						formDetails={formDetails} />
				} */}
				<div className="form-container">
					{/* <RangePicker
						disabledDate={this.disabledDate}
						className="range-picker"
						onChange={this.setDateSort}
						value={[this.state.fromDate, this.state.toDate]}
					/> */}

					{/* <Select
						className="select-dark"
						placeholder="Filter Status"
						defaultValue={this.state.status}
						onChange={this.setStatusFilter}
						value={this.state.status}>
						{this.props.statuses ? this.props.statuses.map((status, i) => <Select.Option key={status.formStatusId} value={status.formStatusId}>{status.formStatusName}</Select.Option>) : ''}
					</Select> */}
					<Tabs defaultActiveKey={'0'} onChange={this.setStatusFilter}>
					<TabPane className="content" tab="Active" key={'1'} >
					</TabPane>
					<TabPane className="content" tab="History" key={'0'}  >
					</TabPane>

				</Tabs>
				{console.log("this.props.forms" , this.props.forms)}
					{this.props.message ?
						<Alert className="message-alert" message={this.props.message} type="error" />
						:
						<>
						
						<div id='my-account-mobile-table'>
						<Table
							className={this.state.expandedRowKeys.length > 0 ? "table shrink-table-expanded-row" : "table"}
							pagination={{
								total: this.props.total,
								//hideOnSinglePage: true
								itemRender: this.itemRender
							}}
							columns={mobileColumns}
							dataSource={mobileTableData}
							onChange={this.handleTableChange}
							loading={this.props.loading}
							// expandedRowRender={this.props.formDetails ? () => this.expendedRowRender() : () => { }}
							onRow={(record, index) => {
								return {
									onClick: event => {
										this.getFormDetails(record)
									}
								}
							}}
							//	expandedRowKeys={this.state.expandedRowKeys}
							scroll={{ x: "max-content" }}
							ref={this.tableRef}
						/>
							{/* <div>
							{this.props.forms.map(item=>{
								return(
									<div style={{borderBottom:"3px dotted white" , fontSize:"10px"}}>
									<div style={{ width:"75%",  margin:"auto"}}>
									<div style={{display:"inline-block" , width:"50%"}}>
										<p>DATA SENT</p>
										<p>FORM NUMBER</p>
										<p>FORM AMOUNT</p>
										<p>WIN AMOUNT</p>
										<p>STATUS</p>
									</div>
									<div style={{display:"inline-block" , width:"50%"}}>
										<p>{item.date}</p>
										<p>{item.formNumber}</p>
										<p>{item.amount}</p>
										<p>{item.winAmount}</p>
										<p>{item.status}</p>
									</div>
								</div>
								</div>
								)
								
							})}
							</div> */}
							
						</div>
						<div id='my-account-desktop-table'>
						<Table
						
							className={this.state.expandedRowKeys.length > 0 ? "table shrink-table-expanded-row" : "table"}
							pagination={{
								total: this.props.total,
								//hideOnSinglePage: true
								itemRender: this.itemRender
							}}
							columns={columns}
							dataSource={this.props.forms}
							onChange={this.handleTableChange}
							loading={this.props.loading}
							// expandedRowRender={this.props.formDetails ? () => this.expendedRowRender() : () => { }}
							onRow={(record, index) => {
								return {
									onClick: event => {
										this.getFormDetails(record)
									}
								}
							}}
							//	expandedRowKeys={this.state.expandedRowKeys}
							scroll={{ x: "max-content" }}
							ref={this.tableRef}
						/>
						</div>
						</>
					}
					{console.log("This . props . forms == my account " , this.props.forms)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		total: state.member.totalForms,
		forms: state.member.forms,
		statuses: state.general.statuses,
		loading: state.member.loading,
		formDetails: state.member.formDetails,
		isGroup: state.member.isGroup,
		betLines: state.member.betLines,
		message: state.member.betsMessage,
		betDetailsModalVisible: state.member.betDetailsModalVisible,
		formDateRange: state.member.formDateRange

	}
}

const mapDispatchToProps = (dispatch) => ({
	memberForms: (data) => dispatch(memberForms(data)),
	formStatuses: (data) => dispatch(formStatuses(data)),
	formsDetails: (data) => dispatch(formsDetails(data)),
	openMobileTableDetails: (open) => dispatch(openMobileTableDetails(open)),
	openBetDetailsModal: (open) => dispatch((openBetDetailsModal(open)))
})

export default connect(mapStateToProps, mapDispatchToProps)(Bet)
import React from "react";
import { connect } from "react-redux";
import { changeFeed } from "../../redux/actions/appActions";
import { pageName } from "../../redux/actions/generalActions";
import { AppConfig } from "../../helpers";
import Images from "../common/Images";
import { Enums } from "../../helpers";

class Footer extends React.Component {
  handleDocument = (page) => {
    this.props.pageName(page);
    this.props.history.push(`/document/${page}`);
    window.scrollTo(0, 0);
  };


  navigate = (
    route,
    feedId = Enums.Feed.Static,
    feedName = Enums.FeedNames.Static
  ) => {
    const { changeFeed, history } = this.props;
    if (history.location.pathname != route) {
      history.push(`${route}`);
      changeFeed(feedId.toString(), feedName);
      window.scrollTo(0, 0);
    }
  };

  render() {
    return (
      <div className="container desktop">
        <div className="content">
          <hr style={{ opacity: "30%" }}></hr>
          <div className="size">
            <div className="child"></div>
            <div className="child" style={{ paddingTop: "2rem" }}>
              <div
                className="link light-on"
                onClick={() => {
                  this.handleDocument("about-us");
                }}
              >
                About Us
              </div>
                          <div
              className="link light-on"
              onClick={() => {
                this.navigate("/contact", Enums.Feed.ContactUs);
              }}
            >
              Contact Us
            </div>
              {/* <div
                className="link light-on"
                onClick={() => {
                  this.handleDocument("contact-us");
                }}
              >
                Contact Us
              </div> */}
			  <div
                className="link light-on"
                onClick={() => {
                  this.handleDocument("terms-and-conditions");
                }}
              >
                Terms and Conditions
              </div>
              <div
                className="link light-on"
                onClick={() => {
                  this.handleDocument("privacy-policy");
                }}
              >
                Privacy Policy
              </div>
              <div
                className="link light-on"
                onClick={() => {
                  this.handleDocument("responsible-gaming");
                }}
              >
                Responsible Gaming
              </div>
              <div
                className="link light-on"
                onClick={() => {
                  this.handleDocument("cookies-policy");
                }}
              >
                Cookies Policy
              </div>
              <div
                className="link light-on"
                onClick={() => {
                  this.handleDocument("general-betting-rules");
                }}
              >
                General Betting Rules
              </div>
              <div
                className="link light-on"
                onClick={() => {
                  this.handleDocument("bumpay-rules");
                }}
              >
                Bumpay Rules
              </div>
              <div
                className="text"
                style={{ opacity: "50%", paddingTop: "3rem"  , paddingBottom:"2rem"}}
              >
                {AppConfig.version}
              </div>
            </div>
            <div className="child">
              <div className="footer-description">
                By accessing, continuing to use or navigating throughout this
                website you accept that we will use certain browser cookies to
                improve your customer experience with us. Winners Inc. uses
                cookies which will improve your experience with us and will not
                interfere with your privacy. Please refer to our cookies policy
                for further information on our use of cookies.
                <br />
                <br />
                Winners Inc. the provider of this website, is established in and
                fully licensed by the Government of the Republic of Liberia and
                regulated by the National Lottery Authority (NLA) under the
                Gambling Act 2015. Winners Inc. has been granted a license to
                operate sports betting, scratch & win and other games of chance.
                <br />
                <br />
                The company duly registered under the Liberian Business
                Registry, enterprise code 050926451, and its registered address
                is: 72 Broad Street, Monrovia, Liberia.
                <br />
                <br />
              </div>
              <div className="disclamer">
                <div className="footer-image-container">
                  <img src={Images.PlusEighteenDarkMode} />
                </div>
                <div className="text">
                  All betting and gambling are forbidden for people under the
                  age of 18
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    feedId: state.app.feedId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeFeed: (feedId) => {
      dispatch(changeFeed(feedId));
    },
    pageName: (page) => dispatch(pageName(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

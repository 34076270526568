import React from 'react'
import Bets from '..'
import { Utils } from '../../../../helpers';


export default class SetScore extends React.Component {
    setScore = (score) => {
        return score ? score.split(':').map(score => score.trim()) : '';
    }
    setMatchScores = (scores) => {
        let newScores = scores.map(score => {
            return score ? score.split(':').map(score => score.trim()) : '';
        });
        return newScores;
    }

    DesktopEvents = (event, finalScore, setScores, gameScore) => {
        return (
            <div className="event">
                <Bets.EventDetails event={event} {...this.props} />
                {
                    <div className="score">
                        <div className="game-scores">
                            {event.eventScore.server === 1 && <div className="current-turn"></div>}
                            <div className="game-current">{gameScore[0]}</div>
                            {setScores.map((match, index) => <div className={match[0] > match[1] ? "match-won" : "match-lost"} key={index}>{match[0]}</div>)}
                            <div className="game-result">{finalScore[0]}</div>


                        </div>
                        <div className="game-scores">
                            {event.eventScore.server === 2 && <div className="current-turn"></div>}
                            <div className="game-current">{gameScore[1]}</div>
                            {setScores.map((match, index) => <div className={match[1] > match[0] ? "match-won" : "match-lost"} key={index}>{match[1]} </div>)}
                            <div className="game-result">{finalScore[1]}</div>
                        </div>
                    </div>
                }
            </div>
        )
    }


    MobileEvents = (event, finalScore, setScores, gameScore) => {
        let setScore = setScores[setScores.length - 1];
        return (<div className="event">
            <Bets.EventDetails event={event} {...this.props} />
            {
                <div className="score">
                    <div className="game-scores">
                        {event.eventScore.server === 1 && <div className="current-turn"></div>}
                        <div className="game-current">{gameScore[0]}</div>
                        {<div className={setScore[0] > setScore[1] ? "match match-won" : "match match-lost"} key={setScore[0]}>{setScore[0]}</div>}
                        <div className="game-result">{finalScore[0]}</div>


                    </div>
                    <div className="game-scores">
                        {event.eventScore.server === 2 && <div className="current-turn"></div>}
                        <div className="game-current">{gameScore[1]}</div>
                        {<div className={setScore[1] > setScore[0] ? " match-won" : " match-lost"} key={setScore[1]}>{setScore[1]} </div>}
                        <div className="game-result">{finalScore[1]}</div>
                    </div>
                    <div className="game-scores" style={{ fontSize: 11 }}>
                        <div></div>
                        <div >P</div>
                        <div >G </div>
                        <div style={{ letterSpacing: 0 }} >Sets</div>
                    </div>
                </div>
            }
        </div>
        )
    }
    render() {
        const { event } = this.props;
        let finalScore = event.eventScore.score ? this.setScore(event.eventScore.score) : [];
        let setScores = event.eventScore.setScores ? this.setMatchScores(event.eventScore.setScores) : [];
        let gameScore = event.eventScore.gameScore ? this.setScore(event.eventScore.gameScore) : [];
        return (
            Utils.IsMobile ? this.MobileEvents(event, finalScore, setScores, gameScore) : this.DesktopEvents(event, finalScore, setScores, gameScore)
        )
    }
}
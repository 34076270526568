import React from 'react'
import { connect } from 'react-redux';
import { Tabs, Popover } from 'antd';
import { Multiple, Single } from './';
import { clearBetSlip, setTab } from '../../../redux/actions/betSlipActions';
import { SessionStorage } from '../../../helpers';

const TabPane = Tabs.TabPane;

class BetSlipTab extends React.Component {

	render() {
		let tab = SessionStorage.betTab;
		return (
			<div>
				<Tabs className="tabs-betslip" defaultActiveKey={tab ? tab.toString() : '2'} onChange={this.props.setTab}>
					<TabPane className="content" tab="Single" key={'1'} >
						<Single {...this.props} />
					</TabPane>
					<TabPane className="content" tab="Multiple" key={'2'}  >
						<Multiple {...this.props} />
					</TabPane>
					<TabPane className="content" tab="System" key="3">
						<div className="events">
							<div className="row error-message">
								<div className="text">Coming soon.</div>
							</div>
						</div>
					</TabPane>
				</Tabs>

			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		betSlipList: state.betSlip.betSlipList,

	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		clearBetSlip: () => {
			dispatch(clearBetSlip())
		},
		setTab: (tab) => {
			dispatch(setTab(tab))
		},

	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BetSlipTab);
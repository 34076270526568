import React from "react";
import { connect } from "react-redux";
import { Alert } from "../../components";
import { Input, Button, Form, Icon } from "antd";
import { changePassword } from "../../redux/actions/authActions";
import { Cookies } from "../../helpers";
import { Images } from "../../components";
const { Item } = Form;

class ChangePassword extends React.Component {
  state = {
    passwordNumbers: false,
    passwordLowerCase: false,
    passwordUpperCase: false,
    passwordLength: false,
    passwordSpecialChar: true,
    
  };
  onSubmit = () => {
    // todo - submit to server
    const { form, changePassword } = this.props;
    let data = {
      oldPassword: form.getFieldValue("current"),
      password: form.getFieldValue("password"),
      confirmPassword: form.getFieldValue("confirm"),
      memberId: Cookies.get("memberId"),
    };
    changePassword(data);
  };

  validatePassword = (rule, value, callback) => {
    const form = this.props.form;
    let regexValidation =
      /^(?=.*[0-9])(?=.*[~_-|\^{}])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9~_-|\^{}]{8,}$/;
    let password = form.getFieldValue("password");
    let confirm = form.getFieldValue("confirm");
    if (password && confirm && password === confirm) {
      if (regexValidation.test(password) && regexValidation.test(confirm)) {
        form.setFields({
          password: {
            value: password,
            errors: undefined,
          },
          confirm: {
            value: confirm,
            errors: undefined,
          },
        });
        callback();
      } else {
        callback(
          ""
        );
      }
    } else if(password && !confirm){
      callback("");
    } else {
      callback("passwords dont match");
    }

    if (password) {
      this.passwordLengthValidator();
      this.passwordNumbersValidator();
      this.passwordUppercaseValidator();
      this.passwordLowercaseValidator();
      this.passwordSpecialCharValidator();
    }
  };

  passwordLengthValidator = () => {
    const form = this.props.form;
    let password = form.getFieldValue("password");
    //if (password && password != '' && password != undefined) {
    this.setState({ passwordLength: password.length >= 8 });
    //}
  };
  passwordNumbersValidator = () => {
    const form = this.props.form;
    let password = form.getFieldValue("password");
    let regexValidation = /\d/;
    this.setState({ passwordNumbers: regexValidation.test(password) });
  };
  passwordUppercaseValidator = () => {
    const form = this.props.form;
    let password = form.getFieldValue("password");
    let regexValidation = /[A-Z]/;
    this.setState({ passwordUpperCase: regexValidation.test(password) });
  };
  passwordLowercaseValidator = () => {
    const form = this.props.form;
    let password = form.getFieldValue("password");
    let regexValidation = /[a-z]/;
    this.setState({ passwordLowerCase: regexValidation.test(password) });
  };
  passwordSpecialCharValidator = () => {
    const form = this.props.form;
    let password = form.getFieldValue("password");
    let regexValidation = /[#[\]!$&()*+,;='<>"`%+]/;
    this.setState({ passwordSpecialChar: !regexValidation.test(password) });
  };
  showPassword = (inputId , iconId) => {
    const inputElement =document.getElementById(inputId)
    const iconElement = document.getElementById(iconId)
    const imgElement = document.createElement("img")
    imgElement.src = Images.ShowPasswordIcon
    // console.log(iconElement.src,"==========" ,<img src={Images.ShowPasswordIcon}/> );
    if (iconElement.src == imgElement.src){
      iconElement.src = Images.HidePasswordIcon
    }else{
      iconElement.src = Images.ShowPasswordIcon
    }
    if (inputElement.type == "password"){
      inputElement.type = "text"
    }else{
      inputElement.type = "password"
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { error, success } = this.props;

    return (
      <div className="change-password">
        <div className="form-container">
          <div>
            <Item
              className="forget-password-labels-containers"
              autoComplete="old-password"
              label="Old Password"
            >
              {getFieldDecorator("current", {
                rules: [
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ],
              })(
                <div>
                  <Input
                    autoComplete="old-password"
                    type="password"
                    id="old-password-input"
                  />
                  <img
                    id="old-password-icon"
                    className="show-password-icon"
                    src={Images.ShowPasswordIcon}
                    onClick={() =>
                      this.showPassword(
                        "old-password-input",
                        "old-password-icon"
                      )
                    }
                  />
                </div>
              )}
            </Item>
          </div>

          <div className="forget-password-second-section">
            <Item
              className="forget-password-labels-containers"
              label="New Password"
            >
              {getFieldDecorator("password", {
                rules: [
                  {
                    // required: true,
                    // message: "Please input your password!",
                  },
                  // { validator: this.validatePassword },
                ],
              })(
                <div>
                  <Input type="password" id="new-password-input" />
                  <img
                    id="new-password-icon"
                    className="show-password-icon"
                    src={Images.ShowPasswordIcon}
                    onClick={() =>
                      this.showPassword(
                        "new-password-input",
                        "new-password-icon"
                      )
                    }
                  />
                </div>
              )}
            </Item>

            <Item
              className="forget-password-labels-containers"
              label="Confirm New Password"
            >
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  {
                    validator: this.validatePassword,
                  },
                ],
              })(
                <div>
                  <Input type="password" id="confirm-password-input" />
                  <img
                    id="confirm-password-icon"
                    className="show-password-icon"
                    src={Images.ShowPasswordIcon}
                    onClick={() =>
                      this.showPassword(
                        "confirm-password-input",
                        "confirm-password-icon"
                      )
                    }
                  />
                </div>
              )}
            </Item>
          </div>

          <div className="validation-meter">
            <div>Your password must contain</div>
            <Form.Item validateStatus="success">
              <Alert
                icon={
                  <Icon
                    type={this.state.passwordLength ? "check" : "close"}
                  ></Icon>
                }
                message={this.state.passwordLength ? <span style={{color:"green"}}>At least 8 characters</span>: <span style={{color:"red"}}>At least 8 characters</span>}
                type={this.state.passwordLength ? "success" : "error"}
                showIcon
              />
              <Alert
                icon={
                  <Icon
                    type={this.state.passwordLowerCase ? "check" : "close"}
                  ></Icon>
                }
                message={this.state.passwordLowerCase ? <span style={{color:"green"}}>Lower case letters(a-z)</span>: <span style={{color:"red"}}>Lower case letters(a-z)</span>}
                type={this.state.passwordLowerCase ? "success" : "error"}
                showIcon
              />
              <Alert
                icon={
                  <Icon
                    type={this.state.passwordUpperCase ? "check" : "close"}
                  ></Icon>
                }
                message={this.state.passwordUpperCase ? <span style={{color:"green"}}>Upper case letters(A-Z)</span>: <span style={{color:"red"}}>Upper case letters(A-Z)</span>}
                type={this.state.passwordUpperCase ? "success" : "error"}
                showIcon
              />
              <Alert
                icon={
                  <Icon
                    type={this.state.passwordNumbers ? "check" : "close"}
                  ></Icon>
                }
                message={this.state.passwordNumbers ? <span style={{color:"green"}}>Numbers(0-9)</span>: <span style={{color:"red"}}>Numbers(0-9)</span>}
                type={this.state.passwordNumbers ? "success" : "error"}
                showIcon
              />
              <Alert
                icon={
                  <Icon
                    type={this.state.passwordSpecialChar ? "info" : "close"}
                  ></Icon>
                }
                message={this.state.passwordSpecialChar ? <span style={{color:"green"}}>Special Characters Allowed ~_-|\^{} </span>: <span style={{color:"red"}}>Special Characters Allowed ~_-|\^{} </span>}
                type={this.state.passwordSpecialChar ? "info" : "error"}
                showIcon
              />
            </Form.Item>
          </div>
          <div className="change-password-buttons-container">
            <Button className="info" type="primary" onClick={this.onSubmit}>
              Save
            </Button>
          </div>
          {error && <Alert message={error} type="error"></Alert>}
          {success && <Alert message={success} type="success"></Alert>}
        </div>
      </div>
    );
  }
}
const MapStateToProps = (state, props) => {
  return {
    error: state.auth.changePasswordErrorMessage,
    success: state.auth.changePasswordSuccessMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changePassword: (data) => dispatch(changePassword(data)),
});

const WrappedChangePassword = Form.create({ name: "ChangePassword" })(
  ChangePassword
);
export default connect(
  MapStateToProps,
  mapDispatchToProps
)(WrappedChangePassword);

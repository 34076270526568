import React from 'react';
import { connect } from 'react-redux';
import { withdrawalToVoucher } from '../../../redux/actions/membersActions'
import { getCurrencies } from '../../../redux/actions/generalActions';
import { Alert } from '../../../components';
import { Input, Button, Form } from 'antd';
import { Cookies } from '../../../helpers';
import ModalVoucher from '../ModalVoucher';
import {  Icon } from "antd";
import moment from 'moment';
import { ConsoleView } from 'react-device-detect';

const { Item } = Form

class WithdrawToVoucher extends React.Component {
	state ={
		openWithdrawVoucher:true,

	}
	componentDidMount() {
		this.props.getCurrencies()
	}
	componentDidUpdate(prevProps) {
		if (this.props.newVoucher && prevProps.newVoucher != this.props.newVoucher) {
			this.props.form.resetFields();
		}
	}
	onSubmit = () => {
		this.setState({openWithdrawVoucher:false})
		let data = {
			amount: this.props.form.getFieldValue('amount'),
			password: this.props.form.getFieldValue('password')
		}
		this.props.withdrawalToVoucher(data)
	}
	hasErrors = () => {
		let values = this.props.form.getFieldsValue(['amount', 'password'])
		return Object.values(values).filter(field => field === undefined || field === false || field === '').length > 0
	}
	nav = (page) => {

		  this.props.history.push(`/account/${page}`);
		}
	  ;
	render() {
		const { getFieldDecorator } = this.props.form;
		const { message, newVoucher, selectedCurrency, loading } = this.props;
		{console.log("getFieldDecorator   ..",this.props.form)}
		console.log("newVoucher" , newVoucher)

		return (
			<div className="withdrawals">
				<div className="form-container">
				{
						((!newVoucher) || (this.state.openWithdrawVoucher)) &&<div>

					
					{selectedCurrency &&
						<div className="balance">Your current balance is
							<span style={{fontWeight:"bold"}}>{` ${selectedCurrency.currencySymbol} ${Math.round(selectedCurrency.balance).format()}`}</span>
						</div>
					}
					
					<Item label="Voucher Amount">
						{getFieldDecorator('amount',  {
							rules: [{ required: true, message: 'Please fill amount' }]
						})(
							<Input size="large" type="number" onFocus={() => { this.setState({ displayError: false }) }} />
						)}
					</Item>
					<Item label="Password">
						{getFieldDecorator('password', {
							rules: [{ required: true, message: 'Please fill password' }]
						})(
							<Input type="password" autoComplete="new-password" size="large"  />
						)}
					</Item>
					<div className='withdraw-button-container'>
					<Button className="info" type="primary" loading={loading} onClick={this.onSubmit} disabled={this.hasErrors()}>Withdraw Amount</Button>
					{
						(message) &&
						<Alert message={message} type="error"></Alert>
					}
					</div>
					</div>}

					{
						(newVoucher) &&  (!this.state.openWithdrawVoucher) &&
						<div className='approve-for-withdraw-container'>
							{selectedCurrency &&
						<div className="balance">Your current balance is
							<span >{` ${selectedCurrency.currencySymbol} ${Math.round(selectedCurrency.balance).format()}`}</span>
						</div>
					}
							<div className="withdraw-confirmation-statement">
							<Icon
              style={{ background: "#00800050", borderRadius: "50%" }}
              type="check-circle"
            /><span >  Withdraw Amount Has Been Placed Successfully</span>
							</div>
							<div className="voucher qr-code"> <ModalVoucher voucher={newVoucher} hideDetails /> </div>
							<div className="voucher"><span>Voucher Number is: </span> <span className="number"> {newVoucher.code}</span></div>
							<div className='withdraw-another-amount-button'>
							<div className='withdraw-button-container'>

							<Button className="info" type="primary" loading={loading} onClick={()=>this.setState({openWithdrawVoucher:true})}>Withdraw Another Amount</Button>
							</div>
							</div>
							{/* <div className="voucher">Status: <div className="number">{newVoucher.statusName}</div></div>
							<div className="voucher">Amount: <div className="number">{newVoucher.amount} {newVoucher.currency.currencySymbol}</div></div>
							<div className="voucher">Create Date: <div className="number">{moment(newVoucher.createDate).format("DD/MM/YYYY HH:mm:ss")}</div></div>
							<div className="voucher">Expiration Date: <div className="number">{moment(newVoucher.expirationDate).format("DD/MM/YYYY HH:mm:ss")}</div></div> */}
						</div>
						
					}
				</div>
			</div>

		)
	}
}
const MapStateToProps = (state, props) => {
	return {
		newVoucher: state.member.newVoucher,
		message: state.member.message,
		selectedCurrency: state.member.selectedCurrency,
		loading: state.member.withdrawLoading,
	};
}

const mapDispatchToProps = (dispatch) => (
	{
		withdrawalToVoucher: (data) => dispatch(withdrawalToVoucher(data)),
		getCurrencies: () => dispatch(getCurrencies())

	}
);

const WrappedWithdraw = Form.create({ name: 'Withdraw' })(WithdrawToVoucher);
export default connect(MapStateToProps, mapDispatchToProps)(WrappedWithdraw)
import { Button } from "antd";
import React from "react";
import { connect } from "react-redux";
import { setEvent } from "../../../redux/actions/sportEventsActions";

class OddsNumber extends React.Component {
  render() {
    return (
      <div className="bet-rows-icon-container">
        <Button
          disabled={this.props.number == 0 || !this.props.data.enableBet}
          className="plus"
          onClick={() => this.onDrilldownClick(this.props.data)}
        >
          <div className="icon">{`+${this.props.number}`}</div>
        </Button>
      </div>
    );
  }

  onDrilldownClick = (item) => {
    const { history, setEvent, feedName, match } = this.props;
    setEvent(item);
    history.push(
      `/${feedName}/${match.params.feed}/${match.params.sport}/${
        item.sport.sportDisplayName
      }/event/${item.eventCategory.sportCategoryId}/${
        item.eventTournament.tournamentId
      }/${item.eventId}`
    );
  };
}

const mapStateToProps = (state) => {
  return {
    sportEvents: state.sportEvents.sportEvents,
    feedName: state.app.feedName,
    feedId: state.app.feedId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEvent: (item) => {
      dispatch(setEvent(item));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OddsNumber);

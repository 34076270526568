import React, { Component } from "react";
import { Step1, Step2 , OTPVertification} from "../forgotPassword";
import { Steps } from "antd";
import { connect } from "react-redux";

const steps = [
  {
    desc: "Forgot Password",
    content: <Step1 />,
  },
  {
    desc: "OTP Vertification",
    content: <OTPVertification/>,
  },
  {
    desc: "Confirmation",
    content: <Step2/>,
  },
];

const { Step } = Steps;
export class ForgetPassword extends Component {
  render() {
    const { current } = this.props;
    return (
      <div className="forget-password-parent-container">
        <div>
          <Steps
            current={current}
            className="forget-password-steps"
          >
            {steps.map((item) => (
              <Step
                style={{ flex: "none" }}
                key={item.title}
                title={item.title}
                description={item.desc}
              />
            ))}
          </Steps>

          <div className="steps-content">
            {steps[current].content }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentGame: state.slots.currentGame,
    current: state.auth.currentForgotPassword,

  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);

import { Fetch, AppConfig } from '../helpers'

export default class registerApi {

	static GetRegisterEntities = () => {
		const response = Fetch.fetch(
			{
				methodName: "GetRegisterEntities",
				method: "GET",
				url: AppConfig.api.register
			}
		)
		return response;
	}

	static ValidatePhoneNumber = (data) => {
		const response = Fetch.fetch(
			{
				methodName: 'ValidatePhoneNumber',
				url: AppConfig.api.register,
				data
			}
		)
		return response;
	}

	static ValidateAddPhoneNumber = (data) => {
		const response = Fetch.fetch(
			{
				methodName: 'ValidateAddPhoneNumber',
				url: AppConfig.api.register,
				data
			}
		)
		return response;
	}


	static RegisterMember = (data) => {
		const response = Fetch.fetch(
			{
				methodName: 'RegisterMember',
				url: AppConfig.api.register,
				data
			}
		)
		return response;
	}

	static ValidateEmail = (data) => {
		const response = Fetch.fetch(
			{
				methodName: 'ValidateEmail',
				url: AppConfig.api.register,
				data
			}
		)
		return response;
	}


}

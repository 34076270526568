import { AppConfig } from "../helpers";

export const Currency = {
	LiberianDollar: 1,
	USD: 2,
}
export const Feed = {
	Static: 6,
	Live: 5,

	//Virtual
	VFL: 1,
	VHC: 2,
	VDR: 3,
	VTO: 4,
	VFLM: 7,
	VBL: 8,
	EPL: 9,

	Home: 10000,
	Fixture: 10001,
	Slots:10002,
	ContactUs:10003
}

export const VirtualFeedIds = [1, 2, 3, 4, 7, 8, 9];

export const FeedNames = {
	Static: "sport",
	Live: "live",
	VFL: "virtual",

	Home: "sport",
	Fixture: "Fixture",
	Slots:"slots",
}

export const FeedChangeStatus = {
	Update: 0,
	Add: 1,
	Delete: 2,

}

export const Days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]

export const Status = {
	Success: 1,
	Warning: 2,
	Error: 3,
	TokenExpired: 4
}

export const Device = {
	Size1: 1,
	Size2: 2,
	Size3: 3,
	Size4: 4,
	Size5: 5,
	Size6: 6,
	Size7: 7,
}

export const Env = {
	PROD: "PROD",
	PRODV1: "PRODV1",
	DEV: "DEV",
	DEV_HTTPS: "DEV_HTTP",
	LOCAL: "LOCAL",
	UK: "UK",
}

//export const ShowSignIn = true
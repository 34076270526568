import React from 'react'
import EventOdd from './EventOdd'
import { Icon } from 'antd'
import { connect } from 'react-redux';


class EventOddGroup extends React.Component {

	render() {
		const { eventEnabled, group, eventId, groupId,selectedTab } = this.props;
		if(selectedTab !== group.betTypeDrillDownGroupName){//if not equal to selected tab don't render
			return null;
		}
		const groupOdds = this.getOddsForGroup(groupId);
		return (
			<div className="group">
				{
					
					<div className="title">{group.betTypeGroupName}</div>
				}

				{
					groupOdds &&
					Object.keys(groupOdds).map((key) =>
						<div key={key}>
						
							<EventOdd eventEnabled={eventEnabled} showDetails showOddName oddId={key} eventId={eventId}></EventOdd>
						</div>

					)
				}
			</div>

		)
	}

	getOddsForGroup(groupKey) {
		const { odds } = this.props;
		let groupOdds = {};
		odds && Object.keys(odds).map((key) => {
			if (`${odds[key].betType.betTypeGroupId}_${odds[key].betType.betTypeGroupName}` === groupKey) {
				groupOdds[key] = odds[key];
			}
		})

		return groupOdds;
	}



}


const mapStateToProps = (_, initialProps) => (state) => {
	return {
		group: state.sportEvents.eventOddGroups[initialProps.groupId],
		odds: state.sportEvents.eventOdds[initialProps.eventId]
	}
}
const mapDispatchToProps = (dispatch) => {
	return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(EventOddGroup);
import { BetSlipActionTypes, GeneralActionTypes, SignalRTypes } from '../actions';
import { SessionStorage } from '../../helpers';

const initState = {
	loadingPlaceBet: false,
	betSlipList: {},
	amount: SessionStorage.betSlipAmount || 100,
	totalOdds: 0,
	possibleWinnings: 0,
	totalStake: 0,
	errors: [],
	successMsg: null,
	serverError: false,
	loadingFields: false,
	hideStake: false,
	betTab: SessionStorage.betTab || 2
};

const betSlip = (state = initState, action) => {

	switch (action.type) {

		case BetSlipActionTypes.Update_BetSlip_List_Done:
			return {
				...state,
				betSlipList: action.betSlipList,
				successMsg: false
			}
		case BetSlipActionTypes.Place_Single_Bets_Response:
			return {
				...state,
				betSlipList: action.betSlipList,
				loadingPlaceBet: false
			}
		case BetSlipActionTypes.On_Amount_Change:
			return {
				...state,
				amount: action.amount
			}
		case BetSlipActionTypes.Set_Tab:
			{
				return {
					...state,
					betTab: action.tab
				}
			}

		case BetSlipActionTypes.Set_Hide_Stake:
			return {
				...state,
				hideStake: action.hideStake
			}
		case BetSlipActionTypes.Set_BetSlip_Errors:
			return {
				...state,
				errors: action.errors,
				loadingPlaceBet: false,

			}
		case BetSlipActionTypes.Set_BetSlip_Total_Odds:
			return {
				...state,
				totalOdds: action.totalOdds,

			}
		case BetSlipActionTypes.Set_BetSlip_Total_Stake:
			return {
				...state,
				totalStake: action.totalStake,

			}
		case BetSlipActionTypes.Set_BetSlip_Possible_Winnings:
			return {
				...state,
				possibleWinnings: action.possibleWinnings,

			}

		case BetSlipActionTypes.Place_Single_Bet_Request:
			return {
				...state,
				loadingPlaceBet: true,

			}
		case BetSlipActionTypes.Place_Multiple_Bet_Request:
			return {
				...state,
				loadingPlaceBet: true,

			}
		case BetSlipActionTypes.Place_Bet_Success:
			return {
				...state,
				successMsg: true,
				loadingPlaceBet: false,


			}
		case BetSlipActionTypes.Place_Bet_Error:
			return {
				...state,
				serverError: action.serverError,
				loadingPlaceBet: false,

			}
		case BetSlipActionTypes.BetSlip_Clear:
			return {
				...state,
				betSlipList: {},
				loadingPlaceBet: false,

			}
		case BetSlipActionTypes.Set_BetSlip_Value_Change_Warning:
			return {
				...state,
				valueChangeWarning: action.valueChangeWarning,
			}
		case BetSlipActionTypes.Set_Login_Error:
			return {
				...state,
				loginError: action.loginError,
				loadingPlaceBet: false,

			}
		case SignalRTypes.Get_Updated_OddField_Details:
			return {
				...state,
				loadingFields: true,
			}
		case SignalRTypes.Get_Updated_OddField_Details_Response:
			return {
				...state,
				loadingFields: false,
			}
		case BetSlipActionTypes.Clear_BetSlip_Success:
			return {
				...state,
				successMsg: false
			}
		default:
			return state;

	}
}

export default betSlip;
import React from "react";
import { connect } from "react-redux";
import { Table, DatePicker, Select, Modal } from "antd";
import {
  memberTransactions,
  formsDetails,
  openMobileTableDetails,
  openBetDetailsModal,
  openVoucherModal,
  getVoucherDetails,
  openMobileVoucherDetails,
} from "../../redux/actions/membersActions";
import moment from "moment";
import { Cookies } from "../../helpers";
import MobileTableDrawer from "./MobileTableDrawer";
import { isMobile } from "react-device-detect";
import ModalBetDetails from "./ModalBetDetails";
import { Images } from "../../components";
import QRCode from "react-qr-code";
import arrowIcon from "../../resources/icons/arrow.svg";

const { RangePicker } = DatePicker;

const columns = [
  {
    title: "DATE OF TRANSACTION",
    dataIndex: "date",
    defaultSortOrder: "descend",
  },
  {
    title: "TRANSACTION TYPE",
    dataIndex: "type",
    className: "transaction-type",
    render(text, record) {
      return {
        children: style(record.type),
      };
    },
  },
  {
    title: "REFERENCE",
    dataIndex: "reference",
    render(text, record) {
      if (record.formNumber || (record.voucher && record.voucher.code)) {
        return {
          children: <div>{text}</div>,
        };
      } else {
        return {
          children: <div>{text}</div>,
        };
      }
    },
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
    render(text, record) {
      return {
        children: (
          <div style={{ color: styleAmount(record.isCreditAmount) }}>
            {text}
          </div>
        ),
      };
    },
  },
  {
    title: "BALANCE",
    dataIndex: "balance",
    render(text, record) {
      return {
        children: <div>{text}</div>,
      };
    },
  },
];

const mobileColumns = [
  {
    title: "title",
    dataIndex: "tHead",
    // defaultSortOrder: 'descend',
    render(text, record) {
      return {
        children: (
          <div style={{ fontWeight: "500" }}>
            {" "}
            {text.split(",").map((item) => (
              <p className="mobile-table-row-data">{item}</p>
            ))}
          </div>
        ),
      };
    },
  },
  {
    title: "Data",
    dataIndex: "data",
    render(text, record) {
      return {
        children: (
          <div>
            <p className="mobile-table-row-data">{text.date || "-"}</p>
            <p style={{ color: "blue" }} className="mobile-table-row-data">
              {style(text.type)}
            </p>
            <p className="mobile-table-row-data">{text.reference || "-"}</p>
            <p
              className="mobile-table-row-data"
              style={{ color: styleAmount(record.item.isCreditAmount) }}
            >
              {text.amount || "-"}
            </p>
            <p className="mobile-table-row-data">{text.balance || "-"}</p>
          </div>
        ),
      };
    },
  },
];

const style = (type) => {
  return (
    <>
      <div
        style={{
          color: `${type === "Withdraw To Voucher" ? "#2C8AF7" : "#ffffff"}`,
        }}
      >
        {type || "-"}{" "}
        {type === "Withdraw To Voucher" ? <img src={arrowIcon} alt="" /> : null}
      </div>
    </>
  );
};

const styleAmount = (record) => {
  if (record === true) {
    return "white";
  } else if (record === false) {
    return "red";
  }
};

class Transactions extends React.Component {
  state = {
    // fromDate: moment().startOf('day'),
    // toDate: moment().endOf('day'),
    pagination: {
      current: 1,
    },
    currencyId: Cookies.get("selectedCurrency")
      ? Cookies.get("selectedCurrency").currencyId
      : this.props.currencies.LiberianDollar,
  };

  getMemberTransactions() {
    let data = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      pageIndex: this.state.pagination.current - 1,
      currencyId: this.state.currencyId,
    };
    this.props.memberTransactions(data);
  }

  componentDidMount() {
    // get transactions
    this.getMemberTransactions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.formDateRange &&
      prevProps.formDateRange !== this.props.formDateRange
    ) {
      this.setState({
        fromDate: moment(this.props.formDateRange.fromDate),
        toDate: moment(this.props.formDateRange.toDate),
      });
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      { pagination: { ...pagination, current: pagination.current } },
      () => {
        this.getMemberTransactions();
      }
    );
  };

  setDateSort = (values) => {
    if (!values[0]) {
      this.setState(
        { fromDate: moment().startOf("day"), toDate: moment().endOf("day") },
        () => {
          this.getMemberTransactions();
        }
      );
    } else {
      this.setState({ fromDate: values[0], toDate: values[1] }, () => {
        this.getMemberTransactions();
      });
    }
  };

  itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <a>
          <img style={{ transform: "rotate(90deg)" }} src={Images.DownArrow} />
        </a>
      );
    }
    if (type === "next") {
      return (
        <a>
          <img style={{ transform: "rotate(-90deg)" }} src={Images.DownArrow} />
        </a>
      );
    }
    return originalElement;
  };

  disabledDate = (current) => {
    return current && (current < moment().day(-30) || current > moment());
  };

  handleCurrencyChange(value) {
    if (value) {
      this.setState({ currencyId: value }, () => {
        this.getMemberTransactions();
      });
    }
  }

  getFormDetails = (record) => {
    const {
      openMobileTableDetails,
      openBetDetailsModal,
      openVoucherModal,
      getVoucherDetails,
      openMobileVoucherDetails,
      expanded,
    } = this.props;
    if (record.formNumber) {
      if (isMobile) {
        openMobileTableDetails(true);
        openBetDetailsModal(true);
        //var keys = [];
        //if (expanded) {
        //	keys.push(record.key);

        //}
        //this.setState({ expandedRowKeys: keys, index: record.key, rendered: false });
      } else {
        openBetDetailsModal(true);
      }
      let data = {
        formNumber: record.formNumber,
        formType: record.formType,
      };
      this.props.formsDetails(data);
    }
    if (record.voucher && record.voucher.code) {
      if (isMobile) {
        openMobileVoucherDetails(true);
      } else {
        openVoucherModal(true);
        var keys = [];
        if (expanded) {
          keys.push(record.key);
        }
        this.setState({
          expandedRowKeys: keys,
          index: record.key,
          rendered: false,
        });
      }
      let data = { id: record.voucher.voucherId };
      getVoucherDetails(data);
    }
  };

  renderDetails = (voucher) => {
    return (
      <div className="voucher-details-expanded">
        <div className="voucher-content-details">
          <div className="issued-date">
            <span>Issued Date:</span>
            <span>{moment(voucher.createDate).format("DD/MM/YYYY")}</span>
          </div>
          <div className="expiry-date">
            <span>Expiry Date:</span>

            <span>{moment(voucher.expirationDate).format("DD/MM/YYYY")}</span>
          </div>
          <div className="voucher-number">
            <span>Voucher number is:</span>
            <span>{voucher.code}</span>
          </div>
          <div className="voucher-status">
            <span>Status:</span>
            <span>{voucher.statusName}</span>
          </div>
        </div>

        <div className="qr-code-top-border">
          <div className="qr-code">
            {voucher.code && (
              <QRCode frameBorder={"#fff"} size={110} value={voucher.code} />
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      formDetails,
      betLines,
      betDetailsModalVisible,
      openBetDetailsModal,
      voucherDetails,
      expanded,
    } = this.props;
    const mobileTableData = [];
    this.props.transactions.map((item) => {
      mobileTableData.push({
        tHead: `DATE OF TRANSACTION,TRANSACTION TYPE,REFERENCE,AMOUNT,BALANCE`,
        data: {
          date: item.date,
          type: item.type,
          reference: item.voucher.code || item.formNumber,
          amount: item.amount,
          balance: item.balance,
        },
        item: item,
      });
    });
    return (
      <div id="transactions-container">

      
      <div className="voucher">
        {formDetails.length > 0 && (
          <Modal
            visible={betDetailsModalVisible}
            footer={null}
            className="bet-details-modal"
            destroyOnClose
            onCancel={() => openBetDetailsModal(false)}
          >
            <ModalBetDetails betLines={betLines} formDetails={formDetails} />
          </Modal>
        )}
        {/* {formDetails.length > 0 && isMobile && (
          <MobileTableDrawer
            betLines={this.props.betLines}
            formDetails={formDetails}
          />
        )} */}
        <div className="form-container">
          <div id="my-account-transaction-mobile-table">
            <Table
              className="table"
              pagination={{
                total: this.props.total,
                //hideOnSinglePage: true
                itemRender: this.itemRender,
              }}
              expandedRowRender={(record) => {
                if (voucherDetails.voucherId !== record.item.voucher.voucherId)
                  return null;
                return <>{this.renderDetails(voucherDetails)}</>;
              }}
              expanded={this.expanded}
              expandIcon={() => <></>}
              expandIconAsCell={false}
              expandRowByClick={true}
              columns={mobileColumns}
              dataSource={mobileTableData}
              onChange={this.handleTableChange}
              loading={this.props.loading}
              onRow={(record, index) => {
                return {
                  onClick: (event) => {
                    this.getFormDetails(record.item);
                  },
                };
              }}
            />
          </div>
          <div id="my-account-desktop-table">
            <Table
              className="table"
              pagination={{
                total: this.props.total,
                //hideOnSinglePage: true
                itemRender: this.itemRender,
              }}
              columns={columns}
              expandedRowRender={(record) => {
                if (voucherDetails.voucherId !== record.voucher.voucherId)
                  return null;
                return <>{this.renderDetails(voucherDetails)}</>;
              }}
              expanded={this.expanded}
              expandIcon={() => <></>}
              expandIconAsCell={false}
              expandRowByClick={true}
              dataSource={this.props.transactions}
              onChange={this.handleTableChange}
              loading={this.props.loading}
              onRow={(record, index) => {
                return {
                  onClick: (event) => {
                    this.getFormDetails(record);
                  },
                };
              }}
            />
          </div>
        </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    transactions: state.member.transactions,
    total: state.member.totalTransactions,
    currencies: state.general.currencies,
    formDetails: state.member.formDetails,
    betLines: state.member.betLines,
    betDetailsModalVisible: state.member.betDetailsModalVisible,
    voucherModalVisible: state.member.voucherModalVisible,
    loading: state.member.loading,
    formDateRange: state.member.formDateRange,
    voucherDetails: state.member.voucherDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  memberTransactions: (data) => dispatch(memberTransactions(data)),
  formsDetails: (data) => dispatch(formsDetails(data)),
  getVoucherDetails: (data) => dispatch(getVoucherDetails(data)),
  openMobileTableDetails: (open) => dispatch(openMobileTableDetails(open)),
  openBetDetailsModal: (open) => dispatch(openBetDetailsModal(open)),
  openVoucherModal: (open) => dispatch(openVoucherModal(open)),
  openMobileVoucherDetails: (open) => dispatch(openMobileVoucherDetails(open)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);


import { all, fork, takeLatest, put, select } from "redux-saga/effects";
import { GeneralActionTypes, SignalRTypes, BetSlipActionTypes, MembersActionTypes, AppActionTypes } from '../actions';
import { generalApi, pagesApi } from '../../api';
import { SessionStorage, Cookies, AppConfig } from "../../helpers";
import { Env } from '../../helpers/enums';
import { Enums } from '../../helpers';

export function* requests() {

	yield takeLatest(GeneralActionTypes.Get_Currencies_Request, function* () {
		//call server
		try {
			let response = yield generalApi.getCurrencies();
			switch (response.status) {
				case 1:
					let data = response.data;
					let balances = data.reduce((acc, current) => {
						let currencyName = current.currencyName.split(' ').join('');
						if (!acc[currencyName]) {
							acc[currencyName] = {
								currencyId: current.currencyId,
								currencySymbol: current.currencySymbol,
								defaultCurrency: current.currencyIsDefault
							}
						}
						return acc;
					}, {});
					let currency = Cookies.get('selectedCurrency');
					if (!currency) {
						let currentCurrency = Object.values(balances).find((item) => item.defaultCurrency)
						yield put({ type: MembersActionTypes.Set_Selected_Currency, currency: currentCurrency })
						Cookies.set('selectedCurrency', currentCurrency)
					}
					else {
						yield put({ type: MembersActionTypes.Set_Selected_Currency, currency: currency })
					}
					yield put({ type: GeneralActionTypes.Get_Currencies_Success, data: balances });
					break;
				case 3 || 4:
					yield put({ type: GeneralActionTypes.Get_Currencies_Error, message: response.message });
					break;
			}
		}
		catch (error) {
			window.log('client =>> could not get currencies: ', error);
		}
	});

	yield takeLatest(GeneralActionTypes.Map_Request, function* (mapData) {
		//call server
		try {
			let response = yield generalApi.mapMarkers(mapData.data);
			let data = response.data;

			switch (response.status) {
				case 1:
					yield put({ type: GeneralActionTypes.Map_Success, data });
					break;
				case 3 || 4:
					yield put({ type: GeneralActionTypes.Map_Error, message: response.message });
					break;
			}
		}
		catch (error) {
			window.log('client =>> could not get map: ', error);
		}
	});

	yield takeLatest(GeneralActionTypes.Form_Statuses_Request, function* ({ status }) {
		//call server
		try {
			let response = yield generalApi.FormStatuses(status);
			let data = response.data;

			switch (response.status) {
				case 1:
					yield put({ type: GeneralActionTypes.Form_Statuses_Success, data });
					break;
				case 3 || 4:
					yield put({ type: GeneralActionTypes.Form_Statuses_Error, message: response.message });
					break;
			}
		}
		catch (error) {
			window.log('client =>> could not get form statuses: ', error);
		}
	});

	yield takeLatest(GeneralActionTypes.Page_Name_Request, function* (pageName) {
		//call server
		try {
			let response = yield pagesApi.document(pageName.data);
			let data = response.data;

			if (data.pageText)
				data.pageText = data.pageText.replace(/&nbsp;/gi, '');

			switch (response.status) {
				case 1:
					yield put({ type: GeneralActionTypes.Page_Name_Success, data });
					break;
				case 3 || 4:
					yield put({ type: GeneralActionTypes.Page_Name_Error, message: response.message });
					break;
			}
		}
		catch (error) {
			window.log('client =>> could not get page: ', error);
		}
	});

	yield takeLatest(GeneralActionTypes.Web_Files_Request, function* () {
		//call server
		try {
			let response = yield generalApi.GetWebFiles({ id: 0 })
			let data = response.data;

			switch (response.status) {
				case 1:
					yield put({ type: GeneralActionTypes.Web_Files_Success, data });
					break;
				case 3 || 4:
					yield put({ type: GeneralActionTypes.Web_Files_Error, message: response.message });
					break;
			}
		}
		catch (error) {
			window.log('client =>> could not get web files: ', error);
		}
	});


	yield takeLatest(GeneralActionTypes.Web_File_Request, function* (webFile) {
		//call server
		try {
			let response = yield generalApi.GetWebFile(webFile.data)

			if (response) {
				let file = response.data.fileContents
				let fileName = response.data.fileDownloadName;
				let binary = atob(file.replace(/\s/g, ''))
				let view = new Uint8Array(file.length);
				for (var i = 0; i < file.length; i++) {
					view[i] = binary.charCodeAt(i);
				}
				let blob = new Blob([view], { type: 'application/pdf' });
				let url = URL.createObjectURL(blob)
				yield put({ type: GeneralActionTypes.Web_File_Response, url, fileName });
			}
		}
		catch (error) {
			window.log('client =>> could not get web file: ', error);
		}
	});

	yield takeLatest(GeneralActionTypes.Bets_By_Bet_Code_Request, function* ({ request }) {
		//call server
		try {
			let response = yield generalApi.BetsByBetCode(request)
			let data = response.data;
			let fieldsrequest = [];
			if (data && data.length > 0) {

				data.map((item) => {
					fieldsrequest.push({
						feed: { feedId: item.feedId },
						sport: { sportId: item.sportId },
						eventId: item.eventId,
						betType: { betTypeId: item.betTypeId },
						oddSpecialField: item.oddSpecialField,
						activeOddFieldId: item.activeOddFieldId,
					})
				});

			}

			switch (response.status) {
				case 1:
					yield put({ type: BetSlipActionTypes.BetSlip_Clear });
					yield put({ type: GeneralActionTypes.Create_Bet_Code_Success, code: request.id });
					yield put({ type: GeneralActionTypes.Bets_By_Bet_Code_Success });
					SessionStorage.betCode = request.id;

					if (fieldsrequest && fieldsrequest.length > 0) {
						yield put({ type: SignalRTypes.Get_Updated_OddField_Details, request: fieldsrequest });
					}
					break;
				case 3 || 4:
					yield put({ type: GeneralActionTypes.Bets_By_Bet_Code_Error, message: response.message });
					break;
			}
		}
		catch (error) {
			window.log('client =>> could not get bet: ', error);
		}
	});


	yield takeLatest(GeneralActionTypes.Create_Bet_Code_Request, function* () {
		//call server
		try {
			const { betSlip } = yield select();
			let list = []
			list = Object.keys(betSlip.betSlipList).map(item => {
				return {
					feedId: betSlip.betSlipList[item].feed.feedId,
					sportId: betSlip.betSlipList[item].sport.sportId,
					eventId: betSlip.betSlipList[item].eventId,
					oddId: betSlip.betSlipList[item].oddId,
					oddFieldNumber: betSlip.betSlipList[item].oddFieldNumber,
					betLineNo: betSlip.betSlipList[item].eventBetLineNo,
					betTypeId: betSlip.betSlipList[item].betType.betTypeId
				}
			});

			let data = {
				betLines: list,
			}
			let response = yield generalApi.CreateBetCode(data)

			switch (response.status) {
				case 1:
					yield put({ type: GeneralActionTypes.Create_Bet_Code_Success, code: response.data.code });
					SessionStorage.betCode = response.data.code;
					break;
				case 3 || 4:
					yield put({ type: GeneralActionTypes.Create_Bet_Code_Error, message: response.message });
					break;
			}
		}
		catch (error) {
			window.log('client =>> could not create bet code: ', error);
		}
	});

	yield takeLatest(GeneralActionTypes.Get_DrillDown_Groups_Request, function* () {
		//call server
		try {
			let response = yield generalApi.getBetTypesDrillDownGroups()

			switch (response.status) {
				case 1:
					yield put({ type: GeneralActionTypes.Get_DrillDown_Groups_Success, data: response.data });
					break;
				case 3 || 4:
					yield put({ type: GeneralActionTypes.Get_DrillDown_Groups_Error, message: response.message });
					break;
			}
		}
		catch (error) {
			window.log('client =>> could not get BetTypes DrillDown Groups: ', error);
		}
	});
	yield takeLatest(GeneralActionTypes.Get_Configuration_Request, function* () {
		//call server
		try {
			let response = yield generalApi.getConfiguration()

			switch (response.status) {
				case 1:
					yield put({ type: GeneralActionTypes.Get_Configuration_Success, data: response.data });
					Cookies.set("tokenExpiresMinutes", response.data.tokenExpiresMinutes, response.data.tokenExpiresMinutes);

					if (AppConfig.ENV != Env.LOCAL) {
						AppConfig.srAddress =  response.data.feedSR; 
						AppConfig.booongoSRAddress = response.data.booongoSR;
						AppConfig.jackpotSRAddress = response.data.jackpotSR;
						AppConfig.ShowSignInAndRegistersBtn = response.data.showSignInAndRegistersBtn === 1 ? true : false;
						// AppConfig.srAddress = "https://localhost:5001/FeedChangesHub"
						// AppConfig.booongoSRAddress = "https://uk-hub-bng.winners.com.lr/BooongoHub"
						// AppConfig.jackpotSRAddress = "https://uk-hub-jp.winners.com.lr/JackpotHub"
						// response.data.imagesPath = "https://uk-web-api.boxsyst.com/api/general/image/"
					}
					AppConfig.booongoAddress = response.data.booongoAddress;
					AppConfig.VirtualAddress.VFL = response.data.vfl; //Not in used
					AppConfig.VirtualAddress.VHC = response.data.vhc; //Not in used
					AppConfig.VirtualAddress.VFLM = response.data.vflm; 
					AppConfig.VirtualAddress.VBL = response.data.vbl;
					AppConfig.VirtualAddress.VTO = response.data.vto; //Not in used
					AppConfig.VirtualAddress.VDR = response.data.vdr; //Not in used
					AppConfig.VirtualAddress.EPL = response.data.epl;
					AppConfig.icons = `${response.data.imagesPath}icons/`;
					AppConfig.symbols = `${response.data.imagesPath}symbols/`;

					AppConfig.StatisticsAddress.Domain = response.data.statisticsDomainUrl;
					AppConfig.StatisticsAddress.Static = response.data.statisticsStaticUrl;
					AppConfig.StatisticsAddress.Virtual = response.data.statisticsVirtualUrl;
					AppConfig.StatisticsAddress.Menu = response.data.statisticsMenuUrl;
			
					yield put({ type: AppActionTypes.Start });

					break;
				case 3 || 4:
					yield put({ type: GeneralActionTypes.Get_Configuration_Error, message: response.message });
					break;
				default:
					break;
			}
		}
		catch (error) {
			window.log('client =>> could not get BetTypes DrillDown Groups: ', error);
		}
	});

	yield takeLatest(GeneralActionTypes.Bet_Code_Clear, function* () {
		//call server
		try {
			yield put({ type: GeneralActionTypes.Create_Bet_Code_Success, code: "" });
			SessionStorage.betCode = "";
		}
		catch (error) {
			window.log('client =>> could not create bet code: ', error);
		}
	});

	yield takeLatest(GeneralActionTypes.Get_CountryPhone_Providers_Request, function* ({ data }) {
		//call server
		try {
			let response = yield generalApi.getCountryPhoneProviders(data)
			switch (response.status) {
				case 1:
					let prefixes = response.data.map((provider) => provider.providerPrefix);
					yield put({ type: GeneralActionTypes.Get_CountryPhone_Providers_Success, prefixes: prefixes });
					break;
				case 3 || 4:
					yield put({ type: GeneralActionTypes.Get_CountryPhone_Providers_Error, message: response.message });
					break;
			}

		}
		catch (error) {
			window.log('client =>> could not get country provides ', error);
		}
	});

	yield takeLatest(GeneralActionTypes.Get_Countries_Request, function* () {
		//call server
		try {
			let response = yield generalApi.getCountries()
			switch (response.status) {
				case 1:
					yield put({ type: GeneralActionTypes.Get_Countries_Success, countries: response.data });
					break;
				case 3 || 4:
					yield put({ type: GeneralActionTypes.Get_Countries_Error, message: response.message });
					break;
			}

		}
		catch (error) {
			window.log('client =>> could not get countries ', error);
		}
	});

	yield takeLatest(GeneralActionTypes.Get_Counties_Request, function* () {
		//call server
		try {
			let response = yield generalApi.getCounties()
			switch (response.status) {
				case 1:
					yield put({ type: GeneralActionTypes.Get_Counties_Success, counties: response.data });
					break;
				case 3 || 4:
					yield put({ type: GeneralActionTypes.Get_Counties_Error, message: response.message });
					break;
			}

		}
		catch (error) {
			window.log('client =>> could not get counties ', error);
		}
	});
}

export default function* GeneralSaga() {
	yield all([
		fork(requests)
	])
}


import { Fetch, AppConfig } from '../helpers'

export default class generalApi {

	static mapMarkers = (query) => {
		const response = Fetch.fetch(
			{
				methodName: "MapMarkers",
				method: "POST",
				url: AppConfig.api.general,
				data: query
			}
		)
		return response;
	}

	static betSlipConfiguration = () => {
		const response = Fetch.fetch(
			{
				methodName: "BetSlipConfiguration",
				method: "POST",
				url: AppConfig.api.general,
			}
		)

		return response;
	}

	static FormStatuses = (data) => {
		const response = Fetch.fetch(
			{
				methodName: 'FormStatuses',
				url: AppConfig.api.general,
				data
			}
		)
		return response;
	}

	static GetWebFiles = () => {
		const response = Fetch.fetch(
			{
				methodName: 'GetWebFiles',
				url: AppConfig.api.general,
			}
		)
		return response;
	}

	static GetWebFile = (data) => {
		const response = Fetch.fetch(
			{
				methodName: 'GetWebFile',
				url: AppConfig.api.general,
				data
			}
		)
		return response;
	}

	static BetsByBetCode = (data) => {
		const response = Fetch.fetch(
			{
				methodName: 'BetsByBetCode',
				url: AppConfig.api.general,
				data
			}
		)
		return response;
	}

	static CreateBetCode = (data) => {
		const response = Fetch.fetch(
			{
				methodName: 'CreateBetCode',
				url: AppConfig.api.general,
				data
			}
		)
		return response;
	}
	static getBetTypesDrillDownGroups = () => {
		const response = Fetch.fetch(
			{
				methodName: 'GetBetTypesDrillDownGroups',
				url: AppConfig.api.general,
			}
		)
		return response;
	}
	static getConfiguration = () => {
		const response = Fetch.fetch(
			{
				methodName: 'Configuration',
				url: AppConfig.api.general,
			}
		)
		return response;
	}

	static getCurrencies = () => {
		const response = Fetch.fetch(
			{
				methodName: 'GetCurrencies',
				url: AppConfig.api.general,
			}
		)
		return response;
	}

	static getCountryPhoneProviders = (data) => {
		const response = Fetch.fetch(
			{
				methodName: 'GetCountryPhoneProviders',
				url: AppConfig.api.general,
				data
			}
		)
		return response;
	}

	static getCountries = () => {
		const response = Fetch.fetch(
			{
				method: 'GET',
				methodName: 'GetCountries',
				url: AppConfig.api.general,
			}
		)
		return response;
	}

	static getCounties = () => {
		const response = Fetch.fetch(
			{
				method: 'GET',
				methodName: 'GetCounties',
				url: AppConfig.api.general,
			}
		)
		return response;
	}
}

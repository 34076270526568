import UniversalCookies from 'universal-cookie';

const cookies = new UniversalCookies();

export default class Cookies {
	static set = (name, value, expiredMinutes, options = { path: '/' }) => {
		let dateExpires = new Date();
		let minutes = parseInt(expiredMinutes);
		dateExpires.setMinutes(dateExpires.getMinutes() + minutes);
		options.expires = dateExpires;

		cookies.set(name, JSON.stringify(value), options);
	}

	static get = (name, options = { path: '/' }) => {
		const value = cookies.get(name, options);
		if (!value || value === undefined || value === 'undefined') {
			return null;
		}

		return value;
	}

	static remove = (name,  options = { path: '/' }) => {
		cookies.remove(name, options)
	}
}
import { Button, Form, Input, Modal, Steps } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Alert, PhoneNumber } from '../../../components';
import { addMemberPhone, sendAddValidatePhone, getMemberDetails } from '../../../redux/actions/membersActions';
import { sendRegisterData } from '../../../redux/actions/registerActions';

class AddPhoneNumber extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.form.validateFields();
	}
	componentDidUpdate(prevProps) {
		if (this.props.current === 1 && this.props.current != prevProps.current) {
			this.props.getMemberDetails()
		}

	}
	hasErrors = (fieldsError) => {
		return Object.keys(fieldsError).some(field => fieldsError[field]);
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.sendRegisterData({ ...this.props.formData, smsCode: this.props.form.getFieldValue('code') })
		this.props.addMemberPhone();
	}

	validateCode = (rule, value, callback) => {
		if (this.props.form.getFieldValue('code') && this.props.form.getFieldValue('code').length === 6) {
			return callback();
		}
		else {
			return callback(rule.message);
		}
	}
	// send second phone for confirmation
	handleAddPhone = (e) => {
		e.preventDefault();
		let secondPhone = '231' + this.props.form.getFieldValue('prefix') + this.props.form.getFieldValue('phoneNum')
		let data = {
			name: secondPhone
		}
		this.props.sendAddValidatePhone(data);
		this.props.sendRegisterData({ ...this.props.formData, secondPhone })
	}
	// resend sms
	handleResendSMS = () => {
		let data = {
			name: this.props.formData.secondPhone
		}
		this.props.sendAddValidatePhone(data);
	}

	close = () => {
		this.props.onCloseAddPhone();
	}

	render() {
		const { Step } = Steps;
		const { isFieldTouched, getFieldError, getFieldDecorator, getFieldsError } = this.props.form;
		const codeError = isFieldTouched('code') && getFieldError('code');
		const prefixError = isFieldTouched('prefix') && getFieldError('prefix');
		const phoneError = isFieldTouched('phoneNum') && getFieldError('phoneNum');

		const confirmPhoneNumber = <div className="modal-content confirmation-form">
			<h3>Confirmation Code</h3>
			<Form.Item
				label='code'
				validateStatus={codeError ? 'error' : ''}
				help={codeError || ''}>
				{getFieldDecorator('code', {
					rules: [{ required: true }, { validator: this.validateCode, message: "must be 6 characters" }]
				})(

					<Input className="confirmation-input" type="number" />
				)}
			</Form.Item>

			<div className="btn">
				<Button type="primary" onClick={this.handleSubmit} loading={this.props.loading} disabled={this.hasErrors(getFieldsError())}>Verify</Button>
			</div>
			<Button className="btn btn_none_bg" onClick={this.handleResendSMS} >Resend SMS</Button>
		</div>

		const addPhoneNumber = this.props.phoneValid ? confirmPhoneNumber :
			<div className="add-second-phone">
				<h3>Add Phone Number</h3>
				<Form onSubmit={this.handleAddPhone}  >
					<Form.Item
						validateStatus={prefixError || phoneError ? 'error' : ''}
						help={prefixError || phoneError || ''}
						className="phone-number-item">
						{getFieldDecorator('secondPhone', {
							rules: [{ message: 'Numbers Only!' }],
						})(
							<PhoneNumber mode="input" form={this.props.form} />
						)}
					</Form.Item>
					<div>
						<Button type="primary" loading={this.props.loading} htmlType="submit">Add</Button>
					</div>
				</Form>
			</div>

		const successPhoneAdd = <div>
			<h3 style={{padding: "5px 0"}}>You have successfully added the second phone!</h3>
			<Button type="primary" onClick={this.close}>Close</Button>
		</div>

		const steps = [{
			title: 'Add Phone Number',
			content: addPhoneNumber,
		}, {
			title: 'Add Phone Success',
			content: successPhoneAdd,
		}];

		return (
			this.props.visible &&
			<Modal className='add-phone-modal' onCancel={this.close} visible={this.props.visible} footer={[null, null]}>
				<Steps style={{ display: 'none' }} current={this.props.current}>
					{steps.map(item => <Step key={item.title} title={item.title} />)}
				</Steps>
				<div className="steps-content">{steps[this.props.current].content}</div>

				{
					this.props.message &&
					<Alert message={this.props.message} type="error" ></Alert>
				}
			</Modal>
		)
	}

}
const mapStateToProps = (state, props) => {
	return {
		message: state.member.message,
		current: state.member.addPhoneCurrent,
		loading: state.member.loading,
		formData: state.register.formData,
		phoneValid: state.member.phoneValid
	}
}

const mapDispatchToProps = (dispatch) => ({
	addMemberPhone: () => dispatch(addMemberPhone()),
	sendAddValidatePhone: (data) => dispatch(sendAddValidatePhone(data)),
	sendRegisterData: (data) => dispatch(sendRegisterData(data)),
	getMemberDetails: () => dispatch(getMemberDetails())
})

const wrappedAddPhoneNumber = Form.create({ name: 'register' })(AddPhoneNumber);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedAddPhoneNumber)
import React from 'react'
import UpcomingRow from './UpcomingRow'
import { connect } from 'react-redux';

class Upcoming extends React.Component {

	render() {
		const { upcomingIds } = this.props;
		return (
			<div className="upcoming">
				{
					upcomingIds && upcomingIds.length > 0 &&
					<div>
						<div className="title bold">Upcoming Events</div>
						<div className="events">
							{
								upcomingIds.map((id) => <UpcomingRow key={id} id={id}></UpcomingRow>)
							}
						</div>
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		upcomingIds: state.sportEvents.upcomingEventIds,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Upcoming);

import { Button, Form, Input } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Alert } from "../../components";
import { Cookies } from "../../helpers";
import {
  registerMember,
  sendAddValidatePhone,
  sendRegisterData,
  sendValidatePhone,
  addMemberPhone,
} from "../../redux/actions/registerActions";
import { skip } from "../../redux/actions/membersActions";

class Step2 extends React.Component {
  state = {
    OTP: {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
    },
  };
  async componentDidMount() {
    this.props.form.validateFields();
  }

  hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };
  // validate confirmation code
  validateCode = (rule, value, callback) => {
    if (
      this.props.form.getFieldValue("code") &&
      this.props.form.getFieldValue("code").length === 6
    ) {
      return callback();
    } else {
      return callback(rule.message);
    }
  };
  // resend sms
  handleResendSMS = () => {
    this.props.form.resetFields();
    // if member is registered than we dont send the captcha
    if (Cookies.get("memberId")) {
      let data = {
        name: this.props.formData.secondPhone,
      };
      this.props.sendAddValidatePhone(data);
    } else {
      let data = {
        phoneNumber: this.props.formData.phone,
        recaptchaResponse: this.props.formData.captcha,
        reSendSms: true,
      };
      this.props.sendValidatePhone(data, true);
    }
  };

  // register new member
  handleOnSubmit = () => {
    // if member exist we send the token and the member id
    this.props.sendRegisterData({
      ...this.props.formData,
      smsCode: Object.values(this.state.OTP).join("").replaceAll(",", ""),
    });
    if (Cookies.get("memberId")) {
      this.props.addMemberPhone();
    } else {
      // register member
      this.props.registerMember();
    }
  };

  skip = () => {
    this.props.skip();
  };
  handleOTPChange = (e, id) => {
    const inputElement = document.getElementById(id);
    console.log(
      "e.target.value.split([e.target.value.length-1]",
      e.target.value.split("")[e.target.value.length - 1]
    );
    // inputElement.value = e.target.value.split("")[e.target.value.length - 1];

    // console.log("[id.split('-')[1]]" , [id.split('-')[1]]);
    // console.log("OTP" , this.state.OTP);
    // console.log("e.target.value" , e.target.value);
    this.setState(
      (this.state.OTP = {
        ...this.state.OTP,
        [id.split("-")[1]]: e.target.value,
      })
    );
    if (e.target.value != "" && inputElement.nextSibling) {
      inputElement.nextSibling.focus();
    }
    if (e.target.value == "" && inputElement.previousSibling) {
      inputElement.previousSibling.focus();
    }
    if (!inputElement.nextSibling) {
      console.log(
        "Object.values(this.state.OTP)",
        Object.values(this.state.OTP).join("").replaceAll(",", "")
      );
    }
  };
  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  onInputFocus = (e) => {
    e.target.select();
  };

  render() {
    const formItemLayout = null;
    const { message, resendSmsMessage, current } = this.props;
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form;
    const codeError = isFieldTouched("code") && getFieldError("code");
    return (
      <div className="step">
        {/* <div className="strip">
					<div className="content">
						<div className="text">
							We sent you a code to
							<br />
							{current === 1 ? this.props.formData.phone : this.props.formData.secondPhone}
						</div>
					</div>
				</div> */}
        <p className="forget-password-headline">SMS Vertification</p>

        <div className="content">
          <div className="form">
            {message && <Alert type="error" icon="!" message={message} />}
            {resendSmsMessage && (
              <Alert type="success" message={resendSmsMessage}></Alert>
            )}
            {/* <Form.Item
							label="Code"
							validateStatus={codeError ? 'error' : ''}
							help={codeError || ''}>

							{
								getFieldDecorator('code', {
									rules: [{ required: true }, { validator: this.validateCode, message: "must be 6 characters" }]
								})(
									<Input className="confirmation-input" type="number"></Input>
								)
							}
						</Form.Item> */}
            <div className="OTP-fields">
              <Form.Item {...formItemLayout}>
                <Input
                  onFocus={(e) => this.onInputFocus(e)}
                  onChange={(e) => this.handleOTPChange(e, "opt-1")}
                  id="opt-1"
                  className="confirmation-input"
                  type="number"
                  maxLength={1}
                  onInput={this.maxLengthCheck}
                />
                <Input
                  onFocus={(e) => this.onInputFocus(e)}
                  onChange={(e) => this.handleOTPChange(e, "opt-2")}
                  id="opt-2"
                  className="confirmation-input"
                  type="number"
                  maxLength={1}
                  onInput={this.maxLengthCheck}
                />
                <Input
                  onFocus={(e) => this.onInputFocus(e)}
                  onChange={(e) => this.handleOTPChange(e, "opt-3")}
                  id="opt-3"
                  className="confirmation-input"
                  type="number"
                  maxLength={1}
                  onInput={this.maxLengthCheck}
                />
                <Input
                  onFocus={(e) => this.onInputFocus(e)}
                  onChange={(e) => this.handleOTPChange(e, "opt-4")}
                  id="opt-4"
                  className="confirmation-input"
                  type="number"
                  maxLength={1}
                  onInput={this.maxLengthCheck}
                />
                <Input
                  onFocus={(e) => this.onInputFocus(e)}
                  onChange={(e) => this.handleOTPChange(e, "opt-5")}
                  id="opt-5"
                  className="confirmation-input"
                  type="number"
                  maxLength={1}
                  onInput={this.maxLengthCheck}
                />
                <Input
                  onFocus={(e) => this.onInputFocus(e)}
                  onChange={(e) => this.handleOTPChange(e, "opt-6")}
                  id="opt-6"
                  className="confirmation-input"
                  type="number"
                  maxLength={1}
                  onInput={this.maxLengthCheck}
                />
              </Form.Item>
            </div>
            <div
              className="login-forget-password-container"
              onClick={this.handleResendSMS}
            >
              <span>Resend SMS</span>
            </div>
            <div className="step1-button-container">
              <Button
                className="next"
                type="primary"
                onClick={this.handleOnSubmit}
                loading={this.props.loading}
                disabled={this.hasErrors(getFieldsError())}
              >
                Next
              </Button>
            </div>
            {this.props.secondPhone && (
              <Button className="next btn_none_bg" onClick={this.skip}>
                Skip
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state, props) => {
  return {
    message: state.register.message,
    loading: state.register.loading,
    current: state.register.current,
    formData: state.register.formData,
    resendSmsMessage: state.register.resendSmsMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendValidatePhone: (data, resend) =>
    dispatch(sendValidatePhone(data, resend)),
  registerMember: () => dispatch(registerMember()),
  sendAddValidatePhone: (data) => dispatch(sendAddValidatePhone(data)),
  addMemberPhone: (data) => dispatch(addMemberPhone(data)),
  sendRegisterData: (data) => dispatch(sendRegisterData(data)),
  skip: () => dispatch(skip()),
});
const WrappedStep2 = Form.create({ name: "Step2" })(Step2);
export default connect(MapStateToProps, mapDispatchToProps)(WrappedStep2);

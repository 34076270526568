import { Button, Form, Input, Select, Steps } from "antd";
import React from "react";
import { connect } from "react-redux";
import { getRegisterEntities, init } from "../../redux/actions/registerActions";
import { updateMemberDetails } from "../../redux/actions/membersActions";
import { Cookies, CountryCodes } from "../../helpers";
import { getCounties } from "../../redux/actions/generalActions";
import { Step5 } from "./";
import { Alert } from "../../components";

class Step4 extends React.Component {
  componentDidMount() {
    this.props.getRegisterEntities();
    this.props.getCounties();

  }

  register = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //update member
        console.log("aunaunauanaua",  values.county);
        let data = {
          memberId: Cookies.get("memberId"),
          email: values.email,
          genderId: values.gender,
          firstName: values.firstName,
          lastName: values.lastName,
          securityQuestionId: values.question,
          answer: values.sequrityAnswer,
          countryId: values.country,
          countyId: values.county,
          city: values.city,
          address: values.address,
          postalCode: values.postalCode,
        };
        window.log("recieved ", data);
        this.props.updateMemberDetails(data);
      }
    });
  };
  getCountryFlag = (name) => {
    let result = CountryCodes.Codes.filter((item) => {
      return item.name.toLowerCase() == name.toLowerCase();
    });

    return result && result.length > 0 ? result[0].code.toLowerCase() : "";
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { message , userData , counties , countries } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return this.props.registered ? (
      <Step5 {...this.props} />
    ) : (
      <div className="step">
        <p className="forget-password-headline">Personal Info</p>

        <div className="content">
          <div className="form">
            <Form onSubmit={this.register}>
              <div className="forget-password-confirmation-input-label-container">
                <Form.Item {...formItemLayout} label="E-mail">
                  {getFieldDecorator("email", {
                    // rules: [{ required: true, type: 'email', message: 'The input is not valid E-mail!' }]
                    rules: [
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Gender">
                  {getFieldDecorator("gender", {
                    rules: [
                      { required: true, message: "Please select your gender!" },
                    ],
                  })(
                    <Select placeholder="Gender">
                      {this.props.genders
                        ? this.props.genders.map((gender, i) => (
                            <Select.Option
                              key={gender.genderId}
                              value={gender.genderId}
                            >
                              {gender.genderName}
                            </Select.Option>
                          ))
                        : ""}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="forget-password-confirmation-input-label-container">
                <Form.Item {...formItemLayout} label="First Name">
                  {getFieldDecorator("firstName", {
                    rules: [{ required: true }],
                  })(<Input />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Last Name">
                  {getFieldDecorator("lastName", {
                    rules: [{ required: true }],
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="forget-password-confirmation-input-label-container">
              <Form.Item {...formItemLayout} label="County">
              {getFieldDecorator("county", {
                initialValue: userData.countyId
                  ? userData.countyId
                  : this.placeholder,
                rules: [
                  { required: true, message: "Please select your county" },
                ],
              })(
                <Select
                  placeholder="County"
                  //disabled={userData.countyId != 0}
                >
                  {counties
                    ? counties.map((county, i) => (
                        <Select.Option
                          key={county.countyName}
                          value={county.countyId}
                        >
                          {county.countyName}
                        </Select.Option>
                      ))
                    : ""}
                </Select>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Country">
                <Select
                  placeholder="Please Select Country"
                  disabled
                  defaultValue={121}
                >
                  {countries
                    ? countries.map((country, i) => (
                        <Select.Option
                          key={country.countryName}
                          value={country.countryId}
                        >
                          {
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            >
                              <div
                                className={
                                  "flag-icon flag-icon-" +
                                  this.getCountryFlag(country.countryName)
                                }
                              ></div>
                              <div
                                style={{
                                  paddingLeft: "10px",
                                  color: `${
                                    userData.countryId != 0
                                      ? "rgba(255, 255, 255, 0.44)"
                                      : "#ffff"
                                  }`,
                                }}
                              >
                                {country.countryName}
                              </div>
                            </div>
                          }
                        </Select.Option>
                      ))
                    : ""}
                </Select>
            </Form.Item>
              </div>

              {/* <Form.Item  {...formItemLayout} label="Question" >
									{getFieldDecorator('question', {
										rules: [{ required: true, message: 'Please select your security Question!' }]
									})(<Select placeholder="Security Question">
										{this.props.securityQuestions ? this.props.securityQuestions.map((question, i) => <Select.Option key={question.questionId} value={question.questionId}>{question.questionName}</Select.Option>) : ''}
									</Select>)}
								</Form.Item>
								<Form.Item {...formItemLayout} label="Security Answer" >
									{getFieldDecorator('sequrityAnswer', {
										rules: [{ required: true, message: 'Please fiil your Security Answer' }],
									})(<Input />)}
								</Form.Item> */}
              {/* <Form.Item  {...formItemLayout} label="Country" >
									{getFieldDecorator('country', {
										initialValue: 121,
									})
										(<Select disabled
										//	placeholder="please select country" 
										>
											{this.props.countries ?
												this.props.countries.map((country, i) => <Select.Option
													key={country.countryName}
													value={country.countryId}>
													<div style={{ marginRight: 10 }} className={"flag-icon flag-icon-" + this.getCountryFlag(country.countryName)}></div>
													{country.countryName}
												</Select.Option>)
												: ''}
										</Select>)}
								</Form.Item> */}
              {/* <Form.Item {...formItemLayout} label="City" >
									{getFieldDecorator('city')(<Input />)}
								</Form.Item>
								<Form.Item {...formItemLayout} label="Address" >
									{getFieldDecorator('address')(<Input />)}
								</Form.Item>
								<Form.Item {...formItemLayout} label="Postal Code" >
									{getFieldDecorator('postalCode')(<Input type="number" />)}
								</Form.Item> */}
              <div className="step1-button-container">
                <Button
                  className="next"
                  type="primary"
                  loading={this.props.loading}
                  htmlType="submit"
                >
                  Finish
                </Button>
              </div>
              {message && <Alert type="error" icon="!" message={message} />}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state, props) => {
  return {
    countries: state.register.countries,
    genders: state.register.genders,
    securityQuestions: state.register.securityQuestions,
    loading: state.register.loading,
    registered: state.register.registered,
    message: state.register.message,
    userData: state.member.userData,
    counties: state.general.counties,
    countries: state.register.countries,



  };
};

const MapDispatchToProps = (dispatch) => ({
  getRegisterEntities: () => dispatch(getRegisterEntities()),
  updateMemberDetails: (data) => dispatch(updateMemberDetails(data)),
  init: () => dispatch(init()),
  getCounties: () => dispatch(getCounties()),

});

const WrappedStep4 = Form.create({ name: "Step4" })(Step4);
export default connect(MapStateToProps, MapDispatchToProps)(WrappedStep4);

import React from 'react'
import { Bets } from '../../..'

const HeaderRow = (props) => {
	const { children, className } = props;
	return (
		<div className={className}>
			{children}
		</div>
	)
}

export default HeaderRow

import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import { SignalRTypes, SportTypesActionTypes } from '../actions';


export function* requests() {

    yield takeEvery(SignalRTypes.SportTypes_Response, function* ({ response }) {
        try {
            const { app } = yield select();

            //When refresh (F5), check if we have a sport category
            if (!app || !app.match) {
                return;
            }

            window.console.response("RES SportTypes", response);

            switch (response.status) {
                case 1:
                    response.data.sort((a, b) => a.sportOrder - b.sportOrder);
                    yield put({
                        type: SportTypesActionTypes.SportTypes_Success,
                        sportTypes: response.data,
                    })

                    //If no sport type, these means that thers no games.
                    //Do not continue...
                    if (response.data.length == 0) {
                        yield put({ type: SportTypesActionTypes.Init })
                        return;
                    }

                    let item = null;

                    const sport = app.match.params["sport"];

                    //Refresh page
                    if (sport) {
                        item = response.data.find(s => s.sport.sportId.toString() == sport)
                    }

                    //Get the default sport type
                    if (!item) {
                        item = response.data[0];
                    }

                    //CAREFUL: In case item is null, return INT 0
                    const sportId = item && item.sport.sportId || 0;

                    //Set the sport item
                    yield put({ type: SportTypesActionTypes.Set_Sport, item: item });

                    //Get the categories
                    let request = {
                        Data: {
                            Feed: { FeedId: Number(app.match.params.feed) },
                            Sport: { SportId: Number(app.match.params.sport) }
                        }
                    }
                    let x = response.data.find(y => y.feed.feedId == app.match.params.feed && y.sport.sportId == app.match.params.sport);
                    if (!x && response.data[0]) {
                        request = {
                            Data: {
                                Feed: { FeedId: Number(response.data[0].feed.feedId) },
                                Sport: { SportId: Number(response.data[0].sport.sportId) }
                            }
                        }
                    }
                    yield put({ type: SignalRTypes.SportCategories_Request, request })

                    break;

                case 3:
                    yield put({
                        type: SportTypesActionTypes.SportTypes_Error,
                        error: response.message,
                    })
                    break;

            }

        }
        catch (e) {
            window.log("ERROR", e)

        }
    })

    yield takeEvery(SportTypesActionTypes.SportTypes_Remove_Disabled, function* () {
        const { sportTypes } = yield select();

        let sportTypesCopy = [...sportTypes.sportTypes];

        sportTypesCopy = sportTypesCopy.filter((item) => !item.disabled);

        yield put({
            type: SportTypesActionTypes.SportTypes_Success,
            sportTypes: sportTypesCopy,
        })

    })
}
export default function* sportSaga() {
    yield all([
        fork(requests),
    ])
}

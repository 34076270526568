
import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import { CategoriesActionTypes, SignalRTypes } from '../actions';

export function* requests() {

	yield takeEvery(SignalRTypes.SportCategories_Response, function* ({ response }) {
		try {
			const { app } = yield select();

			window.console.response("RES Categories", response);

			switch (response.status) {
				case 1:
					// let responseCopy = response.data.sportCategories.map((item) => {
					// 	if (!item.categoryDisplayOrder) {
					// 		item.categoryDisplayOrder = 100000;
					// 	}

					// 	item.sportTournaments.map((item) => {
					// 		if (!item.tournamentDisplayOrder) {
					// 			item.tournamentDisplayOrder = 100000;
					// 		}
					// 	})
					// 	item.sportTournaments.sort((a, b) => a.tournamentDisplayOrder - b.tournamentDisplayOrder)
					// 	return item;



					// })
					// responseCopy.sort((a, b) => {
					// 	if (a.categoryDisplayOrder > b.categoryDisplayOrder) {
					// 		return 1;
					// 	} else if (a.categoryDisplayOrder < b.categoryDisplayOrder) {
					// 		return -1;
					// 	}

					// 	// Else go to the 2nd item
					// 	if (a.categoryDisplayName < b.categoryDisplayName) {
					// 		return -1;
					// 	} else if (a.categoryDisplayName > b.categoryDisplayName) {
					// 		return 1
					// 	} else { // nothing to split them
					// 		return 0;
					// 	}

					// });
					yield put({
						type: CategoriesActionTypes.SportCategories_Success,
						sportCategories: response.data.sportCategories,
					})
					yield put({
						type: CategoriesActionTypes.SportTopLeagues_Success,
						sportTopLeagues: response.data.sportTopLeagues,
					})



					const tournament = app.match.params["tournament"];
					const categoryId = app.match.params["country"];
					const day = app.match.params["day"];

					//Refresh page
					if (day) {// if path is today, get today events
						const request = {
							Data: {
								Feed: { FeedId: Number(app.match.params.feed) },
								Sport: { SportId: Number(app.match.params.sport) },
								Day:Number(day),
								IsLastMin: day == "-1"
							}
						}

						yield put({ type: SignalRTypes.SportEvents_By_Date_Request, request })


					}
					let item = null;
					//Refresh page
					if (tournament) {
						response.data.sportCategories.find(
							category => item = category.sportTournaments.find(t =>
								t.tournamentId == tournament && t.sportCategory.sportCategoryId == categoryId)
						)
					}
					//If no item, dont try load the sport events
					if (!item)
						return;

					//Set the tournament item
					yield put({ type: CategoriesActionTypes.Set_Tournament, item: item });

					break;

				case 3:
					yield put({
						type: CategoriesActionTypes.SportCategories_Error,
						error: response.message,
					})
					break;


			}
		} catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(CategoriesActionTypes.SportCategories_Remove_Disabled, function* () {
		const { categories } = yield select();

		let categoriesCopy = [...categories.sportCategories];
		let topLeaguesCopy = [...categories.sportTopLeagues];

		categoriesCopy = categoriesCopy.filter((item) => !item.disabled);
		topLeaguesCopy = topLeaguesCopy.filter((item) => !item.disabled);

		categoriesCopy.map((category) => {
			let categoryCopy = { ...category };
			let tournamentsCopy = [...categoryCopy.sportTournaments];
			tournamentsCopy = tournamentsCopy.filter((item) => !item.disabled);
			categoryCopy.sportTournaments = tournamentsCopy;
			category = categoryCopy;
		})


		yield put({
			type: CategoriesActionTypes.SportCategories_Success,
			sportCategories: categoriesCopy,
		})

		yield put({
			type: CategoriesActionTypes.SportTopLeagues_Success,
			sportTopLeagues: topLeaguesCopy,
		})
	})

}


export default function* sportSaga() {
	yield all([
		fork(requests),
	])
}

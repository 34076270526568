import React from "react";
import { connect } from "react-redux";
import { Layout, Icon } from "antd";
import { Screen, ScreenTitle } from "../../components";
import { getWebFile, getWebFiles } from "../../redux/actions/generalActions";

const { Sider, Content } = Layout;

class Fixture extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getWebFiles();
  }

  onBoxClick = (file) => {
    let data = {
      fileName: file.fileName,
      fileType: file.fileType,
    };
    this.props.getWebFile(data);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      this.downloadFile(this.props.url);
    }
  }
  /* Helper function */
  downloadFile = (fileURL) => {
    // for non-IE
    if (!window.ActiveXObject) {
      let save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      save.download = this.props.fileName;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        let evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }
  };

  displayFileName = (name) => {
    return name.split(/[-_]/g).join(" ");
  };

  render() {
    const resultsList = this.props.files
      ? this.props.files.resultFiles.map((file, i) => (
          <div
            key={i}
            className="box"
            onClick={() => {
              this.onBoxClick(file);
            }}
          >
            <div className="col1">
              <div className="icon">
                <Icon type="file-pdf" />
              </div>
            </div>
            <div className="col2">
              <div className="title">{this.displayFileName(file.fileName)}</div>
              <div className="text"></div>
            </div>
          </div>
        ))
      : "";
    const fixturesList = this.props.files
      ? this.props.files.fixtureFiles.map((file, i) => (
          <div
            key={i}
            className="box"
            onClick={() => {
              this.onBoxClick(file);
            }}
          >
            <div className="col1">
              <div className="icon">
                <Icon type="file-pdf" />
              </div>
            </div>
            <div className="col2">
              <div className="title">{this.displayFileName(file.fileName)}</div>
              <div className="text"></div>
            </div>
          </div>
        ))
      : "";

    return (
      <Screen {...this.props}>
        <Layout className="fixture mtop-20">
          <Layout className="layout-inner">
            <Sider width={220} className="sider-left"></Sider>
            <div className="content">
              <ScreenTitle hideIcon title="Fixture & Results" />

              <div className="container">
                <div className="left">
                  <div className="title">Download Results</div>
                  <div className="list">{resultsList}</div>
                </div>
                <div className="right">
                  <div className="title">Download Fixtures</div>
                  <div className="list">{fixturesList}</div>
                </div>
              </div>
            </div>

            <div className="right">
              <Sider width="100%" className="right"></Sider>
            </div>
            <Sider width={350} className="sider-right"></Sider>
          </Layout>
        </Layout>
        <div>
          <div></div>
          <div>
            <div className="form-container"></div>
          </div>
        </div>
      </Screen>
    );
  }
}

const MapStateToProps = (state, props) => {
  return {
    files: state.general.webFiles,
    url: state.general.url,
    fileName: state.general.fileName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getWebFiles: () => dispatch(getWebFiles()),
  getWebFile: (data) => dispatch(getWebFile(data)),
});

export default connect(MapStateToProps, mapDispatchToProps)(Fixture);

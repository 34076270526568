import { AuthActionTypes } from './actionTypes';

export function signIn(data) {
    return {
        type: AuthActionTypes.Signin_Request,
        data
    }
}
export function signOut(noActivity) {
    return {
        type: AuthActionTypes.Signout_Request,
        noActivity: noActivity || false
    }
}



export function forgotPasswordCheckDestination(data) {
    if (!data || data === undefined) {
        return {
            type: AuthActionTypes.Forgot_Password_Check_Dest_Error,
            message: 'Please Enter Phone or Email'
        }
    }
    return {
        type: AuthActionTypes.Forgot_Password_Check_Dest_Request,
        data
    }
}

export function forgotPassword(data) {
    if (!data || data === undefined) {
        return {
            type: AuthActionTypes.Forgot_Password_Error,
            message: 'Please Enter Phone or Email'
        }
    }
    return {
        type: AuthActionTypes.Forgot_Password_Request,
        data
    }
}

export function forgotPasswordConfirm(data) {
    if (!data.code || data.code === undefined) {
        return {
            type: AuthActionTypes.Forgot_Password_Confirm_Error,
            message: "Please enter the code that was sent to you"
        }
    }

    if (data.code.length !== 6) {
        return {
            type: AuthActionTypes.Forgot_Password_Confirm_Error,
            message: "code must be atleast 6 characters"
        }
    }

    if (data.isSecurityAnswer && (!data.securityAnswer || data.securityAnswer === undefined)) {
        return {
            type: AuthActionTypes.Forgot_Password_Confirm_Error,
            message: "Please answer the security question"
        }
    }

    // if (!data.isSecurityAnswer && (!data.birthDate || data.birthDate === undefined)) {
    //     return {
    //         type: AuthActionTypes.Forgot_Password_Confirm_Error,
    //         message: "Please fill birth date"
    //     }
    // }
    return {
        type: AuthActionTypes.Forgot_Password_Confirm_Request,
        data
    }
}

export function updatePassword(data) {
    let regex = RegExp(/^(?=.*[0-9])(?=.*[~_-|\^{}])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9~_-|\^{}]{8,}$/)
    if (!data.password || !data.confirmPassword || !data.password === undefined || !data.confirmPassword === undefined) {
        return {
            type: AuthActionTypes.Update_Password_Error,
            message: "please enter password"
        }
    }
    if (data.password !== data.confirmPassword) {
        return {
            type: AuthActionTypes.Update_Password_Error,
            message: "passwords don't match"
        }
    }
    if (!regex.test(data.password) || !regex.test(data.confirmPassword)) {
        return {
            type: AuthActionTypes.Update_Password_Error,
            message: "password must contain At least 8 characters, Lower case letters, Upper case letters, numbers and valid special characters are ~_-|\^{}"
        }
    }
    return {
        type: AuthActionTypes.Update_Password_Request,
        data
    }
}

export function changePassword(data) {
    let regex = RegExp(/^(?=.*[0-9])(?=.*[~_-|\^{}])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9~_-|\^{}]{8,}$/)
    if (!data.password || !data.oldPassword || !data.confirmPassword || data.password === undefined || data.confirmPassword === undefined || data.oldPassword === undefined) {
        return {
            type: AuthActionTypes.Change_Password_Error,
            message: "please enter password"
        }
    }
    if (data.password !== data.confirmPassword) {
        return {
            type: AuthActionTypes.Change_Password_Error,
            message: "passwords don't match"
        }
    }
    if (!regex.test(data.password) || !regex.test(data.confirmPassword)) {
        return {
            type: AuthActionTypes.Change_Password_Error,
            message: "password must contain At least 8 characters, Lower case letters, Upper case letters, numbers and valid special characters are ~_-|\^{}"
        }
    }
    return {
        type: AuthActionTypes.Change_Password_Request,
        data
    }
}

export function setShowLoginPopConfirm(value) {
    return {
        type: AuthActionTypes.Set_Show_Login_Pop_Confirm,
        value
    }
}


export function init() {
    return {
        type: AuthActionTypes.Init
    }
}

import { BetSlipActionTypes, SignalRTypes, GeneralActionTypes } from '../actions'


export function updateBetSlip(field) {
        return {
                type: BetSlipActionTypes.Update_BetSlip_List_Request,
                field
        }
}
export function onAmountChange(amount) {
        return {
                type: BetSlipActionTypes.On_Amount_Change,
                amount
        }
}

export function clearSucceededBets() {
        return {
                type: BetSlipActionTypes.Clear_Succeeded_Bets
        }
}
export function handleHideStake(forceHideStake) {
        return {
                type: BetSlipActionTypes.Handle_Hide_Stake,
                forceHideStake
        }
}


export function onFieldAmountChange(amount, fieldId) {
        return {
                type: BetSlipActionTypes.On_Field_Amount_Change,
                amount,
                fieldId
        }
}

export function setTab(tab) {
        return {
                type: BetSlipActionTypes.Set_Tab,
                tab
        }
}

export function removeField(field) {
        return {
                type: BetSlipActionTypes.BetSlip_Remove_Field,
                field
        }
}

export function placeMultipleBet() {
        return {
                type: BetSlipActionTypes.Place_Multiple_Bet_Request,
        }
}
export function placeSingleBet() {
        return {
                type: BetSlipActionTypes.Place_Single_Bet_Request,
        }
}
export function getFieldsUpdatedDetails(request) {
        return {
                type: SignalRTypes.Get_Updated_OddField_Details,
                request
        }
}
export function getBetSlipConfiguration(request) {
        return {
                type: GeneralActionTypes.Bet_Slip_Configuration_Request,
                request
        }
}
export function clearBetSlip() {
        return {
                type: BetSlipActionTypes.BetSlip_Clear
        }
}

export function clearBetSlipSuccess() {
        return {
                type: BetSlipActionTypes.Clear_BetSlip_Success
        }
}

export function setLoginError(value) {
        return {
                type: BetSlipActionTypes.Set_Login_Error,
                loginError: value
        }
}


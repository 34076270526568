import React, { Component } from "react";
import { Steps } from "antd";
import { connect } from "react-redux";
import { Step1, Step2, Step4 } from "../Register";
import {
  init,
  handleRegisterSignin,
} from "../../redux/actions/registerActions";
import { Images } from "../../components";

const { Step } = Steps;
const steps = [
  {
    // title: "Register Account",
    desc: "Register Account",
    content: <Step1 />,
  },
  {
    // title: "SMS Verifacation",
    desc: "SMS Verifacation",
    content: <Step2 />,
  },
  // {
  // 	title: 'Phone Number',
  // 	desc: 'Step 3',
  // 	content: <Step3 />,
  // },
  {
    // title: "Personal Info",
    desc: "Personal Info",
    content: <Step4 />,
  },
];

export class RegisterUser extends Component {
  removeWelcoming = () => {
    document.getElementById("user-welcoming-container").style.display = "none";
  };
  render() {
    const { current ,formData } = this.props;

    return (
      <div>
        {" "}
        <Steps current={current} className="forget-password-steps">
          {steps.map((item) => (
            <Step style={{ flex: "none" }} key={item.title} title={item.title} description={item.desc} />
          ))}
        </Steps>
        
        
        {current == 0 && (
          <div id="user-welcoming-container" className="strip">
            <div className="register-close-icon">
              <img
                style={{ cursor: "pointer" }}
                src={Images.CloseIcon}
                onClick={this.removeWelcoming}
              />
            </div>
            <div className="content">
              <div className="text">
                Thanks for your interest in Winners! Once you fill in the
                registration form below, you will receive the registration code
                via SMS.
              </div>
            </div>
          </div>
        )}
        {current == 1 && (
          <div id="user-welcoming-container" className="strip">
            <div className="register-close-icon">
              <img
                style={{ cursor: "pointer" }}
                src={Images.CloseIcon}
                onClick={this.removeWelcoming}
              />
            </div>
            <div className="content">
            <div className="text">
							<p>We send you a code to +{this.props.formData.phone ? this.props.formData.phone : this.props.formData.secondPhone}</p>

							
						</div>
            </div>
          </div>
        )}
        {current == 2 && (
          <div id="user-welcoming-container" className="strip">
            <div className="register-close-icon">
              <img
                style={{ cursor: "pointer" }}
                src={Images.CloseIcon}
                onClick={this.removeWelcoming}
              />
            </div>
            <div className="content">
            <div className="text">
							<p>Provide us with your personal information to enjoy the experience</p>
						</div>
            </div>
          </div>
        )}
        <div className="steps-content">{steps[current].content}</div>
      </div>
    );
  }
}

const MapStateToProps = (state, props) => {
  return {
    message: state.register.message,
    current: state.register.current,
    formData: state.register.formData,

  };
};

const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(init()),
  handleRegisterSignin: () => dispatch(handleRegisterSignin()),
});

export default connect(MapStateToProps, mapDispatchToProps)(RegisterUser);

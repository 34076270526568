import { Fetch,AppConfig } from '../helpers'

export default class pagesApi {

	static findUs = () => {
		const response = Fetch.fetch(
			{
				methodName: "find-us",
				method: "GET",
				url: AppConfig.api.pages,
			}
		)
		return response;
	}

	static document = (pageName) => {
		const response = Fetch.fetch(
			{
				methodName: pageName,
				method: "GET",
				url: AppConfig.api.pages,
			}
		)
		return response;
	}

}


import Events from './Events'
import SportRow from './rows/SportRow'
import HeaderRow from './rows/Header'
import EventsCol from './EventsCol'
import EventsHeader from './EventsHeader'
import EventDetails from './EventDetails'
import EventOdd from './EventOdd'
import EventOddGroup from './EventOddGroup'
import EventOddField from './EventOddField'
import DefaultScore from './eventTypes/DefaultScore';
import SetScore from './eventTypes/SetScore'
import OddsNumber from './OddsNumber'
import Statistics from './Statistics'
import TopBets from './topBets/TopBets'
import TopBetsCarousel from './topBets/Carousel'
import DrillDown from './drillDown/DrillDown'
import Upcoming from './upcoming/Upcoming'
import Today from './today/Today'
import Timer from './Timer'
import EventRow from './EventRow'

export default {
	Events,
	EventsCol,
	HeaderRow,
	SportRow,
	EventsHeader,
	EventDetails,
	EventOdd,
	EventOddGroup,
	EventOddField,
	DefaultScore,
	SetScore,
	OddsNumber,
	Statistics,
	TopBets,
	TopBetsCarousel,
	DrillDown,
	Upcoming,
	Today,
	Timer,
	EventRow

}
import { all, fork, takeEvery, put, select } from "redux-saga/effects";
import { contactUsApi } from '../../api/';
import { ContactUsActionTypes } from '../actions';

export function* requests() {
	yield takeEvery(ContactUsActionTypes.Subjects_Request, function* () {
		const { contactUs } = yield select()
		contactUs.resetFields = false;
		//call server
		try {
			let response = yield contactUsApi.getContactSubjects();
			let response2 = yield contactUsApi.findUs();

			switch (response.status) {
				case 1:
					yield put({
						type: ContactUsActionTypes.Subjects_Success,
						data: response.data,
						text: response2.data
					});
					break;
				case 3 || 4:
					yield put({ type: ContactUsActionTypes.Subjects_Error, message: response.message });
					break;
			}

		}
		catch (error) {
			window.log('client =>> could not get subjects: ', error);
		}
	})


	yield takeEvery(ContactUsActionTypes.Contact_Data_Request, function* (data) {
		//call server
		try {
			let response = yield contactUsApi.submitContactUs(data);

			switch (response.status) {
				case 1:
					yield put({ type: ContactUsActionTypes.Contact_Data_Success, message: response, resetFields: true });
					break;
				case 3 || 4:
					yield put({ type: ContactUsActionTypes.Contact_Data_Error, message: response, resetFields: false });
					break;
			}
		}
		catch (error) {
			window.log('could not send data: ', error);
		}
	})

}
export default function* ContactUsSaga() {
	yield all([
		fork(requests)
	])
}

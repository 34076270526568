
import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import { Utils, Enums, Constants } from '../../helpers';
import { SignalRTypes, AppActionTypes, SportEventsActionTypes, SportTypesActionTypes, CategoriesActionTypes, BetSlipActionTypes, VirtualActionTypes } from '../actions';


export function* requests() {

	yield takeEvery(SignalRTypes.Connect_Success, function* () {
		try {
			const { app } = yield select();
			const store = yield select();
			//Get feedName from url
			const feedName = app.match && app.match.url.split("/")[1];

			yield put({
				type: AppActionTypes.ChangeFeed,
				feedId: app.match && app.match.params.feed,
				feedName: feedName
			})

		} catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.OnSportTypesChange, function* ({ response }) {

		try {
			window.console.response("RES Sport Changes", response);

			const { sportTypes, app } = yield select();

			let newSportTypes = response.data.sportTypes;
			let duplicatedSportTypes = [...sportTypes.sportTypes];
			let sportId = app.match && app.match.params.sport;

			if (newSportTypes && newSportTypes.length > 0) {
				duplicatedSportTypes = handleSportTypesFeedChange(newSportTypes, duplicatedSportTypes, sportId);
				yield put({
					type: SportTypesActionTypes.SportTypes_Success,
					sportTypes: duplicatedSportTypes,
				})
			}
		} catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.OnSportTopLeaguesChange, function* ({ response }) {
		try {
			window.console.response("RES Top League Changes", response);

			const { app, categories } = yield select();

			let newTopLeagues = response.data.sportTopLeagues;
			let duplicatedSportTopLeagues = [...categories.sportTopLeagues];
			let sportId = app.match && app.match.params.sport;
			let countryId = app.match && app.match.params.country;


			if (newTopLeagues && newTopLeagues.length > 0) {
				duplicatedSportTopLeagues = handleTopLeaguesFeedChange(newTopLeagues, duplicatedSportTopLeagues, sportId, countryId);
				yield put({
					type: CategoriesActionTypes.SportTopLeagues_Success,
					sportTopLeagues: duplicatedSportTopLeagues,
				})
			}
		} catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.OnSportCategoriesChange, function* ({ response }) {
		try {
			window.console.response("RES Categories Changes", response);

			const { app, categories } = yield select();

			let newCategories = response.data.sportCategories;
			let duplicatedSportCategories = [...categories.sportCategories];
			let sportId = app.match && app.match.params.sport;
			let countryId =  app.match && app.match.params.country;

			if (newCategories && newCategories.length > 0) {

				duplicatedSportCategories = handleCategoriesFeedChange(newCategories, duplicatedSportCategories, sportId, countryId);
				sortCategories(duplicatedSportCategories)
				yield put({
					type: CategoriesActionTypes.SportCategories_Success,
					sportCategories: duplicatedSportCategories,
				})
			}
		} catch (e) {
			window.log("ERROR", e)
		}
	})
	yield takeEvery(SignalRTypes.OnSportTournamentsChange, function* ({ response }) {
		window.console.response("RES Tournament Changes", response);
		try {

			const { app, categories } = yield select();

			let newSportTournaments = response.data.sportTournaments;
			let duplicatedSportCategories = [...categories.sportCategories];
			let sportId = app.match && app.match.params.sport;
			let tournamentId = app.match && app.match.params.tournament;


			if (newSportTournaments && newSportTournaments.length > 0) {

				duplicatedSportCategories = handleTournamentsFeedChange(newSportTournaments, duplicatedSportCategories, sportId, tournamentId);
				sortCategories(duplicatedSportCategories);
				yield put({
					type: CategoriesActionTypes.SportCategories_Success,
					sportCategories: duplicatedSportCategories,
				})
			}
		} catch (e) {
			window.log("ERROR", e)
		}
	})
	yield takeEvery(SignalRTypes.OnSportEventsChange, function* ({ response }) {
		try {
			window.console.response("RES Events Changes", response);

			const { app, sportEvents } = yield select();
			let newSportEvents = response.data.sportEvents;

			let duplicatedSportEvents = { ...sportEvents.sportEvents }
			let duplicatedEventData = sportEvents.eventData && { ...sportEvents.eventData };
			let duplicatedEventsIds = sportEvents.eventsIds &&  [...sportEvents.eventsIds];

			let isTournamentSelected = app.match && app.match.params.hasOwnProperty("tournament")
			let feedId = app.match && app.match.params.feed;
			let sportId = app.match && app.match.params.sport;
			let countryId =  app.match && app.match.params.country;
			let tournamentId = app.match && app.match.params.tournament;



			if (newSportEvents && Object.keys(newSportEvents).length > 0) {
				if (app.match && app.match.params.hasOwnProperty("eventId") && duplicatedEventData) {// if current screen is drilldown, update changes for drilldown event only

					duplicatedEventData = handleSportEventsDrillDownFeedChange(newSportEvents, duplicatedEventData);

					yield put({
						type: SportEventsActionTypes.Set_Event,
						item: duplicatedEventData
					})
				} else {
					duplicatedSportEvents = handleSportEventsFeedChange(newSportEvents, duplicatedSportEvents, isTournamentSelected, sportId, feedId, tournamentId, countryId);
					//sort ids by date after feed change 
					if (duplicatedEventsIds) {
						duplicatedEventsIds.length = 0;
						Object.values(duplicatedSportEvents).sort((a, b) => {
							return new Date(a.eventDateOfMatch) - new Date(b.eventDateOfMatch)
						}).map((item) => {
							duplicatedEventsIds.push(item.key);
						})
					}
						yield put({
							type: SportEventsActionTypes.SportEvents_Events_FeedChange,
							sportEvents: duplicatedSportEvents,
							eventsIds: duplicatedEventsIds
						})
					
				}
			}
		} catch (e) {
			window.log("ERROR", e)
		}
	})
	yield takeEvery(SignalRTypes.OnEventScoreChange, function* ({ response }) {
		try {
			window.console.response("RES Events Score Changes", response);

			const { app, sportEvents } = yield select();
			let { eventScores } = response.data


			let duplicatedSportEvents = { ...sportEvents.sportEvents }
			let duplicatedEventData = sportEvents.eventData && { ...sportEvents.eventData };
			let duplicatedEventsIds = [...sportEvents.eventsIds];



			if (eventScores && Object.keys(eventScores).length > 0) {
				if (app.match && app.match.params.hasOwnProperty("eventId") && duplicatedEventData) {// if current screen is drilldown, update changes for drilldown event only


					duplicatedEventData = handleEventsScoreDrilDownFeedChange(eventScores, duplicatedEventData);
					yield put({
						type: SportEventsActionTypes.Set_Event,
						item: duplicatedEventData
					})

				} else {
					duplicatedSportEvents = handleEventsScoreFeedChange(eventScores, duplicatedSportEvents);
					//sort ids by date after feed change 
					duplicatedEventsIds.length = 0;
					Object.values(duplicatedSportEvents).sort((a, b) => {
						return new Date(a.eventDateOfMatch) - new Date(b.eventDateOfMatch)
					}).map((item) => {
						duplicatedEventsIds.push(item.key);
					})
					yield put({
						type: SportEventsActionTypes.SportEvents_Events_FeedChange,
						sportEvents: duplicatedSportEvents,
						eventsIds: duplicatedEventsIds
					})
				}
			}
		} catch (e) {
			window.log("ERROR", e)
		}
	})
	yield takeEvery(SignalRTypes.OnEventOddsChange, function* ({ response }) {
		try {

			window.console.response("RES Events ODDS Changes", response);

			const { sportEvents } = yield select();

			let { eventOdds } = response.data

			let duplicatedEventOdds = { ...sportEvents.eventOdds }

			if (eventOdds && Object.keys(eventOdds).length > 0) {

				duplicatedEventOdds = handleEventsOddsFeedChange(eventOdds, duplicatedEventOdds);
				yield put({
					type: SportEventsActionTypes.SportEvents_Odds_Success,
					eventOdds: duplicatedEventOdds,
				})
			}
		} catch (e) {
			window.log("ERROR", e)
		}


	})

	yield takeEvery(SignalRTypes.onEventOddGroupsChange, function* ({ response }) {
		try {
			window.console.response("RES Events ODDS GROUP Changes", response);

			const { app, sportEvents } = yield select();

			let { eventOddGroups } = response.data

			let duplicatedEventData = sportEvents.eventData && { ...sportEvents.eventData };


			if (eventOddGroups && Object.keys(eventOddGroups).length > 0) {

				if (app.match && app.match.params.hasOwnProperty("eventId") && duplicatedEventData) {// if current screen is drilldown, update changes for drilldown event only
					let duplicatedEventOddGroupIds = [...sportEvents.eventOddGroupIds];
					let duplicatedEventOddGroups = { ...sportEvents.eventOddGroups };
					duplicatedEventOddGroups = handleGroupDrilDownFeedChange(eventOddGroups, duplicatedEventOddGroups, duplicatedEventData);
					duplicatedEventOddGroupIds.length = 0;
					Object.keys(duplicatedEventOddGroups).sort((a, b) => a.betTypeGroupId - b.betTypeGroupId).map((item) => {
						duplicatedEventOddGroupIds.push(item);
					})
					let drillDownTabs = [];
					Object.values(duplicatedEventOddGroups).map(item => drillDownTabs.pushUnique(item.betTypeDrillDownGroupName))
					yield put({
						type: SportEventsActionTypes.SportEvents_Groups_Success,
						eventOddGroups: duplicatedEventOddGroups,
						eventOddGroupIds: duplicatedEventOddGroupIds,
						drillDownTabs: drillDownTabs

					})
				}

			}

		} catch (e) {
			window.log("ERROR", e)
		}
	})
	yield takeEvery(SignalRTypes.OnEventOddFieldsChange, function* ({ response }) {
		try {
			window.console.response("RES Events ODD FIELDS Changes", response);

			const { sportEvents, betSlip } = yield select();

			let { eventOddFields } = response.data

			let duplicatedEventOddFields = { ...sportEvents.eventOddFields }
			let duplicatedBetSlipList = { ...betSlip.betSlipList };




			if (eventOddFields && Object.keys(eventOddFields).length > 0) {

				duplicatedEventOddFields = handleEventsOddFieldsFeedChange(eventOddFields, duplicatedEventOddFields);

				yield put({
					type: SportEventsActionTypes.SportEvents_OddFields_Success,
					eventOddFields: duplicatedEventOddFields,
				})

				duplicatedBetSlipList = handleBetSlipFeedChange(eventOddFields, duplicatedBetSlipList);
				yield put({
					type: BetSlipActionTypes.Update_BetSlip_From_FeedChange,
					list: duplicatedBetSlipList,
				})
			}
		} catch (e) {
			window.log("ERROR", e)
		}

	})
	yield takeEvery(SignalRTypes.OnTopBetTypesChange, function* ({ response }) {
		try {
			window.console.response("RES TOP BET TYPES Changes", response);

			const { sportEvents } = yield select();

			let { topBetTypes } = response.data

			let duplicatedBetTypes = { ...sportEvents.betTypes };


			// format betTypes structure from screen format to original server format
			if (duplicatedBetTypes && duplicatedBetTypes.optionsBetTypes) {
				let optionsBetTypesCopy = [...duplicatedBetTypes.optionsBetTypes];
				if (optionsBetTypesCopy) {
					optionsBetTypesCopy.push(duplicatedBetTypes.match);
					duplicatedBetTypes.optionsBetTypes = optionsBetTypesCopy
					let optionsCopy = duplicatedBetTypes.optionsBetTypes
					duplicatedBetTypes = [];
					duplicatedBetTypes = optionsCopy;
				}

			}

			if (topBetTypes && topBetTypes.length > 0) {
				duplicatedBetTypes = handleEventsTopBetTypesFeedChange(topBetTypes, duplicatedBetTypes);
				let betTypes = Utils.handleBetTypes(duplicatedBetTypes);
				yield put({
					type: SportEventsActionTypes.SportEvents_BetTypes_Success,
					betTypes: betTypes,
				})
			}

		} catch (e) {
			window.log("ERROR", e)
		}
	})
	yield takeEvery(SignalRTypes.OnUpcomingEventsChange, function* ({ response }) {
		try {
			window.console.response("RES Upcoming Events Changes", response);

			const { sportEvents, app } = yield select();

			let newUpcomingEvents = response.data.upcomingEvents;
			let duplicatedUpcomingEvents = { ...sportEvents.upcomingEvents }
			let duplicatedUpcomingEventIds = [...sportEvents.upcomingEventIds];


			let isTournamentSelected = app.match && app.match.params.hasOwnProperty("tournament")
			let feedId = app.match && app.match.params.feed;
			let sportId = app.match && app.match.params.sport;
			let countryId = app.match && app.match.params.country;
			let tournamentId = app.match && app.match.params.tournament;


			if (newUpcomingEvents && Object.keys(newUpcomingEvents).length > 0) {

				duplicatedUpcomingEvents = handleUpcomingEventsFeedChange(newUpcomingEvents, duplicatedUpcomingEvents, isTournamentSelected, sportId, feedId, tournamentId, countryId);
				//sort ids by date after feed change 
				duplicatedUpcomingEventIds.length = 0;
				Object.values(duplicatedUpcomingEvents).sort((a, b) => {
					return new Date(a.eventDateOfMatch) - new Date(b.eventDateOfMatch)
				}).map((item) => {
					duplicatedUpcomingEventIds.push(item.key);
				})
				yield put({
					type: SportEventsActionTypes.SportEvents_Upcoming_Events_Success,
					upcomingEvents: duplicatedUpcomingEvents,
					upcomingEventIds: duplicatedUpcomingEventIds
				})
			}

		} catch (e) {
			window.log("ERROR", e)
		}
	})

}


function sortCategories(categories) {
	try {
		categories.map(item => {
			item.sportTournaments.sort((a, b) => a.tournamentDisplayOrder - b.tournamentDisplayOrder)
		})
		categories.sort((a, b) => {
			if (a.categoryDisplayOrder > b.categoryDisplayOrder) {
				return 1;
			} else if (a.categoryDisplayOrder < b.categoryDisplayOrder) {
				return -1;
			}

			// Else go to the 2nd item
			if (a.categoryDisplayName < b.categoryDisplayName) {
				return -1;
			} else if (a.categoryDisplayName > b.categoryDisplayName) {
				return 1
			} else { // nothing to split them
				return 0;
			}

		});
	} catch (e) {
		window.log("ERROR", e)
	}
}


function handleSportTypesFeedChange(sportTypes, duplicatedSportTypes, sportId) {
	if (sportTypes && sportTypes.length > 0) {
		sportTypes.map((sport, i) => {
			switch (sport.feedChangeStatus) {
				case Enums.FeedChangeStatus.Update:
					if (duplicatedSportTypes) {
						let index = duplicatedSportTypes.findIndex((item) => item.sport.sportId === sport.sport.sportId && item.feed.feedId === sport.feed.feedId);
						if (index !== -1) {
							let sportsCopy = { ...duplicatedSportTypes[index] }
							Utils.cloneObjectDiff(sportsCopy, sport);
							duplicatedSportTypes[index] = sportsCopy;
						}
					}
					break;
				case Enums.FeedChangeStatus.Add:
					if (duplicatedSportTypes) {
						let index = duplicatedSportTypes.findIndex((item) => item.sport.sportId === sport.sport.sportId && item.feed.feedId === sport.feed.feedId);
						if (index === -1) {
							duplicatedSportTypes.push(sport);
						} else {
							if (duplicatedSportTypes[index].disabled) {
								let sportCopy = { ...duplicatedSportTypes[index] }
								sportCopy.disabled = false;
								duplicatedSportTypes[index] = sportCopy;
							}
						}
					}
					break;

				case Enums.FeedChangeStatus.Delete:

					if (duplicatedSportTypes) {
						let index = duplicatedSportTypes.findIndex((item) => item.sport.sportId === sport.sport.sportId && item.feed.feedId === sport.feed.feedId);
						if (sport.sport.sportId.toString() === sportId) {
							if (index !== -1) {
								let sportCopy = { ...duplicatedSportTypes[index] }
								sportCopy.disabled = true;
								duplicatedSportTypes[index] = sportCopy;
							}

						} else {
							if (index !== -1) {
								duplicatedSportTypes.splice(index, 1);
							}
						}
					}
					break;
			}
		});
	}
	return duplicatedSportTypes;
}

function handleCategoriesFeedChange(categories, duplicatedCategories, sportId, countryId) {
	if (categories && categories.length > 0) {
		categories.map((category, i) => {
			switch (category.feedChangeStatus) {
				case Enums.FeedChangeStatus.Add:
					if (!duplicatedCategories) {
						duplicatedCategories = [];
					}
					if (category.sport.sportId.toString() === sportId) {
						let index = duplicatedCategories.findIndex((item) =>
							item.feed.feedId === category.feed.feedId
							&& item.sport.sportId === category.sport.sportId
							&& item.sportCategoryId === category.sportCategoryId);
						if (index === -1) {
							duplicatedCategories.push(category);
						} else {
							if (duplicatedCategories[index].disabled) {
								let categoryCopy = { ...duplicatedCategories[index] }
								categoryCopy.disabled = false;
								duplicatedCategories[index] = categoryCopy;
							}
						}
					}
					break;

				case Enums.FeedChangeStatus.Update:

					if (duplicatedCategories) {
						let index = duplicatedCategories.findIndex((item) =>
							item.feed.feedId === category.feed.feedId
							&& item.sport.sportId === category.sport.sportId
							&& item.sportCategoryId === category.sportCategoryId);
						if (index !== -1) {
							let categoryCopy = { ...duplicatedCategories[index] }
							Utils.cloneObjectDiff(categoryCopy, category);
							duplicatedCategories[index] = categoryCopy;
						}
					}
					break;

				case Enums.FeedChangeStatus.Delete:

					if (duplicatedCategories) {
						if (category.sportCategoryId.toString() === countryId) {
							let index = duplicatedCategories.findIndex((item) =>
								item.feed.feedId === category.feed.feedId
								&& item.sport.sportId === category.sport.sportId
								&& item.sportCategoryId === category.sportCategoryId);
							let categoryCopy = { ...duplicatedCategories[index] }

							categoryCopy.disabled = true;
							duplicatedCategories[index] = categoryCopy;
						} else {
							let index = duplicatedCategories.findIndex((item) =>
								item.feed.feedId === category.feed.feedId
								&& item.sport.sportId === category.sport.sportId
								&& item.sportCategoryId === category.sportCategoryId);
							if (index !== -1) {
								duplicatedCategories.splice(index, 1);
							}
						}
					}
					break;
			}
		});
	}
	return duplicatedCategories;
}

function handleTournamentsFeedChange(tournaments, duplicatedCategories, sportId, tournamentId) {
	if (tournaments && tournaments.length > 0) {
		tournaments.map((tournament, i) => {
			switch (tournament.feedChangeStatus) {
				case Enums.FeedChangeStatus.Add:
					if (!duplicatedCategories) {
						duplicatedCategories = [];
					}
					if (tournament.sport.sportId.toString() === sportId) {
						let catIndex = duplicatedCategories.findIndex((item) =>
							item.feed.feedId === tournament.feed.feedId
							&& item.sport.sportId === tournament.sport.sportId
							&& item.sportCategoryId === tournament.sportCategory.sportCategoryId);
						if (catIndex !== -1) {

							let tourIndex = duplicatedCategories[catIndex].sportTournaments.findIndex((item) => {
								return item.feed.feedId === tournament.feed.feedId
									&& item.sport.sportId === tournament.sport.sportId
									&& item.sportCategory.sportCategoryId === tournament.sportCategory.sportCategoryId
									&& item.tournamentId === tournament.tournamentId
							});
							if (tourIndex === -1) {
								let categoryCopy = { ...duplicatedCategories[catIndex] };
								let tournamentsCopy = [...categoryCopy.sportTournaments]
								tournamentsCopy.push(tournament);
								categoryCopy.sportTournaments = tournamentsCopy;
								duplicatedCategories[catIndex] = categoryCopy;

							} else {
								if (duplicatedCategories[catIndex].sportTournaments[tourIndex].disabled) {
									let categoryCopy = { ...duplicatedCategories[catIndex] };
									let tournamentCopy = { ...categoryCopy.sportTournaments[tourIndex] }
									tournamentCopy.disabled = false;
									categoryCopy.sportTournaments[tourIndex] = tournamentCopy;
									duplicatedCategories[catIndex] = categoryCopy;
								}
							}
						}
					}

					break;

				case Enums.FeedChangeStatus.Update:

					if (duplicatedCategories) {
						let index = duplicatedCategories.findIndex((item) =>
							item.feed.feedId === tournament.feed.feedId
							&& item.sport.sportId === tournament.sport.sportId
							&& item.sportCategoryId === tournament.sportCategory.sportCategoryId);
						if (index !== -1) {
							let tourIndex = duplicatedCategories[index].sportTournaments.findIndex((item) =>
								item.feed.feedId === tournament.feed.feedId
								&& item.sport.sportId === tournament.sport.sportId
								&& item.sportCategory.sportCategoryId === tournament.sportCategory.sportCategoryId
								&& item.tournamentId === tournament.tournamentId);
							if (tourIndex !== -1) {
								let categoryCopy = { ...duplicatedCategories[index] };
								let tournamentsCopy = [...categoryCopy.sportTournaments]
								tournamentsCopy[tourIndex] = tournament;
								categoryCopy.sportTournaments = tournamentsCopy;
								duplicatedCategories[index] = categoryCopy;
							}
						}
					}
					break;

				case Enums.FeedChangeStatus.Delete:

					if (duplicatedCategories) {
						if (tournament.tournamentId.toString() === tournamentId) {
							let index = duplicatedCategories.findIndex((item) =>
								item.feed.feedId === tournament.feed.feedId
								&& item.sport.sportId === tournament.sport.sportId
								&& item.sportCategoryId === tournament.sportCategory.sportCategoryId);
							if (index !== -1) {
								let tourIndex = duplicatedCategories[index].sportTournaments.findIndex((item) =>
									item.feed.feedId === tournament.feed.feedId
									&& item.sport.sportId === tournament.sport.sportId
									&& item.sportCategory.sportCategoryId === tournament.sportCategory.sportCategoryId
									&& item.tournamentId === tournament.tournamentId);

								if (tourIndex !== -1) {

									let categoryCopy = { ...duplicatedCategories[index] };
									let tournamentCopy = { ...categoryCopy.sportTournaments[tourIndex] }
									tournamentCopy.disabled = true;
									categoryCopy.sportTournaments[tourIndex] = tournamentCopy;
									duplicatedCategories[index] = categoryCopy;
								}
							}
						} else {
							let index = duplicatedCategories.findIndex((item) =>
								item.feed.feedId === tournament.feed.feedId
								&& item.sport.sportId === tournament.sport.sportId
								&& item.sportCategoryId === tournament.sportCategory.sportCategoryId);
							if (index !== -1) {
								let tourIndex = duplicatedCategories[index].sportTournaments.findIndex((item) =>
									item.feed.feedId === tournament.feed.feedId
									&& item.sport.sportId === tournament.sport.sportId
									&& item.sportCategory.sportCategoryId === tournament.sportCategory.sportCategoryId
									&& item.tournamentId === tournament.tournamentId);
								if (tourIndex !== -1) {
									let categoryCopy = { ...duplicatedCategories[index] };

									let tournamentsCopy = [...categoryCopy.sportTournaments]
									tournamentsCopy.splice(tourIndex, 1);
									categoryCopy.sportTournaments = tournamentsCopy;
									duplicatedCategories[index] = categoryCopy;
								}
							}
						}
					}
					break;
			}
		});
	}
	return duplicatedCategories;
}

function handleTopLeaguesFeedChange(topLeagues, duplicatedTopLeagues, sportId, tournamentId) {
	if (topLeagues && topLeagues.length > 0) {
		topLeagues.map((tournament, i) => {
			switch (tournament.feedChangeStatus) {
				case Enums.FeedChangeStatus.Add:
					if (!duplicatedTopLeagues) {
						duplicatedTopLeagues = [];
					}
					if (tournament.sport.sportId.toString() === sportId) {
						let tourIndex = duplicatedTopLeagues.findIndex((item) => {
							return item.feed.feedId === tournament.feed.feedId
								&& item.sport.sportId === tournament.sport.sportId
								&& item.sportCategory.sportCategoryId === tournament.sportCategory.sportCategoryId
								&& item.tournamentId === tournament.tournamentId
						});
						if (tourIndex === -1) {
							duplicatedTopLeagues.push(tournament);
						} else {
							if (duplicatedTopLeagues[tourIndex].disabled) {
								let tournamentCopy = { ...duplicatedTopLeagues[tourIndex] }
								tournamentCopy.disabled = false;
								duplicatedTopLeagues[tourIndex] = tournamentCopy;
							}
						}

					}

					break;

				case Enums.FeedChangeStatus.Update:

					if (duplicatedTopLeagues) {
						let tourIndex = duplicatedTopLeagues.findIndex((item) =>
							item.feed.feedId === tournament.feed.feedId
							&& item.sport.sportId === tournament.sport.sportId
							&& item.sportCategory.sportCategoryId === tournament.sportCategory.sportCategoryId
							&& item.tournamentId === tournament.tournamentId);
						if (tourIndex !== -1) {
							duplicatedTopLeagues[tourIndex] = tournament;
						}
					}
					break;

				case Enums.FeedChangeStatus.Delete:

					if (duplicatedTopLeagues) {
						if (tournament.tournamentId.toString() === tournamentId) {
							tournament.disabled = true;
						} else {
							let tourIndex = duplicatedTopLeagues.findIndex((item) =>
								item.feed.feedId === tournament.feed.feedId
								&& item.sport.sportId === tournament.sport.sportId
								&& item.sportCategory.sportCategoryId === tournament.sportCategory.sportCategoryId
								&& item.tournamentId === tournament.tournamentId);
							if (tourIndex !== -1) {
								duplicatedTopLeagues.splice(tourIndex, 1);
							}

						}
					}
					break;
			}
		});
	}
	return duplicatedTopLeagues;
}


function handleGroupDrilDownFeedChange(eventOddGroups, duplicatedEventOddGroups, duplicatedEventData) {
	if (eventOddGroups && Object.keys(eventOddGroups).length > 0 && duplicatedEventData) {
		Object.values(eventOddGroups).map((group, i) => {
			let groupId = Utils.getGroupId(group);
			switch (group.feedChangeStatus) {
				case Enums.FeedChangeStatus.Add:
					if (duplicatedEventData.feed.feedId == group.feed.feedId
						&& duplicatedEventData.sport.sportId == group.sport.sportId
						&& duplicatedEventData.eventId == group.eventId) {
						duplicatedEventOddGroups[groupId] = group;
					}
					break;

				case Enums.FeedChangeStatus.Update:

					if (duplicatedEventData.feed.feedId == group.feed.feedId
						&& duplicatedEventData.sport.sportId == group.sport.sportId
						&& duplicatedEventData.eventId == group.eventId) {
						duplicatedEventOddGroups[groupId] = group;
					}
					break;
				case Enums.FeedChangeStatus.Delete:

					if (duplicatedEventData.feed.feedId == group.feed.feedId
						&& duplicatedEventData.sport.sportId == group.sport.sportId
						&& duplicatedEventData.eventId == group.eventId) {
						delete duplicatedEventOddGroups[groupId];
					}
					break;
			}
		});
	}
	return duplicatedEventOddGroups;
}


function handleEventsTopBetTypesFeedChange(topBetTypes, duplicatedBetTypes) {
	if (topBetTypes && topBetTypes.length > 0) {
		duplicatedBetTypes = topBetTypes
	}
	return duplicatedBetTypes;
}

function handleEventsScoreFeedChange(eventScores, duplicatedSportEvents) {
	if (eventScores && eventScores.length > 0) {
		eventScores.map((score, i) => {
			let eventId = Utils.getEventId(score);
			if (duplicatedSportEvents[eventId]) {
				let eventCopy = { ...duplicatedSportEvents[eventId] }
				eventCopy.eventScore = score;
				duplicatedSportEvents[eventId] = eventCopy;
			}

		});
	}
	return duplicatedSportEvents;
}
function handleEventsScoreDrilDownFeedChange(eventScores, duplicatedEventData) {
	if (eventScores && eventScores.length > 0 && duplicatedEventData) {
		eventScores.map((score, i) => {
			if (duplicatedEventData.feed.feedId == score.feed.feedId
				&& duplicatedEventData.sport.sportId == score.sport.sportId
				&& duplicatedEventData.eventId == score.eventId) {
				duplicatedEventData.eventScore = score;
			}
		});
	}
	return duplicatedEventData;
}
function handleEventsOddFieldsFeedChange(eventOddFields, duplicatedEventOddFields) {
	if (eventOddFields && Object.keys(eventOddFields).length > 0) {
		Object.values(eventOddFields).map((oddFields) => {
			Object.values(oddFields).map((oddField) => {
				let eventId = Utils.getEventId(oddField);
				let oddId = Utils.getOddId(oddField);
				switch (oddField.feedChangeStatus) {
					case Enums.FeedChangeStatus.Add:
						if (!duplicatedEventOddFields[`${eventId}_${oddId}`]) {
							duplicatedEventOddFields[`${eventId}_${oddId}`] = {};
						}
						let eventOddFieldsCopy = { ...duplicatedEventOddFields[`${eventId}_${oddId}`] }
						eventOddFieldsCopy[`${oddField.activeOddFieldId}`] = oddField;

						duplicatedEventOddFields[`${eventId}_${oddId}`] = eventOddFieldsCopy;
						break;

					case Enums.FeedChangeStatus.Update:
						//oddField.oddValueStatus = 1;
						if (duplicatedEventOddFields[`${eventId}_${oddId}`]) {
							let eventOddFieldsCopy = { ...duplicatedEventOddFields[`${eventId}_${oddId}`] }
							eventOddFieldsCopy[`${oddField.activeOddFieldId}`] = oddField;

							duplicatedEventOddFields[`${eventId}_${oddId}`] = eventOddFieldsCopy;
						}

						break;
				}
			});
		});
	}
	return duplicatedEventOddFields;
}

function handleBetSlipFeedChange(eventOddFields, duplicatedBetSlipList) {
	if (eventOddFields && Object.keys(eventOddFields).length > 0) {
		Object.values(eventOddFields).map((oddFields) => {
			Object.values(oddFields).map((oddField) => {
				switch (oddField.feedChangeStatus) {
					case Enums.FeedChangeStatus.Update:
						if (duplicatedBetSlipList[`${oddField.activeOddFieldId}`]) {
							if (oddField.oddValueStatus === 0) { // if update is 0:no change, then keep old value status to keep alert
								let temp = duplicatedBetSlipList[`${oddField.activeOddFieldId}`].oddValueStatus;
								let betSlipCopy = { ...duplicatedBetSlipList[`${oddField.activeOddFieldId}`] }
								Utils.cloneObjectDiff(betSlipCopy, oddField);
								betSlipCopy.oddValueStatus = temp;
								duplicatedBetSlipList[`${oddField.activeOddFieldId}`] = betSlipCopy;
							}
							else {// else update the value(1:up -1:down)
								let betSlipCopy = { ...duplicatedBetSlipList[`${oddField.activeOddFieldId}`] }
								Utils.cloneObjectDiff(betSlipCopy, oddField);
								duplicatedBetSlipList[`${oddField.activeOddFieldId}`] = betSlipCopy;
							}
						}
						break;
				}
			});
		});
	}
	return duplicatedBetSlipList;
}

function handleEventsOddsFeedChange(eventOdds, duplicatedEventOdds) {
	if (eventOdds && Object.keys(eventOdds).length > 0) {
		Object.values(eventOdds).map((odds, i) => {
			Object.values(odds).map((odd, i) => {
				let eventId = Utils.getEventId(odd);
				let oddId = Utils.getOddId(odd)
				switch (odd.feedChangeStatus) {
					case Enums.FeedChangeStatus.Add:
						if (!duplicatedEventOdds[`${eventId}`]) {
							duplicatedEventOdds[`${eventId}`] = {};
						}
						let eventOddsCopy = { ...duplicatedEventOdds[`${eventId}`] }
						eventOddsCopy[`${oddId}`] = odd;

						duplicatedEventOdds[`${eventId}`] = eventOddsCopy;
						break;

					case Enums.FeedChangeStatus.Update:
						if (duplicatedEventOdds[`${eventId}`]) {
							let eventOddsCopy = { ...duplicatedEventOdds[`${eventId}`] }
							eventOddsCopy[`${oddId}`] = odd;

							duplicatedEventOdds[`${eventId}`] = eventOddsCopy;
						}

						break;
					case Enums.FeedChangeStatus.Delete:
						if (duplicatedEventOdds[`${eventId}`]) {
							let eventOddsCopy = { ...duplicatedEventOdds[`${eventId}`] }
							delete eventOddsCopy[`${oddId}`];
							duplicatedEventOdds[`${eventId}`] = eventOddsCopy;
						}
						break;
					default:
						break;
				}
			});
		});
	}
	return duplicatedEventOdds;
}

function handleSportEventsFeedChange(newSportEvents, duplicatedSportEvents, isTournamentSelected, sportId, feedId, tournamentId, categoryId) {
	if (newSportEvents && Object.keys(newSportEvents).length > 0) {
		Object.values(newSportEvents).map((newEvent, i) => {
			let eventId = Utils.getEventId(newEvent);
			switch (newEvent.feedChangeStatus) {
				case Enums.FeedChangeStatus.Add:
					if (isTournamentSelected) {//if events tournament equal to current tournament
						if (feedId.toString() == newEvent.feed.feedId.toString()
							&& sportId.toString() == newEvent.sport.sportId.toString()
							&& categoryId.toString() == newEvent.eventCategory.sportCategoryId.toString()
							&& tournamentId.toString() == newEvent.eventTournament.tournamentId.toString()) {
							duplicatedSportEvents[eventId] = newEvent
						}
					} else {
						if (feedId.toString() === newEvent.feed.feedId.toString()
							&& sportId.toString() == newEvent.sport.sportId.toString()) {
							duplicatedSportEvents[eventId] = newEvent
						}
					}
					break;

				case Enums.FeedChangeStatus.Update:
					//Find new event in existing events array
					if (duplicatedSportEvents[eventId]) {
						duplicatedSportEvents[eventId] = newEvent;
					}
					break;
			}
		});
	}
	return duplicatedSportEvents;
}

function handleUpcomingEventsFeedChange(newUpcomingEvents, duplicatedUpcomingEvents, isTournamentSelected, sportId, feedId, tournamentId, categoryId) {
	if (newUpcomingEvents && Object.keys(newUpcomingEvents).length > 0) {
		Object.values(newUpcomingEvents).map((newEvent, i) => {
			let eventId = Utils.getEventId(newEvent);
			switch (newEvent.feedChangeStatus) {
				case Enums.FeedChangeStatus.Add:
					if (isTournamentSelected) {//if events tournament equal to current tournament
						if (feedId == newEvent.feed.feedId
							&& sportId.toString() == newEvent.sport.sportId.toString()
							&& categoryId.toString() == newEvent.eventCategory.sportCategoryId.toString()
							&& tournamentId.toString() == newEvent.eventTournament.tournamentId.toString()) {
							duplicatedUpcomingEvents[eventId] = newEvent
						}
					} else {
						duplicatedUpcomingEvents[eventId] = newEvent
					}


					// handle sort array by date of match after update
					// duplicatedSportEvents.sort((a, b) => {
					// 	return new Date(b.eventDateOfMatch) - new Date(a.eventDateOfMatch);
					// });
					break;

				case Enums.FeedChangeStatus.Update:
					//Find new event in existing events array
					if (duplicatedUpcomingEvents[eventId]) {
						duplicatedUpcomingEvents[eventId] = newEvent;
					}
					break;
				case Enums.FeedChangeStatus.Delete:
					//Find new event in existing events array
					if (duplicatedUpcomingEvents[eventId]) {
						delete duplicatedUpcomingEvents[eventId];
					}
					break;
			}
		});
	}
	return duplicatedUpcomingEvents;
}

function handleSportEventsDrillDownFeedChange(newSportEvents, duplicatedEventData) {
	if (newSportEvents && Object.keys(newSportEvents).length > 0) {
		Object.values(newSportEvents).map((newEvent, i) => {
			if (duplicatedEventData.feed.feedId == newEvent.feed.feedId
				&& duplicatedEventData.sport.sportId == newEvent.sport.sportId
				&& duplicatedEventData.eventId == newEvent.eventId) {
				duplicatedEventData = newEvent;
			}
		});
	}
	return duplicatedEventData;
}

export default function* sportSaga() {
	yield all([
		fork(requests),
	])
}

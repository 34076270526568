import React from 'react'
import Bets from '..'


export default class DefaultScore extends React.Component {
	render() {
		const { event, upcoming } = this.props;
		return (
			
			<div className="event">
				<Bets.EventDetails event={event} {...this.props} />
				{
					!upcoming && event && event.feed && event.feed.feedId == 5 &&
					<div className="score">
						<div className="event-score">{this.getHometeamScore(event.eventScore && event.eventScore.score)}</div>
						<div className="event-score">{this.getAwayTeamScore(event.eventScore && event.eventScore.score)}</div>
					</div>
				}
			</div>

		)
	}

	getHometeamScore = (score) => {
		return score ? score.split(":")[0] : "";
	}

	getAwayTeamScore = (score) => {
		return score ? score.split(":")[1] : "";
	}

}
import { Button, Input, Popover, Icon } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Alert } from "../../";
import {
  onAmountChange,
  placeMultipleBet,
  placeSingleBet,
  onFieldAmountChange,
  handleHideStake,
  clearSucceededBets,
  clearBetSlipSuccess,
  setLoginError,
} from "../../../redux/actions/betSlipActions";
import { createBetCode } from "../../../redux/actions/generalActions";
import { checkDepositBonusConditions } from "../../../redux/actions/membersActions";
import { BetSlipRow } from "./";
import { Register } from "../../../screens/";
import Loading from "../Loading";
import { Cookies, SessionStorage, Enums, AppConfig } from "../../../helpers";
import { Images } from "../../";

const Number = (props) => {
  const { children, currency, fixed } = props;

  let getNumber = (n, props) => {
    if (n == "-") return n;

    if (n == 0 || isNaN(n)) return "-";

    return ` ${currency ? "LD" : ""} ${n.format(fixed)}`;
  };

  return getNumber(children, props);
};

class BetSlipDetails extends React.Component {
  state = {
    showRegister: false,
    placebetTime: 0,
    clearBetSlipCounter: 0,
    showPlacebetCounter: false,
    filteredBetSlipList: {},
  };
  filterBitSlipList = (betSlipList) => {
    let newBetSlip = {};
    // betSlipList

    this.setState({ filteredBetSlipList: newBetSlip });
  };

  getBetCode = () => {
    this.props.createBetCode();
  };

  onClickRegister = () => {
    this.setState({ showRegister: true });
  };
  onCloseRegister = () => {
    this.setState({ showRegister: false });
  };
  onAmountClick = (e, action) => {
    e.preventDefault();

    let currentAmount = parseInt(this.props.amount);
    let selectedCurrency =
      Cookies.get("selectedCurrency") || this.props.currencies.LiberianDollar;
    let tab = SessionStorage.betTab;
    switch (action) {
      case "up":
        currentAmount +=
          selectedCurrency.currencyId == Enums.Currency.USD ? 1 : 10;
        break;

      case "down":
        if (selectedCurrency.currencyId == Enums.Currency.USD) {
          if (currentAmount - 1 >= 0) {
            currentAmount -= 1;
          } else {
            currentAmount = 0;
          }
        } else {
          if (currentAmount - 10 >= 0) {
            currentAmount -= 10;
          } else {
            currentAmount = 0;
          }
        }
        break;
    }

    if (tab == 1) {
      let betSlipList = this.props.betSlipList;
      Object.keys(betSlipList).forEach((key) => {
        this.props.onFieldAmountChange(
          currentAmount,
          betSlipList[key].activeOddFieldId
        );
      });
      this.props.handleHideStake(false);
    }
    this.onAmountChange(currentAmount);
  };

  placeBetText = () => {
    const { betSlipList } = this.props;
    let tab = SessionStorage.betTab;
    if (tab == 1) {
      if (Object.values(betSlipList).filter((bet) => bet.response).length > 0) {
        let list = Object.values(betSlipList).filter((bet) => {
          return bet.response && bet.response.type === "error";
        });
        return `${list.length}`;
      } else {
        return `${Object.keys(betSlipList).length}`;
      }
    } else {
      return;
    }
  };

  handlePlaceBetInterval = () => {
    if (this.props.signedIn) {
      this.setState({
        showPlacebetCounter: true,
        clearBetSlipCounter: this.props.placeBetSeconds,
        placebetTime: this.props.placeBetSeconds,
      });
      if (this.betInterval) {
        clearInterval(this.betInterval);
      }
      this.betInterval = setInterval(() => {
        if (this.state.placebetTime <= 0) {
          clearInterval(this.betInterval);
          this.handlePlacebet();
          this.setState({ showPlacebetCounter: false });
        } else {
          this.setState({ placebetTime: this.state.placebetTime - 1 });
        }
      }, 1000);
    } else {
      this.handlePlacebet();
    }
  };

  handleClearBetInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      if (this.state.clearBetSlipCounter <= 0) {
        clearInterval(this.interval);
        this.props.clearBetSlipSuccess();
      } else {
        this.setState({
          clearBetSlipCounter: this.state.clearBetSlipCounter - 1,
        });
      }
    }, 1000);
  };

  handlePlacebet = () => {
    let tab = SessionStorage.betTab;
    if (Cookies.get("token") || SessionStorage.voucher) {
      tab == 1 ? this.props.placeSingleBet() : this.props.placeMultipleBet();
    } else {
      this.props.setLoginError(true);
    }
  };
  displaySportImage = (sportName)=>{
    switch(sportName){
      case "soccer":
        return <img src={Images.Soccer}/>
      case "basketball":
        return <img src={Images.MenuBasketball}/>
      case "ice Hokey":
        return <img src={Images.IceHockey}/>
      case "rugby":
        return <img src={Images.Rugby}/>
      case "aussie rules":
        return <img src={Images.Aussie}/>
      case "dart":
        return <img src={Images.Dart}/>
      case "mma":
        return <img src={Images.MMA}/>

    }
  }
  render() {
    const {
      bonusConditions,
      memberSelectedCurrency,
      totalStake,
      betSlipList,
      totalOdds,
      loadingPlaceBet,
      errors,
      possibleWinnings,
      successMsg,
      serverError,
      valueChangeWarning,
      loginError,
      connected,
      loadingFields,
      creatBetCodeLoading,
    } = this.props;
    const selectedCurrency =
      memberSelectedCurrency || Cookies.get("selectedCurrency");
    let tab = SessionStorage.betTab;
    let user = Cookies.get("user");
    let isSucceededBets = Object.values(betSlipList).every(
      (bet) => bet.response && bet.response.type === "success"
    );
    let filteredBetSlipList = {};
    // Object.keys(betSlipList).length > 0 && Object.values(betSlipList).sort((a, b) => a.eventBetLineNo - b.eventBetLineNo).map((item, idx)=>{
    //   return (filteredBetSlipList = {...item})
    // })
    // console.log(" Object.entries(betSlipList)"  ,  Object.entries(betSlipList));
    let soccerData = Object.values(betSlipList).filter((key, val) => {
      return key.sport.sportId == 1;
    });
    let basketBallData = Object.values(betSlipList).filter((key, val) => {
      return key.sport.sportId == 2;
    });
    let iceHokeyData = Object.values(betSlipList).filter((key, val) => {
      return key.sport.sportId == 4;
    });
    let rugbyData = Object.values(betSlipList).filter((key, val) => {
      return key.sport.sportId == 12;
    });
    let aussieData = Object.values(betSlipList).filter((key, val) => {
      return key.sport.sportId == 13;
    });
    let dartData = Object.values(betSlipList).filter((key, val) => {
      return key.sport.sportId == 22;
    });
    let MMAData = Object.values(betSlipList).filter((key, val) => {
      return key.sport.sportId == 117;
    });


    Object.entries(betSlipList).map((item) => {
      if (item[1].sport.sportId == 117) {
        return (filteredBetSlipList["mma"] = MMAData);
      }
      if (item[1].sport.sportId == 22) {
        return (filteredBetSlipList["dart"] = dartData);
      }

      if (item[1].sport.sportId == 13) {
        return (filteredBetSlipList["aussie"] = aussieData);
      }
      if (item[1].sport.sportId == 12) {
        return (filteredBetSlipList["rugby"] = rugbyData);
      }
      if (item[1].sport.sportId == 4) {
        return (filteredBetSlipList["ice Hokey"] = iceHokeyData);
      }

      if (item[1].sport.sportId == 2) {
        return (filteredBetSlipList["basketball"] = basketBallData);
      }
      if (item[1].sport.sportId == 1) {
        return (filteredBetSlipList["soccer"] = soccerData);
      }
    });



    let filteredBetSlipListValues = Object.values(betSlipList)
    // console.log("Object.keys(filteredBetSlipList)  ====", Object.entries(filteredBetSlipList));
    // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzz" , Object.keys(filteredBetSlipList));
    // console.log("zzzzzzzzzzzzzzzzzzvvvvvvvvvvvvvvvv" , Object.keys(filteredBetSlipListValues));
    return (
      <div>
      <div className="events">
        {/* {console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm", betSlipList)} */}
        <Register
          onCloseRegister={this.onCloseRegister}
          visible={this.state.showRegister}
        ></Register>
        {loadingFields && <Loading text="Loading fields" />}
        {!loadingFields &&
        betSlipList &&
        Object.keys(filteredBetSlipListValues).length > 0 ? (
          <div>
            {/* {console.log("betSlipList", betSlipList)} */}
            {Object.entries(filteredBetSlipList).map((item , keys)=>{
            return(
              <>
              <div className="sport-images">
                {this.displaySportImage(item[0])}
              </div>
              <div className="bet-slip-row-container">
              {Object.values(item[1])
                .sort((a, b) => a.eventBetLineNo - b.eventBetLineNo)
                .map((item) => (
                  <>
                    <BetSlipRow
                      {...this.props}
                      handleTotalStake={this.handleTotalStake}
                      key={item.activeOddFieldId}
                      data={item}
                    />
                  </>
                ))}
                </div>
              </>
            )

            })}
           <div />
            {/* end of divs */}
            <hr id="split-bitslip"/>
            {/* Clear All */}
            <div className="events">

            {betSlipList && Object.keys(betSlipList).length > 0 && (
              <div
                className="delete-all"
                title="Clear all"
                onClick={() => this.props.clearBetSlip()}
              >
                Clear All
              </div>
            )}
            {/* end of Clear All */}

            {/* check bonus */}
            {user &&
              user.bonus &&
              selectedCurrency.currencySymbol ===
                user.bonus.currency.currencySymbol &&
              tab == 2 && (
                <div className="bonus-btn-container">
                  <Button
                    type="primary"
                    className="bonus-btn"
                    onClick={() => {
                      this.props.checkDepositBonusConditions();
                    }}
                  >
                    Check Bonus
                  </Button>
                </div>
              )}
            {/* end of check bonus */}

            {/* place bet div */}
            <div className="placebet">
              {(serverError ||
                (errors && errors.length > 0) ||
                valueChangeWarning ||
                loginError ||
                !connected) && (
                <div>
                  <div className="notice">
                    {errors &&
                      errors.length > 0 &&
                      errors.map((item, index) => (
                        <Alert
                          key={index}
                          message={item.text}
                          type="error"
                          icon="!"
                        />
                      ))}

                    {serverError && (
                      <Alert message={serverError} type="error" icon="!" />
                    )}
                    {loginError && (
                      <Alert type="error" icon="!">
                        <div>
                          Please Signin or
                          {AppConfig.ShowSignInAndRegistersBtn ? (
                            <a onClick={this.onClickRegister}> Register </a>
                          ) : (
                            <Popover
                              className="popup-login-mobile"
                              placement="bottomRight"
                              content={"Coming soon"}
                              trigger="click"
                            >
                              <a> Register </a>
                            </Popover>
                          )}
                          to place bet
                        </div>
                      </Alert>
                    )}
                    {valueChangeWarning && (
                      <Alert
                        message={valueChangeWarning}
                        type="warning"
                        icon="!"
                      />
                    )}
                    {!connected && (
                      <Alert
                        message={"Server no longer available, Please refresh"}
                        type="error"
                        icon="!"
                      />
                    )}
                  </div>
                </div>
              )}
              <div id="first-bet-slip-row" className="bit-slip-rows">
                <div className="stake">
                    {tab === 1 ? "Stake per bet " : "Stake "}
                  {`(${
                    tab == 1
                      ? this.placeBetText()
                      : Object.keys(betSlipList).length
                  } bets)`}
                </div>
                {tab == 1 ? (
                  <div className="amount">
                    <div className="amount-up-down">
                      <div
                        className="up"
                        onClick={(e) => this.onAmountClick(e, "down")}
                      >
                        <Icon type="minus" />
                      </div>
                      <div className="amount">
                        <Input
                          type="number"
                          value={!this.props.hideStake && this.props.amount}
                          className="input"
                          placeholder="-"
                          onChange={(value) => this.onAmountManualChange(value)}
                        />
                      </div>
                      <div
                        className="down"
                        onClick={(e) => this.onAmountClick(e, "up")}
                      >
                        <Icon type="plus" />
                      </div>
                    </div>
                    {/* <div className="currency">
                      {selectedCurrency
                        ? selectedCurrency.currencySymbol
                        : "LD"}
                    </div> */}
                  </div>
                ) : (
                  <div className="amount">
                    <div className="amount-up-down">
                      <div
                        className="up"
                        onClick={(e) => this.onAmountClick(e, "down")}
                      >
                        <Icon type="minus" />
                      </div>
                      <div className="amount">
                        <Input
                          type="number"
                          value={this.props.amount}
                          className="input"
                          placeholder="Amount"
                          onChange={(value) => this.onAmountManualChange(value)}
                        />
                      </div>
                      <div
                        className="down"
                        onClick={(e) => this.onAmountClick(e, "up")}
                      >
                        <Icon type="plus" />
                      </div>
                    </div>
                    {/* <div className="currency">
                      {selectedCurrency
                        ? selectedCurrency.currencySymbol
                        : "LD"}
                    </div> */}
                  </div>
                )}
              </div>
              {bonusConditions && (
                <div>
                  <div className="total-odds">Bonus:</div>
                  <div className="total-odds-value">{`${bonusConditions.currency.currencySymbol} ${bonusConditions.bonusUseAmount}`}</div>
                </div>
              )}
              {tab == 1 ? (
                <div className="bit-slip-rows">
                  <div className="total-odds">Total Stake:</div>
                  <div className="total-odds-value">
                    {selectedCurrency.currencySymbol === "LD" ? (
                      <Number fixed={2}>{totalStake}</Number>
                    ) : (
                      <Number fixed={2}>{totalStake}</Number>
                    )}
                  </div>
                </div>
              ) : (
                <div className="bit-slip-rows">
                  <div className="total-odds">Total Odds:</div>
                  <div className="total-odds-value">
                    <Number fixed={2}>{totalOdds}</Number>
                  </div>
                </div>
              )}

              <div className="bit-slip-rows">
                <div className="total-possible">Possible winnings: </div>
                <div className="total-possible-value">
                  <span style={{fontWeight:"300" , fontSize:"16px"}}>{selectedCurrency ? selectedCurrency.currencySymbol : "LD"}</span>
                  <span style={{fontWeight:"600" }}>{selectedCurrency.currencySymbol == "LD"
                  ? possibleWinnings.format(0)
                  : possibleWinnings.format(2)}</span>
                    
                 
                </div>
              </div>

              <div>
                <div className="buttons">
                  {tab == 1 &&
                    Object.values(betSlipList).filter((bet) => bet.response)
                      .length > 0 &&
                    !Object.values(betSlipList).every(
                      (bet) => bet.response && bet.response.type === "error"
                    ) && (
                      <Button
                        disabled={
                          (errors && errors.length > 0) ||
                          loginError ||
                          !connected ||
                          Object.values(betSlipList).find(
                            (item) => item.errors && item.errors.length > 0
                          )
                        }
                        type="primary"
                        onClick={this.props.clearSucceededBets}
                      >
                        Clear Succeeded Bets
                      </Button>
                    )}
                  {this.props.message && (
                    <Alert message={this.props.message} type="error"></Alert>
                  )}
                  <button
                    disabled={
                      isSucceededBets ||
                      (errors && errors.length > 0) ||
                      loginError ||
                      !connected ||
                      Object.values(betSlipList).find(
                        (item) => item.errors && item.errors.length > 0
                      )
                    }
                    type="primary"
                    onClick={() => this.handlePlaceBetInterval()}
                    className="placebet-btn success bet-slip-buttons"
                    loading={loadingPlaceBet || this.state.showPlacebetCounter}
                  >
                    {this.state.showPlacebetCounter && (
                      <div className="clear-bet-interval">
                        {this.state.placebetTime}
                      </div>
                    )}
                    {tab == 1
                      ? `Place Bet (${this.placeBetText()}) `
                      : "Place Bet "}
                  </button>
                  <button
                    disabled={
                      (errors && errors.length > 0) || !connected || tab == 1
                    }
                    id="get-bet-code-button"
                    type="primary"
                    className="clear bet-slip-buttons"
                    onClick={this.getBetCode}
                    loading={creatBetCodeLoading}
                  >
                    Get Bet Code
                  </button>
                </div>
              </div>
              <div>
                <div className="terms">
                  By placing a bet, you automatically accept the most recent
                  version of the &nbsp;
                  <Link replace to="/Document/terms-and-conditions">
                    Terms and Conditions
                  </Link>
                </div>
                  {/* <br/> */}


              </div>
            </div>
            {/* end of place bet div */}
          
            </div>
          </div>
        ) : (
          <div className="row error-message">
            {successMsg ? (
              <div className="success-bet">
                <div>
                  <img src={Images.CircleCheck} />
                </div>
                <div className="text success">Your bet has been placed</div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={this.props.clearBetSlipSuccess}
                >
                  <Button
                    onLoad={this.handleClearBetInterval()}
                    loading={successMsg}
                    className="clear-bets-btn"
                  >
                    <div className="clear-bet-interval">
                      {this.state.clearBetSlipCounter}
                    </div>
                    Okay
                  </Button>
                </div>
              </div>
            ) : (
              <div className="text">
                No bet has been selected. To select a bet, please click on the
                respective result.
              </div>
            )}
          </div>
        )}
        {/* end of content */}
      </div>
      </div>
    );
  }

  onAmountChange(amount) {
    const { onAmountChange } = this.props;
    onAmountChange(amount);
  }
  onAmountManualChange(e) {
    let currentAmount = parseInt(e.target.value);
    if (SessionStorage.betTab == 1) {
      let betSlipList = this.props.betSlipList;
      Object.keys(betSlipList).forEach((key) => {
        this.props.onFieldAmountChange(
          currentAmount,
          betSlipList[key].activeOddFieldId
        );
      });
    }
    this.onAmountChange(currentAmount);
  }
}

const mapStateToProps = (state) => {
  return {
    betSlipList: state.betSlip.betSlipList,
    loadingPlaceBet: state.betSlip.loadingPlaceBet,
    amount: state.betSlip.amount,
    totalOdds: state.betSlip.totalOdds,
    possibleWinnings: state.betSlip.possibleWinnings,
    errors: state.betSlip.errors,
    successMsg: state.betSlip.successMsg,
    serverError: state.betSlip.serverError,
    valueChangeWarning: state.betSlip.valueChangeWarning,
    loginError: state.betSlip.loginError,
    loadingFields: state.betSlip.loadingFields,
    message: state.general.message,
    connected: state.signalr.connected,
    creatBetCodeLoading: state.general.creatBetCodeLoading,
    memberSelectedCurrency: state.member.selectedCurrency,
    totalStake: state.betSlip.totalStake,
    hideStake: state.betSlip.hideStake,
    currencies: state.general.currencies,
    placeBetSeconds: state.general.configuration.placeBetSeconds,
    signedIn: state.auth.signedIn,
    bonusConditions: state.member.bonusConditions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    placeMultipleBet: () => {
      dispatch(placeMultipleBet());
    },
    placeSingleBet: () => {
      dispatch(placeSingleBet());
    },
    onAmountChange: (amount) => {
      dispatch(onAmountChange(amount));
    },
    createBetCode: () => {
      dispatch(createBetCode());
    },
    onFieldAmountChange: (amount, fieldId) => {
      dispatch(onFieldAmountChange(amount, fieldId));
    },
    handleHideStake: (forceHideStake) => {
      dispatch(handleHideStake(forceHideStake));
    },
    clearSucceededBets: () => {
      dispatch(clearSucceededBets());
    },
    clearBetSlipSuccess: () => {
      dispatch(clearBetSlipSuccess());
    },
    setLoginError: (value) => {
      dispatch(setLoginError(value));
    },
    checkDepositBonusConditions: (data) => {
      dispatch(checkDepositBonusConditions(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BetSlipDetails);

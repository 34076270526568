import { Affix, Drawer, Layout, Menu, Icon } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { MenuTree, Screen, ScreenTitle } from "../../components";
import { openMobileMenu, changeFeed } from "../../redux/actions/appActions";
import { partGroups, init } from "../../redux/actions/membersActions";
import Bet from "./Bet";
import EditPersonalDetails from "./EditPresonalDetails/EditPersonalDetails";
import Transactions from "./Transactions";
import ChangePassword from "./ChangePassword";
import { isMobile } from "react-device-detect";
import { Deposit, DepositFromVoucher, DepositMtn } from "./Deposit";
import { Withdraw, WithdrawToVoucher, WithdrawMtn } from "./Withdrawal";
import { BetSlip } from "../../components";
import { Images } from "../../components";
// import   {FooterMobile} from '../../components'


const { Item } = Menu;
const { Content, Sider } = Layout;

const Title = (props) => {
  const { text, icon } = props;

  return (
    <div className="title">
      {icon && <Icon className="icon" type={icon || "dollar"} />}
      <div className="text">{text}</div>
    </div>
  );
};

class MyAccount extends React.Component {
  state = {
    title: "Account",
    selectedKeys: [],
    yPlacement: 15,
  };

  async componentDidMount() {
    this.setSelected();
    this.props.partGroups();
    //this.props.changeFeed(null, 'account')
  }

  componentDidUpdate() {
    if (!this.props.signedIn) {
      this.props.history.push(`/`);
    }
  }

  setSelected = () => {
    const { match } = this.props;

    let title = "Account";
    let selectedKeys = [];

    switch (match.params.page) {
      case "bets":
        selectedKeys.push("1");
        title = "Bets";
        break;

      case "deposit":
        selectedKeys.push("2");
        title = "Deposit";
        break;

      case "transactions":
        selectedKeys.push("3");
        title = "Transactions";
        break;

      case "withdraw":
        selectedKeys.push("4");
        title = "Withdrawal";
        break;

      case "personal-details":
        selectedKeys.push("5");
        title = "Edit Personal Details";
        break;

      case "change-password":
        selectedKeys.push("6");
        title = "Change Password";
        break;
    }

    this.setState({
      title: title,
      selectedKeys: selectedKeys,
    });
  };

  getScrollProps = () => {
    var bodyRect = document.body.getBoundingClientRect();
    var element = document
      .getElementsByClassName("sub-menu-mobile")[0]
      .getClientRects();
    let yPlacement = element[0].top - 140 - bodyRect.height;
    this.setState({ yPlacement });
  };

  nav = (page, title) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (
      this.props.match.url !== undefined &&
      (this.props.match.url !== `/account/${page}` ||
        this.props.history.location.pathname !== this.props.match.url)
    ) {
      this.props.history.push(`/account/${page}`);
      this.timeout = setTimeout(() => {
        this.setSelected();
      }, 0);
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  };

  renderMobileMenu = () => {
    const { selectedKeys } = this.state;

    return (
      <Affix style={{ position: "fixed", bottom: 0, width: "100%" }}>
        <Menu
          mode="horizontal"
          selectedKeys={selectedKeys}
          className="menu account-menu-mobile"
        >
          <Menu.Item
            key="1"
            onClick={() => this.props.history.push("/sport/6/1/Soccer")}
          >
            <Title icon={"rollback"} text="Back"></Title>
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => {
              this.nav("transactions", "Transactions");
            }}
          >
            <Title text="Transactions" icon="transaction" />
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={() => {
              this.nav("deposit", "Deposit");
            }}
          >
            <Title text="Deposit" icon="vertical-align-bottom" />
          </Menu.Item>
          <Menu.Item
            key="4"
            onClick={() => {
              this.nav("bets", "Bets");
            }}
          >
            <Title text="My Bets" icon="file-search" />
          </Menu.Item>
          <Menu.SubMenu
            onTitleClick={() => this.getScrollProps()}
            popupOffset={[0, this.state.yPlacement]}
            className="sub-menu-mobile"
            title={
              <div className="buttons-container">
                <div>. . .</div>
                <div>More</div>
              </div>
            }
          >
            <Menu.Item
              onClick={() => {
                this.nav("withdraw", "Withdrawals");
              }}
            >
              <Title text="Withdrawals" icon="vertical-align-top" />
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.nav("personal-details", "Edit Personal Details");
              }}
            >
              <Title text="Edit Personal Details" icon="user" />
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.nav("change-password", "Change Password");
              }}
            >
              <Title text="Change Password" icon="edit" />
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </Affix>
    );
  };

  renderMenu = () => {
    const { selectedKeys } = this.state;

    return (
      <div id="my-account-left-menu-container">
      <Menu
        mode="inline"
        selectedKeys={selectedKeys}
        className="menu account-menu"
        onOpenChange={() => this.props.init()}
        onClick={() => {
          this.props.init();
        }}
      >
        {/* <Menu.Item className="item" disabled={true}>
          
        </Menu.Item> */}

        <Item
          key="1"
          onClick={() => {
            this.nav("bets", "Bets");
          }}
        >
          <img
            src={Images.MyAccountBetsIcon}
            className="my-account-menu-Icons"
          />
          <Title text="Bets" />
        </Item>
        <Item
          key="2"
          onClick={() => {
            this.nav("deposit", "Deposit");
          }}
        >
          <img
            src={Images.MyAccountDepositeIcon}
            className="my-account-menu-Icons"
          />
          <Title text="Deposit" />
        </Item>
        <Item
          key="3"
          onClick={() => {
            this.nav("transactions", "Transactions");
          }}
        >
          <img
            src={Images.MyAccountTransactionsIcon}
            className="my-account-menu-Icons"
          />
          <Title text="Transactions" />
        </Item>
        <Item
          key="4"
          onClick={() => {
            this.nav("withdraw", "Withdrawals");
          }}
        >
          <img
            src={Images.MyAccountWithdrawalIcon}
            className="my-account-menu-Icons"
          />
          <Title text="Withdrawals" />
        </Item>
        <Item
          key="5"
          onClick={() => {
            this.nav("personal-details", "Edit Personal Details");
          }}
        >
          <img
            src={Images.MyAccountPersonalInfoIcon}
            className="my-account-menu-Icons"
          />
          <Title text="Edit Personal Details" />
        </Item>
        <Item
          key="6"
          onClick={() => {
            this.nav("change-password", "Change Password");
          }}
        >
          <img
            src={Images.MyAccountChangePasswordIcon}
            className="my-account-menu-Icons"
          />{" "}
          <Title text="Change Password" />
        </Item>
        {/* <Item
          key="7"
          onClick={() => {
            this.nav("add-provider", "Add Provider");
          }}
        >
         <img src={Images.MyAccountAddProviderIcon} className="my-account-menu-Icons" /> <Title text="Add Provider" />
        </Item> */}
      </Menu>
      </div>
    );
  };

  render() {
    const { title } = this.state;
    const {
      mobileMenuOpen,
      openMobileMenu,
      sportCategories,
      sportTopLeagues,
      sportTypes,
      categoriesError,
    } = this.props;
    return (
      <Screen {...this.props}>
         <div id="my-account-body-container">
        <Layout className="account mtop-20">
          <Layout className="layout-inner">
            <Content className="content">
              <div id="my-account-left-sider-container">
                {" "}
                <div
                  className="my-account-headeline"
                  style={{ cursor: "default", backgroundColor: `var(--light-black)` }}
                >
                  My Account
                </div>
                <Sider width={220} className="sider-left">
                  {!isMobile && this.renderMenu()}
                  {isMobile && <MenuTree {...this.props} />}
                </Sider>
              </div>

              <div>
               
                <Content className="content-right">
                  {/* <ScreenTitle hideIcon {...this.props} title={title} /> */}
                  <div id="my-account-headline-menu">
                {" "}
                <div
                  style={{ cursor: "default",  backgroundColor:"transparent" , fontSize:"18px" , marginBottom:"15px"}}
                >
                  My Account
                </div>
                  {this.renderMenu()}
                  {/* {isMobile && <MenuTree {...this.props} />} */}

              </div>
                  <div className="my-account-headeline" style={{paddingBottom:"20px"}}>{title}</div>
                  <div className="container">
                    <Route exact path="/account/bets" component={Bet} />
                    <Route exact path="/account/deposit" component={Deposit} />
                    <Route
                      exact
                      path="/account/deposit/from-voucher"
                      component={DepositFromVoucher}
                    />
                    <Route
                      exact
                      path="/account/deposit/mtn"
                      component={DepositMtn}
                    />
                    <Route
                      exact
                      path="/account/transactions"
                      component={Transactions}
                    />
                    <Route
                      exact
                      path="/account/withdraw"
                      component={Withdraw}
                    />
                    <Route
                      exact
                      path="/account/withdraw/mtn"
                      component={WithdrawMtn}
                    />
                    <Route
                      exact
                      path="/account/withdraw/to-voucher"
                      component={WithdrawToVoucher}
                    />
                    <Route
                      exact
                      path="/account/personal-details"
                      component={EditPersonalDetails}
                    />
                    <Route
                      exact
                      path="/account/change-password"
                      component={ChangePassword}
                    />
                  </div>
                </Content>
              </div>
              <Sider
                width={350}
                className="sider-right"
                style={{ marginLeft: "1vw" }}
              >
                <div style={{ position: "sticky", top: "0px" }}>
                  <BetSlip {...this.props} />
                </div>
              </Sider>
            </Content>
          </Layout>
          {/* {isMobile && this.renderMobileMenu()} */}
        </Layout>
        </div>
      </Screen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mobileMenuOpen: state.app.mobileMenuOpen,
    feedId: state.app.feedId,
    feedName: state.app.feedName,
    sportData: state.sportTypes.sportData,
    tournamentData: state.categories.tournamentData,
    signedIn: state.auth.signedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openMobileMenu: (open) => dispatch(openMobileMenu(open)),
    partGroups: () => dispatch(partGroups()),
    init: () => dispatch(init()),
    changeFeed: (feedId, feedName) => dispatch(changeFeed(feedId, feedName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);

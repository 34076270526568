import React from 'react';
import { Loading } from '../../'
import { Utils, Enums, AppConfig } from '../../../helpers'

class VirtualVideo extends React.Component {
	static resizeTimeout = null;

	state = {
		iframeLoading: true,
		iframeUrl: null,
		iframeWidth: 0,
		className: null
	}

	async componentDidMount() {
		window.addEventListener("resize", this.onResize);
		this.onResize();
	}

	async componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
	}

	onResize = (e) => {
		clearTimeout(this.resizeTimeout);

		const { match } = this.props;
		let url = null;
		let className = Utils.IsMobile ? "mobile" : "url";

		this.resizeTimeout = setTimeout(() => {
			let container = document.getElementsByClassName("virtual-video")[0];

			switch (parseInt(match.params.feed)) {
				case Enums.Feed.VFL:
					url = AppConfig.VirtualAddress.VFL
					break;

				case Enums.Feed.VHC:
					url = AppConfig.VirtualAddress.VHC
					break;

				case Enums.Feed.VFLM:
					if (container) {
						if (container.clientWidth >= 762 && container.clientWidth < 1140) {
							url = `${AppConfig.VirtualAddress.VFLM}/desktop/index?clientid=1365&lang=en`

						}
						else if (container.clientWidth >= 1140) {
							url = `${AppConfig.VirtualAddress.VFLM}/desktop/index?clientid=1365&lang=en&layout=Vflm3`

						}
						else {
							url = `${AppConfig.VirtualAddress.VFLM}/micro/index?clientid=1365&lang=en`

						}
					}
					break;

				case Enums.Feed.VBL:
					url = AppConfig.VirtualAddress.VBL

					break;

				case Enums.Feed.VTO:
					url = AppConfig.VirtualAddress.VTO
					break;

				case Enums.Feed.VDR:
					url = AppConfig.VirtualAddress.VDR
					break;

				case Enums.Feed.EPL:
					url = AppConfig.VirtualAddress.EPL

					break;
			}

			this.setState({
				iframeUrl: url,
				className: className
			})

		}, 10);

	}

	frameLoad = (e) => {
		this.setState({ iframeLoading: false });
	}

	render() {
		const { match } = this.props;
		const { iframeUrl, iframeLoading, iframeWidth, className } = this.state;

		return (
			<div className={`virtual-video virtual-${match.params.feed}${match.params.sport}${Utils.IsMobile ? '-mobile' : ''}`}>
				{
					iframeLoading &&
					<div className="skeleton">
						<Loading text={`Loading ${match.params.name || ""} video...`} />
					</div>
				}

				<iframe
					name="vf_iframe"
					frameBorder="0"
					scrolling="no"
					style={{ display: iframeLoading ? 'none' : 'block' }}
					onLoad={e => this.frameLoad(this)}
					className={`frame-video`}
					src={iframeUrl}
				/>
			</div>
		)
	}
}

export default VirtualVideo
import React from "react";
import { connect } from "react-redux";
import { Icon } from "antd";

import Bets from "./";
import { Utils } from "../../../helpers";

class EventOdd extends React.Component {
  render() {
    const {
      eventEnabled,
      oddFields,
      odd,
      showDetails,
      oddId,
      eventId,
      showFieldName,
      className,
    } = this.props;

    return (
      <div className="container">
        {!showDetails && (!odd || !odd.enableBet) && (
          <Icon type="lock" className="lockIcon" />
        )}
        {oddFields &&
          odd &&
          (odd.enableBet || showDetails) &&
          [...Array(this.getMaxRow())].map((row, i) => {
            // go over number of rows and show them by order
            return this.getRow(i) ? (
              <div
                className={`buttons-ods cols-${this.getMaxCol(
                  i + 1
                )} ${className || ""}`}
                key={i}
              >
                {odd &&
                  [...Array(this.getMaxCol(i + 1))].map((col, j) => {
                    // go over number of cols in row and show them by order
                    const fieldId = this.getFieldId(i, j);
                    return fieldId ? (
                      <Bets.EventOddField
                        eventEnabled={eventEnabled}
                        key={`${fieldId},${oddId}`}
                        eventId={eventId}
                        oddId={oddId}
                        oddFieldId={fieldId}
                        showDetails={showDetails}
                        showFieldName={showFieldName}
                      />
                    ) : (
                      <div className="empty" key={i + j} />
                    );
                  })}
              </div>
            ) : null;
          })}
      </div>
    );
  }

  getFieldId(row, col) {
    const { oddFields } = this.props;
    let fieldId = null;
    Object.keys(oddFields).map((key) => {
      if (
        oddFields[key].oddFieldRow == row + 1 &&
        oddFields[key].oddFieldColumn == col + 1
      ) {
        fieldId = oddFields[key].activeOddFieldId;
        return;
      }
    });

    return fieldId;
  }

  getRow(rowNum) {
    const { oddFields } = this.props;
    let row = null;
    Object.keys(oddFields).map((key) => {
      if (oddFields[key].oddFieldRow == rowNum + 1) {
        row = oddFields[key];
        return;
      }
    });

    return row;
  }

  getFieldWidth(row) {
    const maxCol = this.getMaxCol(row);
    return `${100 / maxCol - 5}%`;
  }

  getMaxRow = () => {
    return Utils.getMaxObjByAttribute(this.props.oddFields, "oddFieldRow");
  };

  getMaxCol = (row) => {
    return Utils.getMaxObjByAttribute(this.props.oddFields, "oddFieldColumn");
  };
}

const mapStateToProps = (_, initialProps) => (state) => {
  let odd = state.sportEvents.eventOdds[initialProps.eventId]
    ? state.sportEvents.eventOdds[initialProps.eventId][initialProps.oddId]
    : null;

  let oddFields = state.sportEvents.eventOddFields
    ? state.sportEvents.eventOddFields[
        `${initialProps.eventId}_${initialProps.oddId}`
      ]
    : null;

  return {
    odd: odd,
    oddFields: oddFields,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(EventOdd);

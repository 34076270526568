import { Col,Popover } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { setCurrentSlotGame } from '../../../redux/actions/slotsActions';
import { AppConfig } from '../../../helpers';


class SlotItem extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            errored: false,
            src: undefined
        }
    }

    componentWillMount() {
        try {
            let imageName = `${this.props.data.platformGameName}.webp`;
            let url = AppConfig.icons;
            let src = `${url}${imageName}?id=${this.props.data.gameImageVersion}`;
            this.setState({
                errored: false,
                src
            })
        } catch (e) {
            this.setState({
                errored: true,
                src: require('../../../resources/images/logo600.webp'),
            })
        }

    }

    navToGame() {
        const { history, data, setCurrentSlotGame } = this.props;
        history.push(`/slots/game/${data.gameId}`);
        setCurrentSlotGame(data.gameName)
    }

    onError = () => {
        if (!this.state.errored) {
            this.setState({
                src: require('../../../resources/images/logo600.webp'),
                errored: true,
            });
        }
    }

    render() {
        const { data, config } = this.props;
        let itemsInRow = (24 / config.slotsGamesRow);

        return (

          !this.props.signedIn ?  <Col className="gutter-row" xs={(itemsInRow + 6)} sm={(itemsInRow + 4)} md={(itemsInRow + 2)} lg={itemsInRow} xl={itemsInRow}>
                <Popover
                    overlayClassName="popup-slots"
                    placement="bottom"
                    arrowPointAtCenter
                    content={
                         <p> Please Sign in or Register </p>
                    }
                    trigger="click">
                    <div className="gutter-box">
                    <img className="slot-image" src={this.state.src} data-game-id={data.gameId} onError={this.onError} /></div>

                    {
                        this.state.errored &&
                        <div>{data.gameName}</div>
                    }
                </Popover>
                 </Col>
                :

                <Col  className="gutter-row" xs={(itemsInRow + 6)} sm={(itemsInRow + 4)} md={(itemsInRow + 2)} lg={itemsInRow} xl={itemsInRow} >
                <div className="gutter-box">
                <img className="slot-image" src={this.state.src} data-game-id={data.gameId} onError={this.onError} onClick={() => this.navToGame()}/></div>

                {
                    this.state.errored &&
                    <div>{data.gameName}</div>
                }

            </Col>

           
        )
    }
}


const mapStateToProps = (_, initialProps) => (state) => {
    return {
        config: state.slots.config,
        signedIn: state.auth.signedIn
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentSlotGame: (game) => { dispatch(setCurrentSlotGame(game)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlotItem);
import React from "react";
import { AppConfig, Enums } from "../../../helpers";
import { Images } from "../../";

const Statistics = (props) => {
  const { sportEvent } = props;

  const onClick = () => {
    let redirectPath = "";
    switch (sportEvent.feed.feedId) {
      case Enums.Feed.Static:
        redirectPath = `${AppConfig.StatisticsAddress.Domain}?path=${
          AppConfig.StatisticsAddress.Static
        }/${sportEvent.eventId}`;
        break;

      default:
        redirectPath = `${AppConfig.StatisticsAddress.Domain}?path=${
          AppConfig.StatisticsAddress.Virtual
        }/${sportEvent.eventId}`;
        break;
    }

    // open statistics in a new window
    window.open(
      redirectPath,
      "winners",
      "left=20,top=20,width=1000,height=800,toolbar=1,resizable=0"
    );
  };

  {
  }
  return (
    <div className="bet-rows-icon-container">
      <a className="stats plus" onClick={() => onClick()}>
        {/* <Icon type="bar-chart" className="icon" /> */}

        <img src={Images.Icon_BarChart} />
      </a>
    </div>
  );
};

export default Statistics;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { Live, Sport, Virtual, ContactUs, Fixture, SlotMachines , MyAccount, Document, EmailValidate, StatisticsPage ,loginAndRegistration} from '../screens/';

import WithRouter from './WithRouter';

const Routes = (props) => {
	const { store, history } = props;
	return (
		<ConnectedRouter store={store} history={history}>

				<WithRouter>
					<Route exact path='/' render={() => (<Redirect to="/sport/6/1/Soccer" />)} />

					<Route path='/sport/:feed/:sport/:sportName' component={Sport} />
					<Route path='/live/:feed/:sport/:sportName' component={Live} />
					<Route path='/virtual/:feed/:sport/:sportName?' component={Virtual} />
					<Route exact path='/contact' component={ContactUs} />
					<Route path='/slots' component={SlotMachines} />
					<Route exact path='/fixture' component={Fixture} />
					<Route path='/emailvalidate/:id' component={EmailValidate} />
					<Route path='/document/:page' component={Document} />
					<Route exact path='/statistics' component={StatisticsPage} />
					<Route exact path='/login-registration/:indicator' component={loginAndRegistration} />
					<PrivateRoute path='/account/:page?' store={store} component={MyAccount} />

				</WithRouter>

		</ConnectedRouter>
	)
}

const PrivateRoute = ({ component: Component, ...rest }) =>{

	let state = rest.store.getState();
	return (
		<Route
			{...rest}
			render={props => state.auth.signedIn ? (
				<Component {...props} />
			) : (
					<Redirect
						to={{
							pathname: "/",
							state: { from: props.location }
						}}
					/>
				)
			}
		/>
	);
}
export default Routes;

import React from "react";
import { connect } from "react-redux";
import { Popover, Button, Switch, Input, Select, Popconfirm, Icon } from "antd";
import { setSelectedCurrency } from "../../../redux/actions/membersActions";
import { getCurrencies } from "../../../redux/actions/generalActions";
import { Cookies , CountryCodes } from "../../../helpers";
import Images from '../Images'


const { Option } = Select;

class BetSlipSettings extends React.Component {
  state = {
    visible: false,
    showCurrencyPopup: false,
    setCurrency: "",
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  popConfirmVisibleChange = () => {
    if (this.state.showCurrencyPopup) {
      this.handleVisibleChange(true);
      this.setState({ showCurrencyPopup: false }, () => {
        if (!this.state.showCurrencyPopup) {
          this.handleVisibleChange(false);
        }
      });
    }
  };

  getCountryCode = (currency) => {
    if (currency == "$"){
      let result = CountryCodes.Codes.filter((item) => {
        return item.name.toLowerCase() == "united states";
      });

      return result && result.length > 0 ? result[0].code.toLowerCase() : "eu";
    }
    if (currency == "LD"){
      let result = CountryCodes.Codes.filter((item) => {
        return item.name.toLowerCase() == "liberia";
      });
      return result && result.length > 0 ? result[0].code.toLowerCase() : "eu";
    }

};
  render() {
    const { currencies } = this.props;
    const user = Cookies.get("user");
    let selectedCurrency = Cookies.get("selectedCurrency");

    return (


      // New Code.
      
      // <div className="currency-setting">
      //               {user ? (
      //                 <Popconfirm
      //                   onVisibleChange={() => {
      //                     this.popConfirmVisibleChange();
      //                   }}
      //                   visible={this.state.showCurrencyPopup}
      //                   overlayClassName="currency-message-popover"
      //                   placement="topLeft"
      //                   title={"You are about to change currency"}
      //                   onConfirm={() => {
      //                     this.setState({ showCurrencyPopup: false }, () =>
      //                       this.props.setSelectedCurrency(
      //                         this.state.setCurrency
      //                       )
      //                     );
      //                   }}
      //                   okText="Yes"
      //                   cancelText="No"
      //                   onCancel={() => {
      //                     this.setState({ showCurrencyPopup: false });
      //                   }}
      //                 >
      //                   <Select
      //                     className="default-currency"
      //                     value={selectedCurrency.currencyId}
      //                     defaultValue={selectedCurrency.currencyId}
      //                   >
      //                     {Object.keys(user.balance).map((currency, index) => (
      //                       <Option
      //                         key={user.balance[currency].currencySymbol}
      //                         onClick={() => {
      //                           this.setState({
      //                             showCurrencyPopup: true,
      //                             setCurrency: user.balance[currency],
      //                           });
      //                         }}
      //                         value={user.balance[currency].currencyId}
      //                       >
      //                         {user.balance[currency].currencySymbol}
      //                       </Option>
      //                     ))}
      //                   </Select>
      //                 </Popconfirm>
      //               ) : (
      //                 currencies && (
      //                   <Popconfirm
      //                     visible={this.state.showCurrencyPopup}
      //                     overlayClassName="currency-message-popover"
      //                     placement="topLeft"
      //                     title={"You are about to change currency"}
      //                     onConfirm={() => {
      //                       this.setState({ showCurrencyPopup: false }, () =>
      //                         this.props.setSelectedCurrency(
      //                           this.state.setCurrency
      //                         )
      //                       );
      //                     }}
      //                     okText="Yes"
      //                     cancelText="No"
      //                     onVisibleChange={() => {
      //                       this.popConfirmVisibleChange();
      //                     }}
      //                     onCancel={() => {
      //                       this.setState({ showCurrencyPopup: false });
      //                     }}
      //                   >
      //                     <Select
      //                       className="default-currency"
      //                       value={selectedCurrency.currencyId}
      //                       defaultValue={selectedCurrency.currencyId}
      //                     >
      //                       {Object.keys(currencies).map((currency, index) => (
      //                         <Option
      //                           onClick={() => {
      //                             this.setState({
      //                               showCurrencyPopup: true,
      //                               setCurrency: currencies[currency],
      //                             });
      //                           }}
      //                           key={currencies[currency].currencySymbol}
      //                           value={currencies[currency].currencyId}
      //                         >
      //                           {currencies[currency].currencySymbol}
      //                         </Option>
      //                       ))}
      //                     </Select>
      //                   </Popconfirm>
      //                 )
      //               )}
      //             </div>


      <div id="default-currency-container">
      {user && currencies ? (
        <Popconfirm
          onVisibleChange={() => {
            this.popConfirmVisibleChange();
          }}
          visible={this.state.showCurrencyPopup}
          overlayClassName="currency-message-popover"
          placement="topLeft"
          title={"You are about to change currency"}
          onConfirm={() => {
            this.setState({ showCurrencyPopup: false }, () =>
              this.props.setSelectedCurrency(
                this.state.setCurrency
              )
            );
          }}
          okText="Yes"
          cancelText="No"
          onCancel={() => {
            this.setState({ showCurrencyPopup: false });
          }}
        >
          <Select
            className="default-currency"
            value={selectedCurrency.currencyId}
            defaultValue={selectedCurrency.currencyId}
          >
            {Object.keys(user.balance).map((currency, index) => (
              <Option
                key={user.balance[currency].currencySymbol}
                onClick={() => {
                  this.setState({
                    showCurrencyPopup: true,
                    setCurrency: user.balance[currency],
                  });
                }}
                value={user.balance[currency].currencyId}
              >
                                  <span
                                        style={{ width: "30px" }}
                                        className={
                                          "flag-icon flag-icon-" +
                                          this.getCountryCode(
                                            currencies[currency].currencySymbol || ""
                                          )
                                        }
                                      />
                  {currencies[currency].currencySymbol}
                  <img style={{marginLeft:"7px" ,marginBottom:"2px" , filter:"var(--convert-brightness)"}} src={Images.DownArrow} height={"14px"} width={"12px"}/>
              </Option>
            ))}
          </Select>
        </Popconfirm>
      ) : (
        currencies && (
          <Popconfirm
            visible={this.state.showCurrencyPopup}
            overlayClassName="currency-message-popover"
            placement="topLeft"
            title={"You are about to change currency"}
            onConfirm={() => {
              this.setState({ showCurrencyPopup: false }, () =>
                this.props.setSelectedCurrency(
                  this.state.setCurrency
                )
              );
            }}
            okText="Yes"
            cancelText="No"
            onVisibleChange={() => {
              this.popConfirmVisibleChange();
            }}
            onCancel={() => {
              this.setState({ showCurrencyPopup: false });
            }}
          >
            <Select
              className="default-currency"
              value={selectedCurrency.currencyId}
              defaultValue={selectedCurrency.currencyId}
            >
              {Object.keys(currencies).map((currency, index) => (
                <Option
                  onClick={() => {
                    this.setState({
                      showCurrencyPopup: true,
                      setCurrency: currencies[currency],
                    });
                  }}
                  key={currencies[currency].currencySymbol}
                  value={currencies[currency].currencyId}
                >
                  <span
                                        style={{ width: "30px" }}
                                        className={
                                          "flag-icon flag-icon-" +
                                          this.getCountryCode(
                                            currencies[currency].currencySymbol || ""
                                          )
                                        }
                                      />
                  {currencies[currency].currencySymbol}
                  <img style={{marginLeft:"7px" ,marginBottom:"2px" , filter:"var(--convert-brightness)"}} src={Images.DownArrow} height={"14px"} width={"12px"}/>
                </Option>
              ))}
            </Select>
          </Popconfirm>
        )
      )}
    </div>


      // old code
      // selectedCurrency && (
      //   <div className="betslip-settings">
      //     <Popover
      //       overlayClassName="settings-popover"
      //       content={
      //         <div className="betslip-settings-container">
      //           {/* <div className="setting">
      //                           <div>Accept odds change automatically</div>
      //                           <div><Switch className="betslip-switch" /></div>
      //                       </div> */}
      //           <div className="setting">
      //             <div>Currency</div>
      //             <div className="currency-setting">
      //               {user ? (
      //                 <Popconfirm
      //                   onVisibleChange={() => {
      //                     this.popConfirmVisibleChange();
      //                   }}
      //                   visible={this.state.showCurrencyPopup}
      //                   overlayClassName="currency-message-popover"
      //                   placement="topLeft"
      //                   title={"You are about to change currency"}
      //                   onConfirm={() => {
      //                     this.setState({ showCurrencyPopup: false }, () =>
      //                       this.props.setSelectedCurrency(
      //                         this.state.setCurrency
      //                       )
      //                     );
      //                   }}
      //                   okText="Yes"
      //                   cancelText="No"
      //                   onCancel={() => {
      //                     this.setState({ showCurrencyPopup: false });
      //                   }}
      //                 >
      //                   <Select
      //                     className="default-currency"
      //                     value={selectedCurrency.currencyId}
      //                     defaultValue={selectedCurrency.currencyId}
      //                   >
      //                     {Object.keys(user.balance).map((currency, index) => (
      //                       <Option
      //                         key={user.balance[currency].currencySymbol}
      //                         onClick={() => {
      //                           this.setState({
      //                             showCurrencyPopup: true,
      //                             setCurrency: user.balance[currency],
      //                           });
      //                         }}
      //                         value={user.balance[currency].currencyId}
      //                       >
      //                         {user.balance[currency].currencySymbol}
      //                       </Option>
      //                     ))}
      //                   </Select>
      //                 </Popconfirm>
      //               ) : (
      //                 currencies && (
      //                   <Popconfirm
      //                     visible={this.state.showCurrencyPopup}
      //                     overlayClassName="currency-message-popover"
      //                     placement="topLeft"
      //                     title={"You are about to change currency"}
      //                     onConfirm={() => {
      //                       this.setState({ showCurrencyPopup: false }, () =>
      //                         this.props.setSelectedCurrency(
      //                           this.state.setCurrency
      //                         )
      //                       );
      //                     }}
      //                     okText="Yes"
      //                     cancelText="No"
      //                     onVisibleChange={() => {
      //                       this.popConfirmVisibleChange();
      //                     }}
      //                     onCancel={() => {
      //                       this.setState({ showCurrencyPopup: false });
      //                     }}
      //                   >
      //                     <Select
      //                       className="default-currency"
      //                       value={selectedCurrency.currencyId}
      //                       defaultValue={selectedCurrency.currencyId}
      //                     >
      //                       {Object.keys(currencies).map((currency, index) => (
      //                         <Option
      //                           onClick={() => {
      //                             this.setState({
      //                               showCurrencyPopup: true,
      //                               setCurrency: currencies[currency],
      //                             });
      //                           }}
      //                           key={currencies[currency].currencySymbol}
      //                           value={currencies[currency].currencyId}
      //                         >
      //                           {currencies[currency].currencySymbol}
      //                         </Option>
      //                       ))}
      //                     </Select>
      //                   </Popconfirm>
      //                 )
      //               )}
      //             </div>
      //           </div>
      //           {/* <div className="setting">
      //                           <div>
      //                               Bet amount by default
      //                          </div>
      //                           <div>
      //                               <Input className="default-bet-amount"></Input>
      //                           </div>
      //                       </div>
      //                       <div className="setting">
      //                           <div>Direct Bet Mode</div>
      //                           <div><Switch defaultChecked className="betslip-switch"></Switch></div>
      //                       </div> */}
      //         </div>
      //       }
      //       title="Bet Slip Settings"
      //       trigger="click"
      //       visible={this.state.visible}
      //       onVisibleChange={this.handleVisibleChange}
      //       placement="bottomRight"
      //     >
      //       <Icon
      //         onClick={this.handleVisibleChange}
      //         type="ellipsis"
      //         rotate="90"
      //       ></Icon>
      //     </Popover>
      //   </div>
      // )
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    selectedCurrency: state.member.selectedCurrency,
    currencies: state.general.currencies,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedCurrency: (currency) => dispatch(setSelectedCurrency(currency)),
  getCurrencies: () => dispatch(getCurrencies()),
});
export default connect(mapStateToProps, mapDispatchToProps)(BetSlipSettings);

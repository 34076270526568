import { List } from "antd";
import React from "react";
import { connect } from "react-redux";
import { setPropsMath } from "../../../redux/actions/appActions";
import { Bets } from "../../";
import { setEvent } from "../../../redux/actions/sportEventsActions";
import EventRow from "./EventRow";

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.rowRenderer = this.rowRenderer.bind(this);
  }

  async componentDidMount() {
    const { match, setPropsMath } = this.props;
    setPropsMath(match);
  }

  async componentDidUpdate(prevProps) {
    const { match, setPropsMath } = this.props;
    setPropsMath(match);
  }

  getStartedEvents = () => {
    const { sportEvents } = this.props;
    return sportEvents ? sportEvents.filter((item) => item.isStarted) : null;
  };

  rowRenderer(item) {
    return <EventRow {...this.props} id={item} />;
  }

  render() {
    const { loadingSportEvents, title, eventsIds } = this.props;

    if (loadingSportEvents) {
      return (
        <div className="events">
          <div className="skeleton">
            <div className="row">
              <div className="loader" />
              <div className="col" />
              <div className="col" />
              <div className="col" />
              <div className="col" />
            </div>

            <div className="row">
              <div className="loader" />
              <div className="col" />
              <div className="col" />
              <div className="col" />
              <div className="col" />
            </div>

            <div className="row">
              <div className="loader" />
              <div className="col" />
              <div className="col" />
              <div className="col" />
              <div className="col" />
            </div>

            <div className="row">
              <div className="loader" />
              <div className="col" />
              <div className="col" />
              <div className="col" />
              <div className="col" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="events">
        <div id="bits-filter" style={{marginleft:"10px"}}>
          {eventsIds && eventsIds.length > 0 && (
            <Bets.EventsHeader {...this.props} title={title} />
          )}
        </div>
        <div id="bits-filter-rows">
          {eventsIds &&
            eventsIds.map((item) => (
              <div className="event-row-parent-container" key={item}>
                <EventRow {...this.props} id={item} key={item} />
                </div>
            ))
          // <List
          // 	dataSource={eventsIds}
          // 	renderItem={this.rowRenderer}
          // />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (_, initialProps) => (state) => {
  return {
    loadingSportEvents: state.sportEvents.loadingSportEvents,
    eventsIds: state.sportEvents.eventsIds,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setEvent: (item) => {
      dispatch(setEvent(item));
    },
    setPropsMath: (match) => {
      dispatch(setPropsMath(match));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Events);

import React from 'react'
import { connect } from 'react-redux';

import { Bets, } from '../../';
import Events from './Events'

import { loadSportEvents, loadTopBets } from '../../../redux/actions/sportEventsActions';
import { setPropsMath } from '../../../redux/actions/appActions'


class EventsLive extends React.Component {

    componentDidMount() {
        const { loadSportEvents, match, loadTopBets, setPropsMath, connected } = this.props;
        setPropsMath(match);

        if (connected) {
            if (match.params.tournament) {

                let request = {
                    Data: {
                        Feed: { FeedId: Number(match.params.feed) },
                        Sport: { SportId: Number(match.params.sport) },
                        SportCategory: { SportCategoryId: Number(match.params.country) },
                        TournamentId: Number(match.params.tournament)
                    }
                }
                loadSportEvents(request);
            } else {

                let request = {
                    Data: {
                        Feed: { FeedId: Number(match.params.feed) },
                        Sport: { SportId: Number(match.params.sport) },
                    }
                }
                loadTopBets(request)
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { loadSportEvents, match, loadTopBets,connected } = this.props;
        if ((prevProps.match.params.tournament !== this.props.match.params.tournament)
        || (prevProps.match.params.sport !== this.props.match.params.sport) || (!prevProps.connected && connected)) {
            if (match.params.tournament) {
                let request = {
                    Data: {
                        Feed: { FeedId: Number(match.params.feed) },
                        Sport: { SportId: Number(match.params.sport) },
                        SportCategory: { SportCategoryId: Number(match.params.country) },
                        TournamentId: Number(match.params.tournament)
                    }
                }
                loadSportEvents(request);
            } else {

                let request = {
                    Data: {
                        Feed: { FeedId: Number(match.params.feed) },
                        Sport: { SportId: Number(match.params.sport) },
                    }
                }
                loadTopBets(request);
            }
        }
    }

    render() {
        const { error } = this.props;

        if (error) {
            return (<div className="title">{error}</div>)
        }
        return (
            <div style={{ height: "100%" }}>
                <Events {...this.props}
                    key="eventsLive" />
                <Bets.Upcoming {...this.props} />
            </div>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        feedId: state.app.feedId,
        sportData: state.sportTypes.sportData,
        tournamentData: state.categories.tournamentData,
        error: state.sportEvents.error,
        connected: state.signalr.connected,

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        loadSportEvents: (request) => {
            dispatch(loadSportEvents(request));
        },
        loadTopBets: (request) => {
            dispatch(loadTopBets(request));
        },
        setPropsMath: (match) => {
            dispatch(setPropsMath(match));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsLive);
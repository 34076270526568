import { SignalRTypes } from "../actions";
import { SessionStorage } from "../../helpers";

const initState = {
  loadingBalances: false,
  jackpotData: null,
  error: null,
  winningMessage: [],
  currentWin: SessionStorage.currentWin, 
  jackpotWinningApproved: SessionStorage.jackpotWinningApproved
};

const jackpot = (state = initState, action) => {
  switch (action.type) {
    case SignalRTypes.Jackpot_Get_Request:
      return {
        ...state,
        loadingBalances: true,
        jackpotData: null
      };
    case SignalRTypes.Jackpot_Get_Success:
      return {
        ...state,
        loadingBalances: false,
        winningMessage: [...state.winningMessage, action.winningMessage],
      };
    case SignalRTypes.Jackpot_Get_Error:
      return {
        ...state,
        loadingBalances: false,
        error: action.message,
        jackpotData: null
      };
    case SignalRTypes.Set_Jackpot_Won_Message:
      return {
        ...state,
        winningMessage: action.messages
      };
    case SignalRTypes.Jackpot_Winning_Approved:
      return {
        ...state,
        jackpotWinningApproved: action.jackpotWinningApproved
      }
      case SignalRTypes.Set_Is_Jackpot_Won:
        return {
          ...state,
          currentWin: action.currentWin
        };
    default:
      return state;
  }
};
export default jackpot;

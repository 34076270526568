import { all, fork, takeEvery } from "redux-saga/effects";
import { HomeActionTypes } from '../actions';


export function* init() {

}

export function* requests() {
  yield takeEvery(HomeActionTypes.Load_Request, function* () {
    // try {
    //   //const hubConnection = new HubConnection('https://localhost:44344');

    //   let feedChangesHub = new HubConnectionBuilder()
    //     //.configureLogging(LogLevel.Trace)
    //     .withUrl("https://localhost:44334/FeedChangesHub")
    //     .build();

    //   feedChangesHub.on("FeedChangedChunksCallback", data => {
    //     window.log("FEEDBACK", data);
    //   });

    //   feedChangesHub.start().then(() => { })
      
    // }
    // catch (e) {
    //   window.log("ERROR", e)
    // }
  })
}

export default function* homeSaga() {
  yield all([
    fork(init),
    fork(requests)
  ])
}

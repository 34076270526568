import { SlotsActionTypes,SignalRTypes } from '../actions'

export function getSlotsGames() {
    return {
        type: SlotsActionTypes.Get_Slots_Games_Request,
    }
}

export function slotsConnect(request) {
    return {
        type: SlotsActionTypes.Slots_Game_Connect_Request,
        request
    }
}



export function gameInit() {
    return {
        type: SlotsActionTypes.Slots_Game_Init,
    }
}

export function setCurrentSlotGame (game){
    return{
        type: SlotsActionTypes.Current_Slots_Game,
        currentGame: game
    }
}
export function partGroups() {
    return {
      type: SignalRTypes.Handle_Join_Part_Groups,
      isPartOnly: true
    };
  }
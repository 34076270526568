import React from 'react';
import { eventChannel } from 'redux-saga';
import { push } from 'react-router-redux';
import { all, fork, takeLatest, put, call, take, select } from "redux-saga/effects";
import { authApi } from '../../api/index';
import { AuthActionTypes, BetSlipActionTypes, RegisterActionTypes, MembersActionTypes, SignalRTypes, AppActionTypes, GeneralActionTypes } from '../actions';
import { Cookies, Events, Utils, AppConfig } from '../../helpers';
import NoActivityModal from '../../components/common/NoActivityModal';

export function* requests() {
    let tokenChannel = null;
    let tokenTimeout = null;

    function clearCookies() {
        Cookies.remove("token");
        Cookies.remove("memberId");
        Cookies.remove("user");
        Cookies.remove("tokenExpiresMinutes");
        Cookies.remove("jackpotId")
    }

    function extendCookiesLife() {
        const token = Cookies.get("token");
        const memberId = Cookies.get("memberId");
        const user = Cookies.get("user");
        const tokenExpiresMinutes = Cookies.get("tokenExpiresMinutes");
        const jackpotId = Cookies.get("jackpotId")

        Cookies.set("token", token, tokenExpiresMinutes);
        Cookies.set("memberId", memberId, tokenExpiresMinutes);
        Cookies.set("user", user, tokenExpiresMinutes);
        Cookies.set("tokenExpiresMinutes", tokenExpiresMinutes, tokenExpiresMinutes);
        Cookies.set("jackpotId", jackpotId)
    }

    function handleTokenExpiresOnClick(emitter) {
        const tokenExpiresMinutes = Cookies.get("tokenExpiresMinutes");
        const timeoutExpires = tokenExpiresMinutes * 60000;
        const token = Cookies.get("token");
        const memberId = Cookies.get("memberId");

        if (!token || !memberId) {
            //emitter({ name: 'timeout' })
        }
        else {
            extendCookiesLife();
            clearTimeout(tokenTimeout);
            tokenTimeout = setTimeout(() => {
                emitter({ name: 'timeout' })
            }, timeoutExpires - 35000);
        }
    }

    function* tokenChannelCreate() {
        try {

            return yield eventChannel(emitter => {

                Events.subscribe("onTokenExpired", e => {
                    emitter({ name: 'signout', tokenExpired: true })

                });

                Events.subscribe('signout', e => {
                    emitter({ name: 'signout', noActivity: e || false })
                })

                clearTimeout(tokenTimeout);
                const tokenExpiresMinutes = Cookies.get("tokenExpiresMinutes");
                const timeoutExpires = tokenExpiresMinutes * 60* 1000;
                window.log(timeoutExpires)
                tokenTimeout = setTimeout(() => {
                    emitter({ name: 'timeout' })
                }, timeoutExpires - 35000);

                let authListener = () => {
                    handleTokenExpiresOnClick(emitter);
                }
                document.addEventListener("click", authListener);
                return () => {
                    clearTimeout(tokenTimeout);
                    document.removeEventListener("click", authListener);

                }
            });
        }
        catch (e) {
            window.log("ERROR", e);
        }
    }

    function tokenChannelClose() {
        if (tokenChannel) {
            tokenChannel.close();
            tokenChannel = null;
        }
    }

    yield takeLatest(AuthActionTypes.Signin_Request, function* (userData) {
        //call server
        try {
            let response = yield authApi.signin(userData.data);


            switch (response.status) {
                case 1:
                    let data = response.data.member;
                    if (data.isSignedIn) {
                        //show pop confirm login 
                        yield put({ type: AuthActionTypes.Set_Show_Login_Pop_Confirm, value: true });
                    } else {
                        const tokenExpiresMinutes = data.tokenExpiresMinutes;
                        yield Cookies.set("token", data.token, tokenExpiresMinutes);
                        yield Cookies.set("memberId", data.memberId);
                        yield Cookies.set("tokenExpiresMinutes", data.tokenExpiresMinutes, tokenExpiresMinutes);

                        if(response.data.jackpotPendingWinning){
                            yield Cookies.set("jackpotId", response.data.jackpotPendingWinning.jackpotId)
                            yield put({type: SignalRTypes.Jackpot_Winning_Approved, jackpotWinningApproved : true })
                        } 
                       
                        let balances = data.memberBalances.reduce((acc, current) => {
                            let currencyName = current.currency.currencyName.split(' ').join('');
                            if (!acc[currencyName]) {
                                acc[currencyName] = {
                                    balance: current.balance,
                                    currencyId: current.currency.currencyId,
                                    currencySymbol: current.currency.currencySymbol,
                                    defaultCurrency: current.currency.currencyIsDefault,
                                    currencyName: current.currency.currencyName
                                }
                            }
                            return acc;
                        }, {});
                        let currency = Object.keys(balances).find((currency) => {
                            return balances[currency].defaultCurrency
                        })
                        yield Cookies.set("selectedCurrency", balances[currency]);
                        yield put({ type: MembersActionTypes.Set_Selected_Currency, currency: balances[currency] })
                        yield Cookies.set('user', {
                            userName: data.userName,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            email: data.email,
                            balance: balances,
                            bonus: data.memberDepositBonusBalance,
                            memberPhones: response.data.memberPhones
                        }, tokenExpiresMinutes)
                        if (userData.register) {
                            yield put({ type: AuthActionTypes.Register_Signin_Success });
                        }
                        else {
                            yield put({ type: AuthActionTypes.Signin_Success, data });
                        }
                    }
                    break;
                case 3 || 4:
                    yield put({ type: AuthActionTypes.Signin_Error, message: response.message });
                    break;
                    default: 
                    break;
            }

        } catch (error) {
            window.log('client ==>> could not sign in');
        }
    })

    yield takeLatest(AuthActionTypes.Signin_Success, function* () {
        try {
            yield put({ type: BetSlipActionTypes.Set_Login_Error, loginError: "" })
            yield put({ type: AuthActionTypes.Start_Click_listener })
           if(AppConfig.booongoSRAddress){
              yield put({ type: SignalRTypes.Booongo_Connect_Request }) 
           } 
           if(AppConfig.jackpotSRAddress){
            yield put({ type: SignalRTypes.Jackpot_Connect_Request });
           }
        }
        catch (e) {
            window.log("ERROR", e);
        }
    })

    yield takeLatest(AuthActionTypes.Start_Click_listener, function* () {
        const { register } = yield select();
        if (!tokenChannel) {
            tokenChannel = yield call(tokenChannelCreate);
            yield window.log("Service START: Token Service");
        }
        while (true) {
            const event = yield take(tokenChannel);
            if (event.name === "signout") {
                if (register.formData.phone) {
                    yield put({ type: RegisterActionTypes.Init });
                }
                yield put({ type: AuthActionTypes.Signout_Request, noActivity: event.noActivity, tokenExpired: event.tokenExpired })
                clearTimeout(tokenTimeout);
            }
            if (event.name === "timeout") {
                const noActivityModal = <NoActivityModal
                    text='You have been not active for a while, member will be signed out in'
                    time={30}
                    okLabel='Stay Connected'
                    onOK={() => Utils.closeModal({ id: 'memberTimeOut' })}
                    onTimeoutFinished={() => {Events.publish('signout', true);  Utils.closeModal({ id: 'memberTimeOut' })}} />;

                let modal = {
                    key: 'memberTimeOut',
                    type: 'custom',
                    children: noActivityModal,
                    visible: true,
                }
                Utils.openModal(modal)
                clearTimeout(tokenTimeout);
            }
        }
    })

    yield takeLatest(AuthActionTypes.Signout_Request, function* ({ noActivity, tokenExpired }) {
        //call server

        try {
            if (!tokenExpired) {
                let response = yield authApi.signout({ id: Cookies.get("memberId") });
                switch (response.status) {
                    case 1:
                        yield put({ type: AuthActionTypes.Signout_Success, response });
                        yield put(push('/'));
                        if (noActivity) {
                            Utils.closeModal({ id: 'memberTimeOut' })
                            let modal = {
                                key: 'memberSignedOut',
                                type: 'text',
                                text: 'You have been not active for a while, you were signed out',
                                visible: true,
                                onOK: () => Utils.closeModal({ id: 'memberSignedOut' }),
                                okLabel: 'OK'
                            }
                            Utils.openModal(modal)
                        }
                        break;
                    case 3 || 4:
                        yield put({ type: AuthActionTypes.Signout_Error, message: response.message });
                        break;
                }

            } else {
                yield put(push('/'));
                yield put({ type: AuthActionTypes.Signout_Success });

                let modal = {
                    key: 'tokenExpired',
                    text: 'Your token was expired, please sign in again.',
                    type: 'text',
                    onOK: () => {Utils.closeModal({ id: 'tokenExpired' })},
                    okLabel: 'Ok',
                }

                Utils.openModal(modal);

            }
        } catch (error) {
            window.log('client ==>> could not sign out');
        }
    })

    yield takeLatest(AuthActionTypes.Signout_Success, function* () {
        try {
            yield clearCookies();
            yield put({ type: AuthActionTypes.Init });
            yield put({ type: MembersActionTypes.Init });
            yield put({ type: RegisterActionTypes.Init });
            yield put({ type: GeneralActionTypes.Get_Currencies_Request });//GET general currencies after user signout 
            yield put({ type: SignalRTypes.Booongo_Disconnect_Request });

            Events.unSubscribe("onTokenExpired");
            yield tokenChannelClose();


            yield put({ type: AppActionTypes.ChangeFeed, feedId: '6', feedName: 'sport' })
        }
        catch (e) {
            window.log("ERROR", e);
        }
    })

    

    yield takeLatest(AuthActionTypes.Forgot_Password_Check_Dest_Request, function* (userName) {
        //call server
        try {
            let response = yield authApi.forgotPasswordCheckDestination({ name: userName.data });
            let data = response.data;
            switch (response.status) {
                case 1:
                    yield put({ type: AuthActionTypes.Forgot_Password_Check_Dest_Suceess, data, message: response.message });
                    break;
                case 3 || 4:
                    yield put({ type: AuthActionTypes.Forgot_Password_Check_Dest_Error, message: response.message });
                    break;
            }
        } catch (error) {
            window.log('client ==>> could not request forgot password check destination');
        }
    })

    yield takeLatest(AuthActionTypes.Forgot_Password_Request, function* (userData) {
        //call server
        try {
            let response = yield authApi.forgotPassword(userData.data);
            let data = response.data;
            switch (response.status) {
                case 1:
                    yield put({ type: AuthActionTypes.Forgot_Password_Suceess, data, message: response.message });
                    yield put({ type:AuthActionTypes.Forgot_Password_First_Step_Confirm_Suceess})
                    break;
                case 3 || 4:
                    yield put({ type: AuthActionTypes.Forgot_Password_Error, message: response.message });
                    break;
            }
        } catch (error) {
            window.log('client ==>> could not request forgot password');
        }
    })

    yield takeLatest(AuthActionTypes.Forgot_Password_Confirm_Request, function* (userData) {
        //call server
        try {
            const { auth } = yield select();
            let response = yield authApi.forgotPasswordConfirm(userData.data);
            switch (response.status) {
                case 1:
                    let current = auth.currentForgotPassword + 1;
                    yield put({ type: AuthActionTypes.Forgot_Password_Confirm_Suceess, current });
                    break;
                case 3 || 4:
                    yield put({ type: AuthActionTypes.Forgot_Password_Confirm_Error, message: response.message });
                    break;
            }
        } catch (error) {
            window.log('client ==>> could not request forgot password confirm');
        }
    })


    yield takeLatest(AuthActionTypes.Change_Password_Request, function* (userData) {
        //call server
        try {
            let response = yield authApi.changePassword(userData.data);
            switch (response.status) {
                case 1:
                    yield put({ type: AuthActionTypes.Change_Password_Suceess, message: response.message });
                    break;
                case 3 || 4:
                    yield put({ type: AuthActionTypes.Change_Password_Error, message: response.message });
                    break;
            }
        } catch (error) {
            window.log('client ==>> could not request change password');
        }
    })

    yield takeLatest(AuthActionTypes.Update_Password_Request, function* ({ data }) {
        //call server
        try {
            let response = yield authApi.updatePassword(data);
            switch (response.status) {
                case 1:
                    yield put({ type: AuthActionTypes.Update_Password_Suceess });
                    break;
                case 3 || 4:
                    yield put({ type: AuthActionTypes.Update_Password_Error, message: response.message });
                    break;
            }
        } catch (error) {
            window.log('client ==>> could not request update password');
        }
    })

    const { auth } = yield select()
    if (auth.signedIn) {
        yield put({ type: AuthActionTypes.Start_Click_listener })
        extendCookiesLife()
    }
}
export default function* AuthSaga() {
    yield all([
        fork(requests)
    ])
}

import { Fetch,AppConfig } from '../helpers'

export default class slotsApi {

	static getSlotsGames = () => {
		const response = Fetch.fetch(
			{
				methodName: "GetSlotsGames",
				method: "GET",
				url: AppConfig.api.slots,
			}
		)
		return response;
	}
	static slotsConnect = (data) => {
		const response = Fetch.fetch(
			{
				methodName: "SlotsConnect",
				method: "POST",
				url: AppConfig.api.slots,
				data
			}
		)
		return response;
	}

	static getMemberBalance = () => {
		const response = Fetch.fetch(
			{
                methodName: "GetMemberBalance",
                method: "POST",
				url: AppConfig.api.slots,
				
			}
		)
		return response;
	}

	static getJackpots = () => {
		const response = Fetch.fetch(
			{
				methodName: "GetJackpots",
				method: "POST",
				url: AppConfig.api.slots,
			}
		)
		return response;
	}
}


import { all, fork, takeEvery, put } from "redux-saga/effects";
import { VirtualActionTypes } from '../actions';

export function* init() {
}

export function* requests() {
	yield takeEvery(VirtualActionTypes.Load_Request, function* ({ feedId }) {

	})

	yield takeEvery(VirtualActionTypes.SportEvents_Request, function* ({ data }) {
		try {
			window.log("virt", data)

		}
		catch (e) {

		}
	})
}

export default function* virtualSaga() {
	yield all([
		fork(requests),
		fork(init),
	])
}

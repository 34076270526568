import { Form, Affix, Input, Tabs, Icon } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Images, Search } from "../";
import { Enums, Utils } from "../../helpers";
import ModeSwitcher from "../common/ModeSwitcher";
import { switchMode } from "../../redux/actions/appActions";

import {
  changeFeed,
  openMobileMenu,
  openMobileBetslip,
} from "../../redux/actions/appActions";
import Connection from "../common/Connection";
import Signin from "./Signin";
const TabPane = Tabs.TabPane;

const Title = (props) => {
  const { text, icon } = props;

  return (
    <div className="title">
      {icon && <Icon className="icon" type={icon || "dollar"} />}
      <div style={{ fontSize: 13 }} className="icon">
        {text}
      </div>
    </div>
  );
};
// const style
class HeaderMobile extends React.Component {
  state = {
    selectedTab: null,
    displayMobileSearchBar: true,
  };
  async componentDidMount() {}

  onDrawerMenu = (open) => {
    const { openMobileMenu } = this.props;
    openMobileMenu(open);
  };

  onSwipe = (e) => {};

  toggleBetweenSearchContainers = () => {
   
    this.setState({
      displayMobileSearchBar: !this.state.displayMobileSearchBar,
    });
  };

  navigate = (
    route,
    feedId = Enums.Feed.Static,
    feedName = Enums.FeedNames.Static
  ) => {
    const { changeFeed, history } = this.props;
    if (history.location.pathname != route) {
      history.push(`${route}`);
      changeFeed(feedId.toString(), feedName);
    }
  };
  onTabChange(key) {
    this.setState({ selectedTab: key });
  }

  render() {
    const {
      feedName,
      feedId,
      betSlipList,
      mobileBetslipOpen,
      mobileMenuOpen,
      openMobileBetslip,
      signedIn,
      switchMode
    } = this.props;

    return (
      <div className="container mobile">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: " 0 6vw 0.5vw 6vw",
            background:`var(--light-black)`
          }}
        >
           <Affix offsetTop={0}>
            
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {this.state.displayMobileSearchBar && !signedIn ? (
                <div
                  onClick={this.toggleBetweenSearchContainers}
                  className="search-box-mobile-view"
                >
                  <Search />
                </div>
              ) : null}
              {!this.state.displayMobileSearchBar && !signedIn ? (
                <div onDoubleClick={this.toggleBetweenSearchContainers} className="search-box-mobile-view-icon">
                  <Search />
                </div>
              ) : null}

              {feedName !== "slots" && (
                <div id="menu-icon-container">
                  <div
                    className="menu"
                    onClick={() => this.onDrawerMenu(!mobileMenuOpen)}
                  >
                    <Icon
                      type={`${mobileMenuOpen ? "close" : "menu"}`}
                      className="icon"
                    />
                  </div>
                </div>
              )}
              {feedName === "slots" && (
                <div
                  className="menu"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  <Title icon={"rollback"} text="Back"></Title>
                </div>
              )}

              {/* <div className="empty">{Utils.InDev && <Connection />}</div> */}
            </div>
          </Affix>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyItems: "space-between",
            }}
          >
            <ModeSwitcher switchMode={switchMode} />
            {/* <div
              className="link light-on"
              onClick={() => {
                this.navigate("/contact", Enums.Feed.ContactUs);
              }}
            >
              Contact Us
            </div> */}
            <Connection />
            <div id="time-mobile-view" className="time" style={{ marginLeft: "10px"   , color:"gray" , textTransform:"uppercase"}}>
              {Utils.formatDate(this.state.time, "HH:mm a")}
            </div>
          </div>
         
        </div>

        <Affix>
          <div className="top-menu">
            <div className="mobile-header-top">
              <div className="logo" onClick={() => this.navigate(`/`)}>
                <img id="winners-logo"
                  style={{ height: "70px", width: "150px" }}
                  alt="Winners"
                  src={Images.LogoWinners}
                />
              </div>

              <Signin isMobile history={this.props.history} />
            </div>

            <div className="mobile-header-title-wrapper">
              <div
                className={`title light-on ${
                  feedId === Enums.Feed.Static.toString() ? "selected" : ""
                }`}
                onClick={() =>
                  this.navigate(
                    `/sport/${Enums.Feed.Static}/1/Soccer`,
                    Enums.Feed.Static,
                    Enums.FeedNames.Static
                  )
                }
              >
                Sports
              </div>
              <div
                className={`title light-on ${
                  feedId === Enums.Feed.Live.toString() ? "selected" : ""
                }`}
                onClick={() =>
                  this.navigate(
                    `/live/${Enums.Feed.Live}/1/Soccer`,
                    Enums.Feed.Live,
                    Enums.FeedNames.Live
                  )
                }
              >
                Live
              </div>
              <div
                className={`title light-on ${
                  feedName === Enums.FeedNames.Slots ? "selected" : ""
                }`}
                onClick={() =>
                  this.navigate(
                    "/slots",
                    Enums.Feed.Slots,
                    Enums.FeedNames.Slots
                  )
                }
              >
                Slots
              </div>
              <div
                className={`title light-on ${
                  feedId === Enums.Feed.VFL.toString() ? "selected" : ""
                }`}
                onClick={() =>
                  this.navigate(
                    "/virtual/7/1",
                    Enums.Feed.VFL,
                    Enums.FeedNames.VFL
                  )
                }
              >
                Virtual
              </div>
              <div
                className={`title light-on ${
                  feedId === Enums.Feed.Fixture.toString() ? "selected" : ""
                }`}
                onClick={() => this.navigate("/fixture", Enums.Feed.Fixture)}
              >
                Spin & Win
              </div>
            </div>

            {/* OLD MAIN MENU */}
            {/* <div className="scroll">
              <Tabs
                defaultActiveKey={this.state.selectedTab}
                className="mobile-menu-tabs"
                onChange={(key) => {
                  this.onTabChange(key);
                }}
              >
                <TabPane
                  tab={
                    <div
                      className={`title light-on ${
                        feedId === Enums.Feed.Static.toString()
                          ? "selected"
                          : ""
                      }`}
                      onClick={() =>
                        this.navigate(
                          `/sport/${Enums.Feed.Static}/1/Soccer`,
                          Enums.Feed.Static,
                          Enums.FeedNames.Static
                        )
                      }
                    >
                      <img src={Images.Icon_Home} />
                      Sports
                    </div>
                  }
                  key={"sports"}
                ></TabPane>
                <TabPane
                  tab={
                    <div
                      className={`title light-on ${
                        feedId === Enums.Feed.Live.toString() ? "selected" : ""
                      }`}
                      onClick={() =>
                        this.navigate(
                          `/live/${Enums.Feed.Live}/1/Soccer`,
                          Enums.Feed.Live,
                          Enums.FeedNames.Live
                        )
                      }
                    >
                      <img src={Images.Icon_Live} />
                      Live
                    </div>
                  }
                  key={"live"}
                ></TabPane>
                <TabPane
                  tab={
                    <div
                      className={`title light-on ${
                        feedName === Enums.FeedNames.Slots ? "selected" : ""
                      }`}
                      onClick={() =>
                        this.navigate(
                          "/slots",
                          Enums.Feed.Slots,
                          Enums.FeedNames.Slots
                        )
                      }
                    >
                      <img src={Images.Icon_Slots} />
                      Slots
                    </div>
                  }
                  key={"slots"}
                ></TabPane>
                <TabPane
                  tab={
                    <div
                      className={`title light-on ${
                        feedId === Enums.Feed.VFL.toString() ? "selected" : ""
                      }`}
                      onClick={() =>
                        this.navigate(
                          "/virtual/7/1",
                          Enums.Feed.VFL,
                          Enums.FeedNames.VFL
                        )
                      }
                    >
                      <img src={Images.Icon_Virtual} />
                      Virtual
                    </div>
                  }
                  key={"virtual"}
                ></TabPane>
                <TabPane
                  tab={
                    <div
                      className={`title light-on ${
                        feedId === Enums.Feed.Fixture.toString()
                          ? "selected"
                          : ""
                      }`}
                      onClick={() =>
                        this.navigate("/fixture", Enums.Feed.Fixture)
                      }
                    >
                      <img src={Images.Icon_Fixture} />
                      Fixture & Results
                    </div>
                  }
                  key={"fixture"}
                ></TabPane>
              </Tabs>
            </div> */}
            {/* END OLD MAIN MENU */}

            {/* <div className="gt">
              <img src={Images.LT} />
            </div> */}
          </div>
        </Affix>

        {feedName != "account" &&
          feedName != Enums.FeedNames.Slots &&
          !mobileBetslipOpen && (
            <Affix
              className="bet-slip-icon"
              offsetTop={500}
              onClick={() => openMobileBetslip(true)}
            >
              <div className="badge">{Object.keys(betSlipList).length}</div>
              <Icon type="profile" theme="twoTone" twoToneColor="#044da1" />
            </Affix>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    signedIn: state.auth.signedIn,
    betSlipList: state.betSlip.betSlipList,
    feedId: state.app.feedId,
    mobileMenuOpen: state.app.mobileMenuOpen,
    mobileBetslipOpen: state.app.mobileBetslipOpen,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openMobileBetslip: (open) => dispatch(openMobileBetslip(open)),
  openMobileMenu: (open) => dispatch(openMobileMenu(open)),
  changeFeed: (feedId, feedName) => dispatch(changeFeed(feedId, feedName)),
  switchMode:()=>    dispatch(switchMode())

});

const WrappedHeaderMobile = Form.create({ name: "time_related_controls" })(
  HeaderMobile
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedHeaderMobile);

import React from "react";
import { Utils, CountryCodes } from "../../../../helpers";

import { Bets } from "../../../";

import TeamName from "../TeamName";
import Timer from "../Timer";

export default class TopBetsDetails extends React.Component {
  onEventClick = (item) => {
    const { history, setEvent, loadAllBetTypes, feedName, match } = this.props;
    setEvent(item);
    history.push(
      `/${feedName}/${match.params.feed}/${match.params.sport}/${
        match.params.sportName
      }/event/${item.eventCategory.sportCategoryId}/${
        item.eventTournament.tournamentId
      }/${item.eventId}`
    );
    const request = {
      Data: {
        Feed: { FeedId: Number(match.params.feed) },
        Sport: { SportId: Number(match.params.sport) },
        EventId: Number(match.params.eventId),
      },
    };
    loadAllBetTypes(request);
  };

  getCountryCode = (name) => {
    let result = CountryCodes.Codes.filter((item) => {
      return item.name.toLowerCase() == name.toLowerCase();
    });

    return result && result.length > 0 ? result[0].code.toLowerCase() : "eu";
  };

  render() {
    const { data, showBetTypes, showNumber } = this.props;
    return (
      <div className="event" onClick={() => this.onEventClick(data)}>
        {data && (
          <>
          <div
          id="top-bets-details-container"
            className="container"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <div
              className="row-1"
              style={{ paddingRight: "15px", paddingLeft: "15px" }}
            >
              <div>
                <p style={{ textAlign: "start", opacity: "50%" }}>
                  Today at {data.eventDateOfMatchFormated.slice(0, 8)}
                </p>
              </div>

              {data.feed.feedId == 5 && (
                <div className="date">
                  <div>{`${data.eventStatus &&
                    data.eventStatus.eventStatusDisplayName}`}</div>
                  {data.eventScore && (
                    <Timer type="up" time={data.eventScore.eventTime} />
                  )}
                </div>
              )}
              <div className="league">
                <span
                  className={
                    "flag-icon flag-icon-" +
                    this.getCountryCode(
                      data.eventCategory.categoryDisplayName || ""
                    )
                  }
                />
                &nbsp;
                <span>{data.eventCategory.categoryDisplayName}</span>
                <span style={{ opacity: "50%" }}>
                  /{data.eventTournament.tournamentDisplayName}
                </span>
              </div>
              <div>
                <p style={{ textAlign: "end", opacity: "50%" }}>{`ID: ${
                  data.eventBetLineNo
                }`}</p>
              </div>
            </div>

            <hr
              style={{ border: "solid", borderWidth: "0.25px", opacity: "25%" }}
            />
            <div className="row-2" style={{ paddingTop: "15px" }}>
              <div className="col-1">
                <div className="row-1">
                  <img
                    className="center"
                    src="/images/realmadrid.svg"
                    alt="dummy-image"
                    height={"70px"}
                    width={"70px"}
                  />
                </div>
                <div className="row-2">
                  <TeamName name={data.eventHomeTeamName} />
                </div>
              </div>

              <div className="col-2" style={{ width: "35%" }}>
                <div className="row-1">
                  <span className="vs">1</span>
                  <span className="vs">X</span>
                  <span className="vs">2</span>
                </div>
                <div className="row-2">
                  {showBetTypes && (
                    <Bets.EventOdd
                      eventEnabled={data.enableBet}
                      showFieldName
                      showDetails
                      oddId={"1_-1"}
                      eventId={data.key}
                    />
                  )}
                </div>
              </div>
              <div className="col-2">
                {" "}
                <div className="row-1">
                  {" "}
                  <img
                    className="center"
                    src="/images/barcelona.svg"
                    alt="dummy-image"
                    height={"70px"}
                    width={"70px"}
                  />
                </div>
                <div className="row-2">
                  <TeamName name={data.eventAwayTeamName} />
                </div>{" "}
              </div>
            </div>
          </div>
   
          <div
          id="mobile-top-bets-details-container"
            className="container"
          >
            <div style={{padding:"13px 0 0 0"}}
            >
              
              <div>
                <p className="top-bets-header-details">{`ID: ${
                  data.eventBetLineNo
                }`}</p>
              </div>
              <div className="league" style={{margin:"auto" , width:"50%"}}>
                <p>
                <span
                  className={
                    "flag-icon flag-icon-" +
                    this.getCountryCode(
                      data.eventCategory.categoryDisplayName || ""
                    )
                  }
                />
                &nbsp;
                <span>{data.eventCategory.categoryDisplayName}</span>
                <span style={{ opacity: "50%" }}>
                  /{data.eventTournament.tournamentDisplayName}
                </span>
                </p>
              </div>

              <div>
                <p className="top-bets-header-details">
                  Today at {data.eventDateOfMatchFormated.slice(0, 8)}
                </p>
                {/* {data.feed.feedId == 5 && (
                <div className="date">
                  <div>{`${data.eventStatus &&
                    data.eventStatus.eventStatusDisplayName}`}</div>
                  {data.eventScore && (
                    <Timer type="up" time={data.eventScore.eventTime} />
                  )}
                </div>
              )} */}
              </div>
            </div>

            <hr
              style={{ border: "solid", borderWidth: "1px", opacity: "0.1" }}
            />
            <div className="top-bets-match-details" >
            <div className="top-bets-match-teams" >
                  <TeamName name={data.eventHomeTeamName} />
                  <TeamName name={data.eventAwayTeamName} />
                </div>
                <div style={{display:"flex" , justifyContent:"space-around"}}>
                  <span className="vs">1</span>
                  <span className="vs">X</span>
                  <span className="vs">2</span>
                </div>
              <div  style={{ width: "100%" }}>
                <div className="row-2">
                  {showBetTypes && (
                    <Bets.EventOdd
                      eventEnabled={data.enableBet}
                      showFieldName
                      showDetails
                      oddId={"1_-1"}
                      eventId={data.key}
                    />
                  )}
                </div>
              </div>
             
            </div>
          </div>
          </>
        )}
      </div>
    );
  }
}

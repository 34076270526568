import React from "react";
import { connect } from "react-redux";
import { Images } from "../../..";
import TopBetDetails from "./TopBetDetails";
import TopBetsCarousel from "./Carousel";
import { Events } from "../../../";
import {
  loadAllBetTypes,
  setEvent,
  loadTopBets,
} from "../../../../redux/actions/sportEventsActions";
import { setPropsMath } from "../../../../redux/actions/appActions";

import { Carousel } from "antd";

class TopBets1 extends React.Component {
  componentDidMount() {
    const { loadTopBets, setPropsMath, match, connected } = this.props;
    setPropsMath(match);

    if (connected) {
      let request = {
        Data: {
          Feed: { FeedId: Number(match.params.feed) },
          Sport: { SportId: Number(match.params.sport) },
        },
      };
      loadTopBets(request);
    }
  }

  componentDidUpdate(prevProps) {
    const { loadTopBets, match, connected } = this.props;

    if (
      prevProps.match.params.sport !== match.params.sport ||
      (!prevProps.connected && connected)
    ) {
      let request = {
        Data: {
          Feed: { FeedId: Number(match.params.feed) },
          Sport: { SportId: Number(match.params.sport) },
        },
      };
      loadTopBets(request);
    }
  }

  onArrowClick = (direction) => {
    switch (direction) {
      case 1:
        this.refs.carousel.prev();
        break;

      case 2:
        this.refs.carousel.next();
        break;
    }
  };

  render() {
    const { sportEvents, loading, match, feedId, error } = this.props;
    if (error) {
      return <div className="title">{error}</div>;
    }

    if (loading) {
      return (
        <div className="title">Loading {match.params["sport"]} events...</div>
      );
    }

    return (
      <div className="top-bets">
        <TopBetsCarousel cardsNumber={1} {...this.props} />
        <TopBetsCarousel cardsNumber={2} {...this.props} />
        <TopBetsCarousel cardsNumber={3} {...this.props} />
        <Events {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sportEvents: state.sportEvents.sportEvents,
    error: state.sportEvents.error,
    feedName: state.app.feedName,
    connected: state.signalr.connected,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadAllBetTypes: (request) => {
      dispatch(loadAllBetTypes(request));
    },
    setEvent: (item) => {
      dispatch(setEvent(item));
    },
    loadTopBets: (request) => {
      dispatch(loadTopBets(request));
    },
    setPropsMath: (match) => {
      dispatch(setPropsMath(match));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBets1);

import { SignalRTypes,CategoriesActionTypes } from '.'


export function loadSportCategories(request) {
	return {
		type: SignalRTypes.SportCategories_Request,
		request
	}
}

export function setTounament(item) {
	return {
		type: CategoriesActionTypes.Set_Tournament,
		item
	}
}

export function init() {
	return {
		type: CategoriesActionTypes.Init
	}
}

export function removeDisabledCategoriesTournaments() {
	return {
		type: CategoriesActionTypes.SportCategories_Remove_Disabled
	}
}
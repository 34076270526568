import { SportEventsActionTypes, SignalRTypes } from '.'

export function loadSportEvents(request) {
	return {
		type: SignalRTypes.SportEvents_Request,
		request
	}
}

export function clearSportEvents() {
	return {
		type: SignalRTypes.SportEvents_Clear,
	}
}

export function loadSportEventsByDate(request) {
	return {
		type: SignalRTypes.SportEvents_By_Date_Request,
		request
	}
}

export function loadTopBets(request) {
	return {
		type: SignalRTypes.TopBets_Request,
		request
	}
}

export function loadAllBetTypes(request) {
	return {
		type: SignalRTypes.AllBetTypes_Request,
		request
	}
}

export function setEvent(item) {
	return {
		type: SportEventsActionTypes.Set_Event,
		item
	}
}

export function onDropDownsChange(selectedValues) {
	return {
		type: SportEventsActionTypes.SportEvents_OnDropDownsChange,
		selectedValues: selectedValues
	}
}

export function init(){
	return {
		type: SportEventsActionTypes.Init
	}
}
import { push } from 'react-router-redux';
import { eventChannel } from 'redux-saga';
import { all, fork, put, takeEvery } from "redux-saga/effects";
import { Enums } from '../../helpers';
import { AppActionTypes,GeneralActionTypes } from '../actions/actionTypes';

export function* init() {
	let chan = null;
	let resizeTimeout = null;

	function* registerEventsChannel() {
		return eventChannel(emitter => {

			function onResize(e) {
				clearTimeout(resizeTimeout);

				resizeTimeout = setTimeout(() => {
					emitter({
						name: "onResize",
						e: e
					})
				}, 500)
			}

			window.addEventListener("resize", onResize);

			return () => {
				clearTimeout(resizeTimeout);
				window.removeEventListener("resize", onResize);
			}
		})
	}

	yield takeEvery(AppActionTypes.Navigate, function* ({ route }) {
		yield put(push(route));
	})

	yield takeEvery(AppActionTypes.ChangeFeed, function* ({feedId}) {
		// yield put({ type: SportTypesActionTypes.Init });
		// yield put({ type: CategoriesActionTypes.Init });
		// yield put({ type: SportEventsActionTypes.Init });

	})

	// yield takeEvery(AppActionTypes.Start, function* () {
	// 	try {
	// 		if (chan) {
	// 			chan.close()
	// 		}
	// 		chan = yield call(registerEventsChannel);

	// 		while (true) {
	// 			let event = yield take(chan);

	// 			switch (event.name) {
	// 				case "onResize":
	// 					yield put({
	// 						type: AppActionTypes.WindowResize,
	// 					})
	// 					break;
	// 			}
	// 		}
	// 	}
	// 	catch (e) {
	// 		window.log("ERROR", e);
	// 	}
	// })

	yield takeEvery(AppActionTypes.WindowResize, function* () {

		const width = window.innerWidth;
		const height = window.innerHeight;
		let device = null;
		let name = null;

		if (width >= 320 && width < 425) {
			device = Enums.Device.Size1;
			name = "Size1";
		}

		if (width >= 425 && width < 550) {
			device = Enums.Device.Size2;
			name = "Size2";
		}

		if (width >= 550 && width < 768) {
			device = Enums.Device.Size3;
			name = "Size3";
		}

		if (width >= 768 && width < 833) {
			device = Enums.Device.Size4;
			name = "Size4";
		}

		if (width >= 833 && width < 1024) {
			device = Enums.Device.Size5;
			name = "Size5";
		}

		if (width >= 1024 && width < 1440) {
			device = Enums.Device.Size6;
			name = "Size6";
		}

		if (width >= 1440) {
			device = Enums.Device.Size7;
			name = "Size7";
		}

		window.log("Resize", `${name}: ${device}, width: ${width}, height: ${height}`);

		yield put({
			type: AppActionTypes.WindowResizeSet,
			device: device
		})
	})

	yield window.log("Service START: APP Service");
	yield put({ type: GeneralActionTypes.Get_Configuration_Request });
	yield put({ type: AppActionTypes.Start });
	yield put({ type: AppActionTypes.WindowResize });
	yield put({ type: AppActionTypes.Switch_mode });
}

export default function* appSaga() {
	yield all([
		fork(init)
	])
}

import React from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../'
import { Cookies, AppConfig, Utils } from '../../../helpers';
import { slotsConnect, gameInit, partGroups } from '../../../redux/actions/slotsActions';
import { Button } from 'antd';
import { isMobile } from 'react-device-detect';
import { setPropsMath, setAvoidClick } from '../../../redux/actions/appActions';


class SlotsGame extends React.Component {
    static resizeTimeout = null;
    state = {
        iframeLoading: true,
        timestamp: (new Date()).getTime(),
        betAmount: 0,
        menuButtonDisabled: false

    }

    frameLoad = (value) => {
        this.setState({ iframeLoading: value });
    }

    componentWillMount() {
        window.addEventListener("message", this.handleSlotsMessage);
    }

    handleSlotsMessage = (message) => {
        let data = message.data;

        if (this.props.token && !data.event) {
            data = JSON.parse(message.data);
        }

        window.log("****ACTION****", data.event);

        if (data.event === undefined)
            return;

        switch (data.event) {
            case "gameChangeBet":
                window.log(message.data, "gameChangeBet")
                this.setState({ betAmount: data.data / 100 });
                break;
            case "bet_changed":
                window.log(message.data, "bet_changed")
                this.setState({ betAmount: data.data.bet / 100 });
                break;
            case "action_start":
            case "mainspin_START":
            case "freespin_START":
            case "bonus_START":
            case "bigwin_start":
                if (this.avoidClickTimeout) {
                    clearTimeout(this.avoidClickTimeout)
                }
                this.setState({ menuButtonDisabled: true });
                this.props.setAvoidClick(true);
                this.avoidClickTimeout = setTimeout(() => { this.props.setAvoidClick(false); this.setState({ menuButtonDisabled: false }) }, 30000)
                break;
            case "action_finish":
            case "bigwin_finish":
            case "game_error":
            case "mainspin_END":
            case "freespin_END":
            case "bonus_END":
            case "gameCashLow":
            case "gameCashNone":
            case "sessionLost":
            case "game_ready":
            case "loaded":
           
                if (this.avoidClickTimeout) {
                    clearTimeout(this.avoidClickTimeout)
                }
                    this.enableClickTimeout = setTimeout(() => {
                    this.props.setAvoidClick(false);
                    this.setState({ menuButtonDisabled: false });
                    clearTimeout(this.avoidClickTimeout)
                }, 5000);
                break;

            default:
                this.setState({ menuButtonDisabled: false });
                this.props.setAvoidClick(false);
                clearTimeout(this.avoidClickTimeout);
                break;
        }

    }


    componentDidMount() {
        window.addEventListener("message", this.triggerBodyClick);
        const { slotsConnect, selectedCurrency, match, setPropsMath, partGroups } = this.props;
        partGroups();
        setPropsMath(match);
        if (selectedCurrency) {
            let request = {
                currencyId: selectedCurrency.currencyId,
                gameId: match.params.gameId,
            }
            if (Cookies.get("token")) {//send memberId only if signedIn
                request.memberId = Cookies.get("memberId");
            }
            slotsConnect(request);
        }
    }

    componentDidUpdate(prevProps) {
        //when refresh, wait till currency is received from server;
        // if currency was changed, reconnect to get new token;
        // if user signed in
        const { slotsConnect, selectedCurrency, match, signedIn } = this.props;

        // scroll to top after the game loaded
        if (!prevProps.token && this.props.token != null) {
            window.scrollTo(0, 0)
        }

        if (
            (!prevProps.selectedCurrency && selectedCurrency)
            || (prevProps.selectedCurrency && selectedCurrency && prevProps.selectedCurrency.currencyId !== selectedCurrency.currencyId)
            || (!prevProps.signedIn && signedIn)) {
            let request = {
                currencyId: selectedCurrency.currencyId,
                gameId: match.params.gameId,
            }
            if (Cookies.get("token")) {//send memberId only if signedIn
                request.memberId = Cookies.get("memberId");
            }
            slotsConnect(request);
            this.setState({ timestamp: (new Date()).getTime() });
            return;
        }
    }


    componentWillUnmount() {
        window.removeEventListener("message", this.handleSlotsMessage);
        window.removeEventListener("message", this.triggerBodyClick);
        const { gameInit } = this.props;
        gameInit();
        if (!this.props.roundFinished && this.props.gameName === this.props.currentGame) {
            let modal = {
                key: 'roundNotFinish',
                type: 'text',
                text: `The Spin you ran already on the game '${this.props.gameName}' must be completed. Please Click back to complete`,
                visible: true,
                textClassName: "modal-not-finish-text",

                onOK: () => {
                    Utils.closeModal({ id: 'roundNotFinish' });
                    let path = `/slots/game/${this.props.gameId}`;
                    this.props.history.push(`${path}`);
                },
                okLabel: `Back to ${this.props.gameName}`,
            }
            Utils.openModal(modal)
        }
    }

    triggerBodyClick(message) {
        document.body.click()
    }

    render() {
        let { match, token, gameSymbols } = this.props;
        const { iframeLoading, menuButtonDisabled } = this.state;
        let Platform = isMobile ? "mobile" : "desktop"
        let slotsUrl = `${AppConfig.booongoAddress}?platform=${Platform}&wl=prod&token=${token}&game=${match.params.gameId}&lang=English&sound=1&ts=${this.state.timestamp}&history_url`;

        if (token) {
            window.log("Slot game ", slotsUrl);
            window.log("Token ", token);
        }
        else {
            window.log("token is empty");
        }
        return (
            <div style={{ textAlign: 'center' }}>
                <Button className="info back" type="primary" icon="left" style={{ width: 100 }} onClick={() => { this.props.history.goBack() }} disabled={menuButtonDisabled}>Menu</Button>
                <div className={`slots-game slots-video`}>

                    {
                        (iframeLoading || !token) &&
                        <div className="skeleton">
                            <Loading text={`Loading ${match.params.name || ""} game...`} />
                        </div>
                    }
                    {
                        token &&
                        <iframe
                            title="game"
                            id="slot-frame"
                            frameBorder="0"
                            scrolling="no"
                            style={{ display: iframeLoading ? 'none' : 'block' }}
                            onLoad={e => this.frameLoad(false)}
                            className={`frame-video`}
                            src={slotsUrl}
                        />
                    }
                    {
                        gameSymbols.length > 0 &&
                        <div className="game-symbols">
                            {
                                gameSymbols
                                    .sort((a, b) => a.symbolNumber < b.symbolNumber)
                                    .map((game) => {
                                        let image = null
                                        try {
                                            image = AppConfig.symbols + game.symbolFileNameWebp;
                                        }
                                        catch (e) {
                                            image = null
                                        }

                                        let selectedCurrency = Cookies.get('selectedCurrency') || this.props.currencies.LiberianDollar;

                                        return <div key={game.symbolId} className="symbol">
                                            <div>  {image && <img src={image} />} </div>
                                            {game.symbolTexts && game.symbolTexts.length > 0 &&
                                                <div className="symbol-text">
                                                    {game.symbolTexts.sort((a, b) => a.symbolTextOrder < b.symbolTextOrder)
                                                        .map((text) => {
                                                            let fontFamily = text.symbolFont || 'sans-serif';
                                                            if (text.currencyId !== 0 && text.currencyId !== selectedCurrency.currencyId) {

                                                                return <div key={text.symbolTextId}/>;
                                                            }

                                                            if (text.symbolTextMultiple !== 0 && this.state.betAmount !== 0) {
                                                                let amount = (text.currencyId === 1) ? (Math.round(this.state.betAmount * text.symbolTextMultiple)).format(2) :
                                                                    (this.state.betAmount * text.symbolTextMultiple).format(2);

                                                                return <p key={text.symbolTextId}>
                                                                    <span style={{ fontFamily: fontFamily }}>{text.symbolTextDisplay}</span> - <span style={{ fontFamily: fontFamily }}>{amount}</span></p>
                                                            }
                                                            else {
                                                                let textArr = text.symbolText.includes("-") ? text.symbolText.split("-") : text.symbolText;
                                                                if (textArr.constructor === Array) {
                                                                    return <p key={text.symbolTextId}>
                                                                        <span style={{ fontFamily: fontFamily }} >{textArr[0]}</span> - <span style={{ fontFamily: fontFamily }}>{textArr[1]}</span></p>
                                                                }
                                                                else {
                                                                    return <p style={{ fontFamily: fontFamily }} key={text.symbolTextId}>{text.symbolText} </p>
                                                                }
                                                            }
                                                        })}
                                                </div>
                                            }
                                        </div>
                                    })
                            }
                        </div>
                    }
                </div>
            </div>

        )
    }
}

const mapStateToProps = (_, initialProps) => (state) => {
    return {
        selectedCurrency: state.member.selectedCurrency,
        token: state.slots.token,
        signedIn: state.auth.signedIn,
        gameSymbols: state.slots.gameSymbols,
        roundFinished: state.slots.roundFinished,
        gameId: state.slots.gameId,
        gameName: state.slots.gameName,
        currentGame: state.slots.currentGame,
        currencies: state.general.currencies,

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        slotsConnect: (request) => dispatch(slotsConnect(request)),
        gameInit: () => dispatch(gameInit()),
        setAvoidClick: (value) => dispatch(setAvoidClick(value)),
        partGroups: () => dispatch(partGroups()),
        setPropsMath: (match) => dispatch(setPropsMath(match)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlotsGame);

export const AppActionTypes = {
	Start: "APP_START",
	PropsMatch: "APP_PROPSMATCH",
	Navigate: "APP_NAVIGATE",
	ChangeFeed: "APP_CHANGEFEED",
	Set_Feed: "APP_SET_FEED",
	SetServerTime: "APP_SET_SERVERTIME",
	WindowResize: "APP_WINDOW_RESIZE",
	WindowResizeSet: "APP_WINDOW_RESIZE_SET",
	OpenMobileMenu: "APP_OPEN_MOBILE_MENU",
	OpenMobileBetslip: "APP_OPEN_MOBILE_BETSLIP",
	Set_Avoid_Click: "SET_AVOID_CLICK",
	Switch_mode:"CHANGE_THEME"

}

//Not in use at the moment
export const SignalRTypes = {
	Connect_Request: "SR_CONNECT_REQUEST",
	Connect_Success: "SR_CONNECT_SUCCESS",
	Connect_Error: "SR_CONNECT_ERROR",
	Connect_Slow: "SR_CONNECT_SLOW",
	//Booongo Hub
	Booongo_Connect_Request: "BOOONGO_CONNECT_REQUEST",
	Booongo_Connect_Success: "BOOONGO_CONNECT_SUCCESS",
	Booongo_Connect_Error: "BOOONGO_CONNECT_ERROR",
	Booongo_Connect_Slow: "BOOONGO_CONNECT_SLOW",
	Booongo_Join_Request: "BOOONGO_JOIN_REQUEST",
	Booongo_Join_Response: "BOOONGO_JOIN_RESPONSE",
	Booongo_Part_Request: "BOOONGO_PART_REQUEST",
	Booongo_Part_Response: "BOOONGO_PART_RESPONSE",
	//Jackpot Hub
	Jackpot_Connect_Request: "JACKPOT_CONNECT_REQUEST",
	Jackpot_Connect_Success: "JACKPOT_CONNECT_SUCCESS",
	Jackpot_Connect_Error: "JACKPOT_CONNECT_ERROR",
	Jackpot_Connect_Slow: "JACKPOT_CONNECT_SLOW",

	Jackpot_Disconnect_Request: "JACKPOT_DISCONNECT_REQUEST",
	Jackpot_Disconnect_Success: "JACKPOT_DISCONNECT_SUCCESS",

	Jackpot_Join_Request: "JACKPOT_JOIN_REQUEST",
	Jackpot_Join_Response: "JACKPOT_JOIN_RESPONSE",
	Jackpot_Part_Request: "JACKPOT_PART_REQUEST",
	Jackpot_Part_Response: "JACKPOT_PART_RESPONSE",

	Jackpot_Update: 'JACKPOT_UPDATE',
	Jackpot_Winning_Approved: 'JACKPOT_WINNING_APPROVED',
	Set_Jackpot_Won_Message: 'SET_JACKPOT_WON_MESSAGE',
	Jackpot_Get_Request: 'JACKPOT_GET_REQUEST',
	Jackpot_Get_Success: 'JACKPOT_GET_SUCCESS',
	Jackpot_Get_Error: 'JACKPOT_GET_ERROR',
	Set_Is_Jackpot_Won: 'SET_IS_JACKPOT_WON',

	Update_Entity_Balance: 'UPDATE_ENTITY_BALANCE',

	Booongo_Disconnect_Request: "BOOONGO_DISCONNECT_REQUEST",
	Booongo_Disconnect_Success: "BOOONGO_DISCONNECT_SUCCESS", 

	Disconnect_Request: "SR_DICONNECT_REQUEST",
	Disconnect_Success: "SR_DICONNECT_SUCCESS",

	GetAllData_Response: "SR_GET_ALL_DATA",

	Join_Request: "SR_JOIN_REQUEST",
	Join_Response: "SR_JOIN_RESPONSE",
	Part_Request: "SR_PART_REQUEST",
	Part_Response: "SR_PART_RESPONSE",

	Set_Join_Groups: "SR_SET_JOIN_GROUPS",


	Handle_Join_Part_Groups: "SR_HANDLE_JOIN_PART_GROUPS",

	SportTypes_Request: "SR_SPORTTYPES_REQUEST",
	SportTypes_Response: "SR_SPORTTYPES_RESPONSE",

	SportCategories_Request: "SR_CATEGORIES_REQUEST",
	SportCategories_Response: "SR_CATEGORIES_RESPONSE",

	SportEvents_Request: "SR_SPORTEVENTS_REQUEST",
	SportEvents_Response: "SR_SPORTEVENTS_RESPONSE",
	SportEvents_Clear: "SR_SPORTEVENTS_Clear",

	TopBets_Request: "SR_TOPBETS_REQUEST",
	TopBets_Response: "SR_TOPBETS_RESPONSE",

	SportEventsVirtual_Request: "SR_SPORTEVENTSVIRTUAL_REQUEST",

	SportEvents_By_Date_Request: "SR_SPORTEVENTS_BY_DATE_REQUEST",
	SportEvents_By_Date_Response: "SR_SPORTEVENTS_BY_DATE_RESPONSE",

	AllBetTypes_Request: "SR_ALL_BETTYPES_REQUEST",
	AllBetTypes_Response: "SR_ALL_BETTYPES_RESPONSE",

	Get_Updated_OddField_Details: 'GET_UPDATED_ODD_FIELDS_DETAILS',
	Get_Updated_OddField_Details_Response: 'GET_UPDATED_ODD_FIELDS_DETAILS_RESPONSE',


	OnFeedChanges: "SR_ONFEEDCHANGED",

		/////
		OnSportEventsChange: "SR_ON_SPORT_EVENTS_CHANGE",
		OnEventOddsChange: "SR_ON_EVENT_ODDS_CHANGE",
		onEventOddGroupsChange: "SR_ON_EVENT_ODD_GROUPS_CHANGE",
		OnEventOddFieldsChange: "SR_ON_EVENT_ODD_FIELDS_CHANGE",
		OnEventScoreChange: "SR_ON_EVENT_SCORE_CHANGE",
		OnSportTournamentsChange: "SR_ON_SPORT_TOURNAMENTS_CHANGE",
		OnTopBetTypesChange: "SR_ON_TOP_BET_TYPES_CHANGE",
		OnSportTypesChange: "SR_ON_SPORT_TYPES_CHANGE",
		OnSportCategoriesChange: "SR_ON_SPORT_CATEGORIES_CHANGE",
		OnSportTopLeaguesChange: "SR_ON_SPORT_TOP_LEAGUES_CHANGE",
		OnUpcomingEventsChange: "SR_ON_UPCOMING_EVENTS_CHANGE",
		OnBarItemsChange: "SR_ON_BAR_ITEMS_CHANGE",
		//////

	OnTimer: "SR_ON_TIMER",
	Re_Connect: "SR_RE_CONNECT",
	Producer_Down:"PRODUCER_DOWN",
	Producer_Up:"PRODUCER_UP",


}

export const SearchActionTypes = {
	GetSearchedData_Request: 'GET_SEARCHED_DATA_REQUEST',
	GetSearchedData_Response: 'GET_SEARCHED_DATA_RESPONSE',
	GetSearchedData_Success: 'GET_SEARCHED_DATA_SUCCESS',
	GetSearchedData_Error: 'GET_SEARCHED_DATA_ERROR',

	Navigate_Search_Item: 'NAVIGATE_SEARCH_ITEM'
}

export const HomeActionTypes = {
	Load_Request: "HOME_LOAD_REQUEST"
}

export const VirtualActionTypes = {
	Load_Request: "VIRTUAL_LOAD_REQUEST",
	SportEvents_Request: "VIRTUAL_SPORTEVENTS_REQUEST",
	SportEvents_Response: "VIRTUAL_SPORTEVENTS_RESPONSE",
}

export const SportActionTypes = {
	Sport_AllEmpty: "SPORT_ALL_EMPTY",
}

export const SportTypesActionTypes = {
	Set_Sport: "SPORT_TYPES_SET_SPORT",
	Init: "SPORT_TYPES_INIT",
	SportTypes_Success: "SPORT_TYPES_SUCCESS",
	SportTypes_Error: "SPORT_TYPES_ERROR",

	SportTypes_Remove_Disabled: "SPORT_TYPES_REMOVE_DISABLED"
}


export const CategoriesActionTypes = {
	Set_Tournament: "CATEGORIES_SET_TOURNAMENT",
	Init: "CATEGORIES_INIT",
	SportCategories_Success: "CATEGORIES_SUCCESS",
	SportCategories_Error: "CATEGORIES_ERROR",
	SportTopLeagues_Success: "TOP_LEAGUES_SUCCESS",

	SportCategories_Remove_Disabled: "SPORT_TYPES_REMOVE_DISABLED"
}

export const SportEventsActionTypes = {
	Set_Event: "SPORT_EVENTS_SET_EVENT",
	Init: "SPORT_EVENTS_INIT",

	SportEvents_Success: "SPORT_EVENTS_SUCCESS",
	SportEvents_Error: "SPORT_EVENTS_ERROR",

	SportEvents_OddFields_Success: "SPORT__ODDFIELDS_EVENTS_SUCCESS",

	SportEvents_Odds_Success: "SPORT_ODDS_EVENTS_SUCCESS",
	SportEvents_Events_FeedChange: "SPORT_EVENTS_FEEDCHANGE",
	SportEvents_Upcoming_Events_Success: "SPORT_UPCOMING_EVENTS_SUCCESS",
	SportEvents_Groups_Success: "SPORT_GROUPS_SUCCESS",
	SportEvents_BetTypes_Success: "SPORT_BETTYPES_SUCCESS",

	AllBetTypes_Success: "SPORT_EVENTS_ALL_BETTYPES_SUCCESS",
	AllBetTypes_Error: "SPORT_EVENTS_ALL_BETTYPES_ERROR",

	SportEvents_Update: "SPORT_EVENTS_UPDATE",
	SportEvents_OnDropDownsChange: "SPORT_EVENTS_ON_DROPDOWNS_CHANGE",
}

export const LiveActionTypes = {
	Load_Request: "LIVE_LOAD_REQUEST"
}

export const GeneralActionTypes = {
	//GET THE MAP DATA
	Map_Request: 'MAP_REQUEST',
	Map_Success: 'MAP_SUCCESS',
	Map_Error: 'MAP_ERROR',

	Form_Statuses_Request: 'FORM_STATUSES_REQUEST',
	Form_Statuses_Success: 'FORM_STATUSES_SUCCESS',
	Form_Statuses_Error: 'FORM_STATUSES_ERROR',


	Bet_Slip_Configuration_Request: 'BET_SLIP_CONFIGURATION_REQUEST',
	Bet_Slip_Configuration_Success: 'BET_SLIP_CONFIGURATION_SUCCESS',
	Bet_Slip_Configuration_Error: 'BET_SLIP_CONFIGURATION_ERROR',

	Page_Name_Request: 'PAGE_NAME_REQUEST',
	Page_Name_Success: 'PAGE_NAME_SUCCESS',
	Page_Name_Error: 'PAGE_NAME_ERROR',

	Web_Files_Request: 'WEB_FILES_REQUEST',
	Web_Files_Success: 'WEB_FILES_SUCCESS',
	Web_Files_Error: 'WEB_FILES_ERROR',

	Web_File_Request: 'WEB_FILE_REQUEST',
	Web_File_Response: 'WEB_FILE_RESPONSE',

	Bets_By_Bet_Code_Request: 'BETS_BY_BET_CODE_REQUEST',
	Bets_By_Bet_Code_Success: 'BETS_BY_BET_CODE_SUCCESS',
	Bets_By_Bet_Code_Error: 'BETS_BY_BET_CODE_ERROR',

	Create_Bet_Code_Request: 'CREATE_BET_CODE_REQUEST',
	Create_Bet_Code_Success: 'CREATE_BET_CODE_SUCCESS',
	Create_Bet_Code_Error: 'CREATE_BET_CODE_ERROR',
	Bet_Code_Clear: 'BET_CODE_CLEAR',

	Get_DrillDown_Groups_Request: 'GET_DRILLDOWN_GROUPS_REQUEST',
	Get_DrillDown_Groups_Success: 'GET_DRILLDOWN_GROUPS_SUCCESS',
	Get_DrillDown_Groups_Error: 'GET_DRILLDOWN_GROUPS_ERROR',

	Get_Configuration_Request: 'GET_CONFIGURATION_REQUEST',
	Get_Configuration_Success: 'GET_CONFIGURATION_SUCCESS',
	Get_Configuration_Error: 'GET_CONFIGURATION_ERROR',

	Get_Currencies_Request: 'GET_CURRENCIES_REQUEST',
	Get_Currencies_Success: 'GET_CURRENCIES_SUCCESS',
	Get_Currencies_Error: 'GET_CURRENCIES_ERROR',

	Get_CountryPhone_Providers_Request: 'GET_COUNTRYPHONE_PROVIDERS_REQUEST',
	Get_CountryPhone_Providers_Success: 'GET_COUNTRYPHONE_PROVIDERS_SUCCESS',
	Get_CountryPhone_Providers_Error: 'GET_COUNTRYPHONE_PROVIDERS_ERROR',

	Get_Countries_Request: 'GET_COUNTRIES_REQUEST',
	Get_Countries_Success: 'GET_COUNTRIES_SUCCESS',
	Get_Countries_Error: 'GET_COUNTRIES_ERROR',

	Get_Counties_Request: 'GET_COUNTIES_REQUEST',
	Get_Counties_Success: 'GET_COUNTIES_SUCCESS',
	Get_Counties_Error: 'GET_COUNTIES_ERROR',


	Server_Time: 'SERVER_TIME'
}

export const ContactUsActionTypes = {
	// GET THE SUBJECTS
	Subjects_Request: 'SUBJECTS_REQUEST',
	Subjects_Success: 'SUBJECTS_SUCCESS',
	Subjects_Error: 'SUBJECT_ERROR',

	Contact_Data_Request: 'CONTACT_DATA_REQUEST',
	Contact_Data_Success: 'CONTACT_DATA_SUCCESS',
	Contact_Data_Error: 'CONTACT_DATA_ERROR'
}

export const RegisterActionTypes = {
	Init: 'INIT_REGISTER',
	//Get Register Entites
	Register_Entities_Request: 'REGISTER_ENTITIES_REQUEST',
	Register_Entities_Success: 'REGISTER_ENTITIES_SUCCESS',
	Register_Entities_Error: 'REGISTER_ENTITIES_ERROR',

	Add_Member_Phone_Request: 'ADD_MEMBER_PHONE_REQUEST',
	Add_Member_Phone_Success: 'ADD_MEMBER_PHONE_SUCCESS',
	Add_Member_Phone_Error: 'ADD_MEMBER_PHONE_ERROR',

	//Validate Phone
	Validate_Phone_Request: 'VALIDATE_PHONE_REQUEST',
	Validate_Phone_Success: 'VALIDATE_PHONE_SUCCESS',
	Validate_Phone_Error: 'VALIDATE_PHNE_ERROR',

	//Validate add phone 
	Validate_Add_Phone_Request: 'VALIDATE_ADD_PHONE_REQUEST',
	Validate_Add_Phone_Success: 'VALIDATE_ADD_PHONE_SUCCESS',
	Validate_Add_Phone_Error: 'VALIDATE_ADD_PHONE_ERROR',

	//Register member 
	Register_Member_Request: 'REGISTER_MEMBER_REQUEST',
	Register_Member_Success: 'REGISTER_MEMBER_SUCCESS',
	Register_Member_Error: 'REGISTER_MEMBER_ERROR',

	// VALIDATE EMAIL
	Validate_Email_Request: 'VALIDATE_EMAIL_RESPONSE',
	Validate_Email_Success: 'VALIDATE_EMAIL_SUCCESS',
	Validate_Email_Error: 'VALIDATE_EMAIL_ERROR',

	Send_Register_Data: 'SEND_REGISTER_DATA',
	Handle_Register_signin: 'HANDLE_REGISTER_SIGNIN',
}

export const AuthActionTypes = {
	Init: 'INIT_AUTH',
	//Sign In 
	Signin_Request: 'SIGNIN_REQUEST',
	Signin_Success: 'SIGNIN_SUCCESS',
	Register_Signin_Success: 'REGISTER_SIGNIN_SUCCESS',
	Signin_Error: 'SIGNIN_ERROR',

	//Sign Out
	Signout_Request: 'SIGNOUT_REQUEST',
	Signout_Success: 'SIGNOUT_SUCCESS',
	Signout_Error: 'SIGNOUT_ERROR',

	Forgot_Password_Check_Dest_Request: 'FORGOT_PASSWORD_CHECK_DEST_REQUEST',
	Forgot_Password_Check_Dest_Suceess: 'FORGOT_PASSWORD_CHECK_DEST_SUCCESS',
	Forgot_Password_Check_Dest_Error: 'FORGOT_PASSWORD_CHECK_DEST_ERROR',

	Forgot_Password_Request: 'FORGOT_PASSWORD_REQUEST',
	Forgot_Password_Suceess: 'FORGOT_PASSWORD_SUCCESS',
	Forgot_Password_Error: 'FORGOT_PASSWORD_ERROR',

	Forgot_Password_Confirm_Request: 'FORGOT_PASSWORD_CONFIRM_REQUEST',
	Forgot_Password_Confirm_Suceess: 'FORGOT_PASSWORD_CONFIRM_SUCCESS',
	Forgot_Password_First_Step_Confirm_Suceess: 'FORGOT_PASSWORD_FIRST_STEP_CONFIRM_SUCCESS',
	Forgot_Password_Confirm_Error: 'FORGOT_PASSWORD_CONFIRM_ERROR',

	Change_Password_Request: 'CHANGE_PASSWORD_REQUEST',
	Change_Password_Suceess: 'CHANGE_PASSWORD_SUCCESS',
	Change_Password_Error: 'CHANGE_PASSWORD_ERROR',

	Update_Password_Request: 'UPDATE_PASSWORD_REQUEST',
	Update_Password_Suceess: 'UPDATE_PASSWORD_SUCCESS',
	Update_Password_Error: 'UPDATE_PASSWORD_ERROR',

	
	Start_Click_listener: 'START_CLICK_LISTENER',
	Set_Show_Login_Pop_Confirm: 'SET_SHOW_LOGIN_POP_CONFIRM',
}

export const MembersActionTypes = {
	Init: 'INIT_MEMBER',
	//Update member phone
	Add_Second_Member_Phone_Request: 'ADD_SECOND_MEMBER_PHONE_REQUEST',
	Add_Second_Member_Phone_Success: 'ADD_SECOND_MEMBER_PHONE_SUCCESS',
	Add_Second_Member_Phone_Error: 'ADD_SECOND_MEMBER_PHONE_ERROR',

	//Validate add phone 
	Validate_Add_Second_Phone_Request: 'VALIDATE_SECOND_ADD_PHONE_REQUEST',
	Validate_Add_Second_Phone_Success: 'VALIDATE_SECOND_ADD_PHONE_SUCCESS',
	Validate_Add_Second_Phone_Error: 'VALIDATE_SECOND_ADD_PHONE_ERROR',

	// Update Member Details
	Member_Details_Request: 'MEMBER_DETAILS_REQUEST',
	Member_Details_Success: 'MEMBER_DETAILS_SUCCESS',
	Member_Details_Error: 'MEMBER_DETAILS_ERROR',


	// Get Member Details 
	Get_Member_Details_Request: 'GET_MEMBER_DETAILS_REQUEST',
	Get_Member_Details_Success: 'GET_MEMBER_DETAILS_SUCCESS',
	Get_Member_Details_Error: 'GET_MEMBER_DETAILS_ERROR',


	Send_Verify_Email_Request: 'SEND_VERIFY_EMAIL_REQUEST',
	Send_Verify_Email_Success: 'SEND_VERIFY_EMAIL_SUCCESS',
	Send_Verify_Email_Error: 'SEND_VERIFY_EMAIL_ERROR',

	Deposit_From_Voucher_Request: 'DEPOSIT_FROM_VOUCHER_REQUEST',
	Deposit_From_Voucher_Success: 'DEPOSIT_FROM_VOUCHER_SUCCESS',
	Deposit_From_Voucher_Error: 'DEPOSIT_FROM_VOUCHER_ERROR',

	Mtn_Deposit_Request: 'MTN_DEPOSIT_REQUEST',
	Mtn_Deposit_Success: 'MTN_DEPOSIT_SUCCESS',
	Mtn_Deposit_Error: 'MTN_DEPOSIT_ERROR',

	Mtn_Withdraw_Request: 'MTN_WITHDRAW_REQUEST',
	Mtn_Withdraw_Success: 'MTN_WITHDRAW_SUCCESS',
	Mtn_Withdraw_Error: 'MTN_WITHDRAW_ERROR',

	Withdrawal_To_Voucher_Request: 'WITHDRAWAL_TO_VOUCHER_REQUEST',
	Withdrawal_To_Voucher_Success: 'WITHDRAWAL_TO_VOUCHER_SUCCESS',
	Withdrawal_To_Voucher_Error: 'WITHDRAWAL_TO_VOUCHER_ERROR',

	Member_Transactions_Request: 'MEMBER_TRANSTACTIONS_REQUEST',
	Member_Transactions_Success: 'MEMBER_TRANSTACTIONS_SUCCESS',
	Member_Transactions_Error: 'MEMBER_TRANSTACTIONS_ERROR',

	Member_Forms_Request: 'MEMBER_FORMS_REQUEST',
	Member_Forms_Success: 'MEMBER_FORMS_SUCCESS',
	Member_Forms_Error: 'MEMBER_FORMS_ERROR',

	Forms_Details_Request: 'FORMS_DETAILS_REQUEST',
	Forms_Details_Success: 'FORMS_DETAILS_SUCCESS',
	Forms_Details_Error: 'FORMS_DETAILS_ERROR',

	Voucher_Details_Request: 'VOUCHER_DETAILS_REQUEST',
	Voucher_Details_Success: 'VOUCHER_DETAILS_SUCCESS',
	Voucher_Details_Error: 'VOUCHER_DETAILS_ERROR',

	Skip: 'SKIP',
	Previous: 'PREVIOUS',

	Member_Update_Balance: "MEMBER_UPDATE_BALANCE",

	Set_Selected_Currency: 'SET_SELECTED_CURRENCY',

	Member_Approved_Jackpot_Winnings_Request: 'MEMBER_APPROVED_JACKPOT_WINNING_REQUEST',
	Member_Approved_Jackpot_Winnings_Success: 'MEMBER_APPROVED_JACKPOT_WINNING_SUCCESS',
	Member_Approved_Jackpot_Winnings_Error: 'MEMBER_APPROVED_JACKPOT_WINNING_ERROR',
	Open_Mobile_Table_Details: "OPEN_MOBILE_TABLE_DETAILS",
	Open_Mobile_Voucher_Details: "OPEN_MOBILE_VOUCHER_DETAILS",
	Open_Bet_Details_Modal: "OPEN_BET_DETAILS_MODAL",
	Open_Voucher_Modal: "OPEN_VOUCHER_MODAL",

	CheckDepositBonusConditions_Request: 'CHECK_DEPOSIT_BONUS_CONDITIONS_REQUEST',
	CheckDepositBonusConditions_Success: 'CHECK_DEPOSIT_BONUS_CONDITIONS_SUCCESS',
	CheckDepositBonusConditions_Error: 'CHECK_DEPOSIT_BONUS_CONDITIONS_ERROR'

}


export const BetSlipActionTypes = {
	Update_BetSlip_List_Request: 'UPDATE_BETSLIP_LIST_REQUEST',
	Update_BetSlip_List_Done: 'UPDATE_BETSLIP_LIST_DONE',
	Update_BetSlip_From_Storage: 'UPDATE_BETSLIP_FROM_STORAGE',

	Update_BetSlip_From_FeedChange: 'UPDATE_BETSLIP_FROM_FEEDCHANGE',

	Set_Tab: 'SET_TAB',
	BetSlip_Clear: 'BETSLIP_CLEAR',
	Clear_Succeeded_Bets: 'CLEAR_SUCCEEDED_BETS',
	BetSlip_Remove_Field: 'BETSLIP_REMOVE_FIELD',

	On_Amount_Change: 'ON_AMOUNT_CHANGE',
	On_Field_Amount_Change: 'ON_FIELD_AMOUNT_CHANGE',

	Place_Multiple_Bet_Request: 'PLACE_MULTIPLE_BET_REQUEST',
	Place_Single_Bet_Request: 'PLACE_SINGLE_BET_REQUEST',
	Place_Bet_Success: 'PLACE_BET_SUCCESS',
	Place_Bet_Error: 'PLACE_BET_ERROR',
	Place_Single_Bets_Response: 'PLACE_SINGLE_BETS_RESPONSE',

	Set_BetSlip_Errors: 'SET_BETSLIP_ERRORS',
	Set_BetSlip_Total_Odds: 'SET_BETSLIP_TOTAL_ODDS',
	Set_BetSlip_Total_Stake: 'SET_BETSLIP_TOTAL_STAKE',
	Set_BetSlip_Possible_Winnings: 'SET_BETSLIP_POSSIBLE_WINNINGS',
	Set_Hide_Stake: 'SET_HIDE_STAKE',
	Handle_Hide_Stake: 'HANDLE_HIDE_STAKE',

	Set_BetSlip_Value_Change_Warning: 'SET_BETSLIP_VALUE_CHANGE_WARNING',

	Set_Login_Error: 'SET_LOGIN_ERROR',
	Clear_Login_Error: 'CLEAR_LOGIN_ERROR',
	Clear_BetSlip_Success: 'CLEAR_BETSLIP_SUCCESS'
}

export const ModalActionTypes = {
	Open_Modal_Request: 'OPEN_MODAL_REQUEST',
	Open_Modal_Success: 'OPEN_MODAL_SUCCESS',

	Close_Modal_Request: 'CLOSE_MODAL_REQUEST',
	Close_Modal_Success: 'CLOSE_MODAL_SUCCESS',
}

export const SlotsActionTypes = {
	Get_Slots_Games_Request: 'GET_SLOTS_GAMES_REQUEST',
	Get_Slots_Games_Success: 'GET_SLOTS_GAMES_SUCCESS',
	Get_Slots_Games_Error: 'GET_SLOTS_GAMES_ERROR',

	Slots_Game_Connect_Request: 'SLOTS_GAME_CONNECT_REQUEST',
	Slots_Game_Connect_Success: 'SLOTS_GAME_CONNECT_SUCCESS',
	Slots_Game_Connect_Error: 'SLOTS_GAME_CONNECT_ERROR',
	Slots_Game_Init: 'SLOTS_GAME_INIT',
	Current_Slots_Game: 'CURRENT_SLOTS_GAME',

	
	Set_Slots_Round_Finish: 'SET_SLOTS_ROUND_FINISH',
}

import { RegisterActionTypes } from './actionTypes';

export function getRegisterEntities() {
    return {
        type: RegisterActionTypes.Register_Entities_Request
    }
}

export function addMemberPhone() {
    return {
        type: RegisterActionTypes.Add_Member_Phone_Request
    }
}


export function sendValidatePhone(data, resend) {
    return {
        type: RegisterActionTypes.Validate_Phone_Request,
        data,
        resend
    }
}

export function sendAddValidatePhone(data) {
    if (!data.name || data.name === undefined) {
        return {
            type: RegisterActionTypes.Validate_Add_Phone_Error,
            message: 'Please add phone number for verification'
        }
    }
    return {
        type: RegisterActionTypes.Validate_Add_Phone_Request,
        data
    }
}


export function registerMember() {
    return {
        type: RegisterActionTypes.Register_Member_Request
    }
}

export function validateEmail(data) {
    return {
        type: RegisterActionTypes.Validate_Email_Request,
        data
    }
}

export function sendRegisterData(data) {
    return {
        type: RegisterActionTypes.Send_Register_Data,
        data
    }
}

export function handleRegisterSignin() {
    return {
        type: RegisterActionTypes.Handle_Register_signin
    }
}
export function init() {
    return {
        type: RegisterActionTypes.Init
    }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import Routes from './Routes'

export default class Root extends Component {

    
    render() {
        const { children ,avoidClick,isDark} = this.props;
        const { store, history } = this.props;
        return (
            <Provider  store={store}>
                <Routes store={store} history={history} />
            </Provider>
        );
    }
}

const mapStateToProps = (_, initialProps) => (state) => {
	return {
	
		isDark: state.app.isDark,

	}
}

Root.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

import React from "react";
import { Icon } from "antd";

export default class Favorite extends React.Component {
  render() {
    const { callBack, selected } = this.props;
    return (
      <div className="tooltip">
        <div className="favoriteContainer" onClick={callBack}>
          <Icon
            type="star"
            style={{
              fontSize: 15,
              color: selected ? "darkgoldenrod" : "#ccc",
              cursor: "pointer",
            }}
          />
        </div>
        <span className="tooltiptext">Add to favorites.</span>
      </div>
    );
  }
}

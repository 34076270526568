import { all, fork, takeEvery, takeLatest, put} from "redux-saga/effects";
import { slotsApi } from '../../api'
import { SlotsActionTypes } from '../actions';
import {  Cookies, SessionStorage } from '../../helpers';


export function* requests() {


    yield takeEvery(SlotsActionTypes.Get_Slots_Games_Request, function* () {
        //call server
        try {
            let response = yield slotsApi.getSlotsGames();

            switch (response.status) {
                case 1:
                    let tabs = [{
                        categoryId: 0,
                        categoryName: "All",
                        categoryOrder: 0,
                        id: 0
                    }]
                    response.data.slotsGamesCategories.map((item, i) => {
                        let result = response.data.slotsGames.find((game) => game.categoryId === item.categoryId)
                        if (result) {
                            tabs.push(item);
                        }
                    });
                    yield put({
                        type: SlotsActionTypes.Get_Slots_Games_Success,
                        slotsGames: response.data.slotsGames,
                        categories: tabs,
                        config: response.data.slotsGamesConfiguration,
                    });

                    break;
                case 3 || 4:
                    yield put({ type: SlotsActionTypes.Get_Slots_Games_Error, message: response.message });
                    break;
                default:
                    yield put({ type: SlotsActionTypes.Get_Slots_Games_Error, message: response.message });
                    break;
            }

        }
        catch (error) {
            window.log('client =>> could not get slots: ', error);
        }
    })

    yield takeEvery(SlotsActionTypes.Slots_Game_Connect_Request, function* ({ request }) {
        //call server
        try {
            let response = yield slotsApi.slotsConnect(request);

            switch (response.status) {
                case 1:
                    yield put({
                        type: SlotsActionTypes.Slots_Game_Connect_Success,
                        token: response.data.slotsToken,
                        currentGame: response.data.gameName,
                        gameSymbols: response.data.slotGameSymbols
                    });

                   
                    break;
                case 3 || 4:
                    yield put({ type: SlotsActionTypes.Slots_Game_Connect_Error, message: response.message });
                    break;
                default:
                    yield put({ type: SlotsActionTypes.Slots_Game_Connect_Error, message: response.message });
                    break;
            }

        }
        catch (error) {
            window.log('client =>> could not connect slots: ', error);
        }
    })

}

export default function* TransactionsSaga() {
    yield all([
        fork(requests)
    ])
}


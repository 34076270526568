import React from 'react';
import { Images } from '../../../';
import { Utils } from '../../../../helpers';
import TeamName from '../TeamName';
import Timer from '../Timer';

export default class DrillDownDetails extends React.Component {

	nav = (data) => {
		const { match, history, feedName } = this.props;
		history.push(`/${feedName}/${match.params.feed}/${match.params.sport}/${match.params.sportName}/events/countries/${data.eventCategory.sportCategoryId}/${data.eventCategory.categoryDisplayName}/${data.eventTournament.tournamentId}/${data.eventTournament.tournamentDisplayName}`);
	}

	render() {
		const { history, data, showBetTypes, showNumber } = this.props;
		return (
			<div className="event">
				{
					data && data.feed &&
					<div className="container">

						<div className="row-1">
							<div className="col-1">
								<div className="league light-on link" onClick={() => this.nav(data)}>
									{data.eventCategory && data.eventCategory.categoryDisplayName} / {data.eventTournament && data.eventTournament.tournamentDisplayName}
								</div>
							</div>

							<div className="col-2">
								{
									data.feed.feedId != 5 &&
									<div className="date">{Utils.formatDate(data.eventDateOfMatch)}</div>
								}
								{
									data.feed.feedId == 5 &&
									<div className="date">
										<div>{`${data.eventScore && data.eventScore.eventStatusDisplayName}`}</div>
										<div className="event-id">ID: {data.eventBetLineNo}</div>
										{
											data.eventScore &&
											<Timer type="up" time={data.eventScore.eventTime} status={data.eventScore.eventTimeStatus}></Timer>

										}
									</div>
								}
							</div>
						</div>

						<div className="row-2 bg-1">
							<div className="back" onClick={() => history.goBack()}>BACK</div>
							<div className="teams">
								<div className="names">
									<TeamName name={data.eventHomeTeamName}></TeamName>
									&nbsp; - &nbsp;
									<TeamName name={data.eventAwayTeamName}></TeamName>
								</div>
								{
									data.feed.feedId == 5 && data.eventScore && data.eventScore.score &&
									<div className="score">
										{this.getHometeamScore(data.eventScore.score)}
										&nbsp; : &nbsp;
										{this.getAwayTeamScore(data.eventScore.score)}
									</div>
								}
							</div>
						</div>

					</div>
				}

			</div>
		)

	}


	getHometeamScore(score) {
		return score ? score.split(":")[0] : "";
	}

	getAwayTeamScore(score) {
		return score ? score.split(":")[1] : "";
	}


}
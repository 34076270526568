import React from "react";
import { connect } from "react-redux";
import { Bets } from "../../";
import { Constants, CountryCodes, Utils, Enums } from "../../../helpers";
import Timer from "./Timer";

class EventRow extends React.Component {
  componentDidMount() {}

  componentWillUnmount() {}

  getCountryCode = (name) => {
    let result = CountryCodes.Codes.filter((item) => {
      return item.name.toLowerCase() == name.toLowerCase();
    });

    return result && result.length > 0 ? result[0].code.toLowerCase() : "eu";
  };

  render() {
    const { id, betTypes, sportEvent, selectedValues, hideLeague, hideDate } =
      this.props;
      console.log("sportEventsportEventsportEventsportEvent" , sportEvent);
    if (!sportEvent) {
      return null;
    }
    return (
      <Bets.SportRow
        {...this.props}
        className={`row ${sportEvent.enableBet ? "" : "disabled"}`}
      >
        <div id="bet-rows-container">
          <div className="events-row-container">
            <div className="row-0" style={{ paddingBottom: "5px" }}>
              {
                //Live
                sportEvent.eventStatus.eventStatusDisplayName !==
                "Not started" ? (
                  <div>
                    {/* <div className="live">Live</div> */}
                    <div >
                      {sportEvent.virtualSeasonName}  Day:{" "}
                      {sportEvent.virtualDayName}
                    </div>
                    <div className="date">
                      {/* {`${
                        sportEvent.eventStatus &&
                        sportEvent.eventStatus.eventStatusDisplayName
                      }`} */}
                      {Utils.formatDate(sportEvent.eventDateOfMatch)}
                    </div>
                    {/* {sportEvent.eventScore && (
                      <Timer
                        type="up"
                        time={sportEvent.eventScore.eventTime}
                        status={sportEvent.eventScore.eventTimeStatus}
                      />
                    )} */}
                    {/* <div className="league">
                      <span
                        className={
                          "flag-icon flag-icon-" +
                          this.getCountryCode(
                            sportEvent.eventCategory.categoryDisplayName || ""
                          )
                        }
                      />{" "}
                      &nbsp;
                      <span className="text">
                        {sportEvent.eventCategory.categoryDisplayName} /{" "}
                        {sportEvent.eventTournament.tournamentDisplayName}
                      </span>
                    </div> */}
                  </div>
                ) : (
                  //Static
                  <div>
                    {!hideDate && (
                      <div className="date">
                        {Utils.formatDate(sportEvent.eventDateOfMatch).slice(
                          0,
                          9
                        )}
                      </div>
                    )}
                  </div>
                )
              }
            </div>

            <div className="row-1">
              <Bets.EventsCol className="col bet-type-0">
                {sportEvent.feed.feedId == Enums.Feed.Live &&
                sportEvent.sport.sportId == 5 ? (
                  <Bets.SetScore event={sportEvent} {...this.props} />
                ) : (
                  <Bets.DefaultScore event={sportEvent} {...this.props} />
                )}
              </Bets.EventsCol>
              <div className="row-of-bet-details-buttons">
                {betTypes && betTypes.match && (
                  <div style={{ marginRight: "30px", matginLeft: "30px" }}>
                    <Bets.EventsCol className="col bet-type-1">
                      <div className="bet-types">
                        <Bets.EventOdd
                          eventEnabled={sportEvent.enableBet}
                          key={Constants.defaultOddKey}
                          oddId={Constants.defaultOddKey}
                          eventId={id}
                        />
                      </div>
                    </Bets.EventsCol>
                  </div>
                )}
                {betTypes &&
                  betTypes.optionsBetTypes &&
                  betTypes.optionsBetTypes.length >= 1 && (
                    <div style={{ marginRight: "30px", matginLeft: "30px" }}>
                      <Bets.EventsCol className="col bet-type-2">
                        <div className="bet-types">
                          <Bets.EventOdd
                            eventEnabled={sportEvent.enableBet}
                            key={selectedValues[0]}
                            oddId={selectedValues[0]}
                            eventId={id}
                          />
                        </div>
                      </Bets.EventsCol>
                    </div>
                  )}

                {betTypes &&
                  betTypes.optionsBetTypes &&
                  betTypes.optionsBetTypes.length >= 2 && (
                    <div style={{ marginRight: "30px", matginLeft: "30px" }}>
                      <Bets.EventsCol className="col bet-type-3">
                        <div className="bet-types">
                          <Bets.EventOdd
                            eventEnabled={sportEvent.enableBet}
                            key={selectedValues[1]}
                            oddId={selectedValues[1]}
                            eventId={id}
                          />
                        </div>
                      </Bets.EventsCol>
                    </div>
                  )}

                {betTypes &&
                  betTypes.optionsBetTypes &&
                  betTypes.optionsBetTypes.length >= 3 && (
                    <div style={{ marginRight: "30px", matginLeft: "30px" }}>
                      <Bets.EventsCol className="col bet-type-4">
                        <div className="bet-types">
                          <Bets.EventOdd
                            eventEnabled={sportEvent.enableBet}
                            key={selectedValues[2]}
                            oddId={selectedValues[2]}
                            eventId={id}
                          />
                        </div>
                      </Bets.EventsCol>
                    </div>
                  )}
              </div>
            </div>

            <div className="row-2">
              {/* {
                //Virtuals
                (sportEvent.feed.feedId == Enums.Feed.VFL ||
                  sportEvent.feed.feedId == Enums.Feed.VFLM ||
                  sportEvent.feed.feedId == Enums.Feed.VBL ||
                  sportEvent.feed.feedId == Enums.Feed.VDR ||
                  sportEvent.feed.feedId == Enums.Feed.VHC ||
                  sportEvent.feed.feedId == Enums.Feed.VTO ||
                  sportEvent.feed.feedId == Enums.Feed.EPL) && (
                  <div>
                    <div className="season">
                      {sportEvent.virtualSeasonName} / Day:{" "}
                      {sportEvent.virtualDayName}
                    </div>
                    <div className="date">
                      {sportEvent.eventDateOfMatchFormated}
                    </div>
                    <div className="league">
                      <span
                        className={
                          "flag-icon flag-icon-" +
                          this.getCountryCode(
                            sportEvent.eventCategory.categoryDisplayName || ""
                          )
                        }
                      />{" "}
                      &nbsp;
                      <span className="text">
                        {sportEvent.eventCategory.categoryDisplayName} /{" "}
                        {sportEvent.eventTournament.tournamentDisplayName}
                      </span>
                    </div>
                  </div>
                )
              } */}

              {/* {
						//Static
						sportEvent.feed.feedId == 6 &&
						<div>
							{
								!hideDate &&
								< div className="date">{Utils.formatDate(sportEvent.eventDateOfMatch)}</div>
							}
							{
								!hideLeague &&
								< div className="league">
									<span className={"flag-icon flag-icon-" + this.getCountryCode(sportEvent.eventCategory.categoryDisplayName || "")}></span> &nbsp;
								<span className="text">{sportEvent.eventCategory.categoryDisplayName} / {sportEvent.eventTournament.tournamentDisplayName}</span>
								</div>
							}
						</div>
					} */}

              {/* {
						//Live
						sportEvent.feed.feedId == 5 &&
						<div>
							<div className="live">Live</div>
							<div className="date">
								{`${sportEvent.eventStatus && sportEvent.eventStatus.eventStatusDisplayName}`}
							</div>
							{
								sportEvent.eventScore &&
								<Timer type="up" time={sportEvent.eventScore.eventTime} status={sportEvent.eventScore.eventTimeStatus}></Timer>
							}
							<div className="league">
								<span className={"flag-icon flag-icon-" + this.getCountryCode(sportEvent.eventCategory.categoryDisplayName || "")}></span> &nbsp;
								<span className="text">{sportEvent.eventCategory.categoryDisplayName} / {sportEvent.eventTournament.tournamentDisplayName}</span>
							</div>
						</div>
					} */}
            </div>

            {/* <div className={"disabled"}>
					
				</div> */}
          </div>
          <div style={{ paddingTop: "25px" }}>
            <div className="row-0">
              <div className="bet-num">{`ID: ${sportEvent.eventBetLineNo}`}</div>
            </div>
            <div className="row-1">
              <Bets.EventsCol className="col bet-type-5">
                <Bets.Statistics sportEvent={sportEvent} />
              </Bets.EventsCol>

              <Bets.EventsCol className="col bet-type-6">
                <Bets.OddsNumber
                  {...this.props}
                  number={sportEvent.eventBetTypesCount}
                  data={sportEvent}
                />
              </Bets.EventsCol>
            </div>
          </div>
        </div>
        <div className="mobile-bet-rows-container">
          <div className="bet-rows-parent">
            <div>
              <div className="row-1">
                <div style={{ alignItems: "center" }} className="row-0">
                  <div className="bet-num">{`ID: ${sportEvent.eventBetLineNo}`}</div>
                </div>
                <div className="row-1">
                  <div>
                    <Bets.EventsCol className="col bet-type-5">
                      <Bets.Statistics sportEvent={sportEvent} />
                    </Bets.EventsCol>
                  </div>
                  <div>
                    {" "}
                    <Bets.EventsCol className="col bet-type-6">
                      <Bets.OddsNumber
                        {...this.props}
                        number={sportEvent.eventBetTypesCount}
                        data={sportEvent}
                      />
                    </Bets.EventsCol>
                  </div>
                </div>
              </div>
            </div>
            {/* {console.log("////////sportEvent.eventStatus.eventStatusDisplayName///////////////",sportEvent.eventStatus.eventStatusDisplayName !==
                  "Not started")} */}
            <div className="row-1" style={{ paddingBottom: "5px" }}>
              {
                //Live
       
                sportEvent.eventStatus.eventStatusDisplayName !==
                "Not started" ? (
                  <div>
                    {/* <div className="live">Live</div> */}
                    <div >
                      {sportEvent.virtualSeasonName}  Day:{" "}
                      {sportEvent.virtualDayName}
                    </div>
                    <div className="date">
                      {/* {`${
                        sportEvent.eventStatus &&
                        sportEvent.eventStatus.eventStatusDisplayName
                      }`} */}
                                            {Utils.formatDate(sportEvent.eventDateOfMatch)}

                    </div>
                    {/* {sportEvent.eventScore && (
                      <Timer
                        type="up"
                        time={sportEvent.eventScore.eventTime}
                        status={sportEvent.eventScore.eventTimeStatus}
                      />
                    )} */}
                    {/* <div className="league">
                      <span
                        className={
                          "flag-icon flag-icon-" +
                          this.getCountryCode(
                            sportEvent.eventCategory.categoryDisplayName || ""
                          )
                        }
                      />{" "}
                      &nbsp;
                      <span className="text">
                        {sportEvent.eventCategory.categoryDisplayName} /{" "}
                        {sportEvent.eventTournament.tournamentDisplayName}
                      </span>
                    </div> */}
                  </div>
                ) : (
                  //Static
                  sportEvent.feed.feedId == 6 && (
                    <div>
                      {!hideDate && (
                        <div className="date">
                          {Utils.formatDate(sportEvent.eventDateOfMatch)}
                        </div>
                      )}
                    </div>
                  )
                )
                
              }
            </div>

            <div className="row-1">
              <Bets.EventsCol className="col bet-type-0">
                {sportEvent.feed.feedId == Enums.Feed.Live &&
                sportEvent.sport.sportId == 5 ? (
                  <Bets.SetScore event={sportEvent} {...this.props} />
                ) : (
                  <Bets.DefaultScore event={sportEvent} {...this.props} />
                )}
              </Bets.EventsCol>
              <div className="row-of-bet-details-buttons">
                {console.log("slslslslsllslslsls",selectedValues)}
                {console.log("slslslslsllslslsls bettypes",betTypes)}
                {console.log("slslslslsllslslsls bettypes.match",betTypes.match)}
                {console.log("slslslslslslslssl betTypes.optionsBetTypes" , betTypes.optionsBetTypes)}
                {console.log("slslslslslslslssl sportevent" , sportEvent)}
                {selectedValues.length > 1 &&
                  selectedValues[0] == "2_0:1" &&
                  selectedValues[1] == "2_0:2" &&
                  selectedValues[2] == "2_0:3" &&
                  betTypes &&
                  betTypes.match && (
                    <div style={{ marginRight: "6vw" }}>
                      {console.log("hello1")}
                      <Bets.EventsCol className="col bet-type-1">
                        <div className="bet-types">
                          <Bets.EventOdd
                            eventEnabled={sportEvent.enableBet}
                            key={Constants.defaultOddKey}
                            oddId={Constants.defaultOddKey}
                            eventId={id}
                          />
                        </div>
                      </Bets.EventsCol>
                    </div>
                  )}
                {selectedValues.length > 1 &&
                  selectedValues[0] == "2_0:1" &&
                  selectedValues[1] == "2_0:2" &&
                  selectedValues[2] == "2_1:0" &&
                  betTypes &&
                  betTypes.optionsBetTypes &&
                  betTypes.optionsBetTypes.length >= 1 && (
                    <div style={{ marginRight: "30px", matginLeft: "30px" }}>
                                            {console.log("hello2")}

                      <Bets.EventsCol className="col bet-type-2">
                        <div className="bet-types">
                          <Bets.EventOdd
                            eventEnabled={sportEvent.enableBet}
                            key={selectedValues[0]}
                            oddId={selectedValues[0]}
                            eventId={id}
                          />
                        </div>
                      </Bets.EventsCol>
                    </div>
                  )}
                {selectedValues.length > 1 &&
                  selectedValues[0] == "2_0:1" &&
                  selectedValues[1] == "" &&
                  selectedValues[2] == "" &&
                  betTypes &&
                  betTypes.optionsBetTypes &&
                  betTypes.optionsBetTypes.length >= 1 && (
                    <div style={{ marginRight: "30px", matginLeft: "30px" }}>
                                            {console.log("hello2")}

                      <Bets.EventsCol className="col bet-type-2">
                        <div className="bet-types">
                          <Bets.EventOdd
                            eventEnabled={sportEvent.enableBet}
                            key={selectedValues[0]}
                            oddId={selectedValues[0]}
                            eventId={id}
                          />
                        </div>
                      </Bets.EventsCol>
                    </div>
                  )}
                {selectedValues.length > 1 &&
                  selectedValues[0] != "2_0:1" &&
                  betTypes &&
                  betTypes.optionsBetTypes &&
                  betTypes.optionsBetTypes.length >= 1 && (
                    <div style={{ marginRight: "30px", matginLeft: "30px" }}>
                                            {console.log("hello2")}

                      <Bets.EventsCol className="col bet-type-2">
                        <div className="bet-types">
                          <Bets.EventOdd
                            eventEnabled={sportEvent.enableBet}
                            key={selectedValues[0]}
                            oddId={selectedValues[0]}
                            eventId={id}
                          />
                        </div>
                      </Bets.EventsCol>
                    </div>
                  )}
                {/* handicaps  */}
                {/* {betTypes &&
                  betTypes.optionsBetTypes &&
                  betTypes.optionsBetTypes.length >= 1 && (
                    <div style={{ marginRight: "30px", matginLeft: "30px" }}>
                      <Bets.EventsCol className="col bet-type-2">
                        <div className="bet-types">
                          <Bets.EventOdd
                            eventEnabled={sportEvent.enableBet}
                            key={selectedValues[0]}
                            oddId={selectedValues[0]}
                            eventId={id}
                          />
                        </div>
                      </Bets.EventsCol>
                    </div>
                  )}

                {betTypes &&
                  betTypes.optionsBetTypes &&
                  betTypes.optionsBetTypes.length >= 2 && (
                    <div style={{ marginRight: "30px", matginLeft: "30px" }}>
                      <Bets.EventsCol className="col bet-type-3">
                        <div className="bet-types">
                          <Bets.EventOdd
                            eventEnabled={sportEvent.enableBet}
                            key={selectedValues[1]}
                            oddId={selectedValues[1]}
                            eventId={id}
                          />
                        </div>
                      </Bets.EventsCol>
                    </div>
                  )}

                {betTypes &&
                  betTypes.optionsBetTypes &&
                  betTypes.optionsBetTypes.length >= 3 && (
                    <div style={{ marginRight: "30px", matginLeft: "30px" }}>
                      <Bets.EventsCol className="col bet-type-4">
                        <div className="bet-types">
                          <Bets.EventOdd
                            eventEnabled={sportEvent.enableBet}
                            key={selectedValues[2]}
                            oddId={selectedValues[2]}
                            eventId={id}
                          />
                        </div>
                      </Bets.EventsCol>
                    </div>
                  )} */}
                {/* END OF  handicaps  */}
              </div>
            </div>
          </div>
        </div>
      </Bets.SportRow>
    );
  }
}

const mapStateToProps = (_, initialProps) => (state) => {
  return {
    sportEvent: state.sportEvents.sportEvents[initialProps.id],
    betTypes: state.sportEvents.betTypes,
    selectedValues: state.sportEvents.selectedValues,
    feedName: state.app.feedName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(EventRow);

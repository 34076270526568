import { Fetch, AppConfig } from '../helpers'

export default class authApi {

	static signin = (credentials) => {
		const response = Fetch.fetch(
			{
				methodName: "Signin",
				url: AppConfig.api.auth,
				data: credentials
			}
		)

		return response;
	}
	static signout = (query) => {
		const response = Fetch.fetch(
			{
				methodName: "SignOut",
				method: "POST",
				url: AppConfig.api.auth,
				data: query
			}
		)
		return response;
	}


	static forgotPasswordCheckDestination = (query) => {
		const response = Fetch.fetch(
			{
				methodName: "forgotPasswordCheckDestination",
				method: "POST",
				url: AppConfig.api.auth,
				data: query
			}
		)
		return response;
	}


	static forgotPassword = (query) => {
		const response = Fetch.fetch(
			{
				methodName: "ForgotPasswordMember",
				method: "POST",
				url: AppConfig.api.auth,
				data: query
			}
		)
		return response;
	}

	static forgotPasswordConfirm = (query) => {
		const response = Fetch.fetch(
			{
				methodName: "ForgotPasswordConfirm",
				method: "POST",
				url: AppConfig.api.auth,
				data: query
			}
		)
		return response;
	}

	static changePassword = (query) => {
		const response = Fetch.fetch(
			{
				methodName: "ChangePassword",
				method: "POST",
				url: AppConfig.api.auth,
				data: query
			}
		)
		return response;
	}
	static updatePassword = (query) => {
		const response = Fetch.fetch(
			{
				methodName: "UpdatePassword",
				method: "POST",
				url: AppConfig.api.auth,
				data: query
			}
		)
		return response;
	}
}

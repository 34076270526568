import React from 'react';
import { withRouter } from 'react-router';

class WithRouter extends React.Component {
    render() {
        const { children } = this.props;
        return children;
    }
}

export default withRouter(WithRouter);
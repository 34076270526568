import React from "react";
import { connect } from "react-redux";
import { Layout, Spin, message, Icon } from "antd";
import { Screen, Alert, ScreenTitle } from "../../components";
import { validateEmail } from "../../redux/actions/registerActions";

const { Sider, Content } = Layout;

class EmailValidate extends React.Component {
  componentDidMount() {
    let path = this.props.location.pathname;
    let key = path.split("emailvalidate/");
    window.log(key[1]);
    let data = {
      name: key[1],
    };
    this.props.validateEmail(data);
  }

  render() {
    const { valid, message } = this.props;
    return (
      <Screen {...this.props}>
        <Layout className="document mtop-20">
          <Layout className="layout-inner">
            <Sider width={220} className="sider-left"></Sider>
            <div className="content" style={{ height: 700 }}>
              <ScreenTitle
                hideIcon
                title={
                  message ? (
                    <Alert message={message} type="error"></Alert>
                  ) : !valid ? (
                    <div className="email_validate">
                      <h1>Please Wait..</h1>
                      <Spin size="large" />
                    </div>
                  ) : (
                    <div className="email_validate">
                      <h1>Account was successfully verified</h1>
                      <Icon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="#52c41a"
                      />
                    </div>
                  )
                }
              ></ScreenTitle>
            </div>
            <div className="right">
              <Sider width={350} className="sider-right"></Sider>
            </div>
          </Layout>
        </Layout>
      </Screen>
    );
  }
}

const MapStateToProps = (state, props) => {
  return {
    valid: state.register.emailVerified,
    message: state.register.emailVerifiedError,
  };
};
const mapDispatchToProps = (dispatch) => ({
  validateEmail: (data) => dispatch(validateEmail(data)),
});

export default connect(MapStateToProps, mapDispatchToProps)(EmailValidate);

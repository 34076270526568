import { Layout } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Screen, SlotList, SlotsGame } from "../../components";
import { init } from '../../redux/actions/appActions';


class SlotMachines extends React.Component {


	checkBackButton = () => {
		const { match, history, init } = this.props;
		if (history.action === "POP") {
			init();
		}
	}

	async componentDidMount() {
		this.checkBackButton();
	}

	render() {
		const { match } = this.props;
		return (
			<Screen {...this.props}>
				<Layout className="slot-machines mtop-10">
					<Layout className="layout-inner">
						<div className="content" style={{padding: 0}}>
							<Route exact path='/slots' component={SlotList} />
							<Route exact path='/slots/game/:gameId' component={SlotsGame} />
						</div>
					</Layout>

				</Layout>
			</Screen>
		)
	}
}

const mapStateToProps = (state) => {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		init: () => dispatch(init()),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SlotMachines);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import {
  memberForms,
  formsDetails,
} from "../../../redux/actions/membersActions";
import { formStatuses } from "../../../redux/actions/generalActions";
import { Images } from "../../";


const TabPane = Tabs.TabPane;

export class MyBets extends Component {
  state = {
    toggleMyBets: false,
    toggleCollapse: false,
    expandedRowKeys: [],
    // fromDate: moment().startOf('day'),
    // toDate: moment().endOf('day'),
    status: 0,
    pagination: {
      current: 1,
    },
    index: 0,
    rendered: false,
    expendedDataSource: [],
    expandedSNWRowKeys: [],
  };
  toggleMeBetsListGroup = () => {
    let collapsable = document.getElementById("my-bets-rows-container");
    this.setState({
      toggleMyBets: !this.state.toggleMyBets,
    });
  };
  componentWillMount() {
    let data = {
      statusId: this.state.status,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      pageIndex: this.state.pagination.current - 1,
    };
    this.props.memberForms(data);
    this.props.formStatuses({ data: { formStatusId: 0 } });
    this.tableRef = React.createRef();
  }
  setStatusFilter = (value) => {
		console.log("value",value);
		this.setState({ status: value, expandedRowKeys: [] }, () => {
			let data = {
				statusId: this.state.status,
				fromDate: this.state.fromDate,
				toDate: this.state.toDate,
				pageIndex: 0
			}
			this.props.memberForms(data)
		})
	}

  createMyBetsStates(formNumber) {
    this.setState({
      formNumber: false,
    });
  }
  convertDate(month, day) {
    switch (month) {
      case "1":
        return `${day} January`;
      case "2":
        return `${day} February`;
      case "3":
        return `${day} March`;
      case "4":
        return `${day} April`;
      case "5":
        return `${day} May`;
      case "6":
        return `${day} June`;
      case "7":
        return `${day} July`;
      case "8":
        return `${day} August`;
      case "9":
        return `${day} September`;
      case "10":
        return `${day} October`;
      case "11":
        return `${day} November`;
      case "12":
        return `${day} December`;
    }
  }
  async toggleCollapse(record, idx) {
    let arrowSpan = document.getElementById(`${idx}`);
    let imageElement = document.createElement('img')

    let targetDiv = document.getElementsByClassName(`${record.formNumber}`);
    let betRowsContainerDiv = document.getElementById(`${record.formNumber}`);
    for (let i = 0; i < targetDiv.length; i++) {
      let divStyle = targetDiv[i].style.display;
      if (divStyle == "none") {
        betRowsContainerDiv.style.borderBottom = "none";

        let data = await {
          formNumber: record.formNumber,
          formType: record.formType,
        };
        await this.props.formsDetails(data);
        // {this.props.formDetails && this.props.formDetails[0].match }

        targetDiv[i].style.display = "block";
        imageElement.src = Images.DownArrow
        arrowSpan.src = imageElement.src;
        arrowSpan.style.transform= "rotate(180deg)"
        this.setState({
          record: record,
        });
      } else {
        betRowsContainerDiv.style.borderBottom = "1px solid #FAF9F6";
        targetDiv[i].style.display = "none";
        arrowSpan.style.transform="none"
        imageElement.src = Images.DownArrow
        arrowSpan.src = imageElement.src;
      }
      // formsDetails action
    }
  }
  render() {
    const { formDetails } = this.props;
    let filteredFormDetails = {};
    function displaySportImage(sportName) {
      switch (sportName) {
        case "Soccer":
          return Images.Soccer;
        case "Basketball":
          return Images.MenuBasketball;
        case "Cricket":
          return Images.MenuCricket;
        case "Ice Hokey":
          return Images.IceHockey;
        case "rugby":
          return Images.Rugby
        case "aussie rules":
          return Images.Aussie
        case "dart":
          return Images.Dart
        case "MMA":
          return Images.MMA
      }
    }
    console.log("rugbyData" , formDetails);
    let soccerData =
      formDetails.length > 0 &&
      formDetails.filter((item) => {
        return item.sport == "Soccer";
      });
    let basketBallData =
      formDetails.length > 0 &&
      formDetails.filter((item) => {
        return item.sport == "Basketball";
      });
    let cricketData =
      formDetails.length > 0 &&
      formDetails.filter((item) => {
        return item.sport == "Cricket";
      });
    let iceHockeyData =
      formDetails.length > 0 &&
      formDetails.filter((item) => {
        return item.sport == "Ice Hokey";
      });
    let rugbyData =
      formDetails.length > 0 &&
      formDetails.filter((item) => {
        return item.sport == "rugby";
      });
    let aussieData =
      formDetails.length > 0 &&
      formDetails.filter((item) => {
        return item.sport == "aussie rules";
      });
    let dartData =
      formDetails.length > 0 &&
      formDetails.filter((item) => {
        return item.sport == "dart";
      });
    let mmaData =
      formDetails.length > 0 &&
      formDetails.filter((item) => {
        return item.sport == "MMA";
      });

    formDetails.length > 0 &&
      formDetails.map((item) => {
        if (item.sport == "MMA") {
          return (filteredFormDetails["MMA"] = mmaData);
        }
        if (item.sport == "dart") {
          return (filteredFormDetails["dart"] = dartData);
        }

        if (item.sport == "aussie rules") {
          return (filteredFormDetails["aussie rules"] = aussieData);
        }
        if (item.sport == "rugby") {
          return (filteredFormDetails["rugby"] = rugbyData);
        }
        if (item.sport == "Ice Hokey") {
          return (filteredFormDetails["ice Hokey"] = iceHockeyData);
        }
        if (item.sport == "Cricket") {
          return (filteredFormDetails["cricket"] = cricketData);
        }

        if (item.sport == "Basketball") {
          return (filteredFormDetails["basketball"] = basketBallData);
        }
        if (item.sport == "Soccer") {
          return (filteredFormDetails["soccer"] = soccerData);
        }
      });
      
    function deployFormDetails(record, formDetails) {
      let targetDiv = document.getElementsByClassName(`${record.formNumber}`);
      targetDiv[0].textContent = "";
      Object.values(filteredFormDetails).map((item, idx) => {
        // console.log("item[idx].sport 111111111111111111",item[idx].sport);
        // image div
        {console.log("filteredFormDetails",filteredFormDetails);}
        let imageSrc = displaySportImage(item['0'].sport);
        let imageAmountDiv = document.createElement("div");
        let imageElement = document.createElement("img");
        imageElement.src = imageSrc;
        // END of image div

        // <img src="/images/lorient.svg" height="18px" width="18px"/>
        // <img src="/images/RC_Lens.svg" height="18px" width="18px"/>
        // creating dummy images to be displayed in mybets rows

        // amount element
        let amountElement = document.createElement("p");
        amountElement.textContent = record.amount;
        amountElement.style.display = "inline";
        // END of amount element

        // appending children to parents
        imageAmountDiv.appendChild(imageElement);
        imageAmountDiv.appendChild(amountElement);
        targetDiv[0].appendChild(imageAmountDiv);

        // END appending children to parents

        // Styling parents
        imageAmountDiv.style.display = "flex";
        imageAmountDiv.style.justifyContent = "space-between";
        imageAmountDiv.style.backgroundColor = "#233355";
        imageAmountDiv.style.paddingRight = "10px";
        imageAmountDiv.style.paddingLeft = "10px";
        imageAmountDiv.style.alignItems= "center";

        // End of styling parents

        // Styling Children
        amountElement.style.marginTop = "15px";
        amountElement.style.color = "#2C8AF7";
        amountElement.style.fontWeight = "bold";

        // END of styling children

        for (let i = 0; i < item.length; i++) {
          // console.log("record.formNumber------------2" , record.formNumber);
          // console.log("this.props.formDetails  -2*******************" , formDetails);
          // console.log("filteredFormDetails .....................",filteredFormDetails);
          let divsContainer = document.createElement("div");

          // creating dummy images to be displayed in mybets rows

          let firstTeamImg = document.createElement("img");
          firstTeamImg.src = "/images/lorient.svg";
          firstTeamImg.style.height = "18px";
          firstTeamImg.style.width = "18px";

          let secondTeamImg = document.createElement("img");
          secondTeamImg.src = "/images/RC_Lens.svg";
          secondTeamImg.style.height = "18px";
          secondTeamImg.style.width = "18px";

          // END OF dummy images

          // MatchId Div declaration and its content
          let matchIdDiv = document.createElement("div");
          let matchParagraph = document.createElement("div");
          let idParagraph = document.createElement("span");

          let firstTeamName = document.createElement("span");
          let secondTeamName = document.createElement("span");
          // END of MatchId

          // vsElement
          let vsElement = document.createElement("span");
          vsElement.textContent = "Vs.";
          vsElement.style.color = "#2C8AF7";
          vsElement.style.fontWeight = "bold";

          // END of vsElement

          // MatchId Div declaration and its content
          let noteOddDiv = document.createElement("div");
          let noteParagraph = document.createElement("span");
          let oddParagraph = document.createElement("span");
          // END of MatchId

          // styling of elements depends on formDetails
          if (targetDiv[0]) {
            divsContainer.style.borderBottom = "1px solid black";
            divsContainer.style.marginBottom = "10px";
          }

          noteOddDiv.style.padding = "10px";
          noteOddDiv.style.display = "flex";
          noteOddDiv.style.justifyContent = "space-between";

          matchIdDiv.style.padding = "10px";
          matchIdDiv.style.display = "flex";
          matchIdDiv.style.justifyContent = "space-between";

          idParagraph.style.opacity = "0.5";

          // End of styling

          if (targetDiv[0]) {
            // adding content to elements
            firstTeamName.textContent = `${item[i].match.split("Vs.")[0]} `;
            secondTeamName.textContent = `${item[i].match.split("Vs.")[1]} `;
            matchParagraph.appendChild(firstTeamImg);
            matchParagraph.appendChild(firstTeamName);
            matchParagraph.appendChild(vsElement);
            matchParagraph.appendChild(secondTeamName);
            matchParagraph.appendChild(secondTeamImg);

            idParagraph.textContent = `ID: ${item[i].bet}`;
            noteParagraph.textContent = item[i].note;
            oddParagraph.textContent = item[i].odd;
            // END of adding content
            // appending
            matchIdDiv.appendChild(matchParagraph);
            matchIdDiv.appendChild(idParagraph);
            divsContainer.appendChild(matchIdDiv);

            noteOddDiv.appendChild(noteParagraph);
            noteOddDiv.appendChild(oddParagraph);
            divsContainer.appendChild(noteOddDiv);
            targetDiv[0].appendChild(divsContainer);
            // END of appending
          }
        }
      });
    }
    // console.log("this.state.record" , this.state.record);
    this.props.formDetails.length > 0 &&
      this.state.record &&
      document.getElementsByClassName(`${this.state.record.formNumber}`)[0] &&
      deployFormDetails(this.state.record, this.props.formDetails);
    function calculateTotalFormOdds(forms) {
      if(forms.length > 0){
        const sumWithInitial = forms.reduce(
          (previousValue, item) =>
            previousValue + Number(item.amount.replace(/[^\d.-]/g, "")),
          0
        );
        return forms[0].amount.includes("LD")
          ? "LD" + sumWithInitial
          : "$" + sumWithInitial;
      }
    }

    // console.log(
    //   "from my  bets ================formDetails=formDetails",
    //   this.props.forms
    // );
    // console.log(
    //   "from my  bets =================Details",
    //   this.props.formDetails
    // );
    // console.log("from my  bets =================allprops", this.props);
    return (
      <div id="my-bets-container">
        <div>
          {!this.state.toggleMyBets && (
            <div className="my-bets-headline">
              <div className="my-bets-header">
                {this.props.forms.length > 0 && (
                  <i
                    style={{ color: "red" }}
                    className="fa-solid fa-circle"
                  ></i>
                )}{" "}
                My Bets{" "}
                {this.props.forms && (
                  <span className="lower-opacity-container">
                    ({this.props.forms.length}){" "}
                  </span>
                )}
              </div>
              {!this.state.toggleMyBets && (
                <span
                  className="my-bets-container-header-arrows"
                  onClick={this.toggleMeBetsListGroup}
                >
                  <img src={Images.DownArrow} />
                </span>
              )}
            </div>
          )}

          {this.state.toggleMyBets && (
            <div className="fixed-my-bets-headline">
              <div className="my-bets-header">
                {this.props.forms.length > 0 && (
                  <i
                    style={{ color: "red" }}
                    className="fa-solid fa-circle"
                  ></i>
                )}{" "}
                My Bets{" "}
                {this.props.forms && (
                  <span className="lower-opacity-container">
                    ({this.props.forms.length}){" "}
                  </span>
                )}
              </div>
              {this.state.toggleMyBets && (
                <span
                  className="my-bets-container-header-arrows"
                  onClick={this.toggleMeBetsListGroup}
                >
                  <img
                    style={{ transform: "rotate(180deg)" }}
                    src={Images.DownArrow}
                  />
                </span>
              )}
            </div>
          )}
        </div>
        {this.state.toggleMyBets && (
          <div className="list-group-container">
            <div className="my-bets-tabs">
              <nav>
                <a
                  onClick={() => {
                    this.setStatusFilter('1')
                  }}
                >
                  Active
                </a>
                <a
                  onClick={() => {
                    this.setStatusFilter('0')
                  }}
                >
                  History
                </a>
              </nav>
            </div>
            {this.props.forms.length > 0 ? (
              this.props.forms.map((item, idx) => {
                return (
                  <>
                    <div className="my-bets-results">
                      <div
                        id={item.formNumber}
                        className="my-bets-rows-container"
                        onClick={() => this.toggleCollapse(item, idx)}
                      >
                        <div className="my-bets-row-1">
                          <div className="my-bets-formId-container">
                            Form Id: {item.formNumber}
                          </div>
                          <div className="my-bets-formId-container">
                            Status:{" "}
                            {item.status == "Active" ? (
                              <span
                                style={{ color: "white", fontWeight: "bold" }}
                              >
                                {item.status}
                              </span>
                            ) : item.status == "Lost" ? (
                              <span
                                style={{ color: "red", fontWeight: "bold" }}
                              >
                                {item.status}
                              </span>
                            ) : (
                              <span
                                style={{ color: "green", fontWeight: "bold" }}
                              >
                                {item.status}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="my-bets-date">
                          <h3>
                            {this.convertDate(
                              item.date.slice(14, 15),
                              item.date.slice(10, 12)
                            )}
                          </h3>

                          <span className="my-bets-rows-arrow">
                            {/* <i  className="fa-solid fa-arrow-down"></i> */}
                            <img id={idx} src={Images.DownArrow}/>
                          </span>
                        </div>
                      </div>
                      <div
                        style={{ display: "none" }}
                        className={item.formNumber}
                      >
                        {/* {item.formNumber} */}
                        {/* {this.props.formDetails && this.props.formDetails[0].match } */}
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div style={{textAlign:"center" , paddingTop:"10px"}}>You Don't Have any Bets Yet !</div>
            )}
          </div>
        )}
        {this.state.toggleMyBets && (
          <div id="my-bets-total-odds-container">
            <span>Total odds</span>
            <span style={{ color: "#2C8AF7" }} className="bolded-element">
              {this.props.forms && calculateTotalFormOdds(this.props.forms)}
            </span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    total: state.member.totalForms,
    forms: state.member.forms,
    statuses: state.general.statuses,
    loading: state.member.loading,
    formDetails: state.member.formDetails,
    isGroup: state.member.isGroup,
    betLines: state.member.betLines,
    message: state.member.betsMessage,
    betDetailsModalVisible: state.member.betDetailsModalVisible,
    formDateRange: state.member.formDateRange,
  };
};

const mapDispatchToProps = (dispatch) => ({
  memberForms: (data) => dispatch(memberForms(data)),
  formStatuses: (data) => dispatch(formStatuses(data)),
  formsDetails: (data) => dispatch(formsDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyBets);

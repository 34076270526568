import { SignalRTypes, SportTypesActionTypes } from '../actions';

const initState = {
    error: null,
    loadingSportTypes: false,
    sportTypes: [],
    sportData: null,
};

const sportTypes = (state = initState, action) => {

    switch (action.type) {

        case SportTypesActionTypes.Init:
            return {
                ...state,
                error: null,
                loadingSportTypes: false,
                sportTypes: [],
                sportData: null,

            }

        case SignalRTypes.SportTypes_Request:
            return {
                ...state,
                loadingSportTypes: true,
                sportTypes: [],
                error: null,

            }

        case SportTypesActionTypes.SportTypes_Success:
            return {
                ...state,
                loadingSportTypes: false,
                sportTypes: action.sportTypes,
                error: null,
            }

        case SportTypesActionTypes.SportTypes_Error:
            return {
                ...state,
                loadingSportTypes: false,
                sportTypes:[],
                error:action.error,
            }
        case SportTypesActionTypes.Set_Sport:
            return {
                ...state,
                sportData: action.item
            }

        default:
            return { ...state };

    }
}

export default sportTypes;
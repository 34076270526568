import { Modal, Steps } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Step1, Step2 } from './';
const { Step } = Steps;

const steps = [
	{
		title: 'Forgot Password',
		desc: 'Step 1',
		content: <Step1 />,
	},
	{
		title: 'Confirmation',
		desc: 'Step 2',
		content: <Step2 />,
	}
];

class ForgotPassword extends React.Component {
	constructor(props) {
		super(props)
	}

	// close modal
	close = () => {
		this.props.onCloseForgotPassword();
	}

	render() {
		const { current, message, visible } = this.props;
		return (
			this.props.visible ?
				<Modal
					className="register"
					closable={true}
					onCancel={this.close}
					maskClosable={false}
					visible={visible}
					footer={null}>
					<Steps current={current} style={{ display: 'flex', justifyContent: 'space-around' }} >
						{steps.map(item => <Step style={{ flex: 'none' }} key={item.title} title={item.title} description={item.desc} />)}
					</Steps>

					<div className="steps-content">
						{steps[current].content}
					</div>
				</Modal> : null
		)
	}
}


const MapStateToProps = (state, props) => {
	return {
		message: state.auth.message,
		current: state.auth.currentForgotPassword,
	};
}

const mapDispatchToProps = (dispatch) => (
	{

	}
);

export default connect(MapStateToProps, mapDispatchToProps)(ForgotPassword);
import React, { Component } from "react";
import { Enums } from "../../helpers";
import Images from "../common/Images";

export class LoginAndRegistrationHeader extends Component {
  navigate = (
    route,

  ) => {
    const { history } = this.props;
    if (history.location.pathname != route) {
      history.push(`${route}`);
    }
  };

  render() {
    const { showForgotPassword, showLoginForm, indicator } = this.props;
    return (
      <div id="login-and-registration-header">
        <div className="top-menu">
          <div className="size">
            <div
              className="logo"
              onClick={() =>
                this.navigate(
                  `/sport/${Enums.Feed.Static}/1/Soccer`,
  
                )
              }
            >
              <img
                style={{ height: "100px", width: "250px" }}
                alt="Winners"
                src={Images.LogoWinners}
              />
            </div>
          </div>
          {indicator == "login" &&
            (!showForgotPassword ? (
              <div className="header-text">
                <span>You don't have an account? </span>
                <div onClick={()=>window.location.assign('2')}>Register</div>{" "}
              </div>
            ) : (
              <div className="back-to-login" onClick={() => showLoginForm()}>
                <img src={Images.DownArrow} height="8px" /> Back To Login
              </div>
            ))}
          {indicator == "register" &&
              <div className="header-text">
                <span>You already have an account? </span>
                <div onClick={()=>window.location.assign('1')}>Login</div>{" "}
              </div>
}
        </div>
      </div>
    );
  }
}

export default LoginAndRegistrationHeader;

import { SignalRTypes, CategoriesActionTypes } from '../actions';

const initState = {
    error: null,
    loadingSportCategories: true,
    sportCategories: [],
    sportTopLeagues: [],
    tournamentData: null,
};

const categories = (state = initState, action) => {

    switch (action.type) {

        case CategoriesActionTypes.Init:
            return {
                ...state,
                error: null,
                loadingSportCategories: false,
                sportCategories: [],
                sportTopLeagues: [],
                tournamentData: null,
            }

        case SignalRTypes.SportCategories_Request:
            return {
                ...state,
                loadingSportCategories: true,
                sportCategories: [],
                sportTopLeagues: [],
                error: null,

            }

        case CategoriesActionTypes.SportCategories_Success:
            return {
                ...state,
                loadingSportCategories: false,
                sportCategories: action.sportCategories,
                tournamentData: null,
                error: null,

            }
        case CategoriesActionTypes.SportTopLeagues_Success:
            return {
                ...state,
                loadingSportCategories: false,
                sportTopLeagues: action.sportTopLeagues,
                tournamentData: null,
                error: null,
            }

        case CategoriesActionTypes.SportCategories_Error:
            return {
                ...state,
                loadingSportCategories: false,
                sportCategories: [],
                sportTopLeagues: [],
                tournamentData: null,
                error: action.error,
            }

        case CategoriesActionTypes.Set_Tournament:
            return {
                ...state,
                tournamentData: action.item
            }

        default:
            return { ...state };

    }
}

export default categories;
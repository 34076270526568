import React from "react";
import { connect } from "react-redux";
import { Input, Button, Form, DatePicker } from "antd";
import {
  forgotPassword,
  forgotPasswordConfirm,
  forgotPasswordCheckDestination,
} from "../../redux/actions/authActions";
import { Alert } from "../../components";
import { LocalStorage } from "../../helpers";

class Step1 extends React.Component {
  state = {
    birthDate: "",
    
  };

 

  hasErrors = (fieldsError) => {
    let values = this.props.destination
      ? this.props.form.getFieldsValue(["userName", "birthDate"])
      : this.props.form.getFieldsValue(["userName"]);
    return (
      Object.keys(fieldsError).some((field) => fieldsError[field]) ||
      Object.values(values).filter(
        (field) => field === undefined || field === false || field === ""
      ).length > 0
    );
  };

  handleSendUserName = async (e) => {
    e.preventDefault();
    let userName = this.props.form.getFieldValue("userName");
    console.log("from================handleuserName=============================" , this.props.destination);
    console.log("===>handleuserName===>this.props.destination" , this.props.destination);
    if (this.props.destination) {
       this.setState({ birthDate: await this.props.form.getFieldValue("birthDate") });
       console.log("===>handleuserName===>this.props.form.getFieldValue",this.props.form.getFieldValue("birthDate"));
      let data = {
        memberId: this.props.destination.memberId,
        birthDate: await this.props.form.getFieldValue("birthDate"),
        phoneNumber: this.props.destination.phoneNumber,
      };
      console.log("===>handleuserName===>data",data);

      this.props.forgotPassword(data);
    } else {
      this.props.forgotPasswordCheckDestination(userName);
    }
    this.setState({ userName });
  };

 

  componentDidUpdate(prevProps) {
    console.log("from ============================componentDidUpdate---------------------" );
    console.log("======componentDidUpdate===>this.props.destination" , this.props.destination);
    console.log("======componentDidUpdate===>this.props.form.getFieldValue",this.props.form.getFieldValue("birthDate"));

    if (
      !prevProps.destination &&
      this.props.destination &&
      !this.props.destination.phoneNumber
    ) {
      let data = {
        memberId: this.props.destination.memberId,
        birthDate:  this.props.form.getFieldValue("birthDate"),
        phoneNumber: this.props.destination.phoneNumber,
      };
      console.log("======componentDidUpdate===>data",data);


      this.props.forgotPassword(data);
    }
  }

  render() {
    const { message, success } = this.props;
    const { getFieldDecorator, getFieldsError, userData } = this.props.form;
    const formItemLayout = null;
    console.log("this.state.birthdat", this.state.birthDate);

    let isSendSMS =
      this.props.destination && this.props.destination.phoneNumber
        ? true
        : false;
    return (
      <div className="step">
        {/* <div className="strip">
					<div className="content">
						<div className="text">
							{success ? success : ''}
						</div>
					</div>
				</div> */}

        <div className="content">
          <div className="form">
            {message && <Alert type="error" icon="!" message={message} />}
            
              <div className="step1-parent-container">
                <p className="forget-password-headline">Forgot Password</p>

                <Form layout="vertical" onSubmit={this.handleSendUserName}>
                  <div>
                    <div className="forget-password-input-label-container">
                      <Form.Item
                        className="form-item"
                        {...formItemLayout}
                        label="Email or Phone Number"
                      >
                        {getFieldDecorator("userName", {
                          rules: [
                            { required: true, message: "Email is required" },
                          ],
                        })(<Input disabled={isSendSMS} />)}
                      </Form.Item>
                      {
                        <Form.Item
                          className="form-item"
                          {...formItemLayout}
                          label="Birth Date"
                        >
                          {getFieldDecorator("birthDate", {
                            rules: [
                              {
                                required: true,
                                message: "Please fill your birth date",
                              },
                            ],
                          })(
                            <DatePicker
                              style={{ width: "100%" }}
                              size="large"
                              showTime
                              format="DD/MM/YYYY"
                            />
                          )}
                        </Form.Item>
                      }
                    </div>

                    <div className="step1-button-container">
                      <Button
                        disabled={this.hasErrors(getFieldsError())}
                        className="next"
                        type="primary"
                        loading={this.props.loading}
                        htmlType="submit"
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>





          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    message: state.auth.forgotPasswordMessage,
    loading: state.auth.loading,
    userData: state.auth.userData,
    success: state.auth.forgotPasswordSucces,
    destination: state.auth.forgotPasswordDest,
  };
};

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (userName) => dispatch(forgotPassword(userName)),
  forgotPasswordConfirm: (data) => dispatch(forgotPasswordConfirm(data)),
  forgotPasswordCheckDestination: (data) =>
    dispatch(forgotPasswordCheckDestination(data)),
});

const WrappedStep1 = Form.create({ name: "ForgotPasswordStep1" })(Step1);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedStep1);

import React from "react";
import { Icon } from "antd";

import { connect } from "react-redux";
import { SportIcon } from "../";
import { loadSportCategories } from "../../redux/actions/categoriesActions";
import {
  init,
  loadSportTypes,
  setSport,
  removeDisabledSportTypes,
} from "../../redux/actions/sportTypesActions";
import { clearSportEvents } from "../../redux/actions/sportEventsActions";
import Swipe from "./Swipe";
import { Enums } from "../../helpers";

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
let handleButtonsCalled = false;
let swipePosition = 0;
let swipeStep = 0;

const SportType = (props) => {
  const { feedId, match, item, onClick, sportData } = props;
  const selected =
    item.sport.sportId.toString() == match.params.sport.toString() &&
    item.feed.feedId.toString() == match.params.feed.toString()
      ? "selected"
      : "";

  return (
    <div className={`sport-type`} onClick={onClick}>
      <div className={`box light-on ${selected}`}>
        <SportIcon
          feedId={item.feed.feedId}
          sportId={item.sport.sportId}
          className="icon"
        />
        <div className="title">{item.sport.sportDisplayName}</div>
      </div>
    </div>
  );
};

class SportTypes extends React.Component {
  state = {
    disabled: false,
    showLeftButton: false,
    showRightButton: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.swipe) {
      if (!handleButtonsCalled) {
        this.handleButtons();
        handleButtonsCalled = true;
      }
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleButtons);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleButtons);
  }

  handleButtons = () => {
    if (this.swipe) {
      const { swiper } = this.swipe;
      if (swiper && swiper.scrollWidth === swiper.clientWidth) {
        this.setState({ showLeftButton: false, showRightButton: false });
      } else {
        if (swiper.scrollLeft > 0) {
          this.setState({ showLeftButton: true });
        }
        if (swiper.scrollLeft < swiper.scrollWidth - swiper.clientWidth) {
          this.setState({ showRightButton: true });
        }
        if (swiper.scrollLeft === 0) {
          this.setState({ showLeftButton: false });
        }
        if (swiper.scrollLeft === swiper.scrollWidth - swiper.clientWidth) {
          this.setState({ showRightButton: false });
        }
      }
    }
  };
  buttonScroll = (direction, speed, distance, step) => {
    let element = this.swipe.swiper;
    let scrollAmount = 0;
    let slideTimer = setInterval(() => {
      if (direction == "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
      this.handleButtons();
    }, speed);
  };

  onSwipeMove = (position, event) => {
    swipeStep = Math.abs(position.x) - Math.abs(swipeStep);
    if (swipePosition != position.x) {
      if (position.x > swipePosition) {
        this.swipeScroll(-(swipeStep / 5));
      }
      if (position.x < swipePosition) {
        this.swipeScroll(swipeStep / 5);
      }
      swipePosition = position.x;
    }
  };

  swipeScroll = (step) => {
    let element = this.swipe.swiper;
    element.scrollLeft += step;
    this.handleButtons();
  };

  onSwipeEnd = (event) => {
    this.setState({ disabled: false });
    swipePosition = 0;
    swipeStep = 0;
  };

  onSwipeStart = (event) => {
    this.setState({ disabled: true });
    swipePosition = 0;
    swipeStep = 0;
  };

  onSportTypeClick = (item) => {
    const {
      history,
      feedId,
      feedName,
      setSport,
      removeDisabledSportTypes,
      loadSportCategories,
      clearSportEvents,
    } = this.props;
    if (
      (item.feed.feedId == 6 && item.sport.sportId == 1) ||
      item.feed.feedId == 5
    ) {
      history.push(
        `/${feedName}/${feedId}/${item.sport.sportId}/${item.sport.sportDisplayName}`
      );
    }
    //Go to today
    else {
      if (Enums.VirtualFeedIds.includes(item.feed.feedId)) {
        const path = `/${feedName}/${item.feed.feedId}/${item.sport.sportId}/${item.sport.sportDisplayName}`;
        clearSportEvents();
        history.push(path);
      } else {
        history.push(
          `/${feedName}/${feedId}/${item.sport.sportId}/${item.sport.sportDisplayName}/today/0`
        );
      }
    }

    setSport(item);
    removeDisabledSportTypes();

    const request = {
      Data: {
        Feed: { FeedId: Number(item.feed.feedId) },
        Sport: { SportId: Number(item.sport.sportId) },
      },
    };
    loadSportCategories(request);
  };

  render() {
    const { connected, sportTypes, loadingSportTypes, sportData } = this.props;

    if (!connected) {
      return (
        <section className="">{/* <div>Connecting server...</div> */}</section>
      );
    }

    if (loadingSportTypes) {
      return (
        <section className="">
          {/* <div>Loading Sport Types...</div> */}
        </section>
      );
    }

    return (
      null
      // <div
      //   className="sport-types"
      //   unselectable="on"
      //   aria-disabled={this.state.disabled}
      // >
      //   {/* <div className="right-paddle paddle">
			// 		{
			// 			this.state.showLeftButton &&
			// 			<a className="left-paddle paddle" onClick={() => this.buttonScroll('left', 10, 100, 20)}>
			// 				<Icon type="left" />
			// 			</a>
			// 		}
			// 	</div> */}

      //   <Swipe
      //     ref={(ref) => (this.swipe = ref)}
      //     className="swipe"
      //     onSwipeStart={this.onSwipeStart}
      //     onSwipeMove={this.onSwipeMove}
      //     onSwipeEnd={this.onSwipeEnd}
      //     allowMouseEvents
      //   >
      //     {sportTypes &&
      //       sportTypes.map((item, index) => {
      //         return (
      //           <SportType
      //             {...this.props}
      //             key={`${item.sport.sportId},${item.feed.feedId}`}
      //             onClick={() => this.onSportTypeClick(item)}
      //             item={item}
      //             sportData={sportData}
      //           />
      //         );
      //       })}
      //   </Swipe>

      //   {/* <div className="right-paddle paddle">
			// 		{
			// 			this.state.showRightButton &&
			// 			<a onClick={() => this.buttonScroll('right', 10, 100, 20)}>
			// 				<Icon type="right" />
			// 			</a>
			// 		}
			// 	</div> */}
      // </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sportData: state.sportTypes.sportData,
    sportTypes: state.sportTypes.sportTypes,
    loadingSportTypes: state.sportTypes.loadingSportTypes,
    feedName: state.app.feedName,
    feedId: state.app.feedId,
    sportId: state.app.sportId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadSportTypes: (item) => {
      dispatch(loadSportTypes(item));
    },
    setSport: (item) => {
      dispatch(setSport(item));
    },
    init: () => {
      dispatch(init());
    },
    loadSportCategories: (data) => {
      dispatch(loadSportCategories(data));
    },
    removeDisabledSportTypes: () => {
      dispatch(removeDisabledSportTypes());
    },
    clearSportEvents: () => {
      dispatch(clearSportEvents());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SportTypes);

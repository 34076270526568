import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import app from './appReducer'
import signalr from './signalrReducer'
import home from './homeReducer'
import virtual from './virtualReducer'
import sport from './sportReducer'
import sportTypes from './sportTypesReducer'
import categories from './categoriesReducer'
import sportEvents from './sportEventsReducer'
import contactUs from './contactUsReducer';
import register from './registerReducer';
import member from './membersReducer';
import auth from './authReducer';
import general from './generalReducer';
import betSlip from './betSlipReducer';
import search from './searchReducer';
import slots from './slotsReducer';
import modal from './modalReducer';
import jackpot from './jackpotReducer';


const rootReducer = combineReducers({
    app: app,
    signalr: signalr,
    routing: routerReducer,
    home: home,
    virtual: virtual,
    sport: sport,
    sportTypes: sportTypes,
    categories: categories,
    sportEvents: sportEvents,
    contactUs: contactUs,
    register: register,
    member: member,
    auth: auth,
    general: general,
    betSlip: betSlip,
    search: search,
     jackpot:jackpot,
    slots: slots,
    modal: modal,
   

});

export default rootReducer;

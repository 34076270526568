import { Layout, Input } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import StickyBox from "react-sticky-box";
import {
  Bets,
  BetSlip,
  EventsSports,
  Images,
  MenuTree,
  Screen,
  ScreenTitle,
  SportTypes,
  Search,
  Banner,
} from "../../components";
import CategoriesMenu from "../../components/layout/CategoriesMenu";
import { init } from "../../redux/actions/appActions";
import {
  loadAllBetTypes,
  setEvent,
} from "../../redux/actions/sportEventsActions";

const { Sider, Content } = Layout;


class Sport extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <Screen {...this.props}>
        <SportTypes {...this.props} />
        <Layout className="sport mtop-10">
          <Layout className="layout-inner">
            <Content>
              <Banner sportType={this.props.match.params.sportName} />
              <CategoriesMenu />
              <Layout>
                <Sider width={220} className="sider-left">
                  <StickyBox offsetTop={140} offsetBottom={30}>
                    {/* <Search {...this.props} /> */}

                    <MenuTree {...this.props} />
                  </StickyBox>
                </Sider>

                <Content className="content">
                  {/* <ScreenTitle
                    {...this.props}
                    title={match.params.sportName || `Sports Betting`}
                    imageIcon={Images.Icon_Soccer}
                    imageTheme={Images.Strip_Soccer}
                  /> */}

                  <Route
                    exact
                    path="/sport/:feed/:sport/:sportName"
                    component={Bets.TopBets}
                  />
                  <Route
                    exact
                    path="/sport/:feed/:sport/:sportName/today/:day"
                    component={Bets.Today}
                  />
                  <Route
                    exact
                    path="/sport/:feed/:sport/:sportName/events/:section/:country/:countryName/:tournament/:tournamentName"
                    component={EventsSports}
                  />
                  <Route
                    exact
                    path="/sport/:feed/:sport/:sportName/event/:country/:tournament/:eventId"
                    component={Bets.DrillDown}
                  />
                </Content>
              </Layout>
            </Content>
            <Sider width={350} className="sider-right">
              <div style={{position:"sticky" , top:"130px"}}>
                <BetSlip {...this.props} />
              </div>
            </Sider>
          </Layout>
        </Layout>
      </Screen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    connecting: state.signalr.connecting,
    connected: state.signalr.connected,
    feedId: state.app.feedId,
    feedName: state.app.feedName,
    sportData: state.sportTypes.sportData,
    tournamentData: state.categories.tournamentData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadAllBetTypes: () => {
      dispatch(loadAllBetTypes());
    },
    setEvent: (item) => {
      dispatch(setEvent(item));
    },
    init: () => {
      dispatch(init());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sport);

import { HomeActionTypes } from '../actions';

const initState = {
    error: null,
    loading: false,
    data: []
};

const home = (state = initState, action) => {

    switch (action.type) {

        case HomeActionTypes.Load_Request:
            return { ...state }

        default:
            return state;

    }
}

export default home;
import React from "react";
import { connect } from "react-redux";
import { Images } from "../";
import { Enums } from "../../helpers";
import { changeFeed } from "../../redux/actions/appActions";
import { Carousel, Layout } from "antd";
const contentStyle = {
  height: "460px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const imgStyle = {
  width: "100%",
  borderRadius: "5px"
};
class Banner extends React.Component {

  render() {
    return (
      
      <div className="main-image" style={{margin:"0px  15px 0px 0px"}}>
        {console.log("this.props.sportType", this.props.sportType)}
        {this.props.sportType.toLowerCase() =='soccer'    &&     <div>
          <img  style={imgStyle} src={Images.SoccerBanner} />
        </div> }
        {this.props.sportType.toLowerCase().toLowerCase() == 'basketball'    &&     <div>
          <img style={imgStyle} src={Images.BasketballBanner} />
        </div> }
        {this.props.sportType.toLowerCase() == 'ice hockey'    &&     <div>
          <img style={imgStyle} src={Images.IcehockeyBanner} />
        </div> }
        {this.props.sportType.toLowerCase() == 'tennis'    &&     <div>
          <img style={imgStyle} src={Images.TennisBanner} />
        </div> }
        {this.props.sportType.toLowerCase() == 'rugby'    &&     <div>
          <img style={imgStyle} src={Images.RugbyBanner} />
        </div> }
        {this.props.sportType.toLowerCase() == 'aussie rules'    &&     <div>
          <img style={imgStyle} src={Images.AussieBanner} />
        </div> }
        {this.props.sportType.toLowerCase() == 'darts'    &&     <div>
          <img style={imgStyle} src={Images.DartBanner} />
        </div> }
        {this.props.sportType.toLowerCase() == 'mma'    &&     <div>
          <img style={imgStyle} src={Images.MMABanner} />
        </div> }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    feedId: state.app.feedId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeFeed: (feedId) => {
      dispatch(changeFeed(feedId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);

import React from "react";
import searchIcon from "../../resources/icons/search.svg";

class SecondarySearchBar extends React.Component {
  render() {
    return (
      <div className="sec-serach-continer">
        <input
          onChange={this.props.onChange}
          placeholder="Search"
          className="sec-search-bar"
        />
        <img className="sec-search-icon" src={searchIcon} />
      </div>
    );
  }
}

export default SecondarySearchBar;

import { AuthActionTypes } from '../actions';
import { Cookies } from '../../helpers/'

const initState = {
	signedIn: Cookies.get('token') ? true : false,
	message: null,
	forgotPasswordMessage: null,
	forgotPasswordSucces: null,
	changePasswordErrorMessage: null,
	changePasswordSuccessMessage: null,
	loading: false,
	userData: null,
	currentForgotPassword: 0,
	passwordChanged: false,
	showLoginPopConfirm: false,
	forgotPasswordDest: null,
}
const auth = (state = initState, action) => {
	switch (action.type) {
		case AuthActionTypes.Init: {
			return {
				...initState,
				signedIn: false
			}
		}
		case AuthActionTypes.Signin_Success:
		case AuthActionTypes.Register_Signin_Success:
			return {
				...state,
				loading: false,
				signedIn: true,
				message: null,
			}
		case AuthActionTypes.Signin_Request:
			return {
				...state,
				signedIn: false,
				loading: true,
				message: null,
			}
		case AuthActionTypes.Signin_Error:
			return {
				...state,
				message: action.message,
				signedIn: false,
				loading: false
			}
		case AuthActionTypes.Signout_Request:
			return {
				...state,
				signedIn: false,
				message: null,
			}
		case AuthActionTypes.Signout_Success:
			return {
				...state, signedIn: false
			}
		case AuthActionTypes.Signout_Error:
			return {
				...state, message: action.message,
				signedIn: false
			}

		case AuthActionTypes.Forgot_Password_Check_Dest_Request:
			return {
				...state,
				forgotPasswordMessage: null,
				loading: true,
				forgotPasswordDest: null
			}
		case AuthActionTypes.Forgot_Password_Check_Dest_Suceess:
			return {
				...state,
				loading: false,
				forgotPasswordMessage: null,
				forgotPasswordSucces: action.message,
				forgotPasswordDest: action.data,
			}
		case AuthActionTypes.Forgot_Password_Check_Dest_Error:
			return {
				...state,
				loading: false,
				forgotPasswordMessage: action.message,
				forgotPasswordDest: null
			}

		case AuthActionTypes.Forgot_Password_Request:
			return {
				...state,
				forgotPasswordMessage: null,
				loading: true,
			}
		case AuthActionTypes.Forgot_Password_Suceess:
			return {
				...state,
				loading: false,
				forgotPasswordMessage: null,
				forgotPasswordSucces: action.message,
				userData: action.data,
			}
		case AuthActionTypes.Forgot_Password_Error:
			return {
				...state,
				loading: false,
				forgotPasswordMessage: action.message
			}
		case AuthActionTypes.Forgot_Password_Confirm_Request:
			return {
				...state,
				loading: true
			}
		case AuthActionTypes.Forgot_Password_Confirm_Suceess:
			return {
				...state,
				loading: false,
				forgotPasswordMessage: null,
				currentForgotPassword: action.current
			}
		case AuthActionTypes.Forgot_Password_First_Step_Confirm_Suceess:
			return {
				...state,
				loading: false,
				forgotPasswordMessage: null,
				currentForgotPassword: initState.currentForgotPassword + 1
			}
		case AuthActionTypes.Forgot_Password_Confirm_Error:
			return {
				...state,
				loading: false,
				forgotPasswordMessage: action.message
			}
		case AuthActionTypes.Change_Password_Request:
			return {
				...state,
				loading: true
			}
		case AuthActionTypes.Update_Password_Suceess:
			return {
				...state,
				loading: false,
				forgotPasswordMessage: null,
				passwordChanged: true

			}
		case AuthActionTypes.Update_Password_Error:
			return {
				...state,
				loading: false,
				forgotPasswordMessage: action.message
			}
		case AuthActionTypes.Change_Password_Suceess:
			return {
				...state,
				loading: false,
				changePasswordSuccessMessage: action.message,
				changePasswordErrorMessage: null
			}
		case AuthActionTypes.Change_Password_Error:
			return {
				...state,
				loading: false,
				changePasswordErrorMessage: action.message,
				changePasswordSuccessMessage: null
			}
		case AuthActionTypes.Set_Show_Login_Pop_Confirm:
			return {
				...state,
				showLoginPopConfirm: action.value,
				loading: false
			}
		default:
			return state
	}
}

export default auth;
import { SignalRTypes, SportActionTypes } from '../actions';

const initState = {
	error: null,
	match: null,
	joinedGroups: []
};

const sport = (state = initState, action) => {

	switch (action.type) {

		case SportActionTypes.Connect:
			return {
				...state,
				error: null,
				match: null,
				joinedGroups: []

			}

		case SportActionTypes.Sport_AllEmpty:
			return {
				...state,
				error: null,
				match: null,
				joinedGroups: []

			}


		case SignalRTypes.Set_Join_Groups:
			return {
				...state,
				joinedGroups: action.joinedGroups,
			}

		default:
			return { ...state };

	}
}

export default sport;
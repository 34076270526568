const LogoWinners = require("../../resources/images/logo.svg");

const SoccerBanner = require("../../resources/images/soc2.png");
const BasketballBanner = require("../../resources/images/Basketballbanner.png");
const IcehockeyBanner = require("../../resources/images/Ice hokey.png");
const TennisBanner = require("../../resources/images/Tennis.png");
const AussieBanner = require("../../resources/images/Aussie Rules.png");
const DartBanner = require("../../resources/images/Dart.png");
const RugbyBanner = require("../../resources/images/Rugby.png");
const MMABanner = require("../../resources/images/MMA.png");

const Rugby = require("../../resources/images/menurugby.png");
const Dart = require("../../resources/images/menudart.png");
const Aussie = require("../../resources/images/menuaussierules.png");
const MMA = require("../../resources/images/menumma.png");
const Soccer = require("../../resources/images/menusoccer.png");
const IceHockey = require("../../resources/images/menuicehokey.png");
const MenuBasketball = require("../../resources/images/menubasketball.png");
const MenuMMA = require("../../resources/images/menumma.png");
const MenuTennis = require("../../resources/images/menutennis.png");
const MenuCricket = require("../../resources/images/CricketMenu.png");

const MyAccountBetsIcon = require("../../resources/images/my-account-bets.svg");
const MyAccountDepositeIcon = require("../../resources/images/my-account-deposite.svg");
const MyAccountWithdrawalIcon = require("../../resources/images/my-account-withdrawal.svg");
const MyAccountChangePasswordIcon = require("../../resources/images/my-account-change-password.svg");
const MyAccountTransactionsIcon = require("../../resources/images/my-account-transactions.svg");
const MyAccountPersonalInfoIcon = require("../../resources/images/my-account-personal-info.svg");
const MyAccountAddProviderIcon = require("../../resources/images/my-account-add-provider.svg");

const LogoWinnersHand = require("../../resources/images/hand.webp");
const DownArrow = require("../../resources/images/chevron-down.svg");
const PlusEighteenDarkMode = require("../../resources/images/+18 - dark.svg");
const BinIcon = require("../../resources/images/delete.svg");
const RedBinIcon = require("../../resources/images/red-trash.svg");

const DarkMoon = require("../../resources/images/dark-moon.svg");


const CloseIcon = require("../../resources/images/close-fill.svg");


const HidePasswordIcon = require("../../resources/images/eye-fill.svg");
const ShowPasswordIcon = require("../../resources/images/eye-fill-white.svg");



const Icon_Home = require("../../resources/icons/home.webp");
const Icon_Favorites = require("../../resources/icons/favorites.webp");
const Icon_Fixture = require("../../resources/icons/fixture.webp");
const Icon_Live = require("../../resources/icons/live.webp");
const Icon_Slots = require("../../resources/icons/slot-machine.webp");
const Icon_Virtual = require("../../resources/icons/virtual.webp");
const Icon_BarChart = require("../../resources/icons/bar-chart-reload.webp");

const LiveBeting = require("../../resources/images/live-betting.webp");
const SportBeting = require("../../resources/images/sport-betting.webp");
const VirtualBetting = require("../../resources/images/virtual-betting.webp");
const Ball1 = require("../../resources/images/ball1.webp");
const Ball2 = require("../../resources/images/ball2.webp");
const LT = require("../../resources/images/lt.webp");

const TopBets1 = require("../../resources/top-bets/studiom1/1.webp");
const TopBets2 = require("../../resources/top-bets/studiom1/2.webp");
const TopBets3 = require("../../resources/top-bets/studiom1/3.webp");

const Strip_Soccer = require("../../resources/images/strip_soccer.webp");
const Strip_Mask = require("../../resources/images/strip_mask.webp");

//const Banner = require('../../resources/images/top-banner-img.webp')
const Banner = require("../../resources/images/top-banner-new.webp");
const Add = require("../../resources/images/add.webp");

const CircleCheck = require("../../resources/icons/checkbox-marked-circle.webp");

const MTN = require("../../resources/deposit/mtn.webp");
const Orange = require("../../resources/deposit/orange.webp");
const TipMe = require("../../resources/deposit/tip-me.webp");
const Voucher = require("../../resources/deposit/voucher.webp");



export default {
  LogoWinners,
  LogoWinnersHand,
  Icon_Home,
  Icon_Favorites,
  Icon_Fixture,
  Icon_Live,
  Icon_Virtual,
  Icon_BarChart,
  Icon_Slots,
  SoccerBanner,
  BasketballBanner,
  IcehockeyBanner,
  TennisBanner,
  AussieBanner,
  DartBanner,
  RugbyBanner,
  MMABanner,
  Strip_Soccer,
  Strip_Mask,

  LiveBeting,
  SportBeting,
  VirtualBetting,
  Ball1,
  Ball2,
  LT,
  DarkMoon,
  CloseIcon,
  HidePasswordIcon,
  ShowPasswordIcon,

  Rugby,
  Dart,
  Aussie,
  MMA,
  Soccer,
  IceHockey,

  MyAccountBetsIcon,
  MyAccountDepositeIcon,
  MyAccountWithdrawalIcon,
  MyAccountChangePasswordIcon,
  MyAccountTransactionsIcon,
  MyAccountPersonalInfoIcon,
  MyAccountAddProviderIcon,

  TopBets1,
  TopBets2,
  TopBets3,

  Banner,
  Add,

  CircleCheck,
  DownArrow,
  PlusEighteenDarkMode,
  BinIcon,
  RedBinIcon,
  MTN,
  Voucher,
  TipMe,
  Orange,

  MenuBasketball,
  MenuMMA,
  MenuTennis,
  MenuCricket,
};

import { Collapse, Select, Tabs, Icon } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Bets } from "../../../";
import { Enums, CountryCodes } from "../../../../helpers";
import { setPropsMath } from "../../../../redux/actions/appActions";
import {
  loadSportEventsByDate,
  setEvent,
} from "../../../../redux/actions/sportEventsActions";
import EventRow from "../EventRow";

const Option = Select.Option;
const TabPane = Tabs.TabPane;
const Panel = Collapse.Panel;

class Today extends React.Component {
  state = {
    sort: "league",
    collapseAll: false,
  };

  componentDidMount() {
    const { match, setPropsMath, connected } = this.props;
    setPropsMath(match);
    const isLastMin = match.params.day == "-1" ? true : false;
    if (connected) {
      this.onTodayClick(match.params.day, isLastMin);
    }
  }

  componentDidUpdate(prevProps) {
    const { match, setPropsMath, connected } = this.props;
    setPropsMath(match);
    if (
      prevProps.match.url !== this.props.match.url ||
      (!prevProps.connected && connected)
    ) {
      const isLastMin = match.params.day == "-1" ? true : false;
      this.onTodayClick(match.params.day, isLastMin);
    }
  }

  onTodayClick = (day = "0", isLastMin = false) => {
    const { history, loadSportEventsByDate, match } = this.props;
    history.push(`${day}`);
    const request = {
      Data: {
        Feed: { FeedId: Number(match.params.feed) },
        Sport: { SportId: Number(match.params.sport) },
        Day: Number(day),
        IsLastMin: isLastMin,
      },
    };
    loadSportEventsByDate(request);
  };

  onTabChange(key) {
    const isLastMin = key == "-1" ? true : false;
    this.onTodayClick(key, isLastMin);
    this.setState({ collapseAll: false, leaguesIds: undefined });
  }

  getTabName(today, i) {
    let dayNumber = today + i + 1;
    dayNumber =
      dayNumber >= Enums.Days.length
        ? dayNumber - Enums.Days.length
        : dayNumber;
    return Enums.Days[dayNumber];
  }

  getCountryCode = (name) => {
    let result = CountryCodes.Codes.filter((item) => {
      return item.name.toLowerCase() == name.toLowerCase();
    });

    return result && result.length > 0 ? result[0].code.toLowerCase() : "eu";
  };

  createGroups(list) {
    const { sort } = this.state;

    let currentLeague = "";
    let currentCountry = "";
    let children = [];
    let groups = [];
    let leaguesIds = [];

    list.map((item, index) => {
      if (!currentLeague) {
        currentLeague = `${item.eventCategory.categoryDisplayName}/${item.eventTournament.tournamentDisplayName}`;
        currentCountry = item.eventCategory.categoryDisplayName;
      }

      if (
        currentLeague ===
        `${item.eventCategory.categoryDisplayName}/${item.eventTournament.tournamentDisplayName}`
      ) {
        children.push(
          <EventRow
            hideLeague={sort == "league"}
            {...this.props}
            key={item.key}
            id={item.key}
          ></EventRow>
        );

        if (list.length === 1) {
          // if one item only, create group
          groups.push(
            <Panel key={`${currentLeague}_${index}`} header={currentLeague}>
              {children.map((item) => item)}
            </Panel>
          );
          leaguesIds.push(`${currentLeague}_${index}`);
        } else {
          if (index === list.length - 1 && list.length !== 1) {
            //if last item, create group
            groups.push(
              <Panel
                key={`${currentLeague}_${index}`}
                header={
                  <div className="league">
                    <span
                      className={
                        "flag-icon flag-icon-" +
                        this.getCountryCode(currentCountry || "")
                      }
                    ></span>{" "}
                    &nbsp;
                    {currentLeague}
                  </div>
                }
              >
                {children.map((item) => item)}
              </Panel>
            );
            leaguesIds.push(`${currentLeague}_${index}`);
          }
        }
      } else {
        //create collapse
        groups.push(
          <Panel
            key={`${currentLeague}_${index}`}
            header={
              <div className="league">
                <span
                  className={
                    "flag-icon flag-icon-" +
                    this.getCountryCode(currentCountry || "")
                  }
                ></span>{" "}
                &nbsp;
                {currentLeague}
              </div>
            }
          >
            {children.map((item) => item)}
          </Panel>
        );
        leaguesIds.push(`${currentLeague}_${index}`);

        //change current league
        currentLeague = `${item.eventCategory.categoryDisplayName}/${item.eventTournament.tournamentDisplayName}`;
        currentCountry = item.eventCategory.categoryDisplayName;
        children.length = 0;

        children.push(
          <EventRow
            hideLeague={sort == "league"}
            {...this.props}
            key={item.key}
            id={item.key}
          ></EventRow>
        );

        if (index === list.length - 1 && list.length !== 1) {
          //if last item, create group
          groups.push(
            <Panel
              key={`${currentLeague}_${index}`}
              header={
                <div className="league">
                  <span
                    className={
                      "flag-icon flag-icon-" +
                      this.getCountryCode(currentCountry || "")
                    }
                  ></span>{" "}
                  &nbsp;
                  {currentLeague}
                </div>
              }
            >
              {children.map((item) => item)}
            </Panel>
          );

          leaguesIds.push(`${currentLeague}_${index}`);
        }
      }
    });

    return { groups, leaguesIds };
  }

  handleSortChange(value) {
    this.setState({ sort: value });
  }

  onCollapseChange(leaguesIds) {
    this.setState({ leaguesIds, collapseAll: false });
  }
  toggleCollapse() {
    const { collapseAll } = this.state;
    collapseAll
      ? this.setState({ collapseAll: false, leaguesIds: undefined })
      : this.setState({ collapseAll: true, leaguesIds: [] });
  }

  render() {
    const { match, error, sportEvents, loadingSportEvents } = this.props;
    const { sort } = this.state;
    const today = new Date().getDay();
    let result;

    if (loadingSportEvents) {
      return (
        <div className="events">
          <div className="skeleton">
            <div className="row">
              <div className="loader"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
            </div>

            <div className="row">
              <div className="loader"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
            </div>

            <div className="row">
              <div className="loader"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
            </div>

            <div className="row">
              <div className="loader"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
            </div>
          </div>
        </div>
      );
    }

    if (sort === "time") {
      let events = Object.values(sportEvents).sort((a, b) => {
        return (
          moment.utc(a.eventDateOfMatch).valueOf() -
          moment.utc(b.eventDateOfMatch).valueOf()
        );
      });
      result = this.createGroups(events);
    } else {
      let events = Object.values(sportEvents).sort((a, b) => {
        return (
          a.eventCategory.categoryDisplayOrder -
          b.eventCategory.categoryDisplayOrder
        );
      });
      result = this.createGroups(events);
    }

    return (
      <div className="today-bets">
        <div className="events">
          <Tabs
            className="tabs-events"
            activeKey={match.params.day}
            onChange={(key) => this.onTabChange(key)}
          >
            <TabPane tab="Last minute bets" key="-1"></TabPane>
            <TabPane tab="TODAY" key="0"></TabPane>

            {[...Array(4)].map((item, i) => (
              <TabPane
                tab={this.getTabName(today, i)}
                key={`${i + 1}`}
              ></TabPane>
            ))}
          </Tabs>

          {error && <div>{error}</div>}

          {!error && sportEvents && Object.keys(sportEvents).length > 0 && (
            <Bets.EventsHeader
              {...this.props}
              title={
                <Title
                  handleSortChange={(value) => this.handleSortChange(value)}
                  toggleCollapse={() => this.toggleCollapse()}
                  collapseAll={this.state.collapseAll}
                />
              }
            />
          )}

          {!error && sportEvents && Object.keys(sportEvents).length > 0 && (
            <Collapse
              className="collaps"
              activeKey={this.state.leaguesIds || result.leaguesIds}
              onChange={(key) => this.onCollapseChange(key)}
            >
              {Object.values(result.groups)}
            </Collapse>
          )}
        </div>
      </div>
    );
  }
}

const Title = (props) => {
  const { handleSortChange, toggleCollapse, collapseAll } = props;

  return (
    <div className="sort">
      <div className="title" onClick={() => toggleCollapse()}>
        {collapseAll ? <Icon type="right" /> : <Icon type="down" />}

        {`  Today Matches`}
      </div>
      <div className="sort-by">
        <div className="text">Sort By:</div>
        <Select
          className="selectlist"
          defaultValue="league"
          onChange={(value) => handleSortChange(value)}
        >
          <Option value="time">Start Time</Option>
          <Option value="league">Leagues</Option>
        </Select>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.sportEvents.error,
    sportEvents: state.sportEvents.sportEvents,
    loadingSportEvents: state.sportEvents.loadingSportEvents,
    connected: state.signalr.connected,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadSportEventsByDate: (request) => {
      dispatch(loadSportEventsByDate(request));
    },
    setPropsMath: (match) => {
      dispatch(setPropsMath(match));
    },
    setEvent: (item) => {
      dispatch(setEvent(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Today);

import React from 'react';
import { connect } from 'react-redux';
import { Layout, Form, Input, Icon, Select, Upload, Button, message } from 'antd';
import { Screen, ScreenTitle, Alert } from "../../components";
import { getSubjects, SendContactData } from '../../redux/actions/contactUsActions';
import { getMapData } from '../../redux/actions/generalActions';
import Map from '../../components/common/map';
import ReCAPTCHA from "react-google-recaptcha";
import { reCaptchaApiKey } from '../../ApiKeys.js';
const { Sider, Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

class ContactUs extends React.Component {
	initalState = {
		displayFile: false,
		chaptcha: '',
		disableUpload: false,
		fileList: [],
		selectedFile: null,
		fileContent: ''
	};
	constructor(props) {
		super(props);
		this.state = this.initalState;
		this.captcha = React.createRef();
	}

	componentDidMount() {
		this.props.getSubjects();
		this.props.getMapData({ id: 1 })
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.resetFields !== this.props.resetFields && this.props.resetFields) {
			//reset values
			this.props.form.setFieldsValue({
				subject: '',
				firstName: '',
				lastName: '',
				email: '',
				confirm: '',
				message: '',
			});
			this.setState(this.initalState)
		}
	}
	compareEmail = (rule, value, callback) => {
		const form = this.props.form;
		if (form.getFieldValue('confirm') && form.getFieldValue('email') && form.getFieldValue('email') !== form.getFieldValue('confirm')) {
			callback('The two E-mails that you entered are inconsistent!');
		} else {
			form.setFields({
				email: {
					value: form.getFieldValue('email'),
					errors: undefined
				},
				confirm: {
					value: form.getFieldValue('confirm'),
					errors: undefined
				}
			})
			callback();
		}
	}

	handleFileUpload = (subjectId) => {
		// if contact us
		if (subjectId == 6) {
			this.setState({ displayFile: true });
		}
		else {
			this.setState({ displayFile: false });
		}
	}

	handleFileRemove = () => {
		this.setState({ disableUpload: false })
	}

	handleFileChange = (info) => {
		if (info.file) {
			info.file.response = info.file.status
			if (info.file.originFileObj) {
				this.setState({
					disableUpload: true
				})
				let reader = new FileReader();
				reader.addEventListener('load', () => {
					this.setState({
						fileContent: reader.result
					})
				});
				reader.readAsDataURL(info.file.originFileObj);
			}


			let extention = info.file.name.split('.')
			let types = /(csv|txt|pdf|doc|docx)/;
			let fileList = info.fileList
			if (info.file.status == 'removed' && fileList.length === 0) {
				this.setState({ disableUpload: false, fileList: [] })
				window.log(1)
			}
			if (info.file.status == 'error') {
				this.setState({ fileList: [{ ...fileList['0'], response: info.file.name }] })
			}
			else if (types.test(extention[extention.length - 1]) && fileList.length !== 0) {
				this.setState({ disableUpload: true, fileList: fileList })
				window.log(2)
			}
			else {
				this.setState({ disableUpload: false, fileList: [] })
				message.error('Invalid file type! only csv, txt, pdf, doc & docx are supported')
				window.log(3)
			}
		}
	}
	handleOnSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const fields = ['subject', 'firstName', 'lastName', 'email', 'message', 'upload'];
		this.props.form.validateFields(fields, (err, values) => {
			if (!err && this.state.captcha) {
				window.log('Received values of form: ', values, " captcha: ", this.state.captcha);
				let data = {}
				if (values.upload) {
					let extension = values.upload.file.name.split('.');
					let fileExtension = extension[extension.length - 1]
					let attachmentFileName = values.upload.file.name;
					data = {
						subject: {
							subjectId: values.subject
						},
						contactFirstName: values.firstName,
						contactLastName: values.lastName,
						contactEmail: values.email,
						contactMessage: values.message,
						file: this.state.fileContent,
						recaptchaResponse: this.state.captcha,
						fileExtension: fileExtension,
						attachmentFileName: attachmentFileName
					}
				}
				else {
					data = {
						subject: {
							subjectId: values.subject
						},
						contactFirstName: values.firstName,
						contactLastName: values.lastName,
						contactEmail: values.email,
						contactMessage: values.message,
						recaptchaResponse: this.state.captcha,
					}
				}
				// data object to post

				this.props.SendContactData(data);
			}
		});
		this.captcha.current.props.grecaptcha.reset()
	}

	onChangeCaptcha = (value) => {
		this.setState({ captcha: value })
	}

	render() {
		const { getFieldDecorator, } = this.props.form;
		const { text, message, resetFields, loading  } = this.props;
		const formItemLayout = null;
		const fileUpload = (
			<Form.Item  {...formItemLayout} label="Upload">
				{getFieldDecorator('upload', {
					rules: [{ required: this.state.displayFile, message: "please upload file!" }],
					valuePropName: 'file'
				})(
					<Upload onPreview={(file) => { file.response = file.url }} fileList={this.state.fileList} onChange={this.handleFileChange} onRemove={this.handleFileRemove} name="cv" beforeUpload={this.beforeUpload}>
						<Button className="upload" disabled={this.state.disableUpload}> <Icon type="upload" /> Click to upload </Button>
					</Upload>

				)}
			</Form.Item>
		)

		return (
			<Screen {...this.props} className="contact">
				<Layout className="mtop-20">
					<Layout className="layout-inner">
						<Sider width={220} className="sider-left">
						</Sider>
						<div className="content">
							<div id='contact-us-content'>
							<ScreenTitle hideIcon title="Contact Us"/>
							<div style={{padding:"0 6vw 0 6vw"}}>
							<div className='document-pages-header'>
								Contact Us
							</div>
							</div>
							<div>
							{
										this.props.map &&
										<Map
											id="myMap"
											options={{
												center: {
													lat: this.props.map[0].markerLat,
													lng: this.props.map[0].markerLon
												},
												zoom: 15,
												disableDefaultUI: true,
												styles: [
													{
														"elementType": "geometry",
														"stylers": [
															{
																"color": "#1d2c4d"
															}
														]
													},
													{
														"elementType": "labels.text.fill",
														"stylers": [
															{
																"color": "#8ec3b9"
															}
														]
													},
													{
														"elementType": "labels.text.stroke",
														"stylers": [
															{
																"color": "#1a3646"
															}
														]
													},
													{
														"featureType": "administrative",
														"elementType": "geometry",
														"stylers": [
															{
																"visibility": "off"
															}
														]
													},
													{
														"featureType": "administrative.country",
														"elementType": "geometry.stroke",
														"stylers": [
															{
																"color": "#4b6878"
															}
														]
													},
													{
														"featureType": "administrative.land_parcel",
														"elementType": "labels.text.fill",
														"stylers": [
															{
																"color": "#64779e"
															}
														]
													},
													{
														"featureType": "administrative.province",
														"elementType": "geometry.stroke",
														"stylers": [
															{
																"color": "#4b6878"
															}
														]
													},
													{
														"featureType": "landscape.man_made",
														"elementType": "geometry.stroke",
														"stylers": [
															{
																"color": "#334e87"
															}
														]
													},
													{
														"featureType": "landscape.natural",
														"elementType": "geometry",
														"stylers": [
															{
																"color": "#023e58"
															}
														]
													},
													{
														"featureType": "poi",
														"stylers": [
															{
																"visibility": "off"
															}
														]
													},
													{
														"featureType": "poi",
														"elementType": "geometry",
														"stylers": [
															{
																"color": "#283d6a"
															}
														]
													},
													{
														"featureType": "poi",
														"elementType": "labels.text.fill",
														"stylers": [
															{
																"color": "#6f9ba5"
															}
														]
													},
													{
														"featureType": "poi",
														"elementType": "labels.text.stroke",
														"stylers": [
															{
																"color": "#1d2c4d"
															}
														]
													},
													{
														"featureType": "poi.park",
														"elementType": "geometry.fill",
														"stylers": [
															{
																"color": "#023e58"
															}
														]
													},
													{
														"featureType": "poi.park",
														"elementType": "labels.text.fill",
														"stylers": [
															{
																"color": "#3C7680"
															}
														]
													},
													{
														"featureType": "road",
														"elementType": "geometry",
														"stylers": [
															{
																"color": "#304a7d"
															}
														]
													},
													{
														"featureType": "road",
														"elementType": "labels.icon",
														"stylers": [
															{
																"visibility": "off"
															}
														]
													},
													{
														"featureType": "road",
														"elementType": "labels.text.fill",
														"stylers": [
															{
																"color": "#98a5be"
															}
														]
													},
													{
														"featureType": "road",
														"elementType": "labels.text.stroke",
														"stylers": [
															{
																"color": "#1d2c4d"
															}
														]
													},
													{
														"featureType": "road.highway",
														"elementType": "geometry",
														"stylers": [
															{
																"color": "#2c6675"
															}
														]
													},
													{
														"featureType": "road.highway",
														"elementType": "geometry.stroke",
														"stylers": [
															{
																"color": "#255763"
															}
														]
													},
													{
														"featureType": "road.highway",
														"elementType": "labels.text.fill",
														"stylers": [
															{
																"color": "#b0d5ce"
															}
														]
													},
													{
														"featureType": "road.highway",
														"elementType": "labels.text.stroke",
														"stylers": [
															{
																"color": "#023e58"
															}
														]
													},
													{
														"featureType": "transit",
														"stylers": [
															{
																"visibility": "off"
															}
														]
													},
													{
														"featureType": "transit",
														"elementType": "labels.text.fill",
														"stylers": [
															{
																"color": "#98a5be"
															}
														]
													},
													{
														"featureType": "transit",
														"elementType": "labels.text.stroke",
														"stylers": [
															{
																"color": "#1d2c4d"
															}
														]
													},
													{
														"featureType": "transit.line",
														"elementType": "geometry.fill",
														"stylers": [
															{
																"color": "#283d6a"
															}
														]
													},
													{
														"featureType": "transit.station",
														"elementType": "geometry",
														"stylers": [
															{
																"color": "#3a4762"
															}
														]
													},
													{
														"featureType": "water",
														"elementType": "geometry",
														"stylers": [
															{
																"color": "#0e1626"
															}
														]
													},
													{
														"featureType": "water",
														"elementType": "labels.text.fill",
														"stylers": [
															{
																"color": "#4e6d70"
															}
														]
													}
												]
											}}
											onMapLoad={map => {
												var marker = new window.google.maps.Marker({
													position: {
														lat: this.props.map[0].markerLat,
														lng: this.props.map[0].markerLon
													},
													map: map
												});
											}}
										/>
									}
							</div>
							<div className="container">
								<div className="left">
									<Sider width="100%" style={{padding: " 0 6vw 0 6vw" , backgroundColor:"transparent"}}>
										<div className="title">Get in Touch</div>

										<Form
											layout={'vertical'}
											className="form"
											onSubmit={this.handleOnSubmit}>
											<Form.Item
												{...formItemLayout}
												label="First Name">
												{
													getFieldDecorator('firstName', {
														rules: [{
															required: true,
															message: 'Please fiil your first name',
														}],
													})(
														<Input placeholder='Enter your First Name..' />
													)
												}
											</Form.Item>
											<Form.Item
												{...formItemLayout}
												label="Last Name" >
												{
													getFieldDecorator('lastName', {
														rules: [{
															required: true,
															message: 'Please fill your last name',
														}],
													})(
														<Input placeholder='Enter your Last Name..' />
													)
												}
											</Form.Item>
											<Form.Item
												{...formItemLayout}
												label="Email Address">
												{
													getFieldDecorator('email', {
														rules: [{
															type: 'email', message: 'The input is not valid E-mail!',
														}, {
															required: true, message: 'Please input your E-mail!',
														}, {
															validator: this.compareEmail
														}],
													})(
														<Input placeholder='Enter your E-mail..' />
													)
												}
											</Form.Item>
											<Form.Item
												{...formItemLayout}
												label="Email Address Confirm">
												{
													getFieldDecorator('confirm', {
														rules: [{
															required: true, message: 'Please confirm your E-mail!',
														}, {
															validator: this.compareEmail,
														}],
													})(
														<Input type="email" onBlur={this.handleConfirmBlur} placeholder='Confirm your E-mail..' />
													)
												}
											</Form.Item>
											<Form.Item
												{...formItemLayout}
												label="Subject" >
												{
													getFieldDecorator('subject', {
														rules: [{ required: true, message: 'Please select a subject!' }]
													})(
														<Select onChange={this.handleFileUpload} placeholder="Please select a subject">
															{this.props.subjects ? this.props.subjects.map((subject) => <Option value={subject.subjectId} key={subject.subjectName} > {subject.subjectName} </Option>) : ''}
														</Select>
													)
												}
											</Form.Item>

											{this.state.displayFile ? fileUpload : ''}

											<Form.Item
												className="message"
												{...formItemLayout} label="Message">
												{
													getFieldDecorator('message', { rules: [{ required: true }] })
														(<TextArea placeholder='Write your message here...' />)
												}
											</Form.Item>

											<ReCAPTCHA
												ref={this.captcha}
												size="normal"
												className="captcha"
												sitekey={reCaptchaApiKey}
												onChange={this.onChangeCaptcha}
												onExpired={() => { this.captcha.reset() }} />)


											<Button loading={loading} type="primary" htmlType="submit">Submit</Button>
										</Form>

										{resetFields && message && 
											<Alert style={{ color: '#fff', fortSize: 15, margin: '10px 0' }} message={message.message} type={message.status === 1 ? 'success' : 'error'} ></Alert>}
									</Sider>
								</div>

								<div className="right">

									<Sider width="100%" className="right">
										{
											text && text.pageText &&
											<div className="text" dangerouslySetInnerHTML={{ __html: text.pageText }}></div>
										}
										{console.log("text.pageText" , text)}
									</Sider>
								</div>
							</div>
							</div>

						</div>

						<Sider width={350} className="sider-right">
						</Sider>
					</Layout>
				</Layout>

			</Screen>
		)
	}
}

const MapStateToProps = (state, props) => {
	return {
		subjects: state.contactUs.subjects,
		message: state.contactUs.message,
		text: state.contactUs.text,
		map: state.general.map,
		resetFields: state.contactUs.resetFields,
		loading: state.contactUs.loading
	};
}

const mapDispatchToProps = (dispatch) => (
	{
		getSubjects: () => dispatch(getSubjects()),
		SendContactData: (data) => dispatch(SendContactData(data)),
		getMapData: (data) => dispatch(getMapData(data))
	}
);

const wrappedContactUs = Form.create({ name: 'contact_us' })(ContactUs);
export default connect(MapStateToProps, mapDispatchToProps)(wrappedContactUs);
import React from 'react';
import { Link } from 'react-router-dom';

const Step5 = (props) => {

	return (
		<div className="step">

			<div className="strip">
				<div className="content">
					<div className="text">
						<h1>Successfully Registered!</h1>
						<h3 onClick={() => props.init()}>Go to <Link className="text" to="/account/bets">My Account</Link> </h3>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Step5
import { Button, Drawer, Input, Tabs, Icon } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Alert } from "../..";
import { openMobileTableDetails } from "../../redux/actions/membersActions";

const TabPane = Tabs.TabPane;

class MobileTableDrawer extends React.Component {
  state = {
    codeNumber: null,
    image: require("../../resources/sport-icons/71.webp"),
  };

  componentDidMount() {}

  statusIcon = (status) => {
    let type = "";
    if (status === true) {
      type = "check-circle";
    } else if (status === false) {
      type = "close-circle";
    } else {
      type = "info-circle";
    }
    return <Icon type={type}></Icon>;
  };

  statusColor = (status) => {
    if (status > 0) {
      return "#00800050";
    } else if (status < 0) {
      return "#ff000050";
    } else {
      return "lightGray";
    }
  };

  renderTab() {
    const { betLines } = this.props;
    return (
      <div className="mobile-table-details">
        <Tabs>
          <TabPane
            className="tab-table"
            tab={`Form Number. ${betLines.formNumber}`}
            key="1"
          >
            {this.props.formDetails.length > 0 &&
              this.props.formDetails.map((form, i) => (
                <div className="events" key={i}>
                  <div className="event">
                    <div className="row">
                      <div className="cell">
                        <img
                          alt="game"
                          className={"icon"}
                          src={this.state.image}
                        />
                      </div>
                      <div className="cell">
                        <div className="text">{form.match}</div>
                      </div>
                      <div className="cell">
                        <div
                          className="status"
                          style={{ background: this.statusColor(form.status) }}
                        >
                          {this.statusIcon(form.status)}
                          <div />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="cell"></div>
                      <div className="cell">
                        <div className="text">{form.note}</div>
                      </div>
                      <div className="cell">
                        {form.status === false && (
                          <div
                            className="text"
                            style={{ color: "red", minWidth: 70 }}
                          >
                            Correct Mark - {form.correctMark}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="cell"></div>
                      <div className="cell">
                        <div className="header">{form.mark}</div>
                      </div>

                      <div className="cell">
                        <div className="header">{form.odd}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div className="bet-lines">
              <div className="row">
                <div className="text">Form Date:</div>

                <div className="winnings-odds">
                  {moment(betLines.formDateTime).format("DD/MM/YYYY HH:mm:ss")}
                </div>
              </div>
              <div className="row">
                <div className="text">Total Odds:</div>
                <div className="winnings-odds">
                  {betLines.totalWinningsOdds.format(2)}
                </div>
              </div>
              <div className="row">
                <div className="text">Possible Winnings:</div>
                <div className="possible-winnings">{`${
                  betLines.formCurrency.currencySymbol
                } ${betLines.possibleWinnings.format(0)}`}</div>
              </div>
              <div className="row">
                <div
                  className="status"
                  style={{
                    background: this.statusColor(betLines.formStatusId),
                  }}
                >
                  {betLines.message}
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }

  render() {
    const { openMobileTableDetails, openTableDetails } = this.props;
    return (
      <div>
        <Drawer
          width={230}
          className="mobile mobile-bet-slip"
          placement="right"
          closable={true}
          destroyOnClose
          onClose={() => openMobileTableDetails(false)}
          visible={openTableDetails}
        >
          {this.renderTab()}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    openTableDetails: state.member.openMobileTableDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    openMobileTableDetails: (open) => dispatch(openMobileTableDetails(open)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileTableDrawer);

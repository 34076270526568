import { VirtualActionTypes } from '../actions';

const initState = {
    error: null,
    loading: false,
    data: []
};

const virtual = (state = initState, action) => {

    switch (action.type) {

        case VirtualActionTypes.Load_Request:
			return { ...state }
			
        default:
            return state;

    }
}

export default virtual;
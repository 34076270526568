import { all, fork, takeEvery, takeLatest, put, select } from "redux-saga/effects";
import { membersApi, registerApi } from '../../api/index';
import { MembersActionTypes, BetSlipActionTypes, SignalRTypes, AuthActionTypes } from '../actions';
import { Cookies, Utils, Enums, SessionStorage } from '../../helpers';

export function* requests() {
    yield takeEvery(MembersActionTypes.Set_Selected_Currency, function* ({ currency }) {
        //call server
        try {
            const { general } = yield select();
            Cookies.set('selectedCurrency', currency);

            let betSlipAmount = (general.betSlipConstants && general.betSlipConstants.betSlipAmountLD && general.betSlipConstants.betSlipAmountUSD) ?
                ((currency.currencyId === Enums.Currency.LiberianDollar) ?
                    general.betSlipConstants.betSlipAmountLD : general.betSlipConstants.betSlipAmountUSD) : 100;
            yield put({ type: BetSlipActionTypes.On_Amount_Change, amount: betSlipAmount })

            if (general.betsRestrictions) {
                yield put({ type: BetSlipActionTypes.Update_BetSlip_From_Storage })
            }

        } catch (error) {
            window.log('client ==>> could not set default currency');
        }
    })
    yield takeEvery(MembersActionTypes.Add_Second_Member_Phone_Request, function* () {
        //call server
        try {
            const { register, member } = yield select();

            let formData = {
                phoneNumber: register.formData.secondPhone,
                code: register.formData.smsCode
            }
            let response = yield membersApi.AddMemberPhone(formData);

            switch (response.status) {
                case 1:
                    let current = member.addPhoneCurrent + 1
                    yield put({ type: MembersActionTypes.Add_Second_Member_Phone_Success, current });
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Add_Second_Member_Phone_Error, message: response.message });
                    break;
            }
        } catch (error) {
            window.log('client ==>> could not add member phone');
        }
    })

    yield takeEvery(MembersActionTypes.Validate_Add_Second_Phone_Request, function* ({ data }) {
        //call server
        try {
            let response = yield registerApi.ValidateAddPhoneNumber(data);

            switch (response.status) {
                case 1:
                    yield put({ type: MembersActionTypes.Validate_Add_Second_Phone_Success });
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Validate_Add_Second_Phone_Error, message: response.message });
                    break;
            }
        } catch (error) {
            window.log('client ==>> could not get phone validation');
        }
    })

    yield takeLatest(MembersActionTypes.Send_Verify_Email_Request, function* (memberData) {
        //call server
        try {
            let response = yield membersApi.SendVerifyEmail(memberData.data);

            switch (response.status) {
                case 1:
                    yield put({ type: MembersActionTypes.Send_Verify_Email_Success, message: response.message });
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Send_Verify_Email_Error, message: response.message });
                    break;
            }
        } catch (error) {
            window.log('client ==>> could not send email');
        }
    })
    yield takeLatest(MembersActionTypes.Member_Details_Request, function* (memberData) {
        //call server
        try {
            let user = Cookies.get('user');
            let response = yield membersApi.UpdateMemberDetails(memberData.data);
            let data = response.data;
            switch (response.status) {
                case 1:
                    let balances = data.memberBalances.reduce((acc, current) => {
                        let currencyName = current.currency.currencyName.split(' ').join('');
                        if (!acc[currencyName]) {
                            acc[currencyName] = {
                                balance: current.balance,
                                currencySymbol: current.currency.currencySymbol,
                                defaultCurrency: current.currency.currencyIsDefault,
                                currencyId: current.currency.currencyId
                            }
                        }
                        return acc;
                    }, {});
                    const tokenExpiresMinutes = Cookies.get("tokenExpiresMinutes");
                    Cookies.set('user', {
                        userName: data.userName,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        balance: balances,
                        bonus: user.bonus,
                        memberPhones: user.memberPhones
                    }, tokenExpiresMinutes)

                    yield put({ type: MembersActionTypes.Member_Details_Success, data, message: response.message });
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Member_Details_Error, message: response.message });
                    break;
                    default:
            }
        } catch (error) {
            window.log('client ==>> could not update member');
        }
    })
    yield takeLatest(MembersActionTypes.Get_Member_Details_Request, function* () {
        //call server
        try {
            let response = yield membersApi.GetMemberDetails();
            let data = response.data;
            switch (response.status) {
                case 1:
                    yield put({ type: MembersActionTypes.Get_Member_Details_Success, data });
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Get_Member_Details_Error, message: response.message });
                    break;
            }
        } catch (error) {
            window.log('client ==>> could not get member');
        }
    })

    yield takeLatest(MembersActionTypes.Mtn_Deposit_Request, function* (request) {
        //call server
        try {
            let response = yield membersApi.mtnDeposit(request.data);
            let data = response.data;
            switch (response.status) {
                case 1:
                    yield put({ type: MembersActionTypes.Mtn_Deposit_Success, data });
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Mtn_Deposit_Error, message: response.message });
                    break;
                default:
            }
        } catch (error) {
            window.log('client ==>> could not deposit mtn');
        }
    })

    yield takeLatest(MembersActionTypes.Deposit_From_Voucher_Request, function* (voucherData) {
        //call server
        try {
            let response = yield membersApi.DepositFromVoucher({ code: voucherData.voucher });
            let data = response.data
            switch (response.status) {
                case 1:
                    let selectedCurrency = Cookies.get('selectedCurrency');
                    const tokenExpiresMinutes = Cookies.get("tokenExpiresMinutes");
                    let user = Cookies.get('user');

                    let balances = data.memberBalances.reduce((acc, current) => {
                        let currencyName = current.currency.currencyName.split(' ').join('');
                        if (!acc[currencyName]) {
                            acc[currencyName] = {
                                balance: current.balance,
                                currencySymbol: current.currency.currencySymbol,
                                defaultCurrency: current.currency.currencyIsDefault,
                                currencyId: current.currency.currencyId
                            }
                        }
                        return acc;
                    }, {});
                    Cookies.set('user', {
                        userName: user.userName,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        balance: balances,
                        bonus: data.memberDepositBonusBalance,
                        memberPhones: user.memberPhones
                    }, tokenExpiresMinutes)

                    let currentCurrency = Object.keys(balances).find((item) => {
                        return balances[item].currencySymbol == selectedCurrency.currencySymbol
                    })

                    Cookies.set('selectedCurrency', balances[currentCurrency]);
                    yield put({ type: MembersActionTypes.Deposit_From_Voucher_Success, message: response.message, balance: balances });
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Deposit_From_Voucher_Error, message: response.message });
                    break;
                default:
            }
        } catch (error) {
            window.log('client ==>> could not voucher dposit');
        }
    })

    yield takeLatest(MembersActionTypes.Mtn_Withdraw_Request, function* (request) {
        //call server
        try {
            let response = yield membersApi.mtnWithdraw(request.data);
            let data = response.data;
            switch (response.status) {
                case 1:
                    yield put({ type: MembersActionTypes.Mtn_Withdraw_Success, data });
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Mtn_Withdraw_Error, message: response.message });
                    break;
                default:
            }
        } catch (error) {
            window.log('client ==>> could not withdraw mtn');
        }
    })

    yield takeLatest(MembersActionTypes.Withdrawal_To_Voucher_Request, function* (memberData) {
        //call server
        try {
            let selectedCurrency = Cookies.get('selectedCurrency');
            let response = yield membersApi.WithdrawalToVoucher({ amount: memberData.data.amount, currencyId: selectedCurrency.currencyId, password: memberData.data.password });
            switch (response.status) {
                case 1:
                    let data = response.data;
                    const tokenExpiresMinutes = Cookies.get("tokenExpiresMinutes");
                    let user = Cookies.get('user');
                    let balances = data.member.memberBalances.reduce((acc, current) => {
                        let currencyName = current.currency.currencyName.split(' ').join('');
                        if (!acc[currencyName]) {
                            acc[currencyName] = {
                                balance: current.balance,
                                currencySymbol: current.currency.currencySymbol,
                                defaultCurrency: current.currency.currencyIsDefault,
                                currencyId: current.currency.currencyId
                            }
                        }
                        return acc;
                    }, {});
                    Cookies.set('user', {
                        userName: user.userName,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        balance: balances,
                        bonus: data.member.memberDepositBonusBalance,
                        memberPhones: user.memberPhones,
                    }, tokenExpiresMinutes)

                    let currentCurrency = Object.keys(balances).find((item) => {
                        return balances[item].currencySymbol == selectedCurrency.currencySymbol
                    })
                    yield put({ type: MembersActionTypes.Set_Selected_Currency, currency: balances[currentCurrency] })

                    Cookies.set('selectedCurrency', balances[currentCurrency]);
                    yield put({ type: MembersActionTypes.Withdrawal_To_Voucher_Success, message: response.message, data, balance: balances[currentCurrency].balance });
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Withdrawal_To_Voucher_Error, message: response.message });
                    break;
                default:
            }
        } catch (error) {
            window.log('client ==>> could not witdraw');
        }
    })

    yield takeLatest(MembersActionTypes.Member_Transactions_Request, function* (memberData) {
        //call server
        try {
            let tableData = {
                fromDate: memberData.data.fromDate,
                toDate: memberData.data.toDate,
                pageSize: 10,
                pageIndex: memberData.data.pageIndex,
                currencyId: memberData.data.currencyId
            }
            let response = yield membersApi.MemberTransactions(tableData);
            let data = response.data;

            switch (response.status) {
                case 1:
                    let transactions = [];
                    data.memberTransactions.map((transaction, i) => {
                        let formatedAmount = transaction.transactionAmount;
                        if (formatedAmount < 0) {
                            formatedAmount = Math.abs(formatedAmount)
                        }
                        formatedAmount = transaction.currency.currencySymbol === 'LD' ? formatedAmount.format(0) : formatedAmount.format(2);
                        formatedAmount = transaction.transactionAmount > 0 ? formatedAmount : `(${formatedAmount})`
                        formatedAmount = transaction.currency.currencySymbol + ' ' + formatedAmount;

                        let formatedBalance = transaction.transactionBalance;
                        if (formatedBalance < 0) {
                            formatedBalance = Math.abs(formatedBalance)
                        }
                        formatedBalance = transaction.currency.currencySymbol === 'LD' ? formatedBalance.format(0) : formatedBalance.format(2);
                        formatedBalance = transaction.transactionBalance > 0 ? formatedBalance : `(${formatedBalance})`
                        formatedBalance = transaction.currency.currencySymbol + ' ' + formatedBalance;
                        const isCreditAmount = transaction.transactionAmount > 0;
                        const isCreditBalance = transaction.transactionBalance > 0;
                        transactions.push({
                            key: i,
                            date: Utils.formatDate(transaction.transactionDate),
                            type: transaction.transactionType.transactionName,
                            isCreditAmount: isCreditAmount,
                            isCreditBalance: isCreditBalance,
                            voucher: transaction.voucher,
                            reference: transaction.voucher.code ? transaction.voucher.code : transaction.form.formNumber ? transaction.form.formNumber : '',
                            amount: formatedAmount,
                            balance: formatedBalance,
                            formNumber: transaction.form.formNumber,
                            formType: transaction.form.formType
                        })
                    });
                    let total = data.transactionsTotals.totalTransactions
                    let formDateRange = { fromDate: data.fromDate, toDate: data.toDate };
                    yield put({ type: MembersActionTypes.Member_Transactions_Success, transactions, total, formDateRange });
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Member_Transactions_Error, message: response.message });
                    break;
                default:
            }
        } catch (error) {
            window.log('client ==>> could not get voucher transactions');
        }
    })

    yield takeLatest(MembersActionTypes.Member_Forms_Request, function* (memberData) {
        //call server
        try {
            let tableData = {
                statusId: memberData.data.statusId,
                // fromDate: memberData.data.fromDate,
                // toDate: memberData.data.toDate,
                fromDate: memberData.data.fromDate ? memberData.data.fromDate : null,
                toDate: memberData.data.toDate ? memberData.data.toDate : null,
                pageSize: 10,
                pageIndex: memberData.data.pageIndex
            }
            let response = yield membersApi.MemberForms(tableData);
            switch (response.status) {
                case 1:
                    let data = response.data;
                    let forms = [];
                    data.betForms.map((form, i) => {
                        let formatedAmount;
                        if (form.formCurrency.currencySymbol === 'LD') {
                            formatedAmount = form.formCurrency.currencySymbol + form.formAmount.format(0);
                        }
                        else {
                            formatedAmount = form.formCurrency.currencySymbol + form.formAmount.format(2);
                        }
                        let formatedWinAmount;
                        if (form.formCurrency.currencySymbol === 'LD') {
                            formatedWinAmount = form.winAmount ? form.formCurrency.currencySymbol + form.winAmount.format(0) : '-';
                        }
                        else {
                            formatedWinAmount = form.winAmount ? form.formCurrency.currencySymbol + form.winAmount.format(2) : '-';
                        }
                        forms.push({
                            key: i,
                            date: Utils.formatDate(form.formDateTime),
                            formNumber: form.formNumber,
                            amount: formatedAmount,
                            winAmount: formatedWinAmount,
                            status: form.formStatus.formStatusName,
                            formType: form.formType
                        })
                    });
                    let total = data.formsTotals.totalForms;
                    let formDateRange = { fromDate: data.fromDate, toDate: data.toDate };
                    yield put({ type: MembersActionTypes.Member_Forms_Success, forms, total, formDateRange });
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Member_Forms_Error, message: response.message });
                    break;
                default:
            }
        } catch (error) {
            window.log('client ==>> could not get form transactions');
        }
    })

    yield takeLatest(MembersActionTypes.Forms_Details_Request, function* (memberData) {
        //call server
        try {
            let response = yield membersApi.FormsDetails(memberData.data);
            let data = response.data
            switch (response.status) {
                case 1:
                    let details = [];
                    if (data.isGroupChecking) {
                        data.betFormsDetails.map((form, i) =>
                            details.push({
                                key: i,
                                formNumber: form.formNumber,
                                issueDate: form.formDateTime,
                                winningOdds: form.totalWinningsOdds,
                                sumOfBet: form.formAmount,
                                winningPossible: form.possibleWinnings,
                                formId: form.formId,
                                formStatus: form.formStatus
                            }));
                    }
                    else {
                        data.betFormDetails.fromBetLines.map((form, i) =>
                            details.push({
                                key: i,
                                bet: form.betLineNo,
                                feed: form.feedName,
                                sport: form.sportName,
                                match: form.eventName,
                                note: form.betType.betTypeName,
                                odd: form.odd,
                                mark: form.formMark,
                                correctMark: form.correctMark,
                                gameScore: form.gameScore,
                                status: form.winStatus,
                                formId: form.formId
                            }));
                    }
                    data.betFormDetails.message = data.messages[0];
                    yield put({ type: MembersActionTypes.Open_Mobile_Table_Details, open: true })
                    yield put({ type: MembersActionTypes.Forms_Details_Success, details, isGroup: data.isGroupChecking, betLines: data.betFormDetails });
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Forms_Details_Error, message: response.message });
                    break;
                default:
            }
        } catch (error) {
            window.log('client ==>> could not get form details');
        }
    })

    yield takeLatest(MembersActionTypes.Voucher_Details_Request, function* (memberData) {
        //call server
        try {
            let response = yield membersApi.GetVoucherDetails(memberData.data);
            switch (response.status) {
                case 1:
                    yield put({ type: MembersActionTypes.Voucher_Details_Success, voucherDetails: response.data  });
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Voucher_Details_Error, message: response.message });
                    break;
                default:
            }
        } catch (error) {
            window.log('client ==>> could not get voucher details');
        }
    })

    yield takeEvery(MembersActionTypes.Member_Approved_Jackpot_Winnings_Request, function* ({ jackpotId }) {
        //call server
        try {
            let data = {
                jackpotId
            }
            let response = yield membersApi.memberApprovedJackpotWinnings(data);

            switch (response.status) {
                case 1:
                    yield put({ type: MembersActionTypes.Member_Approved_Jackpot_Winnings_Success });
                    yield put({ type: SignalRTypes.Jackpot_Winning_Approved, jackpotWinningApproved: false })

                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.Member_Approved_Jackpot_Winnings_Error });
                    break;
                default:
                    yield put({ type: MembersActionTypes.Member_Approved_Jackpot_Winnings_Error });
                    break;
            }

        }
        catch (error) {
            window.log('client =>> could not memberApprovedJackpotWinnings: ', error);
        }
    })
    yield takeEvery(MembersActionTypes.CheckDepositBonusConditions_Request, function* () {
        //call server
        const { general, betSlip } = yield select();
        let currency = Cookies.get('selectedCurrency');
        if (!currency || !currency.currencyId) {
            Cookies.set('selectedCurrency', general.currencies.LiberianDollar)
            currency = Cookies.get('selectedCurrency');
        }
        try {
            let list = SessionStorage.betSlip ? { ...SessionStorage.betSlip } : {};
            let request = {};
            request.betLines = [];
            request.amount = betSlip.amount;
            request.currencyId = currency.currencyId;
            Object.values(list).map((item) => {
                request.betLines.push({
                    feedId: item.feed.feedId,
                    sportId: item.sport.sportId,
                    eventId: item.eventId,
                    oddId: item.oddId,
                    oddFieldNumber: item.oddFieldNumber,
                    betLineNo: item.eventBetLineNo,
                    betTypeId: item.betType.betTypeId,
                })
            })
           
            let response = yield membersApi.checkDepositBonusConditions(request);

            switch (response.status) {
                case 1:
                    yield put({ type: MembersActionTypes.CheckDepositBonusConditions_Success, conditions: response.data });
                    yield put({
                        type: BetSlipActionTypes.Set_BetSlip_Possible_Winnings,
                        possibleWinnings: response.data.possibleWinnings
                    })
                    break;
                case 3 || 4:
                    yield put({ type: MembersActionTypes.CheckDepositBonusConditions_Error, message: response.message });
                    yield put({
                        type: BetSlipActionTypes.Set_BetSlip_Value_Change_Warning,
                        valueChangeWarning: response.message
                    })
                    break;
                    default:
            }
        } catch (error) {
            window.log('client ==>> could not checkDepositBonusConditions');
        }
    })
}

export default function* MembersSaga() {
    yield all([
        fork(requests)
    ])
}

import React from 'react'

import { Utils } from '../../../helpers'
import { connect } from 'react-redux';


class Timer extends React.Component {
	state = {
		time: this.props.time,
	}

	componentDidMount() {
		let intervalTimer = setInterval(() => {
			if (!this.props.producerDown) {
				if (this.props.type == "up") {
					if (this.props.status === 0) {//did not change stop timer
						this.setState({ time: this.state.time })
					} else {
						this.setState({ time: this.state.time + 1 })
					}
				}
				if (this.props.type == "down") {
					if (this.state.time > 0) {
						this.setState({ time: this.state.time - 1 })
					}
				}
			}

		}, 1000)

		this.intervalTimer = intervalTimer;
	}

	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.time !== prevProps.time) {
			this.setState({ time: this.props.time })
		}
	}

	componentWillUnmount() {
		clearInterval(this.intervalTimer);
	}
	render() {
		return (
			<div className="timer">
				{`${this.state.time && this.props.type == "up"
					? Utils.getGameTimeBySeconds(this.state.time)
					: this.state.time <= 120 ? "Starting soon..." : Utils.formatSecondsToTime(this.state.time)}`}
			</div>
		)
	}


}
const mapStateToProps = (state) => {
	return {
		producerDown: state.signalr.producerDown,
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Timer);
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import { render } from 'react-dom';
import Root from './components/Root';
import { history, store } from './redux/stores/configureStore';
import * as serviceWorker from './serviceWorker';
import './styles/ltr/layout.scss'

render(
    <Root store={store} history={history} />,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import { connect } from "react-redux";

import { onDropDownsChange } from "../../../redux/actions/sportEventsActions";

import { Bets } from "../../";
import { Utils, Constants, CountryCodes } from "../../../helpers";

import { Select } from "antd";
const Option = Select.Option;

const Odd = (props) => {
  const { children } = props;
  return <div className="odd">{children}</div>;
};

const ColSelect = (props) => {
  const { title, onChange, selectOptions, defaultValue, selectedBetType } =
    props;

  return (
    <div className="odds">
      <div className="select">
        {title && <div className="title upper">{title}</div>}

        {!title && (
          <Select
            defaultValue={defaultValue}
            className="selectlist"
            onChange={onChange}
          >
            {selectOptions &&
              selectOptions.optionsBetTypes &&
              selectOptions.optionsBetTypes.map((item, index) => (
                <Option key={item.key} value={item.key}>
                  {item.betTypeName}
                </Option>
              ))}
          </Select>
        )}
      </div>
      <div className="odds-desc">
        {
          // if title exist - show match fields
          title &&
            selectOptions &&
            selectOptions.match &&
            selectOptions.match.eventOddFields &&
            sortByCol(selectOptions.match.eventOddFields).map((item, index) => (
              <Odd key={index}>{item.oddField}</Odd>
            ))
        }
        {
          // if no title show selected bet type fields
          !title &&
            selectOptions &&
            selectOptions.optionsBetTypes &&
            selectOptions.optionsBetTypes.length > 0 &&
            selectOptions.optionsBetTypes.map((item) => {
              return item.key == selectedBetType
                ? sortByCol(item.eventOddFields).map((field, index) => (
                    <Odd key={index}>{field.oddField}</Odd>
                  ))
                : null;
            })
        }
      </div>
    </div>
  );
};

const sortByCol = (array) => {
  return Utils.sortArrayByAttribute(array, "oddFieldColumn");
};

class EventsHeader extends React.Component {
  state={
    applyStyle:false,
    style :{ backgroundColor: "#2C8AF7", opacity: "1" },
  }
  componentWillUnmount() {}

  getCountryCode = (name) => {
    let result = CountryCodes.Codes.filter((item) => {
      return item.name.toLowerCase() == name.toLowerCase();
    });

    return result && result.length > 0 ? result[0].code.toLowerCase() : "eu";
  };

  render() {
    const { match, title, betTypes, selectedValues } = this.props;

    return (
      <div>
        <div className="bets-filter-container">
          <div id="bits-filter-button-container">
            <button className="bets-filter-buttons">
              <span>
                <i className="fa-solid fa-trophy" />
              </span>
            </button>
            <button className="bets-filter-buttons">
              <span>All</span>
            </button>
            <button className="bets-filter-buttons">
              <span>
                <i className="fa-solid fa-star" />
              </span>
            </button>
          </div>
          <div id="bits-filter">
            <Bets.HeaderRow {...this.props} className="header">
              {betTypes && betTypes.match && (
                <Bets.EventsCol className="col bet-type-1">
                  <div className="bet-types">
                    <ColSelect
                      title={betTypes.match.betTypeName}
                      selectOptions={betTypes}
                    />
                  </div>
                </Bets.EventsCol>
              )}

              {betTypes &&
                betTypes.optionsBetTypes &&
                betTypes.optionsBetTypes.length >= 1 && (
                  <Bets.EventsCol
                    className="col bet-type-2"
                    style={{ marginLeft: "5px" }}
                  >
                    <div className="bet-types" style={{ marginLeft: "1.5rem" }}>
                      <ColSelect
                        selectedBetType={selectedValues[0]}
                        onChange={(value) => this.onDropDownsChange(value, 0)}
                        selectOptions={betTypes}
                        defaultValue={selectedValues[0]}
                      />
                    </div>
                  </Bets.EventsCol>
                )}

              {betTypes &&
                betTypes.optionsBetTypes &&
                betTypes.optionsBetTypes.length >= 2 && (
                  <Bets.EventsCol
                    className="col bet-type-3"
                    style={{ marginLeft: "5px" }}
                  >
                    <div className="bet-types" style={{ marginLeft: "2.5rem" }}>
                      <ColSelect
                        selectedBetType={selectedValues[1]}
                        onChange={(value) => this.onDropDownsChange(value, 1)}
                        selectOptions={betTypes}
                        defaultValue={selectedValues[1]}
                      />
                    </div>
                  </Bets.EventsCol>
                )}

              {betTypes &&
                betTypes.optionsBetTypes &&
                betTypes.optionsBetTypes.length >= 3 && (
                  <Bets.EventsCol className="col bet-type-4">
                    <div className="bet-types" style={{ marginLeft: "3.5rem" }}>
                      <ColSelect
                        selectedBetType={selectedValues[2]}
                        onChange={(value) => this.onDropDownsChange(value, 2)}
                        selectOptions={betTypes}
                        defaultValue={selectedValues[2]}
                      />
                    </div>
                  </Bets.EventsCol>
                )}

              <Bets.EventsCol className="col bet-type-5">
                <div className="stats">&nbsp;</div>
              </Bets.EventsCol>

              <Bets.EventsCol className="col bet-type-6">
                <div className="plus">&nbsp;</div>
              </Bets.EventsCol>
            </Bets.HeaderRow>
          </div>
        </div>
        <div className="mobile-bets-filter-container">
          <div id="mobile-bets-filter-controller">
            {title && <div className="title upper">{title}</div>}
            {betTypes && betTypes.match && (
              <div
                id={betTypes.match.key}
                onClick={() => this.resetSelectedValues(betTypes.match.key)}
                style={ this.state.style}
                className="mobile-bet-filter-options"
              >
                1 X 2
              </div>
            )}

            {!title &&
              betTypes &&
              betTypes.optionsBetTypes &&
              betTypes.optionsBetTypes.map(
                (item, index) =>
                  !item.betTypeName.includes("Handicaps") && (
                    <div
                      id={item.key}
                      onClick={() => this.filters(item.key )}
                      className="mobile-bet-filter-options"
                    >
                      <span>{item.betTypeName}</span>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    );
  }

  onDropDownsChange(value, dropDownId) {
    const { selectedValues, onDropDownsChange } = this.props;
    let newSelectedValues = [];
    selectedValues.map((item, index) => {
      if (dropDownId == index) {
        newSelectedValues[index] = value;
      } else {
        newSelectedValues[index] = item;
      }
    });
    onDropDownsChange(newSelectedValues);
  }
  changeFilterColor(key){
    if (this.state.id){
      const disabledStyleElement=document.getElementById(this.state.id)
      disabledStyleElement.style.backgroundColor='#0C182D';
      disabledStyleElement.style.opacity='0.5';
    }
    this.setState({
      style:{},
      id:key
    }
    )
    const enabledStyleElement = document.getElementById(key);
    enabledStyleElement.style.backgroundColor='#2C8AF7';
    enabledStyleElement.style.opacity='1';
  }
  filters(key) {
    const { onDropDownsChange } = this.props;
    this.changeFilterColor(key)
    if (key=="2_0:1" ){
      return onDropDownsChange([key , "" , ""])
    }else{
      return onDropDownsChange([key, "2_0:2", "2_0:3"]);
    }
  }
  resetSelectedValues(key) {
    const { onDropDownsChange } = this.props;
    this.changeFilterColor(key)
    onDropDownsChange(["2_0:1", "2_0:2", "2_0:3"]);
  }
}
const mapStateToProps = (state) => {
  return {
    betTypes: state.sportEvents.betTypes,
    selectedValues: state.sportEvents.selectedValues,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onDropDownsChange: (values) => {
      dispatch(onDropDownsChange(values));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsHeader);

import React, { Component } from "react";
import {

    forgotPasswordConfirm,
    forgotPassword,
  } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import { Input, Button, Form } from "antd";
import { Alert } from "../../components";
import { LocalStorage } from "../../helpers";

export class OTPVertification extends Component {
    state ={
        birthDate: "",

        OTP: {
            1: null,
            2: null,
            3: null,
            4: null,
            5: null,
            6: null,
          },
    }
    onInputFocus = (e) => {
        e.target.select();
      };
    
      maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
          object.target.value = object.target.value.slice(
            0,
            object.target.maxLength
          );
        }
      };
      handleResend = () => {
        this.props.form.resetFields();
        let data = {
          memberId: this.props.destination.memberId,
          phoneNumber: this.props.destination.phoneNumber,
          birthDate: this.state.birthDate,
          email: this.props.destination.email,
        };
        if (this.props.destination.phoneNumber) {
          data.birthDate = this.state.birthDate;
        }
        this.props.forgotPassword(data);
      };
    handleOTPChange = (e, id) => {
        const inputElement = document.getElementById(id);
        console.log(
          "e.target.value.split([e.target.value.length-1]",
          e.target.value.split("")[e.target.value.length - 1]
        );
        // inputElement.value = e.target.value.split("")[e.target.value.length - 1];
    
        // console.log("[id.split('-')[1]]" , [id.split('-')[1]]);
        // console.log("OTP" , this.state.OTP);
        // console.log("e.target.value" , e.target.value);
        this.setState(
          (this.state.OTP = {
            ...this.state.OTP,
            [id.split("-")[1]]: e.target.value,
          })
        );
        if (e.target.value != "" && inputElement.nextSibling) {
          inputElement.nextSibling.focus();
        }
        if (e.target.value == "" && inputElement.previousSibling) {
          inputElement.previousSibling.focus();
        }
        if (!inputElement.nextSibling) {
          console.log(
            "Object.values(this.state.OTP)",
            Object.values(this.state.OTP).join("").replaceAll(",", "")
          );
        }
      };
    handleVerify = (e) => {
        e.preventDefault();
        let data = {
          code: Object.values(this.state.OTP).join("").replaceAll(",", ""),
          memberId: this.props.userData.memberId,
        };
        if (this.props.userData.securityQuestion) {
          data.securityAnswer = this.props.form.getFieldValue("answer");
          data.isSecurityAnswer = true;
        } else {
          data.isSecurityAnswer = false;
        }
        this.props.forgotPasswordConfirm(data);
      };
  render() {
    const formItemLayout = null;
    const { getFieldDecorator} = this.props.form;
    const { message, success } = this.props;


    return (
      <div>
        <div id="OTP-vertification-parent-container">
          <p className="forget-password-headline">OTP Vertification</p>
          {message && <Alert type="error" icon="!" message={message} />}


          <Form onSubmit={this.handleVerify}>
            <div>
              <div className="forget-password-input-label-container"  style={{display:"flex" , justifyContent:"center" , whiteSpace:"nowrap"}}>
                {/* <Form.Item label="Code" {...formItemLayout}>
              {getFieldDecorator("code", {
                rules: [{ required: true }],
              })(
                <Input className="confirmation-input" type="number" />
              )}
            </Form.Item> */}
                <div className="OTP-fields">
                  <Form.Item {...formItemLayout}>
                    <Input
                      onFocus={(e) => this.onInputFocus(e)}
                      onChange={(e) => this.handleOTPChange(e, "opt-1")}
                      id="opt-1"
                      className="confirmation-input"
                      type="number"
                      maxLength={1}
                      onInput={this.maxLengthCheck}
                    />
                    <Input
                      onFocus={(e) => this.onInputFocus(e)}
                      onChange={(e) => this.handleOTPChange(e, "opt-2")}
                      id="opt-2"
                      className="confirmation-input"
                      type="number"
                      maxLength={1}
                      onInput={this.maxLengthCheck}
                    />
                    <Input
                      onFocus={(e) => this.onInputFocus(e)}
                      onChange={(e) => this.handleOTPChange(e, "opt-3")}
                      id="opt-3"
                      className="confirmation-input"
                      type="number"
                      maxLength={1}
                      onInput={this.maxLengthCheck}
                    />
                    <Input
                      onFocus={(e) => this.onInputFocus(e)}
                      onChange={(e) => this.handleOTPChange(e, "opt-4")}
                      id="opt-4"
                      className="confirmation-input"
                      type="number"
                      maxLength={1}
                      onInput={this.maxLengthCheck}
                    />
                    <Input
                      onFocus={(e) => this.onInputFocus(e)}
                      onChange={(e) => this.handleOTPChange(e, "opt-5")}
                      id="opt-5"
                      className="confirmation-input"
                      type="number"
                      maxLength={1}
                      onInput={this.maxLengthCheck}
                    />
                    <Input
                      onFocus={(e) => this.onInputFocus(e)}
                      onChange={(e) => this.handleOTPChange(e, "opt-6")}
                      id="opt-6"
                      className="confirmation-input"
                      type="number"
                      maxLength={1}
                      onInput={this.maxLengthCheck}
                    />
                  </Form.Item>
                </div>
              </div>
              {this.props.userData && this.props.userData.securityQuestion && (
          <div>
            <h3>{this.props.userData.securityQuestion}</h3>
            <div className="forget-password-input-label-container">
            <Form.Item
              className="form-item"
              {...formItemLayout}
              label="Answer"
            >
              {getFieldDecorator("answer", {
                rules: [
                  {
                    required: true,
                    message: "Please answer the security question",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            </div>
          </div>
        )}
              <div
                className="login-forget-password-container"
                onClick={this.handleResend}
              >
                <span>Resend the code</span>
              </div>
              <div className="step1-button-container">
                <Button
                  className="next"
                  type="primary"
                  htmlType="submit"
                  loading={this.props.loading}
                >
                  Next
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
    return {
      message: state.auth.forgotPasswordMessage,
      loading: state.auth.loading,
      userData: state.auth.userData,
      destination: state.auth.forgotPasswordDest,


    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    forgotPasswordConfirm: (data) => dispatch(forgotPasswordConfirm(data)),
    forgotPassword: (userName) => dispatch(forgotPassword(userName)),


  });
  
  const WrappedStep1 = Form.create({ name: "ForgotPasswordStep1" })(OTPVertification);
  export default connect(mapStateToProps, mapDispatchToProps)(WrappedStep1);

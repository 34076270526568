import { MembersActionTypes, AppActionTypes } from '../actions';

const initState = {
    message: '',
    depositSuccessMessage: '',
    depositErrorMessage: '',
    depositLoading: false,
    withdrawSuccessMessage: '',
    withdrawErrorMessage: '',
    withdrawLoading: false,
    editDetailsErrorMessage: '',
    editDetailsSuccessMessage: '',
    betsMessage: null,
    loading: false,
    phoneValid: false,
    validateAccount: false,
    userData: '',
    newVoucher: '',
    balance: '',
    addPhoneCurrent: 0,
    transactions: [],
    totalTransactions: 0,
    forms: [],
    totalForms: 0,
    formDetails: '',
    voucherDetails: '',
    selectedCurrency: '',
    isGroup: false,
    betLines: null,
    openMobileTableDetails: false,
    openMobileVoucherDetails: false,
    betDetailsModalVisible: false,
    voucherModalVisible: false,
    bonusConditions: null

}
const member = (state = initState, action) => {
    switch (action.type) {
        case MembersActionTypes.Init:
            return {
                ...initState
            }
        case MembersActionTypes.Set_Selected_Currency:
            return {
                ...state,
                selectedCurrency: action.currency
            }
        case MembersActionTypes.Add_Second_Member_Phone_Request:
            return {
                ...state,
                loading: true
            }
        case MembersActionTypes.Add_Second_Member_Phone_Success:
            return {
                ...state,
                message: '',
                addPhoneCurrent: action.current,
                loading: false
            }
        case MembersActionTypes.Add_Second_Member_Phone_Error:
            return {
                ...state,
                message: action.message,
                loading: false
            }
        case MembersActionTypes.Validate_Add_Second_Phone_Request:
            return {
                ...state,
                loading: true
            }
        case MembersActionTypes.Validate_Add_Second_Phone_Success:
            return {
                ...state,
                message: '',
                loading: false,
                phoneValid: true
            }
        case MembersActionTypes.Validate_Add_Second_Phone_Error:
            return {
                ...state,
                message: action.message,
                loading: false
            }
        case MembersActionTypes.Get_Member_Details_Success:
            return {
                ...state, userData: action.data
            }
        case MembersActionTypes.Send_Verify_Email_Request:
            return {
                ...state, loading: true
            }
        case MembersActionTypes.Send_Verify_Email_Success:
            return {
                ...state, loading: false, validateAccount: true
            }
        case MembersActionTypes.Send_Verify_Email_Error:
            return {
                ...state, loading: false, editDetailsErrorMessage: action.message
            }
        case MembersActionTypes.Member_Details_Request:
            return {
                ...state, loading: true
            }
        case MembersActionTypes.Member_Details_Success:
            return {
                ...state,
                loading: false,
                editDetailsErrorMessage: '',
                editDetailsSuccessMessage: action.message
            }
        case MembersActionTypes.Member_Details_Error:
            return {
                ...state,
                loading: false,
                editDetailsSuccessMessage: '',
                editDetailsErrorMessage: action.message
            }

        case MembersActionTypes.Mtn_Withdraw_Success: {
            return {
                ...state,
                withdrawSuccessMessage: action.message,
                withdrawErrorMessage: null,
                withdrawLoading: false
            }
        }
        case MembersActionTypes.Mtn_Withdraw_Request: {
            return {
                ...state,
                withdrawLoading: true
            }
        }
        case MembersActionTypes.Mtn_Withdraw_Error: {
            return {
                ...state,
                withdrawErrorMessage: action.message,
                withdrawSuccessMessage: null,
                withdrawLoading: false,
            }
        }
        case MembersActionTypes.Mtn_Deposit_Success: {
            return {
                ...state,
                depositSuccessMessage: action.message,
                depositErrorMessage: null,
                depositLoading: false
            }
        }
        case MembersActionTypes.Mtn_Deposit_Request: {
            return {
                ...state,
                depositLoading: true
            }
        }
        case MembersActionTypes.Mtn_Deposit_Error: {
            return {
                ...state,
                depositErrorMessage: action.message,
                depositSuccessMessage: null,
                depositLoading: false,
            }
        }

        case MembersActionTypes.Deposit_From_Voucher_Success: {
            return {
                ...state,
                depositSuccessMessage: action.message,
                depositErrorMessage: null,
                balance: action.balance,
                depositLoading: false
            }
        }
        case MembersActionTypes.Deposit_From_Voucher_Request: {
            return {
                ...state,
                depositLoading: true
            }
        }
        case MembersActionTypes.Deposit_From_Voucher_Error: {
            return {
                ...state,
                depositErrorMessage: action.message,
                depositSuccessMessage: null,
                depositLoading: false,
            }
        }
        case MembersActionTypes.Withdrawal_To_Voucher_Request: {
            return {
                ...state,
                withdrawLoading: true,
                message: null,
                newVoucher: null
            }
        }
        case MembersActionTypes.Withdrawal_To_Voucher_Success: {
            return {
                ...state,
                withdrawSuccessMessage: action.message,
                balance: action.balance,
                newVoucher: action.data.voucher,
                message: null,
                withdrawLoading: false,
            }
        }
        case MembersActionTypes.Withdrawal_To_Voucher_Error: {
            return {
                ...state,
                //withdrawErrorMessage: action.message,
                message: action.message,
                withdrawLoading: false
            }
        }
        
        case MembersActionTypes.Member_Transactions_Request:
            return {
                ...state,
                transactions: [],
                totalTransactions: 0,
                loading: true
            }

        case MembersActionTypes.Member_Transactions_Error:
            return {
                ...state,
                transactions: [],
                totalTransactions: 0,
                message: action.message,
                loading: false
            }

        case MembersActionTypes.Member_Transactions_Success:
            return {
                ...state,
                transactions: action.transactions,
                totalTransactions: action.total,
                formDateRange: action.formDateRange,
                loading: false
            }
        case MembersActionTypes.Member_Forms_Request:
            return {
                ...state,
                loading: true,
                betsMessage: null
            }
        case MembersActionTypes.Member_Forms_Success:
            return {
                ...state,
                forms: action.forms,
                totalForms: action.total,
                formDateRange: action.formDateRange,
                loading: false
            }
        case MembersActionTypes.Member_Forms_Error:
            return {
                ...state,
                loading: false,
                betsMessage: action.message
            }

        case MembersActionTypes.Voucher_Details_Request:
            return {
                ...state,
                loading: true
            }
        case MembersActionTypes.Voucher_Details_Success:
            return {
                ...state,
                voucherDetails: action.voucherDetails,
                loading: false,
            }
        case MembersActionTypes.Voucher_Details_Error:
            return {
                ...state,
                loading: false
            }

        case MembersActionTypes.Forms_Details_Request:
            return {
                ...state,
                loading: true
            }
        case MembersActionTypes.Forms_Details_Success:
            return {
                ...state,
                formDetails: action.details,
                loading: false,
                isGroup: action.isGroup,
                betLines: action.betLines
            }
        case MembersActionTypes.Forms_Details_Error:
            return {
                ...state,
                loading: false
            }
        case MembersActionTypes.Member_Update_Balance:
            return {
                ...state,
                balance: action.balance
            }
        case MembersActionTypes.Open_Mobile_Table_Details:
            return {
                ...state,
                openMobileTableDetails: action.open
            }
        case MembersActionTypes.Open_Mobile_Voucher_Details:

            return {
                ...state,
                openMobileVoucherDetails: action.open
            }
        case MembersActionTypes.Open_Bet_Details_Modal:
            return {
                ...state,
                betDetailsModalVisible: action.open
            }
        case MembersActionTypes.Open_Voucher_Modal:
            return {
                ...state,
                voucherModalVisible: action.open
            }

        case MembersActionTypes.CheckDepositBonusConditions_Request:
            return {
                ...state,
                bonusConditions: null
            }
        case MembersActionTypes.CheckDepositBonusConditions_Success:
            return {
                ...state,
                bonusConditions: action.conditions
            }
        case MembersActionTypes.CheckDepositBonusConditions_Error:
            return {
                ...state,
                bonusConditions: null
            }
        default:
            return state
    }
}

export default member;
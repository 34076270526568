import { Row, Tabs } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { setPropsMath } from '../../../redux/actions/appActions';
import SlotItem from './SlotItem';
import { getSlotsGames } from '../../../redux/actions/slotsActions';
import { isMobile } from 'react-device-detect';


const TabPane = Tabs.TabPane;

class SlotList extends React.Component {
    state = {
        selectedTab: "0"
    }

    async componentDidMount() {
        const { getSlotsGames ,setPropsMath} = this.props;
        getSlotsGames();
        setPropsMath();

    }

    async componentDidUpdate(prevProps) {

    }

    onTabChange(key) {
        this.setState({ selectedTab: key });
    }

    render() {
        const { config, categories, slotsGames } = this.props;


        return (
            categories && <div className="items">
                
                  
                    { isMobile ? 
                        
                  
                    <Tabs
                        className=" tabs-events fixed-height-tab scroll-slot-categories"
                        defaultActiveKey={this.state.selectedTab || categories[0].categoryId.toString()}
                        onChange={(key) => { this.onTabChange(key) }}>

                        {
                            categories.map((item, i) =>
                                <TabPane tab={item.categoryName} key={item.categoryId.toString()} >
                                    <Row gutter={16} align="middle" type="flex">
                                        {
                                            slotsGames && slotsGames.sort((a, b ) => a.gameOrder < b.gameOrder)
                                                .map((row, i) => {
                                                return (this.state.selectedTab !== row.categoryId.toString() && this.state.selectedTab !== "0") ?
                                                    null
                                                    :
                                                    <SlotItem {...this.props} key={i} data={row}></SlotItem>
                                            })
                                        }
                                    </Row>
                                </TabPane>
                            )
                        }
                    </Tabs>

                    :
                    <Tabs
                        className="tabs-events fixed-height-tab"
                        defaultActiveKey={this.state.selectedTab || categories[0].categoryId.toString()}
                        onChange={(key) => { this.onTabChange(key) }}>

                        {
                            categories.map((item, i) =>
                                <TabPane tab={item.categoryName} key={item.categoryId.toString()} >
                                    <Row gutter={16} align="middle" type="flex">
                                        {
                                            slotsGames && slotsGames.sort((a, b ) => a.gameOrder < b.gameOrder)
                                                .map((row, i) => {
                                                let itemsInRow = config.slotsGamesRow;
                                                return (this.state.selectedTab !== row.categoryId.toString() && this.state.selectedTab !== "0") ?
                                                    null
                                                    :
                                                    <SlotItem {...this.props} key={i} data={row}></SlotItem>
                                            })
                                        }
                                    </Row>
                                </TabPane>
                            )
                        }
                    </Tabs>
                    }
                    
              </div>   


           
        )
    }
}

const mapStateToProps = (_, initialProps) => (state) => {
    return {
        slotsGames: state.slots.slotsGames,
        categories: state.slots.categories,
        config: state.slots.config,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getSlotsGames: () => dispatch(getSlotsGames()),
        setPropsMath: () => dispatch(setPropsMath())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlotList);
import { Button, Drawer, Input, Tabs, Icon } from "antd";
import React from "react";
import { connect } from "react-redux";
import StickyBox from "react-sticky-box";
import { Alert } from "../..";
import { openMobileBetslip } from "../../../redux/actions/appActions";
import {
  getBetSlipConfiguration,
  getFieldsUpdatedDetails,
} from "../../../redux/actions/betSlipActions";
import {
  betsByBetCode,
  clearBetCode,
} from "../../../redux/actions/generalActions";
import { BetSlipTab, MyBetsTab, BetSlipSettings } from "./";
const TabPane = Tabs.TabPane;

class BetSlip extends React.Component {
  state = {
    codeNumber: null,
  };

  componentDidMount() {
    const { getBetSlipConfiguration, connected } = this.props;
    if (connected) {
      getBetSlipConfiguration();
    }
  }
  componentDidUpdate(prevProps) {
    const { getBetSlipConfiguration, connected } = this.props;
    if (!prevProps.connected && connected) {
      getBetSlipConfiguration();
    }
  }

  loadCode = () => {
    let request = {
      id: this.state.codeNumber,
    };
    this.props.betsByBetCode(request);
    this.setState({ codeNumber: "" });
  };

  handleTabClick = (key) => {
    if (key === "2") {
      this.props.history.push(`/account/bets`);
    }
  };

  renderBetSlip() {
    const { betCode, betCodeError, signedIn, betTab } = this.props;
    return (
      <div className="bet-slip">
        <div style={{position:"relative"}}>
        <div className="bit-slip-header">Bet slip</div>
        <BetSlipSettings />
        </div>


        <Tabs
          className="tabs-top"
          onTabClick={(key) => this.handleTabClick(key)}
        >
          <TabPane tab="Bet Slip" key="1">
            {betCode && (
              <div className="bet-code">
                <div
                  className="close"
                  onClick={() => {
                    this.props.clearBetCode();
                  }}
                >
                  <Icon type="close" />
                </div>
                <div className="text">Your Bet Code is</div>
                <div className="code">{betCode}</div>
              </div>
            )}
            <BetSlipTab {...this.props} />
          </TabPane>
          {/* <div style={{height:"100px"}}></div>
          <TabPane tab="My Bets" key="2" disabled={!signedIn}>
            <MyBetsTab {...this.props} />
          </TabPane> */}

          {/* <p>By Placing A Bet, You Automatically Accept
The Most Recent Version Of The Terms And
Conditions</p> */}
        </Tabs>

        <div className="load-code">
          <div className="top">Load Bet Code</div>

          <div className="content">
            <div className="fields">
              <div className="code">
                <Input
                  type="number"
                  id="loadcode"
                  placeholder="Code here..."
                  onChange={(e) =>
                    this.setState({ codeNumber: e.target.value })
                  }
                  value={this.state.codeNumber}
                />
              </div>
              <div className="button">
                <Button
                  disabled={betTab == 1}
                  type="primary"
                  // className="success"
                  onClick={this.loadCode}
                >
                  Load
                </Button>
              </div>
            </div>
            {betCodeError && (
              <div className="notice">
                <Alert message={betCodeError} type="error"></Alert>
              </div>
            )}
          </div>
        </div>
        <div className="load-code-mobile-view">
          <fieldset>
          <legend >Load Bet Code</legend>


          <div className="content" >
            <div className="fields">
              <div id="input-code-mobile-view" className="code">
                <Input
                  type="number"
                  id="loadcode"
                  placeholder="Code here..."
                  onChange={(e) =>
                    this.setState({ codeNumber: e.target.value })
                  }
                  value={this.state.codeNumber}
                />
              </div>
              <div id="submit-code-mobile-view" className="button">
                <Button
                  disabled={betTab == 1}
                  type="primary"
                  // className="success"
                  onClick={this.loadCode}
                >
                  Load
                </Button>
              </div>
            </div>
            {betCodeError && (
              <div className="notice">
                <Alert message={betCodeError} type="error"></Alert>
              </div>
            )}
          </div>
          </fieldset>
        </div>
      </div>
    );
  }

  render() {
    const { device, openMobileBetslip, mobileBetslipOpen } = this.props;

    return (
      <div>
        {this.renderBetSlip()}

        <Drawer
          width={280}
          className="mobile mobile-bet-slip"
          placement="right"
          closable={true}
          destroyOnClose
          onClose={() => openMobileBetslip(false)}
          visible={mobileBetslipOpen}
        >
          {this.renderBetSlip()}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    betCode: state.general.betCode,
    betCodeError: state.general.betCodeError,
    device: state.app.device,
    mobileBetslipOpen: state.app.mobileBetslipOpen,
    signedIn: state.auth.signedIn,
    connected: state.signalr.connected,
    betTab: state.betSlip.betTab,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    openMobileBetslip: (open) => dispatch(openMobileBetslip(open)),
    betsByBetCode: (data) => dispatch(betsByBetCode(data)),
    getFieldsUpdatedDetails: (request) =>
      dispatch(getFieldsUpdatedDetails(request)),
    getBetSlipConfiguration: () => dispatch(getBetSlipConfiguration()),
    clearBetCode: () => {
      dispatch(clearBetCode());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BetSlip);

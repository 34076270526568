import { Fetch, AppConfig } from '../helpers'

export default class membersApi {

    static AddMemberPhone = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'AddMemberPhone',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }


    static UpdateMemberDetails = (data) => {

        const response = Fetch.fetch(
            {
                methodName: 'UpdateMemberDetails',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }

    static GetMemberDetails = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'GetMemberDetails',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }

    static SendVerifyEmail = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'SendVerifyEmail',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }
    static ValidateVoucherDeposit = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'ValidateVoucherDeposit',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }

    static mtnDeposit = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'MtnMobileMoneyDeposit',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }

    static mtnWithdraw = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'MtnMobileMoneyWithdrawal',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }
    

    static DepositFromVoucher = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'DepositFromVoucher',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }

    static ValidateWithdrawal = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'ValidateWithdrawal',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }

    static WithdrawalToVoucher = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'WithdrawalToVoucher',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }

    static MemberTransactions = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'MemberTransactions',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }
    static MemberForms = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'MemberForms',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }

    static FormsDetails = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'FormsDetails',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }

    static GetVoucherDetails = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'VoucherDetails',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }
    
    static placeBetStaticLive = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'PlaceBetStaticLive',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }

    static placeBetVirtual = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'PlaceBetVirtual',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }

    static placeBetSingles = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'PlaceBetSingles',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
    }

    static memberApprovedJackpotWinnings = (data) => {
		const response = Fetch.fetch(
			{
				methodName: "MemberApprovedJackpotWinnings",
				method: "POST",
				url: AppConfig.api.members,
				data
			}
		)
		return response;
	}
    static checkDepositBonusConditions  = (data) => {
        const response = Fetch.fetch(
            {
                methodName: 'CheckDepositBonusConditions ',
                url: AppConfig.api.members,
                data
            }
        )
        return response;
        }

}

import { SignalRTypes, VirtualActionTypes } from '../actions'

export function load(feedId) {
    return {
        type: VirtualActionTypes.Load_Request,
        feedId
    }
}

export function loadVirtualEvents(data) {
	return {
		type: SignalRTypes.SportEventsVirtual_Request,
		data
	}	
}

export function clearVirtualEvents(data) {
	return {
		type: SignalRTypes.SportEventsVirtual_Clear,
	}	
}
import { Input, Popover, List, AutoComplete, Spin, Icon } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  getSearchedData,
  navigateSearchItem,
} from "../../redux/actions/searchActions";
import { setEvent } from "../../redux/actions/sportEventsActions";
import { Feed, FeedNames, VirtualFeedIds } from "../../helpers/enums";

class Search extends React.Component {
  state = {
    value: "",
    results: [],
    data: [],
    limit: 5,
    show: false,
  };

  searchInput = React.createRef();

  componentDidUpdate(prevProps) {
    if (prevProps.searchResult !== this.props.searchResult) {
      this.setState({
        data: this.props.searchResult.map(this.renderOption),
        results: this.props.searchResult
          .map(this.renderOption)
          .slice(0, this.state.limit),
      });
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  };

  renderOption = (item) => {
    return (
      <div key={item.id}>
        <h4 style={{ opacity: 0.7, fontSize: "12px" }}>
          #{item.id} {item.sport}, {item.category}, {item.tourment}
        </h4>
        <h3>
          {item.homeTeam} vs {item.awayTeam}
        </h3>
        <h3 style={{ opacity: 0.7 }}>{item.date}</h3>
      </div>
    );
  };

  onLoadMore = () => {
    let resultsLen = this.state.results.length;
    let loadMoreResults = this.state.data.slice(
      0,
      resultsLen + this.state.limit
    );

    this.setState({
      results: loadMoreResults,
    });
  };

  handleSearchItem = (item) => {
    this.searchInput.current.input.state.value = "";
    const {
      searchResult,
      feedName,
      navigateSearchItem,
      setEvent,
      eventsResult,
      loadSportEvents,
    } = this.props;
    let event = searchResult.find((eventItem) => item.key == eventItem.id);

    let eventToSet = Object.keys(eventsResult)
      .map((game) => eventsResult[game])
      .find((eventItem) => {
        return item.key == eventItem.eventBetLineNo;
      });

    let feed;
    let vfl = VirtualFeedIds.find((feed) => feed === event.feedId);
    setEvent(eventToSet);
    if (!feedName) {
      switch (event.feedId) {
        case Feed.Static:
          feed = FeedNames.Static;
          break;
        case Feed.Live:
          feed = FeedNames.Live;
          break;
        case vfl:
          feed = FeedNames.VFL;
        default:
          break;
      }
    }
    navigateSearchItem(
      `/${feedName || feed}/${event.feedId}/${event.sportId}/${
        event.sport
      }/event/${event.sportCategoryId}/${event.tournamentId}/${event.eventId}`
    );
  };

  open = () => {
    this.setState({
      show: true,
    });
  };

  close = (e) => {
    this.setState({
      show: false,
    });
  };

  onVisibleChange = () => {
    this.setState({
      show: false,
    });
    this.searchInput.current.input.state.value = "";
  };

  render() {
    const { data, value, results } = this.state;
    const { getSearchedData, searchResult, loading } = this.props;
    const loadMore =
      results.length !== data.length && data && !loading ? (
        <div
          className="load-more"
          style={{
            textAlign: "center",
            height: 32,
            lineHeight: "32px",
          }}
        >
          <div
            className="light-on"
            style={{ color: "var(--black-to-white)" }}
            onClick={this.onLoadMore}
          >
            Show More
          </div>
        </div>
      ) : null;
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    return (
      <div className="search-box">
        <Popover
          overlayClassName="search-popover-hidden"
          visible={this.state.show}
          placement="bottomLeft"
          trigger="click"
          onVisibleChange={this.onVisibleChange}
        >
          <Popover
            overlayClassName="search-popover"
            title={"Results for " + value}
            placement="bottomLeft"
            visible={this.state.show}
            content={
              searchResult && searchResult.length > 0 ? (
                <List
                  className="search-list"
                  size="small"
                  loadMore={loadMore}
                  dataSource={results}
                  renderItem={(item) => (
                    <List.Item
                      onClick={() => {
                        this.handleSearchItem(item);
                        this.close();
                      }}
                    >
                      {item}
                    </List.Item>
                  )}
                />
              ) : (
                <div style={{ color: "var(--black-to-white)", width: 220 }}>
                  No Results, Please try again searching a Team name or bet #
                </div>
              )
            }
          >
            <Spin indicator={antIcon} spinning={loading}>
              <Input.Search
                placeholder="Search for ..."
                ref={this.searchInput}
                onChange={(e) => {
                  if (this.timeout) {
                    clearTimeout(this.timeout);
                  }
                  this.setState({ value: e.target.value });
                  if (e.target.value.length > 1) {
                    this.open();
                    let value = e.target.value.toLocaleLowerCase();

                    this.timeout = setTimeout(
                      () => {
                        let request = {
                          data: {
                            input: value,
                          },
                        };
                        getSearchedData(request);
                      },
                      500,
                      value
                    );
                  } else {
                    this.close();
                    this.setState({ data: [], results: [], value: "" });
                  }
                }}
              />
            </Spin>
          </Popover>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchResult: state.search.searchResult,
    eventsResult: state.search.eventsResult,
    loading: state.search.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSearchedData: (request) => dispatch(getSearchedData(request)),
    navigateSearchItem: (path) => dispatch(navigateSearchItem(path)),
    setEvent: (item) => dispatch(setEvent(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);

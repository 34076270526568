import { SignalRTypes } from './actionTypes';

export function jackpotConnect() {
    return {
        type: SignalRTypes.Jackpot_Connect_Request
    }
}


export function getJackpot() {
    return {
        type: SignalRTypes.Jackpot_Get_Request
    }
}


export function setJackpotWonMessages(messages) {
    return {
        type: SignalRTypes.Set_Jackpot_Won_Message,
        messages
    }
}


import { Layout, Input } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import StickyBox from "react-sticky-box";
import {
  Bets,
  BetSlip,
  EventsLive,
  Images,
  MenuTree,
  Screen,
  ScreenTitle,
  SportTypes,
  Search,
} from "../../components";
import { init } from "../../redux/actions/appActions";
import { setEvent } from "../../redux/actions/sportEventsActions";

const { Sider, Content } = Layout;

class Sport extends React.Component {
  checkBackButton = () => {
    const { match, history, init } = this.props;
    if (history.action === "POP") {
      init();
    }
  };

  async componentDidMount() {
    this.checkBackButton();
  }

  render() {
    const { match } = this.props;

    return (
      <Screen {...this.props}>
        <SportTypes {...this.props} />

        <Layout className="sport mtop-10">
          <Layout className="layout-inner">
            <Sider width={220} className="sider-left">
              <StickyBox offsetTop={140} offsetBottom={30}>
                <Search />
                <MenuTree {...this.props} />
              </StickyBox>
            </Sider>

            <Content className="content">
              <ScreenTitle
                {...this.props}
                title={match.params.sportName || `Live Betting`}
                imageIcon={Images.Icon_Soccer}
                imageTheme={Images.Strip_Soccer}
              />

              <Route
                exact
                path="/live/:feed/:sport/:sportName"
                component={EventsLive}
              />
              <Route
                exact
                path="/live/:feed/:sport/:sportName/events/:section/:country/:countryName/:tournament/:tournamentName"
                component={EventsLive}
              />
              <Route
                exact
                path="/live/:feed/:sport/:sportName/event/:section/:country/:tournament/:eventId"
                component={Bets.DrillDown}
              />
              <Route
                exact
                path="/live/:feed/:sport/:sportName/event/:country/:tournament/:eventId"
                component={Bets.DrillDown}
              />
            </Content>

            <Sider width={350} className="sider-right">
              <StickyBox offsetTop={140} offsetBottom={30}>
                <BetSlip {...this.props} />
              </StickyBox>
            </Sider>
          </Layout>
        </Layout>
      </Screen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    connecting: state.signalr.connecting,
    connected: state.signalr.connected,
    feedId: state.app.feedId,
    feedName: state.app.feedName,
    sportData: state.sportTypes.sportData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setEvent: (item) => {
      dispatch(setEvent(item));
    },
    init: () => {
      dispatch(init());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sport);

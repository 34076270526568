import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { Images } from '../../../components';
import { Link } from 'react-router-dom';


class Deposit extends React.Component {

    render() {

        return (

            <div className="form-container">
                <div className='deposit-main'>
                    <h1 className='deposit-title'>Please Choose your payment method:</h1>
                    <div className='menu' >
                        <Link className="text" to="/account/deposit/mtn" >
                            <img className='deposit-icon' alt="MTN" src={Images.MTN} />
                        </Link>

                        <Link className="text" to="/account/deposit/from-voucher" >
                            <img className='deposit-icon' alt="Vocher" src={Images.Voucher} />
                        </Link>
                    </div>
                    {/* <div className='deposit-menu'  >

                        <img className='deposit-icon'  alt="Orange" src={Images.Orange} />
                        <img  className='deposit-icon'  alt="TipMe" src={Images.TipMe} />
                    </div> */}
                </div>
            </div>
        )
    }
}

const MapStateToProps = (state, props) => {
    return {};
}

const mapDispatchToProps = (dispatch) => (
    {}
);

const WrappedDeposit = Form.create({ name: 'Deposit' })(Deposit);
export default connect(MapStateToProps, mapDispatchToProps)(WrappedDeposit)
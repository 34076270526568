import React from "react";
import { connect } from "react-redux";
import {
  getCountryPhoneProviders,
  getCountries,
} from "../../redux/actions/generalActions";
import { Input, Select, Form } from "antd";
import { CountryCodes } from "../../helpers";
import ArrowDropdown from "../../resources/icons/arrow-dropdown.svg";

const InputGroup = Input.Group;
const Option = Select.Option;
const allowdedPrefix = ["077" , "055" , "088"]
class PhoneNumber extends React.Component {
  componentDidMount() {
    this.props.getCountries();
  }

  componentDidUpdate() {
    let select = this.props.form.getFieldValue("select");
    if (
      this.props.countries &&
      this.props.prefixes.length === 0 &&
      select != undefined
    ) {
      let data = {
        id: this.props.form.getFieldValue("select"),
      };
      this.props.getCountryPhoneProviders(data);
    }
  }

  getCountryFlag = (name) => {
    let result = CountryCodes.Codes.filter((item) => {
      return item.name.toLowerCase() == name.toLowerCase();
    });

    return result && result.length > 0 ? result[0].code.toLowerCase() : "";
  };

  handlePrefix = (rule, value, callback) => {
    const form = this.props.form;
    const prefixes = this.props.prefixes;
    let prefix = form.getFieldValue("prefix");
    console.log("prefix" , prefix);
    if (prefix != undefined && prefix.length < 3) {
      callback("number must be 3 digits");
    } else if (
      prefix != undefined &&
      prefixes &&
      !prefixes.includes(prefix.toString())
    ) {
      callback(`Prefix  can only be ${prefixes.join(", ")}`);
    }
  };

  handleNumber = (rule, value, callback) => {
    const form = this.props.form;
    let phone = form.getFieldValue("phoneNum");
    // if (phone != undefined && phone[0] === '0') {
    //     callback("number can't begin with 0")
    // }
    if (phone != undefined && phone.length < 7) {
      callback("number must be 7 digits");
    }
  };

  checkPrefix = () => {
    let prefix = this.props.form.getFieldValue("prefix");
    let phoneNum = this.props.form.getFieldValue("phoneNum");
    let prefixError = this.props.form.getFieldError("prefix");
    if (phoneNum && phoneNum.length > 0) {
      return false;
    } else if (prefix && prefix.length === 3 && prefixError === undefined) {
      return false;
    }
    return true;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { countries } = this.props;

    return this.props.mode === "input" ? (
      <div className="phone-number-input-group" style={{position: "relative"}}>
      <InputGroup compact>
        {getFieldDecorator("select", {
          initialValue: 121,
          rules: [{ required: true }],
        })(
          <Select className="country-select" disabled>
            {countries
              ? countries.map((country, i) => (
                  <Option key={country.dailCode} value={country.countryId}>
                    <div
                      style={{ marginRight: 10 }}
                      className={
                        "flag-icon flag-icon-" +
                        this.getCountryFlag(country.countryName)
                      }
                    ></div>
                    <span style={{paddingRight:"15px"}}>{country.dailCode}</span>
                  </Option>
                ))
              : ""}
          </Select>
        )}

        {getFieldDecorator("prefix", {
          initialValue: "077",
          rules: [
            { required: true, message: "Must provide Prefix " },
            { validator: this.handlePrefix },
          ],
        })(
          <Select className="country-select" >
            {allowdedPrefix
              ? allowdedPrefix.map((prefix, i) => (
                  <Option key={prefix} value={prefix} >
                    {prefix}
                  </Option>
                ))
              : ""}
          </Select>
        )}

        {getFieldDecorator("phoneNum", {
          rules: [
            { required: true, message: "Must provide number " },
            { validator: this.handleNumber },
          ],
        })(
          <Input
            disabled={this.checkPrefix()}
            className="number-input"
            type="number"
            onInput={(e) => {
              if (e.target.value.length > 7) {
                e.target.value = e.target.value.slice(0, 7);
              }
            }}
          />
        )}
      </InputGroup>
      </div>

    ) : (
      <div className="phone-number-view">
        <Input
          addonBefore={
            <>
            <div style={{borderRight:"1px solid #FFFFFF"}}>
              <div 
                className={
                  "flag-icon flag-icon-" + this.getCountryFlag("Liberia")
                }
                // style={{ marginInlineEnd: 25 }}
              ></div>
              {this.props.value && <span> +{this.props.value.slice(1 , 4)}</span>}
              <img style={{padding:"0 5px 0 15px"}}src={ArrowDropdown} alt="" />
              </div>
            </>
          }
          disabled
          value={this.props.value && this.props.value.slice(5)}
        ></Input>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    prefixes: state.general.prefixes,
    countries: state.general.countries,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCountryPhoneProviders: (request) =>
    dispatch(getCountryPhoneProviders(request)),
  getCountries: () => dispatch(getCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumber);

import React from "react";
import { connect } from "react-redux";

import { Button, Icon } from "antd";

import { updateBetSlip } from "../../../redux/actions/betSlipActions";
import { SessionStorage } from "../../../helpers";

class EventOddField extends React.Component {
  state = {
    changeStatus: 0,
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.oddField.oddValue !== prevProps.oddField.oddValue) {
      this.setState({ changeStatus: this.props.oddField.oddValueStatus });
      this.timeout = setTimeout(() => this.setState({ changeStatus: 0 }), 2000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { oddField, showDetails, betSlipField, showFieldName } = this.props;
    // console.log("oddField",oddField);

    if (!oddField) {
      return (
        <Button disabled>
          <div className="lock">
            <Icon type="lock" />
          </div>
        </Button>
      );
    }
    return (
      <Button
        disabled={
          !oddField.enableBet ||
          !oddField.eventEnableBet ||
          !oddField.oddEnableBet
        }
        className={`${betSlipField ? "selected" : ""} ${
          this.state.changeStatus == 1 ? "up" : ""
        } ${this.state.changeStatus == -1 ? "down" : ""}`}
        onClick={(e) => this.onFieldClick(e, oddField)}
      >
        <div className="container">
          {(!oddField.enableBet ||
            !oddField.eventEnableBet ||
            !oddField.oddEnableBet) && (
            <div className="lock">
              <Icon type="lock" />
            </div>
          )}
          {oddField.enableBet &&
            oddField.eventEnableBet &&
            oddField.oddEnableBet && (
              <div className="value bold">{oddField.oddValue.format(2)}</div>
            )}
            {console.log("oddField" , oddField)}
        </div>
      </Button>
    );
  }

  onFieldClick(e, field) {
    e.preventDefault();
    e.stopPropagation();

    const { updateBetSlip } = this.props;
    let betTab = SessionStorage.betTab;
    if (betTab == 1) {
      updateBetSlip({
        ...field,
        amount: (!this.props.hideStake && this.props.amount) || 100,
      });
    } else {
      updateBetSlip(field);
    }
  }
}

const mapStateToProps = (_, initialProps) => (state) => {
  return {
    oddField:
      state.sportEvents.eventOddFields[
        `${initialProps.eventId}_${initialProps.oddId}`
      ] &&
      state.sportEvents.eventOddFields[
        `${initialProps.eventId}_${initialProps.oddId}`
      ][initialProps.oddFieldId],
    betSlipField: state.betSlip.betSlipList[initialProps.oddFieldId],
    amount: state.betSlip.amount,
    hideStake: state.betSlip.hideStake,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateBetSlip: (field) => {
      dispatch(updateBetSlip(field));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventOddField);

import { all, fork, takeEvery, put } from "redux-saga/effects";
import { slotsApi } from '../../api/index';
import { SignalRTypes } from '../actions';
import { SessionStorage } from "../../helpers";

export function* requests() {
    // yield takeEvery(SignalRTypes.Jackpot_Get_Request, function* () {
    //     //call server
    //     try {
    //         let response = yield slotsApi.getJackpots();

    //         if (response.status === 1) {
    //             var result = response.data && response.data.reduce(function(map, obj) {
    //                 map[obj.jackpotLevelName] = obj;
    //                 return map;
    //             }, {});
    //             yield put({ type: SignalRTypes.Jackpot_Get_Success, jackpotBalances:result,   winningMessage: [],
    //                 currentWin: SessionStorage.currentWin, 
    //                 jackpotNotificationIntervalSec : SessionStorage.jackpotNotificationIntervalSec})             
    //         }
    //         else {
    //             if (response.message) {
    //                 yield put({ type: SignalRTypes.Jackpot_Get_Error, message: response.message })
    //             }
    //         }
    //     }

    //     catch (error) {
    //         window.log('client ==>> could not get jackpot');
    //     }
    // })

}
export default function* JackpotSaga() {
	yield all([
		fork(requests)
	])
}
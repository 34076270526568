export default class Events {
	static _handlers = [];

	static subscribe = (eventTopic, handler) => {
		if (!Events._handlers[eventTopic])
			Events._handlers[eventTopic] = [];
		Events._handlers[eventTopic].push(handler);
	}

	static publish = (eventTopic, eventArgs) => {
		if (Events._handlers[eventTopic]) {

			Events._handlers[eventTopic].map(handler => {
				if (eventArgs)
					handler(eventArgs);
				else
					handler();
			});
		}
	}

	static unSubscribe =(eventTopic)=>{
		Events._handlers[eventTopic] = [];
	}
}
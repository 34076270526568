import React from 'react';
import { Images } from "../../components";

class ScreenTitle extends React.Component {

	render() {
		const { match, hideIcon, title } = this.props;

		let imageName = null;
		let image, icon = null;

		if (match && match.params.feed && match.params.sport)
			imageName = `${match.params.feed}${match.params.sport}`;

		try {
			image = require('../../resources/strips/' + imageName + '.webp');
		}
		catch (e) {
			image = null;
		}

		try {
			icon = require('../../resources/sport-icons/' + imageName + '.webp');
		}
		catch (e) {
			icon = require('../../resources/sport-icons/0.webp');
		}

		return (
			<div className="strip">
				<div className="title">
					{
						(!hideIcon && icon) &&
						<img src={icon} className="icon" />
					}

					{title}

					{
						(match && match.params.feed == 5) &&
						<div className="live">Live</div>
					}
				</div>
				{
					image &&
					<div className="images">
						<img className="pic" src={image} />
						<img className="mask" src={Images.Strip_Mask} />
					</div>
				}
			</div>
		)
	}
}

export default ScreenTitle
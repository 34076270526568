import React from "react";
import { connect } from "react-redux";
import { Modal, Steps } from "antd";
import {
  init,
  handleRegisterSignin,
} from "../../redux/actions/registerActions";
import { Step1, Step2, Step3, Step4, Step5 } from "./";
import { Alert } from "../../components";
const { Step } = Steps;

const steps = [
  {
    title: "Register Account",
    desc: "Step 1",
    content: <Step1 />,
  },
  {
    title: "SMS Verifacation",
    desc: "Step 2",
    content: <Step2 />,
  },
  // {
  // 	title: 'Phone Number',
  // 	desc: 'Step 3',
  // 	content: <Step3 />,
  // },
  {
    title: "Personal Info",
    desc: "Step 3",
    content: <Step4 />,
  },
];

class Register extends React.Component {
  constructor(props) {
    super(props);
  }

  // close modal
  close = () => {
    this.props.handleRegisterSignin();
    this.props.init();
    this.props.onCloseRegister();
  };

  render() {
    const { current, message, visible } = this.props;

    return visible ? (
      <Modal
        className="register"
        closable={true}
        onCancel={this.close}
        maskClosable={false}
        visible={visible}
        footer={null}
      >
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} description={item.desc} />
          ))}
        </Steps>

        <div className="steps-content">{steps[current].content}</div>
      </Modal>
    ) : null;
  }
}

const MapStateToProps = (state, props) => {
  return {
    message: state.register.message,
    current: state.register.current,
  };
};

const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(init()),
  handleRegisterSignin: () => dispatch(handleRegisterSignin()),
});

export default connect(MapStateToProps, mapDispatchToProps)(Register);

import { GeneralActionTypes } from './actionTypes'

export function getCurrencies() {
    return {
        type: GeneralActionTypes.Get_Currencies_Request
    }
}
export function getMapData(data) {
    return {
        type: GeneralActionTypes.Map_Request,
        data
    }
}

export function getBetSlipConfiguration(data) {
    return {
        type: GeneralActionTypes.Bet_Slip_Configuration_Request,
        data
    }
}

export function formStatuses(data) {
    return {
        type: GeneralActionTypes.Form_Statuses_Request,
        data
    }
}


export function pageName(data) {
    return {
        type: GeneralActionTypes.Page_Name_Request,
        data
    }
}

export function getWebFiles() {
    return {
        type: GeneralActionTypes.Web_Files_Request
    }
}

export function getWebFile(data) {
    return {
        type: GeneralActionTypes.Web_File_Request,
        data
    }
}

export function createBetCode() {
    return {
        type: GeneralActionTypes.Create_Bet_Code_Request
    }
}

export function betsByBetCode(request) {
    if (!request.id || request.id === undefined) {

        return {
            type: GeneralActionTypes.Bets_By_Bet_Code_Error,
            message: 'Please enter bet code'
        }
    }
    if (request.id.length !== 6) {
        return {
            type: GeneralActionTypes.Bets_By_Bet_Code_Error,
            message: 'Bet code must have 6 characters'
        }
    }
    return {
        type: GeneralActionTypes.Bets_By_Bet_Code_Request,
        request
    }
}

export function clearBetCode() {
    return {
        type: GeneralActionTypes.Bet_Code_Clear,
    }
}

export function getCountryPhoneProviders(data) {
    return {
        type: GeneralActionTypes.Get_CountryPhone_Providers_Request,
        data
    }
}

export function getCountries() {
    return {
        type: GeneralActionTypes.Get_Countries_Request
    }
}

export function getCounties() {
    return {
        type: GeneralActionTypes.Get_Counties_Request
    }
}
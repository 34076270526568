import {
  all
} from 'redux-saga/effects';

import AppSaga from './appSaga'
import SignalrSaga from './signalrSaga'
import HomeSaga from './homeSaga'
import VirtualSaga from './virtualSaga'
import SportSaga from './sportSaga'
import FeedChanges from './feedChangesSaga'
import SportTypes from './sportTypesSaga'
import Categories from './categoriesSaga'
import SportEvents from './sportEventsSaga'
import ContactUsSaga from './contactUsSaga';
import RegisterSaga from './registerSaga';
import membersSaga from './membersSaga';
import AuthSaga from './authSaga';
import GeneralSaga from './generalSaga';
import BetSlipSaga from './betSlipSaga';
import SearchSaga from './searchSaga';
import SlotsSaga from './slotsSaga';
import ModalSaga from './modalSaga';
import JackpotSaga from './jackpotSaga';

export default function* rootSaga(getState) {
  yield all([
    SignalrSaga(),
    GeneralSaga(),

    HomeSaga(),
    VirtualSaga(),
    SportSaga(),
    FeedChanges(),
    SportTypes(),
    Categories(),
    SportEvents(),
    ContactUsSaga(),
    RegisterSaga(),
    membersSaga(),
    AuthSaga(),
    BetSlipSaga(),
    AppSaga(),
    SearchSaga(),
    SlotsSaga(),
    ModalSaga(),
    JackpotSaga()
  ])
}
import React from 'react'
import { connect } from 'react-redux';

import Events from './Events'
import { loadSportEvents } from '../../../redux/actions/sportEventsActions';



class EventsSports extends React.Component {

	componentDidMount() {
		const { loadSportEvents, match, connected } = this.props;
		if (connected) {
			let request = {
				Data: {
					Feed: { FeedId: Number(match.params.feed) },
					Sport: { SportId: Number(match.params.sport) },
					SportCategory: { SportCategoryId: Number(match.params.country) },
					TournamentId: Number(match.params.tournament)
				}
			}
			loadSportEvents(request)
		}
	}

	componentDidUpdate(prevProps) {
		const { loadSportEvents, match,connected } = this.props;
		if ((prevProps.match.params.tournament !== this.props.match.params.tournament)
		|| (!prevProps.connected && connected)) {
			let request = {
				Data: {
					Feed: { FeedId: Number(match.params.feed) },
					Sport: { SportId: Number(match.params.sport) },
					SportCategory: { SportCategoryId: Number(match.params.country) },
					TournamentId: Number(match.params.tournament)
				}
			}
			loadSportEvents(request);
		}
	}

	render() {
		const { error } = this.props;

		if (error) {
			return (<div>{error}</div>)
		}
		return (
			<Events {...this.props}
				key="eventsSports" />
		)
	}

}

const mapStateToProps = (state) => {
	return {
		feedId: state.app.feedId,
		sportData: state.sportTypes.sportData,
		error: state.sportEvents.error,
		tournamentData: state.categories.tournamentData,
		connected: state.signalr.connected,

	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		loadSportEvents: (request) => {
			dispatch(loadSportEvents(request));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsSports);
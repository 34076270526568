import { all, fork, takeEvery, put } from "redux-saga/effects";
import { push } from 'react-router-redux'
import { SearchActionTypes } from '../actions';
import moment from 'moment';

export function* requests() {
    yield takeEvery(SearchActionTypes.GetSearchedData_Response, function* ({ response }) {
        try {
            window.console.response("search data", response)

            if (response) {
                response = response.data;
                let results = Object.keys(response).map(game => {
                    return {
                        id: response[game].eventBetLineNo,
                        category: response[game].eventCategory.categoryDisplayName,
                        sportCategoryId: response[game].eventCategory.sportCategoryId,
                        tourment: response[game].eventTournament.tournamentDisplayName,
                        tournamentId: response[game].eventTournament.tournamentId,
                        homeTeam: response[game].eventHomeTeamName,
                        awayTeam: response[game].eventAwayTeamName,
                        date: moment(response[game].eventDateOfMatchFormated, ['hh:mm a DD/MM/YYYY']).format('MMMM DD - HH:mm '),
                        sport: response[game].sport.sportDisplayName,
                        feedId: response[game].feed.feedId,
                        sportId: response[game].sport.sportId,
                        eventId: response[game].eventId,
                    }
                });

                let liveGames = [];
                let games = [];
                results.forEach(result => {
                    if (result.feedId === 5) {
                        liveGames.push(result);
                    } else {
                        games.push(result);
                    }
                });

                results = [...liveGames, ...games];

                yield put({ type: SearchActionTypes.GetSearchedData_Success, results: results, response: response })
            }
            else {
                yield put({ type: SearchActionTypes.GetSearchedData_Error })
            }
        }
        catch (error) {
            window.log('client =>> could not get search: ', error);
        }
    })
    yield takeEvery(SearchActionTypes.Navigate_Search_Item, function* ({ path }) {
        yield put(push(path))
    })



}
export default function* SearchSaga() {
    yield all([
        fork(requests)
    ])
}

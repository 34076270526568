import React from 'react'
import { connect } from 'react-redux';
import { Utils, Constants } from '../../../../helpers'
import Timer from '../Timer'
import { Bets } from '../../../'

class EventRow extends React.Component {

	render() {
		const { id, betTypes, sportEvent, selectedValues } = this.props;

		if (!sportEvent) {
			return (null);
		}
		return (
			<Bets.SportRow {...this.props} className={`row ${sportEvent.enableBet ? "" : "disabled"}`}>
				<div className="row-0">
					<div className="bet-num">{`ID: ${sportEvent.eventBetLineNo}`}</div>
				</div>

				<div className="row-1">

					<Bets.EventsCol className="col bet-type-0">
						<Bets.DefaultScore event={sportEvent} upcoming {...this.props} />
					</Bets.EventsCol>

				</div>

				<div className="row-2">
					{
						sportEvent.feed.feedId != 5 &&
						<div style={{ display: "flex" }}>
							<div className="date">{Utils.formatDate(sportEvent.eventDateOfMatch)}</div>
							<div className="league">
								{sportEvent.eventCategory.categoryDisplayName} / {sportEvent.eventTournament.tournamentDisplayName}
							</div>
						</div>
					}
					{
						sportEvent.feed.feedId == 5 &&
						<div style={{ display: "flex" }}>
							<div className="live">Live</div>
							{/* <div className="date">
								{`${sportEvent.eventStatus && sportEvent.eventStatus.eventStatusDisplayName}`}
							</div> */}
							<Timer time={sportEvent.eventTimeToStart} type="down"></Timer>
							<div className="league">
								{sportEvent.eventCategory.categoryDisplayName} / {sportEvent.eventTournament.tournamentDisplayName}
							</div>
						</div>
					}
				</div>
			</Bets.SportRow>
		)
	}
}

const mapStateToProps = (_, initialProps) => (state) => {
	return {
		sportEvent: state.sportEvents.upcomingEvents[initialProps.id],
	}
}
const mapDispatchToProps = (dispatch) => {
	return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(EventRow);
import { ModalActionTypes } from '../actions';

const initState = {
    modals: [],
    type: ''
};

const sport = (state = initState, action) => {

    switch (action.type) {

        case ModalActionTypes.Open_Modal_Success:
            return {
                ...state,
                modals: action.modals
            }
        case ModalActionTypes.Close_Modal_Success:
            return {
                ...state,
                modals: action.modals
            }
        default:
            return { ...state };

    }
}

export default sport;
import { Layout, Input, Form, Button, Tooltip, Popconfirm, Label } from "antd";
import React from "react";
import { connect } from "react-redux";
import { LoginAndRegistrationScreen } from "../../components";
// import { Steps } from "antd";
import LoginForm from "./LoginForm";
import ForgetPassword from "./ForgetPassword";
import { Images } from "../../components";
import RegisterUser from './RegisterUser'


const { Content } = Layout;

class loginAndRegistration extends React.Component {
  state = {
    validUsername: true,
    tooltipUsername: null,
    validPassword: true,
    tooltipPassword: null,
    showRegister: false,
    showForgotPassword: false,
    loginPopoverOpen: false,
    showLoginError: false,
    showCurrencyPopup: false,
    setCurrency: "",
    bounce: false,
    accountVisible: false,
    showBalance: true,
    indicator: ''
  };
  componentDidMount = ()=> {
    window.location.pathname.split('/')[2] == '1' ?
    this.setState({indicator: 'login'}):this.setState({indicator: 'register'})
  }

  onClickForgotPassword = () => {
    this.setState({
      showForgotPassword: true,
      loginPopoverOpen: false,
    });
  };
  showLoginForm = () => {
    this.setState({
      showForgotPassword: false,
    });
  };
  showPassword = (inputId, iconId) => {
    const inputElement = document.getElementById(inputId);
    const iconElement = document.getElementById(iconId);
    const imgElement = document.createElement("img");
    imgElement.src = Images.ShowPasswordIcon;
    // console.log(iconElement.src,"==========" ,<img src={Images.ShowPasswordIcon}/> );
    if (iconElement.src == imgElement.src) {
      iconElement.src = Images.HidePasswordIcon;
    } else {
      iconElement.src = Images.ShowPasswordIcon;
    }
    if (inputElement.type == "password") {
      inputElement.type = "text";
    } else {
      inputElement.type = "password";
    }
  };

  render() {
    const { signedIn, history, current } = this.props;

    console.log("signedIn", signedIn);

    // if (signedIn) {
    //   history.push("/");
    // }
    return (
      <LoginAndRegistrationScreen
        showLoginForm={this.showLoginForm}
        showForgotPassword={this.state.showForgotPassword}
        {...this.props}
        indicator = {this.state.indicator}
      >
        <Layout className="sport mtop-10">
          <Layout className="layout-inner">
            <Content>
              <Layout>
                <Content className="content">
                  <div id="login-and-regisration-container">
                    {(this.state.indicator == 'login' && !this.state.showForgotPassword) ? (
                      <LoginForm
                        showPassword={this.showPassword}
                        history = {history}
                        onClickForgotPassword={this.onClickForgotPassword}
                        state={this.state}
                      />
                    ):this.state.indicator == 'register' && <div id="register-user-container"><RegisterUser/></div>}
                    {this.state.showForgotPassword && <ForgetPassword />}
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </Layout>
      </LoginAndRegistrationScreen>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentGame: state.slots.currentGame,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(loginAndRegistration);

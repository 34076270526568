import { GeneralActionTypes } from '../actions';
import { SessionStorage } from '../../helpers';

const initState = {
    map: null,
    betSlipConfiguration: null,
    doc: null,
    webFiles: null,
    url: null,
    fileName: '',
    betCode: SessionStorage.betCode || '',
    bets: '',
    message: '',
    betCodeError: '',
    drillDownGroups: [],
    drillDownGroupsError: '',
    currencies: '',
    prefixes: '',
    countries: '',
    counties: '',
    configuration: {}
}

const general = (state = initState, action) => {
    switch (action.type) {

        case GeneralActionTypes.Get_Currencies_Error:
            return {
                ...state,
                message: action.message,
                currencies: ''
            }

        case GeneralActionTypes.Get_Currencies_Success:
            return {
                ...state,
                currencies: action.data
            }

        case GeneralActionTypes.Map_Success:
            return {
                ...state,
                map: action.data
            }

        case GeneralActionTypes.Bet_Slip_Configuration_Success:
            return {
                ...state,
                betSlipConstants: action.betSlipConstants,
                betsRestrictions: action.betsRestrictions,
                minimumBetsAmount: action.minimumBetsAmount,
            }
        case GeneralActionTypes.Form_Statuses_Success: {
            return {
                ...state,
                statuses: action.data
            }
        }
        case GeneralActionTypes.Page_Name_Success:
            return {
                ...state,
                doc: action.data
            }
        case GeneralActionTypes.Web_Files_Success:
            return {
                ...state,
                webFiles: action.data
            }
        case GeneralActionTypes.Web_File_Response:
            return {
                ...state,
                url: action.url,
                fileName: action.fileName
            }
        case GeneralActionTypes.Create_Bet_Code_Request:
            return {
                ...state,
                creatBetCodeLoading: true,
                message: ''
            }
        case GeneralActionTypes.Create_Bet_Code_Success:
            return {
                ...state,
                betCode: action.code,
                message: '',
                creatBetCodeLoading: false,
            }
        case GeneralActionTypes.Create_Bet_Code_Error:
            return {
                ...state,
                message: action.message,
                creatBetCodeLoading: false,
            }
        case GeneralActionTypes.Bets_By_Bet_Code_Success:
            return {
                ...state,
                bets: action.data,
                betCodeError: ''
            }
        case GeneralActionTypes.Bets_By_Bet_Code_Error:
            return {
                ...state,
                betCodeError: action.message
            }
        case GeneralActionTypes.Remove_BetCode:
            return {
                ...state,
                betCode: null
            }
        case GeneralActionTypes.Get_DrillDown_Groups_Success:
            return {
                ...state,
                drillDownGroups: action.data,
            }
        case GeneralActionTypes.Get_DrillDown_Groups_Error:
            return {
                ...state,
                drillDownGroupsError: action.message
            }
        case GeneralActionTypes.Get_Configuration_Success:
            return {
                ...state,
                configuration: action.data,
            }
        case GeneralActionTypes.Get_Configuration_Error:
            return {
                ...state,
                configurationError: action.message
            }
        case GeneralActionTypes.Get_CountryPhone_Providers_Success:
            return {
                ...state,
                prefixes: action.prefixes
            }
        case GeneralActionTypes.Get_Countries_Success:
            return {
                ...state,
                countries: action.countries
            }

        case GeneralActionTypes.Get_Counties_Success:
            return {
                ...state,
                counties: action.counties
            }
        default:
            return state
    }
}

export default general
/* eslint-disable require-yield */
/* eslint-disable default-case */
import * as signalR from '@microsoft/signalr';
import { eventChannel, END, delay } from 'redux-saga';
import { all, call, fork, put, select, take, takeEvery } from "redux-saga/effects";
import { push } from 'react-router-redux';

import { Utils, Database, Cookies, AppConfig, Enums, SessionStorage } from "../../helpers";
import { AppActionTypes, CategoriesActionTypes, SignalRTypes, SlotsActionTypes, SportEventsActionTypes, MembersActionTypes, AuthActionTypes, SearchActionTypes } from '../actions';

export function* requests() {
	let hubConnection = null;
	let BooongoHubConnection = null;
	let booongoChannel = null;
	let JackpotHubConnection = null;
	let jackpotChannel = null;
	let chan = null;
	let connectInterval = null;
	let isActive = true;


	function connect() {
		switch (hubConnection.connectionState) {
			case  'Disconnected':
				
					hubConnection.start().then(() => {
						if (hubConnection.onopen)
							hubConnection.onopen();

					}).catch(error => {
						hubConnection.onopen(error);
					});
					break;	
		}
	}
	function booongoConnect() {
		switch (BooongoHubConnection.connectionState) {
			case  'Disconnected':
				
					BooongoHubConnection.start().then(() => {
						if (BooongoHubConnection.onopen)
							BooongoHubConnection.onopen();

					}).catch(error => {
						BooongoHubConnection.onopen(error);
					});
					break;
				
		}
	}

	function jackpotConnect() {
		switch (JackpotHubConnection.connectionState) {
			case  'Disconnected':
				
					JackpotHubConnection.start().then(() => {
						if (JackpotHubConnection.onopen)
							JackpotHubConnection.onopen();

					}).catch(error => {
						JackpotHubConnection.onopen(error);
					});
					break;
				
		}
	}


	function handleSignalrExpiresOnClick(emitter, e, timeout) {
		if (isActive) {
			clearTimeout(connectInterval);
			connectInterval = setTimeout(() => {
				isActive = false
				emitter({ name: 'timeout' })
			}, timeout);
		} else {
			isActive = true;
			clearTimeout(connectInterval);
			emitter({ name: 'reconnect' })
		}
	}

	function* registerSignalRChannel() {
		return eventChannel(emitter => {
			
			if (!hubConnection) {
				hubConnection = new signalR.HubConnectionBuilder()
					.configureLogging(Utils.InDev ? signalR.LogLevel.Information : signalR.LogLevel.None)
					.withUrl(AppConfig.srAddress, {skipNegotiation:true, transport: signalR.HttpTransportType.WebSockets})
					.withAutomaticReconnect()
					.build();
				hubConnection.serverTimeoutInMilliseconds = 60000
			}
			hubConnection.onreconnected((connectionId) => {
				window.log("*******ON RECONNECTED*******: Feed Changes - client reconnected. connectionId ", connectionId)
				emitter({
					name: 'onConnect',
					connectionState: hubConnection.state
				})
				//Set no activity timout for 4.5 minutes
				clearTimeout(connectInterval);

			});

			hubConnection.onreconnecting((error) => {
				error && window.log(`*******ON RECONNECTING*******:  Feed Changes - client connect error ${error.toString()}, reconnecting... `)

				emitter({
					name: 'onDisconnect',
					connectionState: hubConnection && hubConnection.state,
					response: error
				})
			});
			hubConnection.onopen = (error) => {
				const tokenExpiresMinutes = Cookies.get("tokenExpiresMinutes");
				let timeout = (tokenExpiresMinutes || 5) * 60 * 1000;
				if (!error) {
					emitter({
						name: 'onConnect',
						connectionState: hubConnection.state
					})

					//Set no activity timout for 4.5 minutes
					// clearTimeout(connectInterval);
					// connectInterval = setTimeout(() => {
					// 	if (isActive) {
					// 		isActive = false
					// 		emitter({ name: 'timeout' })
					// 	}
					// }, timeout);
					// document.addEventListener("click", (e) => handleSignalrExpiresOnClick(emitter, e, timeout));
				}
				else {
					setTimeout(() => { connect() }, 5000);
					emitter({
						name: 'onConnectFailed',
						connectionState: hubConnection.state,
						response: error
					})
					error && console.log("*******CONNECTION FAILED*******: ", error.toString())
				}
			}

			hubConnection.onclose(error => {
				emitter({
					name: 'onDisconnect',
					connectionState: hubConnection && hubConnection.state,
					response: error
				})
				error && console.log("*******ON DISCONNECT*******: ", error.toString())
			})

			hubConnection.on("OnConnectionSlow", data => {
				emitter({
					name: 'onConnectionSlow',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})

			hubConnection.on("OnError", data => {
				emitter({
					name: 'onError',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})

			hubConnection.on("OnSportTypes", data => {
				emitter({
					name: 'onSportTypes',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})

			hubConnection.on("OnSportCategories", data => {
				emitter({
					name: 'onSportCategories',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})

			hubConnection.on("OnSportEvents", data => {
				emitter({
					name: 'onSportEvents',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})

			hubConnection.on("OnTopBets", data => {
				emitter({
					name: 'onSportEvents',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})


			hubConnection.on("OnAllBetTypes", data => {
				emitter({
					name: 'onAllBetTypes',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})
			hubConnection.on("OnSportEventsByDate", data => {
				emitter({
					name: 'onSportEventsByDate',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})
			hubConnection.on("OnEventOddFieldData", data => {
				emitter({
					name: 'onEventOddFieldData',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})


			hubConnection.on("OnJoinGroup", data => {
				emitter({
					name: 'onJoinGroup',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})

			hubConnection.on("OnPartGroup", data => {
				emitter({
					name: 'onPartGroup',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})

			// hubConnection.on("OnFeedChanges", data => {
			// 	emitter({
			// 		name: 'onFeedChanges',
			// 		connectionState: hubConnection.state,
			// 		response: Utils.inflate(data)
			// 	})
			// })

			hubConnection.on("OnSportEventsChange", data => {
				emitter({
					name: 'onSportEventsChange',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})
			hubConnection.on("OnEventOddsChange", data => {
				emitter({
					name: 'onEventOddsChange',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})
			hubConnection.on("OnEventOddGroupsChange", data => {
				emitter({
					name: 'onEventOddGroupsChange',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})
			hubConnection.on("OnEventOddFieldsChange", data => {
				emitter({
					name: 'onEventOddFieldsChange',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})
			hubConnection.on("OnEventScoreChange", data => {
				emitter({
					name: 'onEventScoreChange',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})
			hubConnection.on("OnSportTournamentsChange", data => {
				emitter({
					name: 'onSportTournamentsChange',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})
			hubConnection.on("OnTopBetTypesChange", data => {
				emitter({
					name: 'onTopBetTypesChange',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})
			hubConnection.on("OnSportTypesChange", data => {
				emitter({
					name: 'onSportTypesChange',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})
			hubConnection.on("OnSportCategoriesChange", data => {
				emitter({
					name: 'onSportCategoriesChange',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})
			hubConnection.on("OnSportTopLeaguesChange", data => {
				emitter({
					name: 'onSportTopLeaguesChange',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})
			hubConnection.on("OnUpcomingEventsChange", data => {
				emitter({
					name: 'onUpcomingEventsChange',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})

			hubConnection.on("OnAllCacheData", data => {
				emitter({
					name: 'onAllCacheData',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})

			hubConnection.on('OnSearchedData', data => {
				emitter({
					name: 'onSearchedData',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})

			hubConnection.on('OnProducerDown', data => {
				emitter({
					name: 'OnProducerDown',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})
			hubConnection.on('OnProducerUp', data => {
				emitter({
					name: 'OnProducerUp',
					connectionState: hubConnection.state,
					response: Utils.inflate(data)
				})
			})


			//Connect to hub
			connect();

			// The subscriber must return an unsubscribe function
			return () => {
				hubConnection.stop();
				console.log("*******FeedChangeHubConnection.stop(); return*******: ")
				hubConnection = null;
				clearTimeout(connectInterval);
				document.removeEventListener("click", (e) => handleSignalrExpiresOnClick(emitter, e));

			}
		})
	}
	function* registerBooongoChannel() {
		if (AppConfig.booongoSRAddress) {
			return eventChannel(emitter => {

				if (!BooongoHubConnection) {
					BooongoHubConnection = new signalR.HubConnectionBuilder()
						.configureLogging(signalR.LogLevel.Information)
						.withUrl(AppConfig.booongoSRAddress)
						.withAutomaticReconnect()
						.build();
					BooongoHubConnection.serverTimeoutInMilliseconds = 60000
				}
	BooongoHubConnection.onreconnected((connectionId) => {
				console.log("BOOONGO SIGNALR: client reconnected. connectionId ", connectionId)
				emitter({
					name: 'onBooongoConnect',
					connectionState: BooongoHubConnection.state
				})
				//Set no activity timout for 4.5 minutes
				clearTimeout(connectInterval);

			});

			BooongoHubConnection.onreconnecting((error) => {
				error && console.log(`BOOONGO SIGNALRr - client connect error ${error.toString()}, reconnecting... ` )

				emitter({
					name: 'onBooongoDisconnect',
					connectionState: BooongoHubConnection && BooongoHubConnection.state,
					response: error
				})
			});

				BooongoHubConnection.onopen = (error) => {
					if (!error) {
						emitter({
							name: 'onBooongoConnect',
							connectionState: BooongoHubConnection.state
						})
					}
					else {
						setTimeout(() => { booongoConnect() }, 5000);
						emitter({
							name: 'onBooongoConnectFailed',
							connectionState: BooongoHubConnection.state,
							response: error
						})
					}
				}

				BooongoHubConnection.onclose(error => {
					emitter({
						name: 'onBooongoDisconnect',
						connectionState: BooongoHubConnection && BooongoHubConnection.state,
						response: error
					})
				})

				BooongoHubConnection.on("OnError", data => {
					emitter({
						name: 'onError',
						connectionState: BooongoHubConnection.state,
						response: Utils.inflate(data)
					})
				})

				BooongoHubConnection.on("OnEntityBalances", data => {
					emitter({
						name: 'onEntityBalances',
						connectionState: BooongoHubConnection.state,
						response: Utils.inflate(data)
					})
				})
				//Connect to hub
				booongoConnect();

				// The subscriber must return an unsubscribe function
				return () => {
					BooongoHubConnection.stop();
					BooongoHubConnection = null;

				}
			})
		}

	}
	function* registerJackpotChannel() {
		return eventChannel(emitter => {

			if (!JackpotHubConnection) {
				JackpotHubConnection = new signalR.HubConnectionBuilder()
					.configureLogging(signalR.LogLevel.Information)
					.withUrl(AppConfig.jackpotSRAddress)
					.withAutomaticReconnect()
					.build();
				JackpotHubConnection.serverTimeoutInMilliseconds = 60000
			}
	JackpotHubConnection.onreconnected((connectionId) => {
				window.log("JACKPOT SIGNALR: client reconnected. connectionId ", connectionId)
				emitter({
					name: 'onJackpotConnect',
					connectionState: JackpotHubConnection.state
				})
				//Set no activity timout for 4.5 minutes
				clearTimeout(connectInterval);

			});

			JackpotHubConnection.onreconnecting((error) => {
				error && window.log(`JACKPOT SIGNALR - client connect error ${error.toString()}, reconnecting... ` )

				emitter({
					name: 'onJackpotDisconnect',
					connectionState: JackpotHubConnection && JackpotHubConnection.state,
					response: error
				})
			});

			JackpotHubConnection.onopen = (error) => {
				if (!error) {
					emitter({
						name: 'onJackpotConnect',
						connectionState: JackpotHubConnection.state
					})
				}
				else {
					setTimeout(() => { jackpotConnect() }, 5000);
					emitter({
						name: 'onJackpotConnectFailed',
						connectionState: JackpotHubConnection.state,
						response: error
					})
				}
			}

			JackpotHubConnection.onclose(error => {
				emitter({
					name: 'onJackpotDisconnect',
					connectionState: JackpotHubConnection && JackpotHubConnection.state,
					response: error
				})
			})

			JackpotHubConnection.on("OnError", data => {
				emitter({
					name: 'onError',
					connectionState: JackpotHubConnection.state,
					response: Utils.inflate(data)
				})
			})

			JackpotHubConnection.on("OnJackpotWinning", data => {
				emitter({
					name: 'onJackpotWinning',
					connectionState: JackpotHubConnection.state,
					response: Utils.inflate(data)
				})
			})
			//Connect to hub
			jackpotConnect();

			// The subscriber must return an unsubscribe function
			return () => {
				JackpotHubConnection.stop();
				JackpotHubConnection = null;
				clearTimeout(connectInterval);

			}
		})
	}

	yield takeEvery(SignalRTypes.Connect_Request, function* () {
		try {
			if (chan) {
				chan.close()
			}
			// Prevent registering SignalR channel before setting the srAddress
			if (!AppConfig.srAddress) {
				return;
			}
			chan = yield call(registerSignalRChannel);

			while (true) {
				let event = yield take(chan);

				yield put({
					type: AppActionTypes.SetServerTime,
					serverTime: event.response && event.response.serverDateTime
				})

				switch (event.name) {
					case "onConnect":

						yield put({
							type: SignalRTypes.Connect_Success,
						})
						break;

					case "onError":
						window.console.response("SignalR Error", event.response);
						break;

					case "onConnectFailed":
						yield put({
							type: SignalRTypes.Connect_Error,
							response: event.response
						})
						break;
					case "onConnectionSlow":
						yield put({
							type: SignalRTypes.Connect_Slow,
							response: event.response
						})
						break;

					case "onDisconnect":
						yield put({
							type: SignalRTypes.Disconnect_Success,
						})
						if (isActive) {
							setTimeout(() => { connect() }, 5000);
						}

						break;

					case "onSportTypes":
						yield put({
							type: SignalRTypes.SportTypes_Response,
							response: event.response
						})
						break;

					case "onSportCategories":
						yield put({
							type: SignalRTypes.SportCategories_Response,
							response: event.response
						})
						break;

					case "onSportEvents":
						yield put({
							type: SignalRTypes.SportEvents_Response,
							response: event.response
						})
						break;

					case "onAllBetTypes":
						yield put({
							type: SignalRTypes.AllBetTypes_Response,
							response: event.response
						})
						break;

					case "onSportEventsByDate":
						yield put({
							type: SignalRTypes.SportEvents_Response,
							response: event.response
						})
						break;
					case "onEventOddFieldData":
						yield put({
							type: SignalRTypes.Get_Updated_OddField_Details_Response,
							response: event.response
						})
						break;

					case "onJoinGroup":
						yield put({
							type: SignalRTypes.Join_Response,
							response: event.response
						})
						break;
					case "onPartGroup":
						yield put({
							type: SignalRTypes.Part_Response,
							response: event.response
						})
						break;

					// case "onFeedChanges":
					// 	yield put({
					// 		type: SignalRTypes.OnFeedChanges,
					// 		response: event.response
					// 	})
					// 	break;

					////////////////////////////
					case "onSportEventsChange":
						yield put({
							type: SignalRTypes.OnSportEventsChange,
							response: event.response
						})
						break;
					case "onEventOddsChange":
						yield put({
							type: SignalRTypes.OnEventOddsChange,
							response: event.response
						})
						break;
					case "onEventOddGroupsChange":
						yield put({
							type: SignalRTypes.onEventOddGroupsChange,
							response: event.response
						})
						break;
					case "onEventOddFieldsChange":
						yield put({
							type: SignalRTypes.OnEventOddFieldsChange,
							response: event.response
						})
						break;
					case "onEventScoreChange":
						yield put({
							type: SignalRTypes.OnEventScoreChange,
							response: event.response
						})
						break;
					case "onSportTournamentsChange":
						yield put({
							type: SignalRTypes.OnSportTournamentsChange,
							response: event.response
						})
						break;
					case "onTopBetTypesChange":
						yield put({
							type: SignalRTypes.OnTopBetTypesChange,
							response: event.response
						})
						break;
					case "onSportTypesChange":
						yield put({
							type: SignalRTypes.OnSportTypesChange,
							response: event.response
						})
						break;
					case "onSportCategoriesChange":
						yield put({
							type: SignalRTypes.OnSportCategoriesChange,
							response: event.response
						})
						break;
					case "onSportTopLeaguesChange":
						yield put({
							type: SignalRTypes.OnSportTopLeaguesChange,
							response: event.response
						})
						break;
					case "onUpcomingEventsChange":
						yield put({
							type: SignalRTypes.OnUpcomingEventsChange,
							response: event.response
						})
						break;
					//////////////////////////////////////


					case "onTimer":
						yield put({
							type: SignalRTypes.OnTimer
						})
						break;
					case "timeout":
						yield put({
							type: SignalRTypes.Disconnect_Request,
						})
						clearTimeout(connectInterval);
						break;
					case "reconnect":
						yield put({
							type: SignalRTypes.Re_Connect,
						})
						clearTimeout(connectInterval);
						break;

					case "onAllCacheData":
						yield put({
							type: SignalRTypes.GetAllData_Response,
							response: event.response
						})
						break;
					case "onSearchedData":

						yield put({
							type: SearchActionTypes.GetSearchedData_Response,
							response: event.response
						})
						break;
					case "OnProducerDown":
						yield put({
							type: SignalRTypes.Producer_Down,
							response: event.response.data
						})

						window.log("*********Producer Down*************")

						break;
					case "OnProducerUp":
						yield put({
							type: SignalRTypes.Producer_Up,
							response: event.response.data
						})

						window.log("*********Producer Up*************")


						break;
				}

			}
		} catch (e) {
			window.log("ERROR", e)
			yield put({ type: SignalRTypes.Connect_Error })
		}
	})
	yield takeEvery(SignalRTypes.Booongo_Connect_Request, function* () {
		try {
			if (booongoChannel) {
				booongoChannel.close()
			}
			booongoChannel = yield call(registerBooongoChannel);

			while (true) {
				let event = yield take(booongoChannel);

				switch (event.name) {
					case "onBooongoConnect":

						yield put({
							type: SignalRTypes.Booongo_Connect_Success,
						})
						console.log("*BOOONGO SIGNALR CONNECTION TRUE*")
						break;

					case "onError":
						window.console.response("Booongo Error", event.response);
						break;

					case "onBooongoConnectFailed":
						yield put({
							type: SignalRTypes.Booongo_Connect_Error,
							response: event.response
						})
						break;

					case "onBooongoDisconnect":
						yield put({
							type: SignalRTypes.Booongo_Disconnect_Success,
						})
						setTimeout(() => { booongoConnect() }, 5000);
						break;

					case "onEntityBalances":
						yield put({
							type: SignalRTypes.Update_Entity_Balance,
							response: event.response
						})
						break;
				}

			}
		} catch (e) {
			window.log("ERROR", e)
			yield put({ type: SignalRTypes.Booongo_Connect_Error })	
		}
	})
	yield takeEvery(SignalRTypes.Jackpot_Connect_Request, function* () {
		try {
			if (jackpotChannel) {
				jackpotChannel.close()
			}
			jackpotChannel = yield call(registerJackpotChannel);

			while (true) {
				let event = yield take(jackpotChannel);

				switch (event.name) {
					case "onJackpotConnect":

						yield put({
							type: SignalRTypes.Jackpot_Connect_Success,
						})
						break;

					case "onError":
						window.console.response("Jackpot Error", event.response);
						break;

					case "onJackpotConnectFailed":
						yield put({
							type: SignalRTypes.Jackpot_Connect_Error,
							response: event.response
						})
						break;

					case "onJackpotDisconnect":
						yield put({
							type: SignalRTypes.Jackpot_Disconnect_Success,
						})
						setTimeout(() => { jackpotConnect() }, 5000);
						break;

					case "onJackpotWinning":
						yield put({
							type: SignalRTypes.Jackpot_Update,
							response: event.response
						})
						break;
				}

			}
		} catch (e) {
			window.log("ERROR", e)
		}
	})
	//******************** Booongo hub functions *********************//
	yield takeEvery(SignalRTypes.Booongo_Connect_Success, function* () {
		try {
			window.log("booongo connect success")
			if (Cookies.get("token")) {
				const memberId = Cookies.get("memberId");
				window.log(`T2ID${memberId}`);
				yield BooongoHubConnection.send("JoinGroup", `T2ID${memberId}`);
			}

		}
		catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.Booongo_Disconnect_Request, function* () {
		try {
			BooongoHubConnection.stop();

		}
		catch (e) {
			window.log("ERROR", e)
		}
	})
	yield takeEvery(SignalRTypes.Update_Entity_Balance, function* ({ response }) {
		try {
			window.console.response("Balance slot update", response);
			if (response[0]) {
				//window.log("**** Finish **** ", response[0].roundFinished);
				yield put({
					type: SlotsActionTypes.Set_Slots_Round_Finish, roundFinished: response[0].roundFinished,
					gameId: response[0].gameId, gameName: response[0].gameName
				})
			}

			if (response && response.length > 0) {
				let { jackpot } = yield select();

				let jackpotWinningApproved = response.some(item => item.jackpotWinningApproved === true)
				yield response.map((item) => {
					let user = Cookies.get('user');
					let selectedCurrency = Cookies.get('selectedCurrency');
					if (user) {
						let balances = user.balance;
						Object.values(balances).map((balance, index) => {
							if (balance.currencyId === item.currencyId) {
								balance.balance = item.balance;
							}
						})

						user.balance = balances;
						Cookies.set("user", user);
						let currentCurrency = Object.keys(balances).find((balance) => {
							return balances[balance].currencySymbol === selectedCurrency.currencySymbol
						})
						Cookies.set('selectedCurrency', balances[currentCurrency]);
						return put({ type: MembersActionTypes.Member_Update_Balance, balance: balances[currentCurrency].balance });
					}
				})
				if (jackpotWinningApproved) {
					const { auth } = yield select()
					if (auth.signedIn) yield put({ type: AuthActionTypes.Start_Click_listener })
					yield put({ type: SignalRTypes.Set_Is_Jackpot_Won, currentWin: false })
					SessionStorage.currentWin = false
				}
				SessionStorage.jackpotWinningApproved = jackpotWinningApproved
				if (jackpot.jackpotWinningApproved !== jackpotWinningApproved) yield put({ type: SignalRTypes.Jackpot_Winning_Approved, jackpotWinningApproved })
			}
		}
		catch (e) {
			window.log("ERROR", e)
		}
	})
	//******************** Jackpot hub functions *********************//
	yield takeEvery(SignalRTypes.Jackpot_Connect_Success, function* () {
		try {
			window.log("jackpot connect success")
			const memberId = Cookies.get("memberId");
			window.log(`T2ID${memberId}`);
			//yield put({ type: SignalRTypes.Jackpot_Get_Request })

			yield JackpotHubConnection.send("JoinGroup", `T2ID${memberId}_Win`)
			window.log("website display")

		}
		catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.Jackpot_Disconnect_Request, function* () {
		try {
			JackpotHubConnection.stop();
		}
		catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.Jackpot_Update, function* ({ response }) {
		try {
			let { jackpot } = yield select();
			//TODO: handle winning jackpot update.
			window.console.response("jackpot update", response);

			if (response.isWon) {
				const memberId = Cookies.get("memberId");
				const currency = Cookies.get('selectedCurrency')

				if (response.winnerMemberId == memberId) {
					SessionStorage.currentWin = true;
					yield put({ type: SignalRTypes.Jackpot_Get_Success, winningMessage: `You Won ${response.payout} ${currency.currencySymbol}, The ${response.jackpotName} Jackpot winner` })
				}
				//if (auth.signedIn) yield put({ type: AuthActionTypes.Stop_Click_listener })
			}
			else {
				yield put({
					type: SignalRTypes.Jackpot_Get_Success,
					winningMessage: []
				});//update jackpot balances list.
				SessionStorage.currentWin = false;

			}
		}
		catch (e) {
			window.log("ERROR", e)
		}
	})
	yield takeEvery(AppActionTypes.Start, function* () {
		yield put({
			type: SignalRTypes.Connect_Request
		})

		if (Cookies.get("token") && AppConfig.booongoSRAddress) {
			yield put({ type: SignalRTypes.Booongo_Connect_Request })
			yield put({ type: SignalRTypes.Jackpot_Connect_Request });
		}
	})

	yield takeEvery(SignalRTypes.Connect_Success, function* () {
		try {

			// console.request("GetAllData", { test: 123 });
			// yield delay(1000);
			// yield hubConnection.send("GetAllCacheData");
		}
		catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.Disconnect_Success, function* () {
		try {
			//hubConnection.start();
		}
		catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.Re_Connect, function* () {
		try {
			clearTimeout(connectInterval);
			yield put(push("/"))
			connect();
		}
		catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.Disconnect_Request, function* () {
		try {
			hubConnection.stop();

		}
		catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.Join_Request, function* ({ groupName }) {
		try {
			yield hubConnection.send("JoinGroup", groupName);
			window.log(`joined: ${groupName}`);
		}
		catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.Part_Request, function* ({ groupName }) {
		try {
			yield hubConnection.send("PartGroup", groupName);
			window.log(`parted: ${groupName}`);

		}
		catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.GetAllData_Response, function* ({ response }) {
		try {
			console.response("GetAllData", response);
			alert("RESPONSE GET DATA !")
			Database.insertAllData(response)
		}
		catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.SportTypes_Request, function* ({ request }) {
		try {
			yield put({ type: CategoriesActionTypes.Init });
			yield put({ type: SportEventsActionTypes.Init });

			yield put({
				type: SignalRTypes.Handle_Join_Part_Groups,
				data: request.Data,
			})


			window.console.request("REQ SportTypes", request);
			yield hubConnection.send("GetSportTypes", request);

		} catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.SportCategories_Request, function* ({ request }) {
		try {

			yield put({
				type: SignalRTypes.Handle_Join_Part_Groups,
				data: request.Data,
			})

			window.console.request("REQ Categories", request);
			yield hubConnection.send("GetSportCategories", request);
		} catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.SportEvents_Request, function* ({ request }) {
		try {

			window.console.request("SportEvents_Request", request);

			yield put({ type: SportEventsActionTypes.Init });


			yield put({
				type: SignalRTypes.Handle_Join_Part_Groups,
				data: request.Data,
			})

			yield hubConnection.send("GetSportEvents", request);
		} catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.TopBets_Request, function* ({ request }) {
		try {

			window.console.request("TopBets_Request", request);

			yield put({ type: SportEventsActionTypes.Init });


			yield put({
				type: SignalRTypes.Handle_Join_Part_Groups,
				data: request.Data,
			})

			yield hubConnection.send("GetTopBets", request);
		} catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.SportEventsVirtual_Request, function* ({ data }) {
		try {
			let request = {
				Data: {
					Feed: { FeedId: Number(data.FeedId) },
					Sport: { SportId: Number(data.SportId) },
					MatchDay: Number(data.MatchDay),
					Season: Number(data.Season),
				}
			}

		
			window.console.request("Virtual Events", request);

			yield put({ type: SportEventsActionTypes.Init });

			yield put({
				type: SignalRTypes.Handle_Join_Part_Groups,
				data: request.Data,
			})

			yield hubConnection.send("GetVirtualEvents", request);
		} catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.SportEvents_By_Date_Request, function* ({ request }) {
		try {
			yield put({ type: SportEventsActionTypes.Init });


			yield put({
				type: SignalRTypes.Handle_Join_Part_Groups,
				data: request.Data,
			})

			window.console.request("REQ Sport Events By Date", request)

			yield hubConnection.send("GetSportEventsByDate", request);
		} catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SearchActionTypes.GetSearchedData_Request, function* ({ request }) {
		try {
			window.console.request("search data", request)

			yield hubConnection.send("GetSearchedData", request);
		}
		catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.AllBetTypes_Request, function* ({ request }) {
		try {

			yield put({ type: SportEventsActionTypes.Init });


			yield put({
				type: SignalRTypes.Handle_Join_Part_Groups,
				data: request.Data,
			})

			window.console.request("All BetTypes", request)

			yield hubConnection.send("GetAllBetTypes", request);
		} catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.Get_Updated_OddField_Details, function* ({ request }) {
		try {
			window.console.request("REQ Bet Slip details", request)

			yield hubConnection.send("GetEventOddFieldData", request);

		} catch (e) {
			window.log("ERROR", e)
		}
	})

	yield takeEvery(SignalRTypes.Handle_Join_Part_Groups, function* ({ data, isPartOnly }) {
		try {
			const { sport, betSlip } = yield select();
			let joinedGroupsCopy = sport.joinedGroups ? Utils.deepClone(sport.joinedGroups) : [];

			yield joinedGroupsCopy && joinedGroupsCopy.length > 0 && joinedGroupsCopy.map((item) => {
				// if event join, check if exist in betslip, if yes don't part, to keep getting feed change
				if (item.indexOf("E") > -1) {
					let betSlipFields = Object.values(betSlip.betSlipList);
					let eventJoinedId = item.substring(item.lastIndexOf("E") + 1);
					if (betSlipFields.find((item) => item.eventId.toString() === eventJoinedId)) {
						return;
					}

				}
				return put({
					type: SignalRTypes.Part_Request,
					groupName: item,
				})
			})
			yield put({
				type: SignalRTypes.Set_Join_Groups,
				joinedGroups: []
			})

			if (!isPartOnly) {

				let keyList = Utils.generateGroupKeys(data);

				let newGroups = [];


				yield keyList && keyList.length > 0 && keyList.map((item) => {
					if (item) {
						newGroups.push(item);
						return put({
							type: SignalRTypes.Join_Request,
							groupName: item,
						})
					}
				})
				yield put({
					type: SignalRTypes.Set_Join_Groups,
					joinedGroups: newGroups
				})
			}
		}
		catch (e) {
			window.log("ERROR", e)
		}
	})
}


export default function* signalRSaga() {
	yield all([
		fork(requests),
	])
}
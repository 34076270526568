import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { Screen, ScreenTitle } from "../../components";
import { pageName } from '../../redux/actions/generalActions';

const { Sider, Content } = Layout;

class Document extends React.Component {

	componentDidMount() {
		const { match, pageName } = this.props;
		pageName(match.params && match.params.page)
	}

	render() {
		const { doc } = this.props;

		window.log(doc)

		return (
			<Screen {...this.props} >
				<Layout className="document mtop-20">

					<Layout className="layout-inner">

						<Sider width={220} className="sider-left">
						</Sider>

						<div className="content">

							{
								doc &&
								<div>
									<ScreenTitle hideIcon title={doc.pageTitle || "Unknown Document"} />

									<div className="container">
										
										<div className="mobile-title">{doc.pageTitle || "Unknown Document"}</div>
										<div dangerouslySetInnerHTML={{ __html: doc.pageText || "Unknown Document" }}></div>
									</div>
								</div>
							}
						</div>
						{console.log("doc.pageText" , doc)}

						<div className="right">
							<Sider width="100%" className="right">
							</Sider>
						</div>
						<Sider width={350} className="sider-right">
						</Sider>
					</Layout>
				</Layout>
			
			</Screen >
		)
	}
}

const MapStateToProps = (state, props) => {
	return {
		doc: state.general.doc
	};
}

const mapDispatchToProps = (dispatch) => (
	{
		pageName: (page) => dispatch(pageName(page))
	}
);

export default connect(MapStateToProps, mapDispatchToProps)(Document);
import React from "react";
import { Bets } from "../../../";

const SportRow = (props) => {
  const { ref, children, className } = props;
  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
};

export default SportRow;

import { BackTop, Layout } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  App,
  Banner,
  BannerMobile,
  Footer as FooterDiv,
  FooterMobile as FooterMobileDiv,
  Header as HeaderDiv,
  HeaderMobile as HeaderMobileDiv,
} from "../";
import { Modal } from "../";
import { Events, Constants, Cookies } from "../../helpers";
import { openModal, closeModal } from "../../redux/actions/modalActions";
import { signOut } from "../../redux/actions/authActions";

const { Header, Footer, Content } = Layout;

class Screen extends React.Component {
  backgroundImage = () => {
    const { match } = this.props;

    if (match.params.feed && match.params.sport)
      return `bg bg-${match.params.feed}${match.params.sport}`;

    if (match.url === "/slots") return "";

    return "bg-00";
  };

  async componentDidMount() {
    const { openModal, closeModal } = this.props;
    this.modalListener = Events.subscribe(
      Constants.modal,
      (modal) => {
        modal.visible ? openModal(modal) : closeModal(modal.id);
      },
      true
    );

    //window.addEventListener('beforeunload', this.handleWindowClose);
  }

  async componentWillUnmount() {
    if (this.modalListener) {
      this.modalListener.unSubscribe();
      this.modalListener = null;
    }
    //window.removeEventListener('beforeunload', this.handleWindowClose);
  }

  handleWindowClose() {
    if (Cookies.get("token")) {
      let status = this.props.signOut();
      if (status) {
        return;
      }
    }
  }

  renderModals = () => {
    const { modals } = this.props;

    return modals.map((m, i) => {
      return (
        <Modal
          key={i}
          type={m.type}
          children={m.children}
          visible={m.visible}
          title={m.title}
          text={m.text}
          onOK={m.onOK}
          okLabel={m.okLabel}
          onCancel={m.onCancel}
          cancelLabel={m.cancelLabel}
          textClassName={m.textClassName}
        />
      );
    });
  };

  render() {
    const { children, className } = this.props;

    return (
      <App {...this.props}>
        {this.renderModals()}
        <div className={`screen ${className}`}>
          <Header className="header sticky-header">
            <HeaderMobileDiv {...this.props} />
            <HeaderDiv {...this.props} />
          </Header>

          {this.props.match.path != "/slots" && (
            <Header className="banner">
              {/* <BannerMobile {...this.props} /> */}
              {/* <Banner {...this.props} /> */}
            </Header>
          )}
          <Content className={`body ${this.backgroundImage()}`}>
            {/* {console.log("children" , children)} */}
            <div className="size">{children}</div>
          </Content>

          <Footer className="footer">
            {(
              <FooterMobileDiv {...this.props} />
            )}
            <FooterDiv {...this.props} />
          </Footer>
        </div>

        <BackTop />
      </App>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modals: state.modal.modals,
    connecting: state.signalr.connecting,
    connected: state.signalr.connected,
    feedId: state.app.feedId,
    feedName: state.app.feedName,
    slots: state.slot,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (modal) => dispatch(openModal(modal)),
    closeModal: (id) => dispatch(closeModal(id)),
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

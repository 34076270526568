import { Button, Checkbox, Form, Input, Select, Icon , DatePicker } from "antd";
import moment from "moment";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reCaptchaApiKey } from "../../ApiKeys.js";
import { Alert, PhoneNumber } from "../../components";
import Cookies from "../../helpers/cookies.js";
import {
  sendRegisterData,
  sendValidatePhone,
} from "../../redux/actions/registerActions";
const InputGroup = Input.Group;
const { Option } = Select;

class Step1 extends React.Component {
  state = {
    captcha: "",
    passwordNumbers: false,
    passwordLowerCase: false,
    passwordUpperCase: false,
    passwordLength: false,
    passwordSpecialChar: true,
  };
  async componentDidMount() {
    this.props.form.validateFields();
    Cookies.remove("memberId");
  }

  hasErrors = (fieldsError) => {
    //	let values = this.props.form.getFieldsValue(['captcha', 'year', 'month', 'day', 'prefix', 'phoneNum', 'password', 'termsAndConditions'])
    let values = this.props.form.getFieldsValue([
      "birthDate",
      "prefix",
      "phoneNum",
      "password",
      "termsAndConditions",
    ]);
    console.log("ERROR=>values" , values);
    return (
      Object.keys(fieldsError).some((field) => fieldsError[field]) ||
      Object.values(values).filter(
        (field) => field === undefined || field === false || field === ""
      ).length > 0
    );
  };

  // send first phone for confirmation
  handleValidatePhone = (e) => {
    e.preventDefault();
    let values = this.props.form.getFieldsValue([
      "birthDate",
      "prefix",
      "phoneNum",
      "password",
      "termsAndConditions",
    ]);
    if (!Object.values(values).every((item) => item === undefined)) {


      let formData = {
        phone: "231" + values.prefix + values.phoneNum,
        birthDate: values.birthDate,
        password: values.password,
        termsAndConditions: values.termsAndConditions,
        captcha: this.state.captcha,
      };

      this.props.sendRegisterData(formData);

      //if (this.state.captcha) {
      let data = {
        phoneNumber: "231" + values.prefix + values.phoneNum,
        recaptchaResponse: this.state.captcha,
        reSendSms: false,
      };
      this.props.sendValidatePhone(data);
      //}
    }
  };

  validatePassword = (rule, value, callback) => {
    const form = this.props.form;
    let regexValidation =
      /^(?=.*[0-9])(?=.*[~_-|\^{}])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9~_-|\^{}]{8,}$/;
    let password = form.getFieldValue("password");
    let confirm = form.getFieldValue("confirm");
    if (password && confirm && password === confirm) {
      if (regexValidation.test(password) && regexValidation.test(confirm)) {
        form.setFields({
          password: {
            value: password,
            errors: undefined,
          },
          confirm: {
            value: confirm,
            errors: undefined,
          },
        });
        callback();
      }
    } else if(password && !confirm){
      callback();
    }else {
      callback("passwords dont match");
    }
    if (password) {
      this.passwordLengthValidator();
      this.passwordNumbersValidator();
      this.passwordUppercaseValidator();
      this.passwordLowercaseValidator();
      this.passwordSpecialCharValidator();
    }
  };

  validateCheckedTerms = (rule, value, callback) => {
    if (value === true) {
      return callback();
    } else {
      return callback(rule.message);
    }
  };

  //Validate User Above 18
  validatorIsAbove18 = (rule, value, callback) => {
    const form = this.props.form;
    // let day = form.getFieldValue("day");
    // let month = form.getFieldValue("month");
    // let year = form.getFieldValue("year");
    let birthDate = form.getFieldValue("birthDate");
    console.log("validatorIsAbove18 => birthDate" , birthDate);
    console.log("birthDate._isAMomentObject()" , birthDate._isAMomentObject);
    // // 
    // let birthate = moment(
    //   `${year} ${parseInt(month) + 1} ${day}`,
    //   "YYYY MM DD"
    // );
    // console.log("birthate" , birthate);
    // // 
    if (birthDate._isAMomentObject) {


      if (birthDate.isValid()) {
        let today = moment();
        if (
          today.diff(birthDate, "years", true) >= 18 &&
          today.diff(birthDate) > 0
        ) {
          form.setFields({
            birthDate: {
              value: birthDate,
              errors: undefined,
            },
            // month: {
            //   value: month,
            //   errors: undefined,
            // },
            // year: {
            //   value: year,
            //   errors: undefined,
            // },
          });
          callback();
        } else {
          let error = [new Error("You Must Be Above 18")];
          form.setFields({
            birthDate: {
              touched: true,
              value: birthDate,
              errors: error,
            },
            // month: {
            //   touched: true,
            //   value: month,
            //   errors: error,
            // },
            // year: {
            //   touched: true,
            //   value: year,
            //   errors: error,
            // },
          });
          callback("You Must Be Above 18");
        }
      } else {
        callback("Invalid Date, please fill correct date values");
      }
    } else {
      callback("Please enter birth date");
    }
  };

  onChangeCaptcha = (value) => {
    this.setState({ captcha: value });
  };

  passwordLengthValidator = () => {
    const form = this.props.form;
    let password = form.getFieldValue("password");
    //if (password && password != '' && password != undefined) {
    this.setState({ passwordLength: password.length >= 8 });
    //}
  };
  passwordNumbersValidator = () => {
    const form = this.props.form;
    let password = form.getFieldValue("password");
    let regexValidation = /\d/;
    this.setState({ passwordNumbers: regexValidation.test(password) });
  };
  passwordUppercaseValidator = () => {
    const form = this.props.form;
    let password = form.getFieldValue("password");
    let regexValidation = /[A-Z]/;
    this.setState({ passwordUpperCase: regexValidation.test(password) });
  };
  passwordLowercaseValidator = () => {
    const form = this.props.form;
    let password = form.getFieldValue("password");
    let regexValidation = /[a-z]/;
    this.setState({ passwordLowerCase: regexValidation.test(password) });
  };
  passwordSpecialCharValidator = () => {
    const form = this.props.form;
    let password = form.getFieldValue("password");
    let regexValidation = /[#[\]!$&()*+,;='<>"`%+]/;
    this.setState({ passwordSpecialChar: !regexValidation.test(password) });
  };

  render() {
    const { loading, message } = this.props;
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form;
    const formItemLayout = null;

    const dayDateError = isFieldTouched("day") && getFieldError("day");
    const monthDateError = isFieldTouched("month") && getFieldError("month");
    const yearDateError = isFieldTouched("year") && getFieldError("year");
    const passwordError =
      (isFieldTouched("password") && getFieldError("password")) ||
      (isFieldTouched("confirm") && getFieldError("confirm"));
    const termsError =
      isFieldTouched("termsAndConditions") &&
      getFieldError("termsAndConditions");
    const captchaError = isFieldTouched("captcha") && getFieldError("captcha");
    const prefixError = isFieldTouched("prefix") && getFieldError("prefix");
    const phoneError = isFieldTouched("phoneNum") && getFieldError("phoneNum");

    return (
      <div className="step">
        {/* <div className="strip">
          <div className="content">
            <div className="text">
              Thanks for your interest in Winners! Once you fill in the
              registration form below, you will receive the registration code
              via SMS.
            </div>
          </div>
        </div> */}
        <div className="register-headline">
          Register Account
        </div>
        <div className="register-form-container">
          <div className="register">
            <Form
              layout="vertical"
              onSubmit={(e) => this.handleValidatePhone(e)}
            >
              <div>
                <div className="register-form-sections">
              <Form.Item
                className="phone-number-item"
                validateStatus={prefixError || phoneError ? "error" : ""}
                help={prefixError || phoneError || ""}
                label="Phone Number"
              >
                {getFieldDecorator("phonenumber", {
                  rules: [{ required: true }],
                })(<PhoneNumber  form={this.props.form} mode="input" />)}
              </Form.Item>
              
              <Form.Item
                          className="form-item"
                          {...formItemLayout}
                          label="Birth Date"
                        >
                          {getFieldDecorator("birthDate", {
                            rules: [
                              {
                                required: true,
                                // message: "Please fill your birth date",
                                validator: this.validatorIsAbove18 ,

                              },
                            ],
                          })(
                            <DatePicker
                              style={{ width: "100%" }}
                              size="large"
                              showTime
                              format="DD/MM/YYYY"
                            />
                          )}
                        </Form.Item>
              </div>
              <div className="register-form-sections">
              <Form.Item
                {...formItemLayout}
                label="Password"
              
              >
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      
                    },
                    
                  ],
                })(<Input type="password" autoComplete="off" />)}
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label="Confirm Password"
                validateStatus={passwordError ? "error" : ""}
                help={passwordError || ""}
              >
                {getFieldDecorator("confirm", {
                  rules: [
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    {
                      validator: this.validatePassword,
                    },
                  ],
                })(<Input type="password" autoComplete="new-password" />)}
              </Form.Item>
              </div>
              </div>
              <div className="validation-meter">
                <div className="validation-meter-headline">Your password must contain</div>
                <Form.Item validateStatus="success">
                  <Alert
                    icon={
                      <Icon
                        type={this.state.passwordLength ? "check" : "close"}
                      ></Icon>
                    }
                    message={this.state.passwordLength ? <span style={{color:"green"}}>At least 8 characters</span>:<span style={{color:"red"}}>At least 8 characters</span> }
                    type={this.state.passwordLength ? "success" : "error"}
                    showIcon
                  />
                  <Alert
                    icon={
                      <Icon
                        type={this.state.passwordLowerCase ? "check" : "close"}
                      ></Icon>
                    }
                    
                    message={this.state.passwordLowerCase ? <span style={{color:"green"}}>Lower case letters(a-z)</span>:<span style={{color:"red"}}>Lower case letters(a-z)</span> }
                    type={this.state.passwordLowerCase ? "success" : "error"}
                    showIcon
                  />
                  <Alert
                    icon={
                      <Icon
                        type={this.state.passwordUpperCase ? "check" : "close"}
                      ></Icon>
                    }
                    
                    message={this.state.passwordUpperCase ? <span style={{color:"green"}}>Upper case letters(A-Z)</span>:<span style={{color:"red"}}>Upper case letters(A-Z)</span> }
                    type={this.state.passwordUpperCase ? "success" : "error"}
                    showIcon
                  />
                  <Alert
                    icon={
                      <Icon
                        type={this.state.passwordNumbers ? "check" : "close"}
                      ></Icon>
                    }
                    
                    message={this.state.passwordNumbers ? <span style={{color:"green"}}>Numbers(0-9)</span>:<span style={{color:"red"}}>Numbers(0-9)</span> }
                    type={this.state.passwordNumbers ? "success" : "error"}
                    showIcon
                  />
                  <Alert
                    icon={
                      <Icon
                        type={this.state.passwordSpecialChar ? "info" : "close"}
                      ></Icon>
                    }
                    message="Special Characters Allowed ~_-|\^{} "
                    type={this.state.passwordSpecialChar ? "info" : "error"}
                    showIcon
                  />
                </Form.Item>
              </div>

              

              {/* <Form.Item
								className="captcha"
								{...formItemLayout}
								validateStatus={captchaError ? 'error' : ''}
								help={captchaError || ''}>
								{
									getFieldDecorator('captcha', {
										rules: [{ required: true, message: 'You must validate captcha' }]
									})
										(< ReCAPTCHA
											ref={(r) => this.captcha = r}
											sitekey={reCaptchaApiKey}
											onChange={this.onChangeCaptcha}
											onExpired={() => { this.captcha.reset() }} />)
								}
							</Form.Item> */}
              <div id = "register-check-box">
              <Form.Item
                className="terms"
                validateStatus={termsError ? "error" : ""}
                help={termsError || ""}
                style={{width:"100%"}}
                
              >
                {getFieldDecorator("termsAndConditions", {
                  rules: [
                    { required: true, message: "you must accept the terms!" },
                    {
                      validator: this.validateCheckedTerms,
                      message: "you must accept the terms!",
                    },
                  ],
                })(
                  <Checkbox style={{opacity:"0.44"}}>
                    By checking this message, I hereby confirm that I agree with
                    the{" "}
                    <Link to="/Document/terms-and-conditions" target="_blank">
                      Terms and Conditions
                    </Link>
                    , that I am 18 years old or over and that all information
                    given is true.
                  </Checkbox>
                )}
              </Form.Item>
              </div>
              <div className="register-buttons-container">
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={this.hasErrors(getFieldsError())}
              >
                Next
              </Button>
              </div>
             
              {message && <Alert type="error" icon="!" message={message} />}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state, props) => {
  return {
    message: state.register.message,
    loading: state.register.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendValidatePhone: (data) => dispatch(sendValidatePhone(data)),
  sendRegisterData: (data) => dispatch(sendRegisterData(data)),
});

const WrappedStep1 = Form.create({ name: "Step1" })(Step1);
export default connect(MapStateToProps, mapDispatchToProps)(WrappedStep1);

/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Tooltip, Popover, Popconfirm, Icon } from "antd";
import { Cookies, Events, Enums, AppConfig } from "../../helpers";
import { Link } from "react-router-dom";
import { ForgotPassword, Register } from "../../screens/index";
import MyBets from "../../components/common/myBets/MyBets";
import {
  browserName,
  fullBrowserVersion,
  osName,
  osVersion,
  isMobile,
  isTablet,
} from "react-device-detect";
import {
  signIn,
  signOut,
  init,
  setShowLoginPopConfirm,
} from "../../redux/actions/authActions";
import { setSelectedCurrency } from "../../redux/actions/membersActions";
import { navigate } from "../../redux/actions/appActions";

class Signin extends React.Component {
  state = {
    validUsername: true,
    tooltipUsername: null,
    validPassword: true,
    tooltipPassword: null,
    showRegister: false,
    showForgotPassword: false,
    loginPopoverOpen: false,
    showLoginError: false,
    showCurrencyPopup: false,
    setCurrency: "",
    bounce: false,
    accountVisible: false,
    showBalance: true,
  };

  toggleShowBalance() {
    this.setState({ showBalance: !this.state.showBalance });
  }

  onClickRegister = () => {
    this.setState({
      showRegister: true,
      loginPopoverOpen: false,
    });
  };

  onClickForgotPassword = () => {
    this.setState({
      showForgotPassword: true,
      loginPopoverOpen: false,
    });
  };

  onCloseRegister = () => {
    this.setState({
      showRegister: false,
      loginPopoverOpen: false,
    });
  };

  onCloseForgotPassword = () => {
    this.setState({
      showForgotPassword: false,
      loginPopoverOpen: false,
    });
  };

  handleVisibleChange = () => {
    this.setState({ accountVisible: !this.state.accountVisible });
  };
  componentDidUpdate() {
    const { signedIn, signOut, message, setActivity } = this.props;
    const { showLoginError } = this.state;

    let user = Cookies.get("user");

    if (!user && signedIn) {
      window.log("user " + user + "signedIn " + signedIn);
      //signOut();
      this.props.init();
    }

    if (message && !showLoginError) {
      this.setState({
        showLoginError: true,
      });
    }
  }

  handleSearch = (value) => {
    window.log(value);
  };

  handleSubmit = (e, isLoginConfirm) => {
    const { form, signIn } = this.props;

    e.preventDefault();
    e.stopPropagation();
    form.validateFields(["userName", "password"], (err, values) => {
      if (!err) {
        this.setState({
          validUsername: true,
          validPassword: true,
          tooltipUsername: null,
          tooltipPassword: null,
        });

        let data = {
          userName: values.userName,
          password: values.password,
          platform: isTablet ? "Tablet" : isMobile ? "Mobile" : "Desktop",
          os: `${osName} ${osVersion}`,
          browser: `${browserName} ${fullBrowserVersion}`,
        };
        if (isLoginConfirm) {
          data.isSignedInConfirm = true;
          this.props.setShowLoginPopConfirm(false);
        }

        signIn(data);
      } else {
        if (err.userName && err.userName.errors.length > 0) {
          this.setState({
            validUsername: false,
            tooltipUsername: err.userName.errors[0].message,
          });

          setTimeout(() => {
            this.setState({
              validUsername: true,
              tooltipUsername: null,
            });
          }, 4000);
        }

        if (err.password && err.password.errors.length > 0) {
          this.setState({
            validPassword: false,
            tooltipPassword: err.password.errors[0].message,
          });

          setTimeout(() => {
            this.setState({
              validPassword: true,
              tooltipPassword: null,
            });
          }, 4000);
        }
      } 
    });
  };

  logOut = () => {
    this.props.signOut();
  };

  popConfirmVisibleChange = () => {
    if (this.state.showCurrencyPopup) {
      this.setState({ showCurrencyPopup: false });
    }
  };

  setSelectCurrency = (currency) => {
    this.props.setSelectedCurrency(currency);
    this.setState({ bounce: true }, () => {
      let timer = setTimeout(() => {
        this.setState({ bounce: false });
        clearTimeout(timer);
      }, 1000);
    });
    this.handleVisibleChange();
  };

  renderLogedin = () => {
    let { currentGame } = this.props;
    let user = Cookies.get("user");
    let selectedCurrency = Cookies.get("selectedCurrency");
    return (
      <div>

        <Popover
          className="popup-login-mobile"
          placement="bottomRight"
          title="My Account"
          visible={this.state.accountVisible}
          onVisibleChange={this.handleVisibleChange}
          overlayClassName="logged-in-popover"
          trigger="click"
          content={
            <div className="top-nav-account">
              <div className="balance">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4>My Balance:</h4>
                  <Icon
                    type={this.state.showBalance ? "eye-invisible" : "eye"}
                    className="eye-icon"
                    onClick={() => this.toggleShowBalance()}
                  />
                </div>
                <Popconfirm
                  onVisibleChange={() => {
                    this.popConfirmVisibleChange();
                  }}
                  visible={this.state.showCurrencyPopup}
                  overlayClassName="currency-message-popover"
                  placement="topRight"
                  title={"You are about to change currency"}
                  onConfirm={() => {
                    this.setState({ showCurrencyPopup: false }, () =>
                      this.setSelectCurrency(this.state.setCurrency)
                    );
                  }}
                  okText="Yes"
                  cancelText="No"
                  onCancel={() => {
                    this.setState({ showCurrencyPopup: false });
                  }}
                >
                  {user &&
                    selectedCurrency &&
                    Object.keys(user.balance).map((currency, index) => (
                      <Button
                        className={
                          user.balance[currency].currencySymbol ===
                          selectedCurrency.currencySymbol
                            ? "btn-selected-balance"
                            : ""
                        }
                        key={user.balance[currency].currencySymbol}
                        style={{ display: "flex" }}
                        onClick={() => {
                          this.setState({
                            showCurrencyPopup: true,
                            setCurrency: user.balance[currency],
                          });
                        }}
                      >
                        <a style={{ fontWeight: "bold", paddingRight: 2 }}>
                          {" "}
                          {`${user.balance[currency].currencySymbol}`}{" "}
                        </a>
                        <a>
                          {` ${
                            Math.round(user.balance[currency].balance) !==
                            user.balance[currency].balance
                              ? user.balance[currency].balance.format(2)
                              : user.balance[currency].balance.format(0)
                          }`}
                        </a>
                      </Button>
                    ))}
                </Popconfirm>
              </div>
              <div className="details">
                {user && user.bonus && (
                  <h4>
                    My Bonus:{" "}
                    {`${user.bonus.currency.currencySymbol} ${
                      user.bonus.currency.currencyId === 1
                        ? user.bonus.balance.format(0)
                        : user.bonus.balance.format(2)
                    }`}
                  </h4>
                )}
                <Link replace to="/account/bets">
                  <Button
                    onClick={() => {
                      this.handleVisibleChange();
                    }}
                    className="info"
                    type="primary"
                  >
                    My Account
                  </Button>
                </Link>
                <Link replace to="/account/deposit">
                  <Button
                    onClick={() => {
                      this.handleVisibleChange();
                    }}
                    className="info"
                    type="primary"
                  >
                    Deposit
                  </Button>
                </Link>
                <Button
                  style={{ flex: "none" }}
                  className="info"
                  type="primary"
                  onClick={() => this.logOut()}
                >
                  Sign Out
                </Button>
              </div>
            </div>
          }
        >
          <Button
            className="account-details"
            onClick={this.handleVisibleChange}
          >
            {user && selectedCurrency && (
              <div className="account-details-headline">
                <span id="my-account-username" style={{fontSize: 12 }}>
                  {user.firstName} {user.lastName}
                </span>
                {currentGame ? (
                  <span>{currentGame}</span>
                ) : this.state.showBalance ? (
                  <span
                    id="my-account-balance"
                    className={this.state.bounce ? "balance-bounce" : ""}
                    style={{ fontSize: 15 ,fontWeight:"bold" }}
                  >
                    {selectedCurrency.currencySymbol}{" "}
                    {` ${
                      Math.round(selectedCurrency.balance) !==
                      selectedCurrency.balance
                        ? selectedCurrency.balance.format(2)
                        : selectedCurrency.balance.format()
                    }`}{" "}
                  </span>
                ) : (
                  <Icon type="eye-invisible" />
                )}
              </div>
            )}
          </Button>
        </Popover>
        <MyBets/>
      </div>
    );
  };

  onSigninPopoverVisibleChange(visible) {
    this.props.init();
    this.setState({ showLoginError: false });
    if (!visible) {
      this.props.setShowLoginPopConfirm(false);
    }
  }
  navigateToPage = (page) =>{
    const {history} = this.props
    history.push(`/${page}`)
  }

  renderLoginForm = () => {
    const { message, loading, showLoginPopConfirm } = this.props;
    const {
      validUsername,
      validPassword,
      tooltipUsername,
      tooltipPassword,
      showLoginError,
      loginPopoverOpen,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    console.log("this.props.form" , this.props.form);
    return (
  
        <div style={{marginRight:"10px"}} >
        <Button onClick={() => this.navigateToPage('login-registration/1')} className="sec-btn">LOG IN</Button>
        </div>

    );
  };
  // content={
  //   AppConfig.ShowSignInAndRegistersBtn ? (
  //     <div className="login">
  //       <Form
  //         layout="inline"
  //         onSubmit={this.handleSubmit}
  //         autoComplete="off"
  //       >
  //         <Form.Item>
  //           {getFieldDecorator("userName", {
  //             rules: [
  //               {
  //                 required: true,
  //                 message: "Enter Email or Phone or Number",
  //               },
  //             ],
  //           })(
  //             <div>
  //               <Tooltip
  //                 overlayClassName="error"
  //                 trigger={["focus"]}
  //                 placement="topLeft"
  //                 title={tooltipUsername}
  //                 visible={!validUsername}
  //               ></Tooltip>
  //               <Input
  //                 onChange={() => {
  //                   this.props.init();
  //                   this.setState({ showLoginError: false });
  //                 }}
  //                 className="username"
  //                 placeholder="Email or Phone or Number"
  //               />
  //             </div>
  //           )}
  //           <a
  //             className="register light-on"
  //             onClick={this.onClickRegister}
  //           >
  //             Register
  //           </a>
  //         </Form.Item>
  //         <Form.Item>
  //           {getFieldDecorator("password", {
  //             rules: [{ required: true, message: "Enter Password" }],
  //           })(
  //             <div>
  //               <Tooltip
  //                 overlayClassName="error"
  //                 trigger={["focus"]}
  //                 placement="topLeft"
  //                 title={tooltipPassword}
  //                 visible={!validPassword}
  //               ></Tooltip>
  //               <Input
  //                 onChange={() => {
  //                   this.props.init();
  //                   this.setState({ showLoginError: false });
  //                 }}
  //                 type="password"
  //                 className="password"
  //                 placeholder="Password"
  //               />
  //             </div>
  //           )}
  //           <a
  //             className="forgot light-on"
  //             onClick={this.onClickForgotPassword}
  //           >
  //             Forgot Password
  //           </a>
  //         </Form.Item>
  //         {showLoginPopConfirm ? (
  //           <Form.Item>
  //             <Popconfirm
  //               overlayClassName="login-confirm-popover"
  //               placement="bottomLeft"
  //               title={
  //                 "You are already signed in on different machine, do you want to continue?"
  //               }
  //               onConfirm={(e) => this.handleSubmit(e, true)}
  //               onCancel={() => this.props.setShowLoginPopConfirm(false)}
  //               okText="Yes"
  //               cancelText="No"
  //               visible={showLoginPopConfirm}
  //             >
  //               <Button
  //                 className="info"
  //                 type="primary"
  //                 htmlType="submit"
  //                 loading={loading}
  //               >
  //                 {loading ? "" : "Sign In"}
  //                 <Tooltip
  //                   overlayClassName="error"
  //                   //trigger={['focus']}
  //                   placement="bottom"
  //                   title={message}
  //                   visible={showLoginError}
  //                 ></Tooltip>
  //               </Button>
  //             </Popconfirm>
  //           </Form.Item>
  //         ) : (
  //           <Form.Item>
  //             <Button
  //               className="info"
  //               type="primary"
  //               htmlType="submit"
  //               loading={loading}
  //             >
  //               {loading ? "" : "Sign In"}
  //               <Tooltip
  //                 overlayClassName="error"
  //                 //trigger={['focus']}
  //                 placement="bottom"
  //                 title={message}
  //                 visible={showLoginError}
  //               ></Tooltip>
  //             </Button>
  //           </Form.Item>
  //         )}
  //       </Form>
  //     </div>
  //   ) : (
  //     "Coming soon"
  //   )
  // }

  renderSignin = () => {
    const { signedIn } = this.props;
    if (signedIn) {
      return this.renderLogedin();
    } else {
      return (
        <div className="buttons">
          {this.renderLoginForm()}
          {AppConfig.ShowSignInAndRegistersBtn ? (
            <Button
              type="primary"
              className="pri-btn"
              // onClick={this.onClickRegister}
              onClick={() => this.navigateToPage('login-registration/2')}
            >
              REGISTER
            </Button>
          ) : (
            <Popover
              className="popup-login-mobile"
              overlayClassName="logged-in-popover"
              placement="bottomRight"
              content={"Coming soon"}
              title={"Register"}
              trigger="click"
            >
              <Button type="primary" className="clear glint">
                Register
              </Button>
            </Popover>
          )}
        </div>
      );
    }
  };

  render() {
    return (
      <div className="login">
        <Register
          onCloseRegister={this.onCloseRegister}
          visible={this.state.showRegister}
        ></Register>
        <ForgotPassword
          onCloseForgotPassword={this.onCloseForgotPassword}
          visible={this.state.showForgotPassword}
        ></ForgotPassword>

        {this.renderSignin()}
        
      </div>
    );
  }
}

const WrappedSigninForm = Form.create({ name: "Signin_Form" })(Signin);

const mapStateToProps = (state, props) => {
  return {
    signedIn: state.auth.signedIn,
    message: state.auth.message,
    loading: state.auth.loading,
    balance: state.member.balance,
    selectedCurrency: state.member.selectedCurrency,
    showLoginPopConfirm: state.auth.showLoginPopConfirm,
    currentGame: state.slots.currentGame,
  };
};

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
  signIn: (data) => dispatch(signIn(data)),
  setSelectedCurrency: (currency) => dispatch(setSelectedCurrency(currency)),
  init: () => dispatch(init()),
  setShowLoginPopConfirm: (value) => dispatch(setShowLoginPopConfirm(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WrappedSigninForm);

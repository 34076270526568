export default class SessionStorage {


    //getters
    static get betSlip() {
        const betSlip = JSON.parse(sessionStorage.getItem('betSlip')) || '';
        return betSlip;
    }


    static get betSlipAmount() {
        const betSlipAmount = JSON.parse(sessionStorage.getItem('betSlipAmount')) || '';
        return betSlipAmount;
    }

    static get betCode() {
        const betCode = JSON.parse(sessionStorage.getItem('betCode')) || '';
        return betCode;
    }

    static get betTab() {
        const betTab = JSON.parse(sessionStorage.getItem('betTab')) || '';
        return betTab;
    }
    static get currentWin() {
        const currentWin = JSON.parse(sessionStorage.getItem('currentWin')) || false;
        return currentWin;
    }
    static get jackpotWinningApproved() {
        const jackpotWinningApproved = JSON.parse(sessionStorage.getItem('jackpotWinningApproved')) || false;
        return jackpotWinningApproved;
    }


    //setters

    static set betSlip(betSlip) {
        sessionStorage.setItem('betSlip', JSON.stringify((betSlip)));
    }

    static set betSlipAmount(betSlipAmount) {
        sessionStorage.setItem('betSlipAmount', JSON.stringify((betSlipAmount)));
    }

    static set betCode(betCode) {
        sessionStorage.setItem('betCode', JSON.stringify((betCode)));
    }
    
    static set betTab(betTab) {
        sessionStorage.setItem('betTab', JSON.stringify((betTab)));
    }
    static set currentWin(currentWin) {
        sessionStorage.setItem('currentWin', JSON.stringify((currentWin)));
    }
    static set jackpotWinningApproved(jackpotWinningApproved) {
        sessionStorage.setItem('jackpotWinningApproved', JSON.stringify((jackpotWinningApproved)));
    }

}
import React from 'react';
import { connect } from 'react-redux';
import { Input, Button, Form, Select, InputNumber } from 'antd';
import { getCurrencies } from '../../../redux/actions/generalActions';

import { mtnDeposit } from '../../../redux/actions/membersActions'
import { Alert } from '../../../components';
import { Cookies } from '../../../helpers';

class DepositMtn extends React.Component {

    state = {
        currencyId: Cookies.get('selectedCurrency') ? Cookies.get('selectedCurrency').currencyId : this.props.currencies.LiberianDollar
    }
    componentDidMount() {
		this.props.getCurrencies()
	}
    
    onDeposit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let data = {
                    phoneNumber: values.phoneNumber,
                    currencyId: values.currencyId,
                    amount: values.amount,
                }

                this.props.mtnDeposit(data);
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { message, success, loading, currencies , selectedCurrency } = this.props;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const user = Cookies.get('user');
        let phoneNumber = null;
        user && user.memberPhones && user.memberPhones.forEach((phone) => {
            if (phone.phonePrefixId === 2) {
                phoneNumber = phone.phoneNumber;
            }
        })

        return (
            <div className="deposit-mtn">
                <div className="form-container  mtn-container">
                    <div className='title'>MTN Deposit</div>
                    {selectedCurrency&&  <div className="balance">Your current balance is
							<span >{` ${selectedCurrency.currencySymbol} ${Math.round(selectedCurrency.balance).format()}`}</span>
						</div>}
                   
                    {phoneNumber == null &&
                        <div className='text'>Only Lonstar phones can use MTN deposit</div>
                    }
                    {phoneNumber &&
                        <div>
                            <Form
                                layout="horizontal"
                                className="form form-white">


                                <Form.Item {...formItemLayout} label="Phone Number" >
                                    {getFieldDecorator('phoneNumber', {
                                        initialValue: phoneNumber,
                                        rules: [{ required: true, message: 'Please provide phone number' }]
                                    })(<Input className="deposit-input" disabled />)}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Amount" >
                                    {getFieldDecorator('amount', {
                                        rules: [{ required: true, message: 'Please fill amount' }]
                                    })(<InputNumber />)}

                                </Form.Item>
                                <Form.Item  {...formItemLayout} label="Currency" >
                                    {getFieldDecorator('currencyId',
                                        {
                                            initialValue: this.state.currencyId,
                                            rules: [{ required: true, message: 'Please select currency' }]
                                        })
                                        (<Select>
                                            {currencies ? Object.values(currencies).map((currency, i) => <Select.Option key={currency.currencyId} value={currency.currencyId}>{currency.currencySymbol}</Select.Option>) : ''}
                                        </Select>)}
                                </Form.Item>


                            </Form>
                            <Button className="info" type="primary" loading={loading} onClick={this.onDeposit}>Deposit</Button>
                        </div>
                    }

                    {
                        message &&
                        <Alert message={message} type={'error'}></Alert>
                    }
                    {
                        success &&
                        <Alert message={success} type={'success'}></Alert>
                    }
                </div>
            </div>
        )
    }
}

const MapStateToProps = (state, props) => {
    return {
        message: state.member.depositErrorMessage,
        success: state.member.depositSuccessMessage,
        loading: state.member.depositLoading,
        currencies: state.general.currencies,
        selectedCurrency: state.member.selectedCurrency,

    };
}

const mapDispatchToProps = (dispatch) => (
    {
        mtnDeposit: (request) => dispatch(mtnDeposit(request)),
        getCurrencies: () => dispatch(getCurrencies())

    }
);

const WrappedDeposit = Form.create({ name: 'DepositMtn' })(DepositMtn);
export default connect(MapStateToProps, mapDispatchToProps)(WrappedDeposit)
import React from 'react';
import { BetSlipDetails } from './'

class Single extends React.Component {

	render() {
		return (
			<div>
				<BetSlipDetails {...this.props}/>
			</div>
		)
	}
}

export default Single
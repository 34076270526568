import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd'
import { setPropsMath } from '../../redux/actions/appActions'
import MetaTags from 'react-meta-tags';
import EQ from 'css-element-queries'

//Import all styles
import 'antd/dist/antd.css';
import '../../styles/ltr/layout.scss'

class App extends React.Component {

	async componentDidMount() {
		const { match, setPropsMath } = this.props;
		setPropsMath(match);

		//attaches to DOMLoadContent
		EQ.ElementQueries.listen();

		// //or if you want to trigger it yourself.
		// // Parse all available CSS and attach ResizeSensor to those elements which have rules attached
		// // (make sure this is called after 'load' event, because CSS files are not ready when domReady is fired.
		EQ.ElementQueries.init();
		
	}

	render() {
		const { children ,avoidClick,isDark} = this.props;
		const mode = localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
		mode == "dark" ? document.documentElement.setAttribute("data-theme", "dark") : document.documentElement.setAttribute("data-theme", "light")
		

		return (

			<Layout  className={`app ${avoidClick ? "avoid-clicks" :""}`}>
				<MetaTags>
					<title>Winners</title>
					<meta property="og:site_name" content="Winners" />
					<meta property="og:title" content="Winners" />
					<meta property="og:description" content="Sport Betting" />
					<meta property="og:image" itemProp="image" content="../../resources/images/logo600.webp" />
					<meta property="og:type" content="website" />
				</MetaTags>
				{children}
			</Layout>

		);
	}
}

const mapStateToProps = (_, initialProps) => (state) => {
	return {
		avoidClick :  state.app.avoidClick,
		isDark: state.app.isDark,

	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		setPropsMath: (match) => {
			dispatch(setPropsMath(match));
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(App);

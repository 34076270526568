import React from 'react';
import { Button } from 'antd';

class NoActivityModal extends React.Component {

	state = {
		time: this.props.time
	}

	componentDidMount() {
		if (this.intervalTimer) {
			clearInterval(this.intervalTimer)
		}
		const { onTimeoutFinished } = this.props;
		this.intervalTimer = setInterval(() => {
			if (this.state.time > 0) {
				this.setState({ time: (this.state.time - 1) })
			}
			else {
				clearInterval(this.intervalTimer);
				onTimeoutFinished()
			}

		}, 1000)
	}

	componentWillUnmount() {
		clearInterval(this.intervalTimer);
	}

	render() {
		const { text, onOK, okLabel } = this.props;
		return (
			<div>
				<h3 className="text">{text} {this.state.time} </h3>
				{onOK &&
					<Button
						type="primary"
						className="info"
						id="stay"
						onClick={() => onOK()}>
						{okLabel}
					</Button>
				}

			</div>

		);
	}
}


export default NoActivityModal;
import React from 'react';
import { connect } from 'react-redux';
import { Bets } from '../../../';
import { loadAllBetTypes } from '../../../../redux/actions/sportEventsActions';
import { setPropsMath } from '../../../../redux/actions/appActions';

import { Tabs } from 'antd';

import DrillDownDetails from './DrillDownDetails';

const TabPane = Tabs.TabPane;

class DrillDown extends React.Component {

	state = {
		selectedTab: null
	}

	async componentDidMount() {
		const { match, setPropsMath, loadAllBetTypes, connected } = this.props;
		setPropsMath(match);
		if (connected) {
			const request = {
				Data: {
					Feed:  { FeedId: Number(match.params.feed) },
					Sport: { SportId: Number(match.params.sport) },
					EventId: Number(match.params.eventId),
				}
			}
			loadAllBetTypes(request);
		}
	}

	async componentDidUpdate(prevProps) {
		const { match, setPropsMath, loadAllBetTypes,connected } = this.props;
		setPropsMath(match);

		if ((prevProps.match.params.eventId !== this.props.match.params.eventId)
		|| (!prevProps.connected && connected)) {
			const request = {
				Data: {
					Feed: { FeedId: Number(match.params.feed) },
					Sport: { SportId: Number(match.params.sport) },
					EventId: Number(match.params.eventId),
				}
			}
			loadAllBetTypes(request);
		}
	}

	onTabChange(key) {
		this.setState({ selectedTab: key });
	}

	render() {
		const { eventData, groupsIds, error, drillDownTabs } = this.props
		return (
			<div className="dd">
				{/* <DrillDownDetails {...this.props} data={eventData}></DrillDownDetails> */}

				{

					(error) &&
					<div className="groups">
						<div>{error}</div>
					</div>
				}

				{
					drillDownTabs &&
					<Tabs
						className="tabs-events"
						defaultActiveKey={this.state.selectedTab || drillDownTabs[0]}
						onChange={(key) => { this.onTabChange(key) }}>

						{
							drillDownTabs.map((item, i) =>
								<TabPane tab={item} key={item} />
							)
						}
					</Tabs>
				}

				{
					eventData &&
					eventData.feed &&
					groupsIds &&
					drillDownTabs &&
					<div className="groups">
						{
							groupsIds.map((id) =>
								<Bets.EventOddGroup eventEnabled={eventData.enableBet} key={id} groupId={id} eventId={eventData.key} selectedTab={this.state.selectedTab || drillDownTabs[0]} />
							)
						}
					</div>
				}

			</div>

		)
	}

}

const mapStateToProps = (state) => {
	return {
		eventData: state.sportEvents.eventData,
		groupsIds: state.sportEvents.eventOddGroupIds,
		error: state.sportEvents.error,
		drillDownTabs: state.sportEvents.drillDownTabs,
		feedName: state.app.feedName,
		connected: state.signalr.connected,

	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		loadAllBetTypes: (request) => {
			dispatch(loadAllBetTypes(request));
		},
		setPropsMath: (match) => {
			dispatch(setPropsMath(match));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DrillDown);
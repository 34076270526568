import React from 'react'
import { Select } from 'antd'

const EventsCol = (props) => {
	const { children, className } = props;
	return (
		<div  className={className}>
			{children}
		</div>
	)
}

export default EventsCol
import { Button, Form, Input } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { skip } from '../../redux/actions/membersActions';
import { sendRegisterData, sendAddValidatePhone } from '../../redux/actions/registerActions';
import { Step2 } from './';
import { Alert, PhoneNumber } from '../../components';

const Step3 = (props) => {
	const skip = () => {
		props.skip();
	}

	// send second phone for confirmation
	const handleAddPhone = (e) => {
		e.preventDefault();
		let secondPhone = '231' + props.form.getFieldValue('prefix') + props.form.getFieldValue('phoneNum')
		let data = {
			name: secondPhone
		}
		props.sendRegisterData({ ...props.formData, secondPhone })
		props.sendAddValidatePhone(data);
	}

	
	const hasErrors = (fieldsError) => {
		let values = props.form.getFieldsValue(['phoneNum'])
		return Object.keys(fieldsError).some(field => fieldsError[field]) || Object.values(values).filter(field => field === undefined || field === false || field === '').length > 0
		 
	}

	const { getFieldDecorator, isFieldTouched, getFieldError, getFieldsError } = props.form;
	const prefixError = isFieldTouched('prefix') && getFieldError('prefix');
	const phoneError = isFieldTouched('phoneNum') && getFieldError('phoneNum');

	return (
		props.phoneValid ?
			<Step2 secondPhone="true"/> :

			<div className="step">
				<div className="strip">
					<div className="content">
						<div className="text">
						</div>
					</div>
				</div>

				<div className="content">
					<div className="form">
						{
							props.message &&
							<Alert type="error" message={props.message}></Alert>
						}
						<Form onSubmit={handleAddPhone}>
							<div>
								<Form.Item
									validateStatus={prefixError || phoneError ? 'error' : ''}
									help={prefixError || phoneError || ''}
									className="form-item phone-number-item"
									label="Add Phone Number">

									{getFieldDecorator('secondPhone', {
										rules: [{ message: 'Numbers Only!' }],
									})(
										<PhoneNumber form={props.form} mode='input' />
									)}
								</Form.Item>

								<Button className="next" disabled={hasErrors(getFieldsError())} type="primary" loading={props.loading} htmlType="submit">Verify Phone</Button>
								<Button className="next btn_none_bg" onClick={skip}>Skip The Step</Button>
							</div>
						</Form>
					</div>
				</div>
			</div>
	)
}
const MapStateToProps = (state, props) => {
	return {
		message: state.register.message,
		loading: state.register.loading,
		phoneValid: state.register.phoneValid,
		formData: state.register.formData
	};
}

const mapDispatchToProps = (dispatch) => (
	{
		skip: () => dispatch(skip()),
		sendAddValidatePhone: (data) => dispatch(sendAddValidatePhone(data)),
		sendRegisterData: (data) => dispatch(sendRegisterData(data))
	}
);

const WrappedStep3 = Form.create({ name: 'Step3' })(Step3);

export default connect(MapStateToProps, mapDispatchToProps)(WrappedStep3);

import React from 'react'

const Alert = props => {
	const { children, icon, type, message } = props;

	return (
		<div className="alert">
			<div className="icon">
				<div className={`inner ${type}`}>{icon}</div>
			</div>
			<div className="message">{children || message}</div>
		</div>
	)
}

export default Alert
import { SlotsActionTypes } from '../actions';

const initState = {
    message: null,
    loading: false,
    slotsGames: null,
    categories: null,
    config: null,
    currentGame: '',
    gameSymbols: [],
    loadingBalance: false,
    messageBalance: null,
    balances: [],
}

const slots = (state = initState, action) => {
    switch (action.type) {
        case SlotsActionTypes.Get_Slots_Games_Request:
            return {
                ...state,
                loading: true,
                message: null,
                slotsGames:null,
                currentGame: null,
                gameSymbols: []
            }
        case SlotsActionTypes.Get_Slots_Games_Success:
            return {
                ...state,
                loading: false,
                slotsGames: action.slotsGames,
                categories: action.categories,
                config: action.config,
                message: null,
            }
        case SlotsActionTypes.Get_Slots_Games_Error:
            return {
                ...state,
                loading: false,
                message: action.message,
                slotsGames:null
            }

        case SlotsActionTypes.Slots_Game_Connect_Request:
            return {
                ...state,
                token:null,
                message: null,

            }
        case SlotsActionTypes.Slots_Game_Connect_Success:
            return {
                ...state,
                token: action.token,
                message: null,
                currentGame: action.currentGame,
                gameSymbols: action.gameSymbols
            }
        case SlotsActionTypes.Slots_Game_Connect_Error:
            return {
                ...state,
                message: action.message,
            }
        case SlotsActionTypes.Slots_Game_Init:
            return {
                ...state,
                token:null,
                currentGame: null,
                gameSymbols: []

            }
        case SlotsActionTypes.Current_Slots_Game:
        return {
            ...state,
            currentGame: action.currentGame,
        }
        case SlotsActionTypes.Set_Slots_Round_Finish:
            return {
                ...state,
                roundFinished: action.roundFinished,
                gameId: action.gameId,
                gameName: action.gameName,
            }
     
        default:
            return state
    }
}
export default slots;
import { SignalRTypes } from '../actions';

const initState = {
	connected: false,
	connecting: true,
	connectSlow:false,
	producerDown:false
};

const signalr = (state = initState, action) => {

	switch (action.type) {

		case SignalRTypes.Connect_Request:
			return {
				...state,
				connected: false,
				connecting: true,
			}

		case SignalRTypes.Connect_Success:
			return {
				...state,
				connected: true,
				connecting: false,

			}

		case SignalRTypes.Connect_Error:
			return {
				...state,
				connected: false,
				connecting: false,

			}

		case SignalRTypes.Connect_Slow:
			return {
				...state,
				connected: true,
				connecting: false,
				connectSlow: true,


			}
		case SignalRTypes.Disconnect_Success:
			return {
				...state,
				connected: false,


			}
		case SignalRTypes.Producer_Down:
			return {
				...state,
				producerDown: true,


			}
		case SignalRTypes.Producer_Up:
			return {
				...state,
				producerDown: false,


			}
		
		default:
			return state;

	}
}

export default signalr;
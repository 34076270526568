import React from "react";
import { Layout, Tabs } from "antd";
import { Images } from "../";
import Loading from "../common/Loading";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import {
  init,
  loadSportCategories,
  removeDisabledCategoriesTournaments,
  setTounament,
} from "../../redux/actions/categoriesActions";
import {
  loadSportTypes,
  removeDisabledSportTypes,
  setSport,
} from "../../redux/actions/sportTypesActions";

const { TabPane } = Tabs;
const borderClass = {
  soccer: "blueBorder",
  basketball: "orangeBorder",
  iceHokey: "greyBlueBorder",
  mma: "redBorder",
  greenBorder: "greenBorder",
  purpleBorder: "purpleBorder",
  Rugby: "redBorder",
  Aussie: "redBorder",
  Cricket: "purpleBorder",
  Dart: "greenBorder",
  MMA:"redBorder",


};

class CategoriesMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { index: 0 };
  }
  onChange = (sportId) => {
    const getBorderClass = () => {
      switch (sportId) {
        case 1:
          return borderClass.soccer;
        case 2:
          return borderClass.basketball;
        case 4:
          return borderClass.iceHokey;
        case 12:
          return borderClass.Rugby;
        case 13:
          return borderClass.Aussie;
        case 21:
          return borderClass.Cricket;
        case 22:
          return borderClass.Dart;
        case 117:
          return borderClass.MMA;
      }
    };
    this.setState({
      borderClass: getBorderClass(),
    });
  };

  onSportTypeClick = (item) => {
    const {
      history,
      setSport,
      feedName,
      loadSportCategories,
      removeDisabledSportTypes,
      feedId,
      match,
    } = this.props;
    if (
      (item.feed.feedId == 6 && item.sport.sportId == 1) ||
      item.feed.feedId == 5
    ) {
      history.push(
        `/${feedName}/${feedId}/${item.sport.sportId}/${item.sport.sportDisplayName}`
      );
    }
    //Go to today
    else {
      history.push(
        `/${feedName}/${feedId}/${item.sport.sportId}/${item.sport.sportDisplayName}/today/0`
      );
    }

    setSport(item);
    removeDisabledSportTypes();

    const request = {
      Data: {
        Feed: { FeedId: Number(item.feed.feedId) },
        Sport: { SportId: Number(item.sport.sportId) },
      },
    };
    loadSportCategories(request);
  };

  onSportClick(sport) {
    //if (sport.sport.sportId != sportData.sport.sportId) {
    this.onChange(sport.sport.sportId);
    this.onSportTypeClick(sport);
  }

  getTabPanelBySport(sport) {
    const sportId = sport.sport.sportId;
    console.log("sportId ================================================" ,    sport);
    const src = () => {
      switch (sportId) {
        case 1:
          return Images.Soccer;
        case 2:
          return Images.MenuBasketball;
        case 4:
          return Images.IceHockey;
        case 12:
          return Images.Rugby;
        case 13:
          return Images.Aussie;
        case 21:
          return Images.MenuCricket
        case 22:
          return Images.Dart
        case 117:
          return Images.MMA
      }
    };
    return (
      <TabPane
        tabBarGutter={200}
        tab={
          <img
            className="tab mobile-view-images"
            onClick={() => this.onSportClick(sport)}
            src={src()}
          />
        }
        key={sportId}
      />
    );
  }

  render() {
    const {
      mobileMenuOpen,
      openMobileMenu,
      sportCategories,
      sportTopLeagues,
      sportTypes,
      sportTypesError,
      categoriesError,
      loadingSportTypes,
    } = this.props;

    if (loadingSportTypes) {
      return <Loading />;
    }
    if (sportTypesError) {
      return <div>{sportTypesError}</div>;
    }
    // console.log("sportTypes ===>" , sportTypes);
    return (
      
      <>
      <div id="sport-categories-menu">
        <img />
        <Tabs
          className={this.state.borderClass}
          size="large"
          defaultActiveKey="1"
        >
          {sportTypes.map((sport) => {
            return this.getTabPanelBySport(sport);
          })}
          
          {/* <TabPane
            tabBarGutter={200}
            tab={<img className="tab" src={Images.MenuSoccer} />}
            key="1"
          />
          <TabPane
            tab={<img className="tab" src={Images.MenuBasketball} />}
            key="2"
          />
          <TabPane
            tab={<img className="tab" src={Images.MenuTennis} />}
            key="3"
          />
          <TabPane tab={<img className="tab" src={Images.MenuMMA} />} key="4" />
          <TabPane
            tab={<img className="tab" src={Images.MenuIcehokey} />}
            key="5"
          />
          <TabPane
            tab={<img className="tab" src={Images.MenuCricket} />}
            key="6"
          /> */}
        </Tabs>
        <img />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sportCategories: state.categories.sportCategories,
    sportTopLeagues: state.categories.sportTopLeagues,
    tournamentData: state.categories.tournamentData,
    loadingSportCategories: state.categories.loadingSportCategories,
    categoriesError: state.categories.error,
    sportTypesError: state.sportTypes.error,
    loadingSportTypes: state.sportTypes.loadingSportTypes,
    sportData: state.sportTypes.sportData,
    sportTypes: state.sportTypes.sportTypes,
    appMatch: state.app.match,
    mobileMenuOpen: state.app.mobileMenuOpen,
    connected: state.signalr.connected,
    feedName: state.app.feedName,
    feedId: state.app.feedId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // openMobileMenu: (open) => dispatch(openMobileMenu(open)),
    loadSportCategories: (request) => {
      dispatch(loadSportCategories(request));
    },
    setTounament: (item) => {
      dispatch(setTounament(item));
    },
    init: () => {
      dispatch(init());
    },
    setSport: (item) => {
      dispatch(setSport(item));
    },
    loadSportTypes: (request) => {
      dispatch(loadSportTypes(request));
    },
    removeDisabledSportTypes: () => {
      dispatch(removeDisabledSportTypes());
    },
    removeDisabledCategoriesTournaments: () => {
      dispatch(removeDisabledCategoriesTournaments());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoriesMenu)
);

import { Layout, Input } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import StickyBox from "react-sticky-box";
import { Bets, BetSlip, EventsVirtual, Images, Screen, ScreenTitle, SportTypes, VirtualMenu, Search } from "../../components";
import { init } from '../../redux/actions/appActions';
import { setEvent } from '../../redux/actions/sportEventsActions';
import { loadVirtualEvents } from '../../redux/actions/virtualActions';

const { Sider, Content } = Layout;

class Virtual extends React.Component {

	checkBackButton = () => {
		const { match, history, init } = this.props;
		if (history.action === "POP") {
			init();
		}
	}

	async componentDidMount() {
		this.checkBackButton();
	}

	render() {
		const { match } = this.props;
		return (
			<Screen {...this.props}>
				<SportTypes {...this.props} />

				<Layout className="sport mtop-10">

					<Layout className="layout-inner">

						<Sider width={220} className="sider-left">
							<StickyBox offsetTop={140} offsetBottom={30}>
								<Search />
								<VirtualMenu {...this.props} />
							</StickyBox>
						</Sider>

						<div className="content">
							<ScreenTitle
								{...this.props}
								title={match.params.sportName || "Virtual Betting"}
								imageIcon={Images.Icon_Soccer}
								imageTheme={Images.Strip_Soccer}
							/>

							<div>
								<Route exact path='/virtual/:feed/:sport/:sportName?' component={() => <EventsVirtual {...this.props} />} />
								<Route exact path='/virtual/:feed/:sport/:sportName?/event/:country/:tournament/:eventId' component={Bets.DrillDown} />
							</div>


						</div>

						<Sider width={350} className="sider-right">
							<StickyBox offsetTop={140} offsetBottom={30}>
								<BetSlip {...this.props} />
							</StickyBox>
						</Sider>

					</Layout>

				</Layout>

			</Screen>
		)
	}


}

const mapStateToProps = (state) => {
	return {
		connecting: state.signalr.connecting,
		connected: state.signalr.connected,
		feedId: state.app.feedId,
		feedName: state.app.feedName
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		loadVirtualEvents: (data) => {
			dispatch(loadVirtualEvents(data));
		},
		setEvent: (item) => {
			dispatch(setEvent(item));
		},
		init: () => {
			dispatch(init());
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Virtual);

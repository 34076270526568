
class Database {
	DB = null;
	DBPromise = null;

	constructor() {
		//this.init();
	}

	init = () => {

		if (!('indexedDB' in window)) {
			window.log('This browser doesn\'t support IndexedDB');
			return;
		}

		var dbrequest = window.indexedDB.open('Winners', 1);

		dbrequest.onerror = e => {
			window.log("Database Error", e);
		}

		dbrequest.onupgradeneeded = e => {
			const db = e.target.result;

			//if (!db.objectStoreNames.contains('Customers')) {
			var EventOddField = db.createObjectStore(
				'EventOddField',
				{
					keyPath: 'id',
					//autoIncrement: true
				}
			);

			// EventOddField.createIndex('activeOddFieldId', 'activeOddFieldId', { unique: true });
			// EventOddField.createIndex('ssn', 'ssn', { unique: true });
			//}

			window.log("Database Upgrade", e)
		}

		dbrequest.onsuccess = e => {
			this.DB = e.target.result;
			window.log("Database Open", this.DB)

			//this.massCreate();
		}


	}

	insertAllData = (data) => {

		try {
			window.log("DATA", data.eventOddFields)
			var transaction = this.DB.transaction(["EventOddField"], "readwrite");

			transaction.oncomplete = function (e) {
				window.log("Database Transaction Complete");
			};

			transaction.onerror = function (e) {
				//window.log("Database Transaction Error", e);
			};

			var objectStore = transaction.objectStore("EventOddField");
			var i = 0;

			let arr = [];
			for(let p in data.eventOddFields){
				arr.push(data.eventOddFields[p]);
			}

			putNext();

			function putNext() {
				if (i < arr.length) {
					objectStore.put(arr[i]).onsuccess = putNext;
					i++;
				}
				else {
					window.log("COMPLETE !!!")
					alert("COMPLETE !")
				}

			}
		}
		catch (e) {
			window.log("ERROR insertAllData", e);
		}

	}

	indexCount = 0;
	massCreate = () => {


		var transaction = this.DB.transaction(["Customers"], "readwrite");

		transaction.oncomplete = function (e) {
			window.log("Database Transaction Complete");
		};

		transaction.onerror = function (e) {
			//window.log("Database Transaction Error", e);
		};

		var objectStore = transaction.objectStore("Customers");

		const customerData = [
			// { ssn: "444-44-44441", name: "Bill", age: 35, email: "bill@company.com1" },
			// { ssn: "555-55-55551", name: "Donna", age: 32, email: "donna@home.org1" }
		];

		if (this.indexCount > 10000) {
			window.log("ENDF !!!!!!!!!!!!!!!!!!!!!!!")
			return;
		}

		for (let index = 0; index < 1000; index++) {
			this.indexCount++;
			customerData.push({
				ssn: this.indexCount.toString(),
				name: "name" + this.indexCount,
				age: this.indexCount,
				email: "email@email.com" + this.indexCount
			})
		}

		customerData.forEach(function (customer) {
			var request = objectStore.add(customer);
			request.onsuccess = function (e) {
				//window.log("Database Transaction Success", e);
			};
		});

		window.log(this.indexCount)

		if (this.indexCount < 10000)
			this.massCreate();

		//var customerObjectStore = this.DB.transaction("Customers", "readwrite").objectStore("Customers");
	}
}

export default new Database()
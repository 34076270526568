import { ModalActionTypes } from './actionTypes';
import { Cookies } from '../../helpers';

export function openModal(modals) {
    return {
        type: ModalActionTypes.Open_Modal_Request,
        modals
    }
}

export function closeModal(id) {
    return {
        type: ModalActionTypes.Close_Modal_Request,
        id
    }
}

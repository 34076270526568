import React from "react";
import { connect } from "react-redux";
import { Input, Button, Form, Icon } from "antd";
import { updatePassword } from "../../redux/actions/authActions";
import { Alert } from "../../components";
import { Images } from "../../components";

class Step2 extends React.Component {
  constructor(props) {
    super(props);
  }

  validatePassword = (rule, value, callback) => {
    const form = this.props.form;
    let password = form.getFieldValue("password");
    let confirm = form.getFieldValue("confirm");
    if (value && password === confirm) {
      return callback();
    } else {
      return callback(rule.message);
    }
  };

  handleChnagePassword = (e) => {
    e.preventDefault();
    let data = {
      password: this.props.form.getFieldValue("password"),
      confirmPassword: this.props.form.getFieldValue("confirm"),
      memberId: this.props.userData.memberId,
    };
    this.props.updatePassword(data);
  };
  showPassword = (inputId, iconId) => {
    const inputElement = document.getElementById(inputId);
    console.log("inputElement", inputElement);
    console.log("inputId", inputId);
    const iconElement = document.getElementById(iconId);
    console.log("iconElement", iconElement);
    const imgElement = document.createElement("img");
    imgElement.src = Images.ShowPasswordIcon;
    // console.log(iconElement.src,"==========" ,<img src={Images.ShowPasswordIcon}/> );
    if (iconElement.src == imgElement.src) {
      iconElement.src = Images.HidePasswordIcon;
    } else {
      iconElement.src = Images.ShowPasswordIcon;
    }

    if (inputElement.type == "password") {
      inputElement.type = "text";
    } else {
      inputElement.type = "password";
    }
  };

  render() {
    const { loading, message, passwordChanged } = this.props;
    const { getFieldDecorator, isFieldTouched, getFieldError } =
      this.props.form;
    const formItemLayout = null;

    const passwordError =
      isFieldTouched("password") && getFieldError("password");
    const confirmError = isFieldTouched("confirm") && getFieldError("confirm");
    return (
      <div className="step">
        <p className="forget-password-headline">Confirmation</p>
        {passwordChanged && (
          <div className="strip">
            <div className="content">
              <div className="text" style={{ color: "green" }}>
                <Icon
                  style={{
                    background: "#00800050",
                    borderRadius: "50%",
                    marginRight: "5px",
                  }}
                  type="check-circle"
                />
                Password changed successfully
              </div>
            </div>
          </div>
        )}

        <div className="content">
          <div className="form">
            {message && <Alert type="error" icon="!" message={message} />}

            <Form layout="vertical" onSubmit={this.handleChnagePassword}>
              <div className="forget-password-confirmation-input-label-container">
                <Form.Item
                  {...formItemLayout}
                  label="Password"
                  validateStatus={passwordError ? "error" : ""}
                  help={passwordError || ""}
                >
                  <div>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          type: "regexp",
                          pattern: new RegExp(
                            "/^(?=.*[0-9])(?=.*[~_-|^{}])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9~_-|^{}]{8,}$/"
                          ),
                          required: true,
                          message: "Please input your password!",
                        },
                        { validator: this.validatePassword },
                      ],
                    })(
                      <div>
                        <Input
                          id="confirmation-new-password-input"
                          type="password"
                        />{" "}
                        <img
                          id="new-password-icon"
                          className="show-password-icon"
                          src={Images.ShowPasswordIcon}
                          onClick={() =>
                            this.showPassword(
                              "confirmation-new-password-input",
                              "new-password-icon"
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Confirm Password"
                  validateStatus={confirmError ? "error" : ""}
                  help={confirmError || ""}
                >
                  <div>
                    {getFieldDecorator("confirm", {
                      rules: [
                        {
                          type: "regexp",
                          pattern: new RegExp(
                            "/^(?=.*[0-9])(?=.*[~_-|^{}])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9~_-|^{}]{8,}$/"
                          ),
                          required: true,
                          message: "Please confirm your password!",
                        },
                        {
                          validator: this.validatePassword,
                          message:
                            "password must contain At least 8 characters, Lower case letters, Upper case letters, numbers and valid special characters are ~_-|^{}",
                        },
                      ],
                    })(
                      <div>
                      <Input
                        id="confirmation-confirm-password-input"
                        type="password"
                      />
                      <img
                      id="confirm-password-icon"
                      className="show-password-icon"
                      src={Images.ShowPasswordIcon}
                      onClick={() =>
                        this.showPassword(
                          "confirmation-confirm-password-input",
                          "confirm-password-icon"
                        )
                      }
                    />
                    </div>
                    )}
                   
                  </div>
                </Form.Item>
              </div>
              <div className="step2-button-container">
                <Button
                  className="next"
                  type="primary"
                  htmlType="submit"
                  loading={this.props.loading}
                >
                  Finish
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loading: state.auth.loading,
    message: state.auth.forgotPasswordMessage,
    userData: state.auth.userData,
    passwordChanged: state.auth.passwordChanged,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (data) => dispatch(updatePassword(data)),
});
const WrappedStep2 = Form.create({ name: "ForgotPasswordStep2" })(Step2);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedStep2);

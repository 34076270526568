import { Fetch, AppConfig } from '../helpers'

export default class contactUsApi {

	static getContactSubjects = () => {
		const response = Fetch.fetch(
			{
                methodName: "GetContactSubjects",
                method: "GET",
				url: AppConfig.api.contactUs
			}
		)
		return response;
	}

	static submitContactUs = (query) => {
		const response = Fetch.fetch(
			{
				methodName: "AddContactUs",
				method: "POST",
				url: AppConfig.api.contactUs,
			   data: query.data
			}
		)
		return response;
	}

	static findUs = () => {
		const response = Fetch.fetch(
			{
				methodName: "find-us",
				method: "GET",
				url: AppConfig.api.pages,
			}
		)
		return response;
	}

}

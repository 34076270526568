import { Tooltip } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

class Connection extends React.Component {

	render() {
		const { connected, connecting, connectSlow } = this.props;

		if (connected)
			return (
				<div title="Connected" className="connection success"></div>
			)

		if (connectSlow)
			return (
				<div title="Connection Slow" className="connection warning"></div>
			)

		if (connecting)
			return (
				<div title="Connecting" className="connection info"></div>
			)

		if ((!connected ) && !connecting)
			return (
				<div title="Disconnected" className="connection error"></div>
			)
	}
}

const mapStateToProps = (state) => {

	return {
		connected: state.signalr.connected,
		connecting: state.signalr.connecting,
		connectSlow: state.signalr.connectSlow,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Connection);
import React from 'react';
import moment from 'moment';
import QRCode from "react-qr-code";



class ModalVoucher extends React.Component {

    render() {
        const { loading, voucher, hideDetails } = this.props
        let qrColor = (voucher.statusId == 7 || voucher.statusId == 3) ? '#C0C0C0' : '#000000';
        return (
            <div className="voucher-container">
                {!hideDetails && <h1>Voucher Details</h1>}
                {voucher &&
                    <div className="voucher-details">
                        {!hideDetails &&
                            <div className="details">
                                <div >
                                    <h3 >Code: </h3> {voucher.code}
                                </div>
                                <div >
                                    <h3>Status: </h3> {voucher.statusName}
                                </div>
                                <div >
                                    <h3>Amount: </h3> {voucher.amount}  {voucher.currency.currencySymbol}
                                </div>
                                <div>
                                    <h3>Create Date: </h3>  {moment(voucher.createDate).format("DD/MM/YYYY HH:mm:ss")}
                                </div>

                                <div >
                                    <h3>Expiration Date: </h3> {moment(voucher.expirationDate).format("DD/MM/YYYY HH:mm:ss")}
                                </div>

                            </div>
                        }
                        <QRCode fgColor={qrColor} size={ 130} value={voucher.code} />

                    </div>
                }

                {/* {
                    betLines.message &&
                    // <Alert message={betLines.message} type={betLines.formStatusId > 0 ? "success" : betLines.formStatusId < 0 ? "error" : "message-no-status" }></Alert>
                    <div className="status" style={{ background: this.statusColor(betLines.formStatusId) }} >{betLines.message}</div>
                }
                {
                    formDetails && betLines &&
                    <div className='details-table'>
                        <Table
                            align="center"
                            loading={loading}
                            rowKey={(item, index) => index}
                            columns={this.columns}
                            dataSource={formDetails}
                            className="ant-table-v1"
                            rowClassName={(record) => record.isFinal ? (record.winStatus === true ? 'table-winning-row' : 'table-losing-row') : 'table-no-result-row'}
                        />
                    </div>
                } */}

            </div>
        )

    }
}

export default ModalVoucher;

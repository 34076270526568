import React from 'react';
import { connect } from 'react-redux';
import { Loading } from '../..';
import { loadSportTypes } from '../../../redux/actions/sportTypesActions';
import { clearSportEvents } from '../../../redux/actions/sportEventsActions';
import { SportIcon } from '../../';
import { Menu } from 'antd'


const Title = props => {
	const { feedId, sportId, text } = props;

	return (
		<div className="title">
			<SportIcon feedId={feedId} sportId={sportId} />
			<div className="text">{text}</div>
		</div>
	)
}

class VirtualMenu extends React.Component {

	state = {
		selectedKeys: []
	}

	componentDidUpdate(prevProps) {
		if ((prevProps.appMatch && prevProps.appMatch.url !== this.props.appMatch.url)
			|| (!prevProps.connected && this.props.connected)) {
				let request = {
					Data: {
						Feed: { FeedId: Number(this.props.match.params.feed) }
					}
				}
				this.props.loadSportTypes(request);
			this.handleOpenTree();
		}
	}

	handleOpenTree() {
		const { tournamentData, appMatch,match } = this.props;

		let matchTemp = appMatch ? appMatch : match;
		let selectedKeys = [];
		let sport = matchTemp.params.sport;
		let feed = matchTemp.params.feed;

		selectedKeys.push(`${sport},${feed}`);

		this.setState({
			selectedKeys,
		});

	}

	async componentDidMount() {
		const { loadSportTypes, match, connected } = this.props;
		if (connected) {
			let request = {
				Data: {
					Feed: { FeedId: Number(match.params.feed) }
				}
			}
			loadSportTypes(request);
		}
		this.handleOpenTree();
	}

	onSportTypeClick = (item) => {
		const { history, clearSportEvents, feedName } = this.props;
		const path = `/${feedName}/${item.feed.feedId}/${item.sport.sportId}/${item.sport.sportDisplayName}`;
		clearSportEvents();
		history.push(path);
	}

	render() {
		const { sportTypes, loadingSportTypes, error } = this.props;

		if (error) {
			return (<div>{error}</div>)
		}

		return (
			<div>
				{
					loadingSportTypes &&
					<Loading />
				}

				{
					!loadingSportTypes
					&& sportTypes
					&& sportTypes.length > 0 &&
					<Menu
						className="menu virtual-menu"
						mode="inline"
						selectedKeys={this.state.selectedKeys}>

						<Menu.Item className="item">
							<div className="title upper">Virtual Sports</div>
						</Menu.Item>

						{
							sportTypes.map((item, index) => {
								return (
									<Menu.Item
										key={`${item.sport.sportId},${item.feed.feedId}`}
										className="item ant-menu-submenu"
										onClick={() => this.onSportTypeClick(item)}>
										<Title text={item.sport.sportDisplayName} feedId={item.feed.feedId} sportId={item.sport.sportId} />
									</Menu.Item>
								)
							})
						}

					</Menu>
				}
			</div>
		)
	}
}


const mapStateToProps = (state) => {
	return {
		sportTypes: state.sportTypes.sportTypes,
		loadingSportTypes: state.sportTypes.loadingSportTypes,
		error: state.sportTypes.error,
		feedName: state.app.feedName,
		appMatch: state.app.match,
		connected: state.signalr.connected,

	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		loadSportTypes: (request) => {
			dispatch(loadSportTypes(request));
		},
		clearSportEvents: () => {
			dispatch(clearSportEvents());
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VirtualMenu);

import { BackTop, Layout } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  App,
  LoginAndRegistrationHeader
} from "..";

const { Header, Footer, Content } = Layout;

class LoginAndRegistrationScreen extends React.Component {
  backgroundImage = () => {
    const { match } = this.props;

    if (match.params.feed && match.params.sport)
      return `bg bg-${match.params.feed}${match.params.sport}`;

    if (match.url === "/slots") return "";

    return "bg-00";
  };

  render() {
    
    const { children, className } = this.props;

    return (
      <App {...this.props}>
        <div className={`screen ${className}`}>
            <LoginAndRegistrationHeader showLoginForm={this.props.showLoginForm} showForgotPassword={this.props.showForgotPassword} {...this.props}/>


          {this.props.match.path != "/slots" && (
            <Header className="banner">
              {/* <BannerMobile {...this.props} /> */}
              {/* <Banner {...this.props} /> */}
            </Header>
          )}
          <Content className={`body ${this.backgroundImage()}`}>
            {/* {console.log("children" , children)} */}
            <div className="size">{children}</div>
          </Content>

  
        </div>

        <BackTop />
      </App>
    );
  }
}




export default LoginAndRegistrationScreen






import { Events, Cookies, AppConfig, Utils } from '../helpers'

class Fetch {
    static makeUrl = (args) => {
        return `${args.url}/${args.methodName}`;
    }

    static fetch = (args) => {

        const url = Fetch.makeUrl(args);
        const token = Cookies.get("token") || undefined;
        const memberId = Cookies.get("memberId") || undefined;
        const headers = {
            'Content-type': 'application/json;charset=utf-8;',
            'Token': token ||"",
            'MemberId': memberId||""
        };

        const request = {
            data: args.data,
            token: token || undefined,
            memberId: memberId || undefined
        }

        window.log(" ");
        window.log("-------------------------- API Request --------------------------")
        window.log(url);
        window.log(request);
        window.log(headers);
        window.log("-----------------------------------------------------------------")

        return new Promise((resolve, reject) => {
            let response = {};
            var http = new XMLHttpRequest();
            let error = null;

            try {

                http.timeout = AppConfig.api.timeout;

                http.open(args.method || "POST", url, true);
                Object.keys(headers).forEach(function (key) {
                    http.setRequestHeader(key, headers[key])
                })

                http.onreadystatechange = () => {

                    if (http.readyState === 4) {

                        switch (http.status) {
                            //Timeout
                            case 0:
                                error = `Connection request error`;//. \nStatus: ${http.status}, ReadyState: ${http.readyState} \n${http._response}. 1008`;
                                response.error = {
                                    message: error
                                }
                                response.status = 3;
                                response.success = false;
                                response.message = error;
                                break;

                            //OK
                            case 200:
                                response = JSON.parse(http.responseText);
                                switch (response.status) {
                                    case 1:
                                        //SUCCESS
                                        break;

                                    case 2:
                                    case 3:
                                        //ERROR
                                        break;

                                    //Token expired
                                    case 4:
                                        response.error = {
                                            message: response.message
                                        }
                                        Events.publish("onTokenExpired", null);
                                        break;

                                    default:
                                        break;
                                }

                                break;

                            default:
                                error = `Connection request error: Status: ${http.status}, ReadyState: ${http.readyState}`;
                                response.error = {
                                    message: error
                                };
                                response.status = 3;
                                response.success = false;
                                response.message = error;
                                break;
                        }

                        window.log(" ");
                        window.log("-------------------------- API Response --------------------------")
                        window.log(url);
                        window.log(response);
                        window.log("------------------------------------------------------------------")

                        resolve(response);
                        Events.publish("onHttpResponse", { request: request, response: response });

                    }

                };

                http.onerror = (error) => {
                    Events.publish("onHttpError", { request: request, error: error });
                };

                http.ontimeout = () => {
                    Events.publish("onHttpTimeout", { request: request });
                };

                http.send(JSON.stringify(request));
                Events.publish("onHttpSend", { request: request });
            }
            catch (e) {
                const error = `Connection request error: ${e.message} Status: ${http.status}, ReadyState: ${http.readyState}. 1013`;
                response.error = {
                    message: error
                };

                resolve(response);
                Events.publish("onHttpError", { request: request, error: error });
            }
        });

    }

};

export default Fetch;

import { MembersActionTypes, SignalRTypes } from './actionTypes';
import { Cookies } from '../../helpers';


export function openMobileTableDetails(open) {
	return {
		type: MembersActionTypes.Open_Mobile_Table_Details,
		open
	}
}


export function openBetDetailsModal(open) {
	return {
		type: MembersActionTypes.Open_Bet_Details_Modal,
		open
	}
}

export function openVoucherModal(open) {
	return {
		type: MembersActionTypes.Open_Voucher_Modal,
		open
	}
}

export function openMobileVoucherDetails(open) {
	return {
		type: MembersActionTypes.Open_Mobile_Voucher_Details,
		open
	}
}

export function partGroups() {
	return {
		type: SignalRTypes.Handle_Join_Part_Groups,
		isPartOnly: true,
	}
}

export function setSelectedCurrency(currency) {
	return {
		type: MembersActionTypes.Set_Selected_Currency,
		currency
	}
}
export function addMemberPhone() {
	return {
		type: MembersActionTypes.Add_Second_Member_Phone_Request
	}
}

export function sendAddValidatePhone(data) {
	return {
		type: MembersActionTypes.Validate_Add_Second_Phone_Request,
		data
	}
}

export function updateMemberDetails(data) {
	return {
		type: MembersActionTypes.Member_Details_Request,
		data
	}
}

export function getMemberDetails(data) {
	return {
		type: MembersActionTypes.Get_Member_Details_Request,
		data
	}
}

export function sendVerifyEmail(data) {
	return {
		type: MembersActionTypes.Send_Verify_Email_Request,
		data
	}
}

export function mtnDeposit(data) {
	return {
		type: MembersActionTypes.Mtn_Deposit_Request,
		data
	}
}

export function mtnWithdraw(data) {
	return {
		type: MembersActionTypes.Mtn_Withdraw_Request,
		data
	}
}

export function depositFromVoucher(voucher) {
	if (voucher.length === 0) {
		return {
			type: MembersActionTypes.Deposit_From_Voucher_Error,
			message: 'Please Enter Voucher'
		}
	}

	if (voucher.length !== 16) {
		return {
			type: MembersActionTypes.Deposit_From_Voucher_Error,
			message: 'Voucher Must Be 16 characters'
		}
	}

	return {
		type: MembersActionTypes.Deposit_From_Voucher_Request,
		voucher
	}
}

export function withdrawalToVoucher(data) {
	const selectedCurrency = Cookies.get("selectedCurrency");
	let amount = data && data.amount;
	if (!amount || amount === "0") {
		return {
			type: MembersActionTypes.Withdrawal_To_Voucher_Error,
			message: "Please enter Amount"
		};
	}

	let amountCheck = amount % 5 === 0;
	if (!amountCheck && selectedCurrency.currencyId === 1) {
		return {
			type: MembersActionTypes.Withdrawal_To_Voucher_Error,
			message: "Amount must divide by 5"
		};
	}

	if (amount > selectedCurrency.balance) {
		return {
			type: MembersActionTypes.Withdrawal_To_Voucher_Error,
			message: "Amount is bigger then your balance"
		};
	}

	return {
		type: MembersActionTypes.Withdrawal_To_Voucher_Request,
		data
	};
}

export function memberTransactions(data) {
	return {
		type: MembersActionTypes.Member_Transactions_Request,
		data
	}
}

export function memberForms(data) {
	return {
		type: MembersActionTypes.Member_Forms_Request,
		data
	}
}


export function formsDetails(data) {
	return {
		type: MembersActionTypes.Forms_Details_Request,
		data
	}
}

export function getVoucherDetails(data) {
	return {
		type: MembersActionTypes.Voucher_Details_Request,
		data
	}
}

export function skip() {
	return {
		type: MembersActionTypes.Skip
	}
}

export function init() {
	return {
		type: MembersActionTypes.Init
	}
}

export function setMemberApprovedJackpotWinnings(jackpotId) {
	return {
		type: MembersActionTypes.Member_Approved_Jackpot_Winnings_Request,
		jackpotId
	}
}

export function checkDepositBonusConditions(){
    return {
        type: MembersActionTypes.CheckDepositBonusConditions_Request
    }
}
import React from 'react';
import { googleMapKey } from '../../ApiKeys.js';

class Map extends React.Component {
	constructor(props) {
		super(props);
		this.onScriptLoad = this.onScriptLoad.bind(this)
	}

	onScriptLoad() {

		const map = new window.google.maps.Map(
			document.getElementById(this.props.id),
			this.props.options
		);
		this.props.onMapLoad(map)
	}

	componentDidMount() {

		if (!window.google) {

			var s = document.createElement('script');
			s.type = 'text/javascript';
			s.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapKey}`;
			var x = document.getElementsByTagName('script')[0];
			x.parentNode.insertBefore(s, x);
			s.addEventListener('load',
				e => {
					this.onScriptLoad()
				}
			)
		} else {
			this.onScriptLoad()
		}
	}

	render() {
		return (
			<div className="google-map" id={this.props.id} />
		);
	}
}

export default Map;

import React from 'react';
import { Modal as AntModal, Button } from 'antd';

class Modal extends React.Component {
    render() {
        let { children, type, title, text, visible, onOK, onCancel, okLabel, cancelLabel, textClassName } = this.props
        return (
            <AntModal
                className="general-modal"
                visible={visible}
                footer={null}
                closable={false}
                title={title}
            >
                <div>
                    {type === 'text' &&
                        <div className="modal-container">
                            <h3 className={`text ${textClassName}`}>{text}</h3>
                            <div className="modal-buttons">

                                {
                                    onCancel &&
                                    <Button
                                        type="primary"
                                        className="clear modal-button"
                                        id="stay"
                                        onClick={() => onCancel()}>
                                        {cancelLabel}
                                    </Button>
                                }
                                {onOK &&
                                    <Button
                                        type="primary"
                                        className="info modal-button"
                                        id="stay"
                                        onClick={() => onOK()}>
                                        {okLabel}
                                    </Button>
                                }
                            </div>

                        </div>
                    }

                    {type === 'custom' &&
                        <div>
                            {children}
                        </div>
                    }

                </div>
            </AntModal>
        )
    }
}

export default Modal;
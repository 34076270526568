import React from "react";
import TeamName from "./TeamName";

export default class EventDetails extends React.Component {
  nav = (item) => {
    const { history, setEvent, feedName, match } = this.props;
    setEvent(item);
    history.push(
      `/${feedName}/${match.params.feed}/${match.params.sport}/${
        item.sport.sportDisplayName
      }/event/${item.eventCategory.sportCategoryId}/${
        item.eventTournament.tournamentId
      }/${item.eventId}`
    );
  };

  render() {
    const { event, showCategory } = this.props;
    return (
      <div className="details" onClick={() => this.nav(event)}>
        {showCategory && (
          <div className="categoryDetails">
            <div className="category">{`${
              event.eventCategory.categoryDisplayName
            }, ${event.eventTournament.tournamentDisplayName}`}</div>
          </div>
        )}
        <div className="teams">
          <div className="bet-info">
            <span>
              <i
                className="fa-solid fa-star"
                style={{color:"#334260" , paddingRight: "5px" }}
              />
            </span>
            <div className="teams-position">
               { event.homeTeamRank}
              </div>
            <div style={{ paddingRight: "5px" }}>
              
              <img
                src="/images/lorient.svg"
                alt="leagues-icon"
                height={"18px"}
                width={"18px"}
              />
            </div>
            <TeamName name={event.eventHomeTeamName} />
          </div>
          <div className="bet-info">
            <span>
              <i
                className="fa-solid fa-star"
                style={{ color: "#334260", paddingRight: "5px" }}
              />
            </span>
            <div className="teams-position">
               { event.awayTeamRank}
              </div>
            <div style={{ paddingRight: "5px" }}>
              {" "}
 
              <img
                src="/images/RC_Lens.svg"
                alt="leagues-icon"
                height={"18px"}
                width={"18px"}
              />
            </div>
            <TeamName name={event.eventAwayTeamName} />
          </div>
        </div>
      </div>
    );
  }
}

import { List } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { setPropsMath } from '../../../redux/actions/appActions'
import { Bets, VirtualVideo } from '../../';
import { setEvent } from '../../../redux/actions/sportEventsActions';
import { loadVirtualEvents } from '../../../redux/actions/virtualActions';
import EventRow from './EventRow';

class EventsVirtual extends React.Component {

	constructor(props) {
		super(props);
		this.rowRenderer = this.rowRenderer.bind(this);
	}

	async componentDidMount() {
		const { match, setPropsMath } = this.props;
		setPropsMath(match);
	}

	async componentWillMount() {
		window.addEventListener("message", this.handleVideoMessage);
	}

	async componentWillUnmount() {
		window.removeEventListener("message", this.handleVideoMessage);
	}

	handleVideoMessage = (message) => {
		const { feedId, match, loadVirtualEvents, connected, params } = this.props;

		if (message.data.setVfStatus) {
			//window.log("from video iframe setVfStatus", message.data)
		}

		if (connected) {
			//VFL
			if (message.data.setVflMatchday) {
				const Data = {
					FeedId: Number(match.params.feed),
					SportId: Number(match.params.sport),
					MatchDay: Number(message.data.setVflMatchday.match_day),
					Season: message.data.setVflMatchday.season_name.replace("VFL Season ", ""),
				}

				loadVirtualEvents(Data);
			}

			//VFLM
			if (message.data.setVfMatches) {
				const Data = {
					FeedId: Number(match.params.feed),
					SportId: Number(match.params.sport),
					MatchDay: Number(message.data.setVfMatches.match_day),
					Season: message.data.setVfMatches.season_nr,
				}
				loadVirtualEvents(Data);
			}
			//VBL
			if (message.data.setVblMatchday) {
				const Data = {
					FeedId: Number(match.params.feed),
					SportId: Number(match.params.sport),
					MatchDay: Number(message.data.setVblMatchday.match_day),
					Season: message.data.setVblMatchday.season_name,
				}

				loadVirtualEvents(Data);
			}
		}
	}

	rowRenderer(item) {
		return (
			<EventRow
				{...this.props}
				id={item}
				key={item}
			/>
		)
	}

	render() {
		const { match, loadingSportEvents, title, eventsIds, error } = this.props;
		return (
			<div>
				<VirtualVideo {...this.props} />
				{
					(error) &&
					<div>{error}</div>
				}

				{
					loadingSportEvents &&
					<div className="events">
						<div className="skeleton">
							<div className="row">
								<div className="loader"></div>
								<div className="col"></div>
								<div className="col"></div>
								<div className="col"></div>
								<div className="col"></div>
							</div>

							<div className="row">
								<div className="loader"></div>
								<div className="col"></div>
								<div className="col"></div>
								<div className="col"></div>
								<div className="col"></div>
							</div>

							<div className="row">
								<div className="loader"></div>
								<div className="col"></div>
								<div className="col"></div>
								<div className="col"></div>
								<div className="col"></div>
							</div>

							<div className="row">
								<div className="loader"></div>
								<div className="col"></div>
								<div className="col"></div>
								<div className="col"></div>
								<div className="col"></div>
							</div>
						</div>
					</div>
				}

				{
					!loadingSportEvents && !error &&
					<div className="events">
						{
							eventsIds && eventsIds.length > 0 &&
							<Bets.EventsHeader {...this.props} title={title} />
						}

						{
							eventsIds &&
							<List
								dataSource={eventsIds}
								renderItem={this.rowRenderer}
							/>
						}
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = (_, initialProps) => (state) => {
	return {
		loadingSportEvents: state.sportEvents.loadingSportEvents,
		eventsIds: state.sportEvents.eventsIds,
		error: state.sportEvents.error,
		connected: state.signalr.connected,

	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		loadVirtualEvents: (data) => {
			dispatch(loadVirtualEvents(data));
		},
		setEvent: (item) => {
			dispatch(setEvent(item));
		},
		setPropsMath: (match) => {
			dispatch(setPropsMath(match));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsVirtual);
import React from "react";
import { connect } from "react-redux";
import { Images } from "../../..";
import TopBetDetails from "./TopBetDetails";
import { Events } from "../../..";
import {
  loadAllBetTypes,
  setEvent,
  loadTopBets,
} from "../../../../redux/actions/sportEventsActions";
import { setPropsMath } from "../../../../redux/actions/appActions";

import { Carousel } from "antd";

class TopBetsCarousel extends React.Component {
  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  onArrowClick = (direction) => {
    switch (direction) {
      case 1:
        this.refs.carousel.prev();
        break;

      case 2:
        this.refs.carousel.next();
        break;
    }
  };

  render() {
    const {
      cardsNumber,
      sportEvents,
      loading,
      match,
      feedId,
      error,
    } = this.props;
    
    return (
      <div className={`carousel-${cardsNumber || 1}`}>
        <div className="top">
          <div className="title">Top Matches</div>
          {sportEvents && Object.keys(sportEvents).length > cardsNumber && (
            <div className="container">
              <div className="bt" onClick={() => this.onArrowClick(1)}>
                <img src={Images.LT} />
              </div>
              <div className="bt" onClick={() => this.onArrowClick(2)}>
                <img src={Images.LT} />
              </div>
            </div>
          )}
        </div>
        {sportEvents && Object.keys(sportEvents).length > 0 && (
          <Carousel ref="carousel" dots={false} autoplay>
            {Object.keys(sportEvents).map((key, index) => {
              //if (index % cardsNumber === 0) {

              const item1 = sportEvents[Object.keys(sportEvents)[index]];
              const item2 = sportEvents[Object.keys(sportEvents)[index + 1]];
              const item3 = sportEvents[Object.keys(sportEvents)[index + 2]];

              return (
                <div className="card" key={index}>
                  {item1 && (
                    <TopBetDetails
                      feedId={feedId}
                      {...this.props}
                      key={item1.eventId}
                      showBetTypes
                      showNumber
                      data={item1}
                    />
                  )}

                  {/* {
												item2 && cardsNumber >= 2 &&
												<TopBetDetails
													feedId={feedId}
													{...this.props}
													key={item2.eventId}
													showBetTypes
													showNumber data={item2}>
												</TopBetDetails>
											}

											{
												item3 && cardsNumber >= 3 &&
												<TopBetDetails
													feedId={feedId}
													{...this.props}
													key={item3.eventId}
													showBetTypes
													showNumber data={item3}>
												</TopBetDetails>
											}  */}
                </div>
              );
              //}
            })}
          </Carousel>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sportEvents: state.sportEvents.sportEvents,
    error: state.sportEvents.error,
    feedName: state.app.feedName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadAllBetTypes: (request) => {
      dispatch(loadAllBetTypes(request));
    },
    setEvent: (item) => {
      dispatch(setEvent(item));
    },
    loadTopBets: (request) => {
      dispatch(loadTopBets(request));
    },
    setPropsMath: (match) => {
      dispatch(setPropsMath(match));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBetsCarousel);

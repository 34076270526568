import { all, fork, takeEvery, takeLatest, put, select } from "redux-saga/effects";
import { ModalActionTypes } from '../actions';

export function* requests() {

    yield takeEvery(ModalActionTypes.Open_Modal_Request, function* ({ modals }) {
        const { modal } = yield select();
        //call server
        try {

            //Remove if exists, this way we dont keep uneccesery components
            let newModals = modal.modals.filter(m => m.key != modals.key)

            newModals = [...newModals, modals];
            yield put({ type: ModalActionTypes.Open_Modal_Success, modals: newModals })
        } catch (error) {
            window.log('client ==>> could not set modal');
        }
    })

    yield takeEvery(ModalActionTypes.Close_Modal_Request, function* ({ id }) {
        const { modal } = yield select();

        //Remove, this way we dont keep uneccesery components
        let modals = modal.modals.filter(m => {
            return m.id === id;
        })

        yield put({ type: ModalActionTypes.Close_Modal_Success, modals });

    })
}
export default function* ModalSaga() {
    yield all([
        fork(requests)
    ])
}

import React from 'react';
import { connect } from 'react-redux';
import { Input, Col, Button, Form } from 'antd';
import { depositFromVoucher } from '../../../redux/actions/membersActions'
import { Alert } from '../../../components';


const { Item } = Form;
const InputGroup = Input.Group;

class DepositFromVoucher extends React.Component {

    state = {
        col1: '',
        col2: '',
        col3: '',
        col4: ''
    }

    onSubmit = () => {

        this.props.form.setFieldsValue({
            col1: this.state.col1,
            col2: this.state.col2,
            col3: this.state.col3,
            col4: this.state.col4,
        })
        let voucher = this.props.form.getFieldsValue();
        voucher = Object.keys(voucher).reduce((acc, curr) => {
            if (voucher[curr] === undefined) {
                return acc;
            }
            else {
                acc += voucher[curr];
                return acc;
            }
        }, '');
        this.props.depositFromVoucher(voucher)
    }

    nextInput = (e) => {
        let element = e.target.parentNode;
        if (!element.nextElementSibling) {
            return false
        }
        if (e.target.value.length === 4) {
            if (element.nextElementSibling) {
                let nextSibling = element.nextElementSibling.firstElementChild;
                nextSibling.focus();
            }
        }
    }

    onPaste = (e) => {
        e.stopPropagation()
        let pasteText = e.clipboardData.getData('text/plain');
        let element = e.target.parentNode;
        const TAKE_MORE = 4;
        let start = 0;
        let stateNum = 1;
        while (element) {
            let pasteTextPartial = pasteText.substr(start, TAKE_MORE);
            element.firstElementChild.value = pasteTextPartial;
            let col = `col` + stateNum;
            this.setState({ [col]: pasteTextPartial })
            stateNum++;
            element = element.nextElementSibling;
            start += TAKE_MORE;
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { message, success, loading } = this.props;

        return (
            <div style={{display: "flex",justifyContent: "center",width: "100%"}}>
                <div className="deposit">

                    <div className="form-container">
                        <div className="title">Deposit From Voucher</div>
                        <Item label="Enter Voucher to Deposit">
                            <InputGroup>
                                {getFieldDecorator('col1')(
                                    <Col span={4}>
                                        <Input id='col1' value={this.state.col1} onChange={(e) => { this.setState({ col1: e.target.value }); }} onPaste={this.onPaste} onInput={this.nextInput} maxLength={4} />
                                    </Col>
                                )}
                                {getFieldDecorator('col2')(
                                    <Col span={4}>
                                        <Input id='col2' value={this.state.col2} onChange={(e) => { this.setState({ col2: e.target.value }); }} onPaste={this.onPaste} onInput={this.nextInput} maxLength={4} />
                                    </Col>
                                )}
                                {getFieldDecorator('col3')(
                                    <Col span={4}>
                                        <Input id='col3' value={this.state.col3} onChange={(e) => { this.setState({ col3: e.target.value }); }} onPaste={this.onPaste} onInput={this.nextInput} maxLength={4} />
                                    </Col>
                                )}
                                {getFieldDecorator('col4')(
                                    <Col span={4}>
                                        <Input id='col4' value={this.state.col4} onChange={(e) => { this.setState({ col4: e.target.value }); }} onPaste={this.onPaste} onInput={this.nextInput} maxLength={4} />
                                    </Col>
                                )}
                            </InputGroup>
                        </Item>
                        <Button className="info" type="primary" loading={loading} onClick={this.onSubmit}>Deposit Voucher</Button>
                        {
                            message &&
                            <Alert message={message} type={'error'}></Alert>
                        }
                        {
                            success &&
                            <Alert message={success} type={'success'}></Alert>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const MapStateToProps = (state, props) => {
    return {
        message: state.member.depositErrorMessage,
        success: state.member.depositSuccessMessage,
        loading: state.member.depositLoading,
    };
}

const mapDispatchToProps = (dispatch) => (
    {
        depositFromVoucher: (voucher) => dispatch(depositFromVoucher(voucher)),
    }
);

const WrappedDeposit = Form.create({ name: 'Deposit' })(DepositFromVoucher);
export default connect(MapStateToProps, mapDispatchToProps)(WrappedDeposit)
import { Env } from "../helpers/enums";

class AppConfig {
  static ENV = Env.DEV;
  static srAddress = "";
  static booongoSRAddress = "";
  static jackpotSRAddress = "";
  static booongoAddress = "";
  static icons = "";
  static symbols = "";
  static ShowSignInAndRegistersBtn = true;

  static VirtualAddress = {
    VFL: "",
    VHC: "",
    VFLM: "",
    VBL: "",
    VTO: "",
    VDR: "",
    EPL: "",
  };

  static StatisticsAddress = {
    Domain: "",
    Static: "",
    Virtual: "",
    Menu: "",
    VFL: "",
    VHC: "",
    VFLM: "",
    VBL: "",
    VTO: "",
    VDR: "",
    EPL: "",
  };

  //static api =
  // 	switch (ENV) {
  // 		case Env.DEV:
  // 			currentAmount += selectedCurrency.currencyId == Enums.Currency.USD ? 1 : 10;
  // 			break;

  // 		case "down":
  // 			break;
  // 	}
  // static a
  static api =
    AppConfig.ENV === Env.DEV
      ? {
          contactUs: `http://web-api-dev.boxsyst.com/api/ContactUs`,
          auth: `http://web-api-dev.boxsyst.com/api/Auth`,
          register: `http://web-api-dev.boxsyst.com/api/Register`,
          members: `http://web-api-dev.boxsyst.com/api/Members`,
          pages: `http://web-api-dev.boxsyst.com/api/Pages`,
          general: `http://web-api-dev.boxsyst.com/api/General`,
          slots: `http://web-api-dev.boxsyst.com/api/Slots`,
        }
      : AppConfig.ENV === Env.LOCAL
      ? {
          contactUs: `https://localhost:5001//api/ContactUs`,
          auth: `https://localhost:5001/api/Auth`,
          register: `https://localhost:5001/api/Register`,
          members: `https://localhost:5001/api/Members`,
          pages: `https://localhost:5001/api/Pages`,
          general: `https://localhost:5001/api/General`,
          slots: `https://localhost:5001/api/Slots`,
        }
      : AppConfig.ENV === Env.DEV_HTTPS
      ? {
          contactUs: `https://web-api-dev.boxsyst.com/api/ContactUs`,
          auth: `https://web-api-dev.boxsyst.com/api/Auth`,
          register: `https://web-api-dev.boxsyst.com/api/Register`,
          members: `https://web-api-dev.boxsyst.com/api/Members`,
          pages: `https://web-api-dev.boxsyst.com/api/Pages`,
          general: `https://web-api-dev.boxsyst.com/api/General`,
          slots: `https://web-api-dev.boxsyst.com/api/Slots`,
        }
      : AppConfig.ENV === Env.STAGE
      ? {
          contactUs: `https://stage-web-api.winners.com.lr/api/ContactUs`,
          auth: `https://stage-web-api.winners.com.lr/api/Auth`,
          register: `https://stage-web-api.winners.com.lr/api/Register`,
          members: `https://stage-web-api.winners.com.lr/api/Members`,
          pages: `https://stage-web-api.winners.com.lr/api/Pages`,
          general: `https://stage-web-api.winners.com.lr/api/General`,
          slots: `https://stage-web-api.winners.com.lr/api/Slots`,
        }
      : AppConfig.ENV === Env.UK
      ? {
          contactUs: `https://uk-web-api.winners.com.lr/api/ContactUs`,
          auth: `https://uk-web-api.winners.com.lr/api/Auth`,
          register: `https://uk-web-api.winners.com.lr/api/Register`,
          members: `https://uk-web-api.winners.com.lr/api/Members`,
          pages: `https://uk-web-api.winners.com.lr/api/Pages`,
          general: `https://uk-web-api.winners.com.lr/api/General`,
          slots: `https://uk-web-api.winners.com.lr/api/Slots`,
        }
      : AppConfig.ENV === Env.PRODV1
      ? {
          contactUs: `https://web-api-v1.winners.com.lr/api/ContactUs`,
          auth: `https://web-api-v1.winners.com.lr/api/Auth`,
          register: `https://web-api-v1.winners.com.lr/api/Register`,
          members: `https://web-api-v1.winners.com.lr/api/Members`,
          pages: `https://web-api-v1.winners.com.lr/api/Pages`,
          general: `https://web-api-v1.winners.com.lr/api/General`,
          slots: `https://web-api-v1.winners.com.lr/api/Slots`,
        }
      : {
          contactUs: `https://web-api.winners.com.lr/api/ContactUs`,
          auth: `https://web-api.winners.com.lr/api/Auth`,
          register: `https://web-api.winners.com.lr/api/Register`,
          members: `https://web-api.winners.com.lr/api/Members`,
          pages: `https://web-api.winners.com.lr/api/Pages`,
          general: `https://web-api.winners.com.lr/api/General`,
          slots: `https://web-api.winners.com.lr/api/Slots`,
        };

  static version = `1.0.21.${
    AppConfig.ENV === Env.DEV ||
    AppConfig.ENV === Env.LOCAL ||
    AppConfig.ENV === Env.DEV_HTTPS
      ? "D"
      : "R"
  }`;
}

export default AppConfig;

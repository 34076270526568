import React from "react";
import { Table, Button, Spin, Icon } from "antd";
import { AppConfig, Utils } from "../../helpers";
import moment from "moment";
import { Alert } from "../../components";

class ModalBetDetails extends React.Component {
  columns = [
    {
      title: "Bet No.",
      dataIndex: "bet",
      className: "column-header",
    },
    {
      title: "Match",
      dataIndex: "match",
      className: "column-header",
    },
    {
      title: "Feed",
      dataIndex: "feed",
      className: "column-header",
    },
    {
      title: "Sport",
      dataIndex: "sport",
      className: "column-header",
    },
    {
      title: "Bet Type",
      dataIndex: "note",
      className: "column-header",
    },
    {
      title: "Odd",
      dataIndex: "odd",
      className: "column-header",
    },
    {
      title: "Mark",
      dataIndex: "mark",
      className: "column-header",
    },
    {
      title: "Correct Mark",
      dataIndex: "correctMark",
      className: "column-header",
    },
    {
      title: "Game Score",
      dataIndex: "gameScore",
      className: "column-header",
    },
    {
      title: "Status",
      dataIndex: "winStatus",
      className: "column-header",
      render: (text, record) => {
        //  if (record.isFinal) {
        if (record.status === true) {
          return (
            <Icon
              style={{ background: "#00800050", borderRadius: "50%" }}
              type="check-circle"
            />
          );
        } else if (record.status === false) {
          return (
            <Icon
              style={{ background: "#ff000050", borderRadius: "50%" }}
              type="close-circle"
            />
          );
        }
        //  }
        else {
          return (
            <Icon
              style={{ background: "lightGray", borderRadius: "50%" }}
              type="question-circle"
            />
          );
        }
      },
    },
  ];
  mobileColumns = [
    {
      title: "Bet Decription",
      dataIndex: "tHead",
      className: "column-header",
      render(text, record) {
        return {
          children: (
            <div className="mobile-modal-row-details" style={{ fontWeight: "500" }}>
              {" "}
              {text.split(",").map((item) => (
                <p className="mobile-table-row-data">{item}</p>
              ))}
            </div>
          ),
        };
      },
    },
    {
      title: "Bet Details",
      dataIndex: "data",
      className: "column-header",
      render(text, record) {
        return {
          children: (
            <div className="mobile-modal-row-details">
              <p>{text.bet ? text.bet :                   <Icon
                    style={{ background: "lightGray", borderRadius: "50%" }}
                    type="question-circle"
                  />}</p>
              <p>{text.match ? text.match :                   <Icon
                    style={{ background: "lightGray", borderRadius: "50%" }}
                    type="question-circle"
                  />}</p>
              <p>{text.feed ? text.feed :                   <Icon
                    style={{ background: "lightGray", borderRadius: "50%" }}
                    type="question-circle"
                  />}</p>
              <p>{text.sport ? text.sport :                   <Icon
                    style={{ background: "lightGray", borderRadius: "50%" }}
                    type="question-circle"
                  />}</p>
              <p>{text.note ? text.note :                   <Icon
                    style={{ background: "lightGray", borderRadius: "50%" }}
                    type="question-circle"
                  />}</p>
              <p
                style={{
                  color:
                    (text.status == "Won - Not Claimed" ||
                      text.status == "Claimed") &&
                    "green",
                }}
              >
                {text.odd ? text.odd :                   <Icon
                    style={{ background: "lightGray", borderRadius: "50%" }}
                    type="question-circle"
                  />}
              </p>
              <p>{text.mark ? text.mark :                   <Icon
                    style={{ background: "lightGray", borderRadius: "50%" }}
                    type="question-circle"
                  />}</p>
              <p>{text.correctMark ? text.correctMark :                   <Icon
                    style={{ background: "lightGray", borderRadius: "50%" }}
                    type="question-circle"
                  />}</p>
              <p>{text.gameScore ? text.gameScore :                   <Icon
                    style={{ background: "lightGray", borderRadius: "50%" }}
                    type="question-circle"
                  />}</p>
              <p>
                {" "}
                {text.winStatus === true ? (
                  <Icon
                    style={{ background: "#00800050", borderRadius: "50%" }}
                    type="check-circle"
                  />
                ) : text.winStatus === false ? (
                  <Icon
                    style={{ background: "#ff000050", borderRadius: "50%" }}
                    type="close-circle"
                  />
                ) : (
                  <Icon
                    style={{ background: "lightGray", borderRadius: "50%" }}
                    type="question-circle"
                  />
                )}
              </p>
            </div>
          ),
        };
      },
    },

    // {
    //   title: "Status",
    //   dataIndex: "winStatus",
    //   className: "column-header",
    //   render: (text, record) => {
    //     //  if (record.isFinal) {
    //     if (record.status === true) {
    //       return (
    //         <Icon
    //           style={{ background: "#00800050", borderRadius: "50%" }}
    //           type="check-circle"
    //         />
    //       );
    //     } else if (record.status === false) {
    //       return (
    //         <Icon
    //           style={{ background: "#ff000050", borderRadius: "50%" }}
    //           type="close-circle"
    //         />
    //       );
    //     }
    //     //  }
    //     else {
    //       return (
    //         <Icon
    //           style={{ background: "lightGray", borderRadius: "50%" }}
    //           type="question-circle"
    //         />
    //       );
    //     }
    //   },
    // },
  ];

  statusColor = (status) => {
    if (status > 0) {
      return "#1C5924";
    } else if (status < 0) {
      return "#651F31";
    } else {
      return "lightGray";
    }
  };

  render() {
    const { loading, formDetails, betLines } = this.props;
    const mobileTableData = [];
    console.log("formDetailssssssssssssssssssssssss", formDetails);
    this.props.formDetails.map((item) => {
      mobileTableData.push({
        tHead: `BET NO.,MATCH,FEED,SPORT,BET TYPE,ODD,MARK,CORRECT MARK,GAME SCORE,STATUS`,
        data: {
          bet: item.bet,
          match: item.match,
          feed: item.feed,
          sport: item.sport,
          note: item.note,
          odd: item.odd,
          mark: item.mark,
          correctMark: item.correctMark,
          gameScore: item.gameScore,
          winStatus: item.status,
        },
      });
    });
    console.log(
      "mobileTableData --__-- from modal bet detials",
      mobileTableData
    );

    return (
      <div className="bet-details-container">
        <h1>Bet Details</h1>
        {betLines.message && (
          // <Alert message={betLines.message} type={betLines.formStatusId > 0 ? "success" : betLines.formStatusId < 0 ? "error" : "message-no-status" }></Alert>
          <div
            className="status"
            style={{ background: this.statusColor(betLines.formStatusId) }}
          >
            {betLines.message.replaceAll('.', '')}
          </div>
        )}
        {formDetails && betLines && (
          <div className="details-table">
            <div id="modal-details-table-mobile-view">
              <Table
                align="center"
                loading={loading}
                rowKey={(item, index) => index}
                columns={this.mobileColumns}
                dataSource={mobileTableData}
                className="ant-table-v1"
                rowClassName={(record) =>
                  record.isFinal
                    ? record.winStatus === true
                      ? "table-winning-row"
                      : "table-losing-row"
                    : "table-no-result-row"
                }
              />
            </div>
            <div id="modal-details-table-desktop-view">
              <Table
                align="center"
                loading={loading}
                rowKey={(item, index) => index}
                columns={this.columns}
                dataSource={formDetails}
                className="ant-table-v1"
                rowClassName={(record) =>
                  record.isFinal
                    ? record.winStatus === true
                      ? "table-winning-row"
                      : "table-losing-row"
                    : "table-no-result-row"
                }
              />
            </div>
          </div>
        )}
        {betLines && (
          <div className="bet-details">
            <div className="modal-description-container">
              <div className="modal-left-description">
                <div className="modal-desciption-divs">
                  <span>Form Number: {betLines.formNumber || "XXX"} </span>
                </div>
                <div className="modal-desciption-divs">
                  <span>
                    Wining Odds: {betLines.totalWinningsOdds.toFixed(2)}
                  </span>
                </div>
                <div className="modal-desciption-divs">
                  <span>
                    Winning possible: {betLines.formCurrency.currencySymbol}{" "}
                    {betLines.formCurrency.currencyId === 1
                      ? betLines.possibleWinnings.format(0)
                      : betLines.possibleWinnings.format(2)}{" "}
                  </span>{" "}
                </div>
              </div>
              <div className="modal-right-description">
                <div className="modal-desciption-divs">
                  <span>
                    Date Of Purchase:{" "}
                    {moment(betLines.formDateTime).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </span>{" "}
                </div>
                <div className="modal-desciption-divs">
                  <span>
                    Sum of bets:{betLines.formCurrency.currencySymbol}{" "}
                    {betLines.formCurrency.currencyId === 1
                      ? betLines.formAmount.format(0)
                      : betLines.formAmount.format(2)}{" "}
                  </span>
                </div>
                {betLines.bonus != 0 && (
                  <div className="modal-desciption-divs">
                    <span>Bonus: {betLines.bonus}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ModalBetDetails;

import React from "react";
import { connect } from "react-redux";
import {
  removeField,
  handleHideStake,
} from "../../../redux/actions/betSlipActions";
import { Tooltip, Input, Spin, Icon } from "antd";
import { SportIcon, Alert } from "../../";
import { SessionStorage, Cookies } from "../../../helpers";
import Images from "../Images";

class BetSlipRow extends React.Component {
  state = {
    changeStatus: 0,
  };

  onAmountClick = (e, action, amount, betSlipField, selectedCurrency) => {
    e.preventDefault();
    let currentAmount = parseInt(amount);
    switch (action) {
      case "up":
        currentAmount += selectedCurrency.currencyId === 1 ? 10 : 1;
        break;

      case "down":
        if (currentAmount - (selectedCurrency.currencyId === 1 ? 10 : 1) >= 0) {
          currentAmount -= selectedCurrency.currencyId === 1 ? 10 : 1;
        } else {
          currentAmount = 0;
        }
        break;
    }
    this.props.onFieldAmountChange(
      currentAmount,
      betSlipField.activeOddFieldId
    );
    this.props.handleHideStake();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.betSlipField.oddValue !== prevProps.betSlipField.oddValue) {
      this.setState({ changeStatus: 0 }, () => {
        setTimeout(
          () =>
            this.setState({
              changeStatus: this.props.betSlipField.oddValueStatus,
            }),
          10
        );
      });
    }
  }

  render() {
    const { betSlipField, loadingPlaceBet, memberSelectedCurrency , isDark } =
      this.props;
    const image = `${betSlipField.feed.feedId}${betSlipField.sport.sportId}.webp`;
    let betTab = SessionStorage.betTab;
    const selectedCurrency =
      memberSelectedCurrency || Cookies.get("selectedCurrency");
    const spinner = (
      <Icon type="loading" style={{ fontSize: 24 }} spin={loadingPlaceBet} />
    );
    let hasResponse = betSlipField.response !== undefined;
    return (
      <div className="event">
          <div style={{display:"flex" , flexDirection:"row"}} className="text">
            <div style={{width:"80%" , display:"flex" , flexDirection:"column"}} >
            {/* <SportIcon
              feedId={betSlipField.feed.feedId}
              sportId={betSlipField.sport.sportId}
            /> */}
            <div className="teams" onClick={() => this.navToDrillDown()} style={{width:"90%" ,  display:"inline-block" , justifyContent:"center"}} >
              {betSlipField.feed.feedId === 5 && (
                <div className="live">Live</div>
              )}
              <div className="bet-slip-teams-container">
                <div className="bet-slip-teams">
              <img src="/images/lorient.svg" height="18px" width="18px"/><span>  {betSlipField.eventDisplayName.split("Vs.")[0]}</span>
              
              <span style={{fontSize:"16px"}}><p style={{color:"#2d8af7" ,display:"inline",fontWeight:"bold" , fontSize:"13px"}}>Vs </p></span>
              </div>
              <div className="bet-slip-teams">
              <img src="/images/RC_Lens.svg" height="18px" width="18px"/><span>{betSlipField.eventDisplayName.split("Vs.")[1]}</span>
              </div>
              </div>
            </div>
            <div className="icon">
            {betSlipField.errors &&
              betSlipField.errors.length > 0 &&
              betTab == 2 && (
                <Tooltip
                  title={betSlipField.errors.map((item, index) => (
                    <div key={index}>{` * ${item}`}</div>
                  ))}
                >
                  <div className="alert-icon error">!</div>
                </Tooltip>
              )}
          </div>
          <div className="text">
            <div style={{opacity:"0.5" , fontSize:"12px"}} className="odd-name">{betSlipField.betType.betTypeName}</div>
          </div>
          <div className="text odds">
            <div className="icon"></div>
            <div>
            <div className="team" >{betSlipField.oddFieldDisplayName}</div>
            </div>
          </div>
          </div>
            {/* separator comment */}

            <div style={{width:"20%" , display:"flex" , flexDirection:"column"  , justifyContent:"space-between" , alignItems:"center" }} >
            <div
              className="close"
              onClick={() => this.removeField(betSlipField)}
            >
              <img  src={isDark ? Images.BinIcon : Images.RedBinIcon} height="17px" width="17px" />
              {/* <Icon type="delete" theme="filled"/> */}
             
              </div>
              <div 
              className={`odd-value ${
                this.state.changeStatus == 1 ? "up" : ""
              } ${this.state.changeStatus == -1 ? "down" : ""}`}
            >
              {betSlipField.enableBet &&
              betSlipField.eventEnableBet &&
              betSlipField.oddEnableBet &&
              !betSlipField.notAvailable ? (
                betSlipField.oddValue.format(2)
              ) : betSlipField.notAvailable ? (
                <div className="text"> No Longer Available</div>
              ) : (
                <Icon type="lock" />
              )}
            </div>

            </div>
          </div>
  
        {/* ====================================================================== */}
        {/* Mounting component and possible winnings score in the uppper of the bits details component */}
        {/* {betTab &&
          betTab == 1 &&
          (hasResponse
            ? hasResponse && betSlipField.response.type !== "success"
            : true) && (
            <div className="row-amount">
              <div className="amount-up-down">
                <div
                  className="up"
                  onClick={(e) =>
                    this.onAmountClick(
                      e,
                      "down",
                      betSlipField.amount,
                      betSlipField,
                      selectedCurrency
                    )
                  }
                >
                  <Icon type="minus" />
                </div>
                <div className="amount">
                  <Input
                    type="number"
                    value={betSlipField.amount || 0}
                    className="input"
                    placeholder="Amount"
                    onChange={(e, value) =>
                      this.onAmountClick(
                        e,
                        "manual",
                        e.target.value,
                        betSlipField
                      )
                    }
                  />
                </div>
                <div
                  className="down"
                  onClick={(e) =>
                    this.onAmountClick(
                      e,
                      "up",
                      betSlipField.amount,
                      betSlipField,
                      selectedCurrency
                    )
                  }
                >
                  <Icon type="plus" />
                </div>
              </div>
              <div className="currency">
                {selectedCurrency ? selectedCurrency.currencySymbol : "LD"}
              </div>
            </div>
          )}
        {loadingPlaceBet &&
          betTab == 1 &&
          (!hasResponse || betSlipField.response.type === "error") && (
            <Spin indicator={spinner} />
          )}
        {betTab &&
          betTab == 1 &&
          (hasResponse
            ? hasResponse && betSlipField.response.type !== "success"
            : true) && (
            <div className="row-total-possible">
              <div className="total-possible">Possible winnings: </div>
              <div className="total-possible-value">
                {`${selectedCurrency ? selectedCurrency.currencySymbol : "LD"}
						${
              selectedCurrency.currencySymbol === "LD"
                ? betSlipField.possibleFieldWin.format(0)
                : betSlipField.possibleFieldWin.format(2)
            }`}
              </div>
            </div>
          )}
        {betSlipField.errors &&
          betSlipField.errors.length > 0 &&
          betTab == 1 &&
          betSlipField.errors.map((item, index) => (
            <Alert key={index} message={item} type="error" icon="!" />
          ))}
        {betSlipField.oddValueStatus !== 0 && (
          <Alert message={"Bet value changed"} type="warning" icon="!" />
        )}
        {hasResponse && betSlipField.response.type === "success" && (
          <div style={{ background: "#00800036" }}>
            <Alert
              key={betSlipField.response.betLine}
              message={betSlipField.response.message}
            ></Alert>
          </div>
        )}
        {hasResponse && betSlipField.response.type === "error" && (
          <Alert
            key={betSlipField.response.betLine}
            type={betSlipField.response.type}
            icon={"!"}
            message={betSlipField.response.message}
          ></Alert>
        )} */}
      </div>
    );
  }

  removeField(field) {
    const { removeField } = this.props;
    removeField(field);
  }

  navToDrillDown() {
    const { history, feedName, match, betSlipField, feedId } = this.props;
    history.push(
      `/${feedName}/${feedId}/${match.params.sport}/${match.params.sportName}/event/${betSlipField.eventCategory.sportCategoryId}/${betSlipField.eventTournament.tournamentId}/${betSlipField.eventId}`
    );
  }
}

const mapStateToProps = (_, initialProps) => (state) => {
  return {
    betSlipField: state.betSlip.betSlipList[initialProps.data.activeOddFieldId],
    amount: state.betSlip.amount,
    memberSelectedCurrency: state.member.selectedCurrency,
    isDark: state.app.isDark,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeField: (field) => {
      dispatch(removeField(field));
    },
    handleHideStake: () => {
      dispatch(handleHideStake());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BetSlipRow);

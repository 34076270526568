import React from "react";
import moonIcon from "../../resources/icons/moon.svg";
import Images from "./Images";
import { connect } from 'react-redux';

class ModeSwitcher extends React.Component {

  changeThemeMode = () =>{
    this.props.switchMode()
    !this.props.isDark ? localStorage.setItem("theme" , "dark") : localStorage.setItem("theme" , "light")
    {console.log("this.props.isDark" , this.props.isDark);}
  }

  render() {
    return (
      <div onClick={()=> this.changeThemeMode()}  className="mode-switcher">

          <div>
            
            <img src={ localStorage.getItem("theme") == "dark" ? moonIcon:  Images.DarkMoon} width="15" />
            <span id="mode-switcher-text"> {localStorage.getItem("theme") == "dark" ?   'Light Mode': 'Dark Mode' }</span>
          </div>


      </div>
    );
  }
}

const mapStateToProps = (_, initialProps) => (state) => {
	return {
		isDark: state.app.isDark,

	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		setPropsMath: (match) => {
			;
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ModeSwitcher);

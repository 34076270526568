import {
  all,
  fork,
  takeEvery,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { registerApi, membersApi } from "../../api/index";
import { RegisterActionTypes, AuthActionTypes } from "../actions";
import { Cookies } from "../../helpers";
// import fetch from 'node-fetch';

export function* requests() {
  yield takeEvery(RegisterActionTypes.Register_Entities_Request, function* () {
    //call server
    try {
      let response = yield registerApi.GetRegisterEntities();
      let data = response.data;

      switch (response.status) {
        case 1:
          yield put({
            type: RegisterActionTypes.Register_Entities_Success,
            data,
          });
          break;
        case 3 || 4:
          yield put({
            type: RegisterActionTypes.Register_Entities_Success,
            message: response.message,
          });
          break;
      }
    } catch (error) {
      window.log("client ==>> could not get register entities: ", error);
    }
  });

  yield takeEvery(RegisterActionTypes.Add_Member_Phone_Request, function* () {
    //call server
    try {
      const { register } = yield select();

      let formData = {
        phoneNumber: register.formData.secondPhone,
        code: register.formData.smsCode,
      };
      let response = yield membersApi.AddMemberPhone(formData);

      switch (response.status) {
        case 1:
          let current = register.current + 1;
          yield put({
            type: RegisterActionTypes.Add_Member_Phone_Success,
            current,
          });
          break;
        case 3 || 4:
          yield put({
            type: RegisterActionTypes.Add_Member_Phone_Error,
            message: response.message,
          });
          break;
      }
    } catch (error) {
      window.log("client ==>> could not add member phone");
    }
  });
  yield takeLatest(
    RegisterActionTypes.Validate_Phone_Request,
    function* ({ data, resend }) {
      //call server
      const { register } = yield select();

      try {
        let response = yield registerApi.ValidatePhoneNumber(data);

        switch (response.status) {
          case 1:
            let current = resend ? register.current : register.current + 1;
            let message = resend ? response.message : "";
            yield put({
              type: RegisterActionTypes.Validate_Phone_Success,
              current,
              message,
            });
            break;
          case 3 || 4:
          default:
            //window.grecaptcha.reset();
            yield put({
              type: RegisterActionTypes.Validate_Phone_Error,
              message: response.message,
            });
            break;
        }
      } catch (error) {
        window.log("client ==>> could not get phone validation");
      }
    }
  );

  yield takeEvery(
    RegisterActionTypes.Validate_Add_Phone_Request,
    function* ({ data }) {
      //call server
      try {
        let response = yield registerApi.ValidateAddPhoneNumber(data);

        switch (response.status) {
          case 1:
            yield put({
              type: RegisterActionTypes.Validate_Add_Phone_Success,
              message: response.message,
            });
            break;
          case 3 || 4:
            yield put({
              type: RegisterActionTypes.Validate_Add_Phone_Error,
              message: response.message,
            });
            break;
        }
      } catch (error) {
        window.log("client ==>> could not get phone validation");
      }
    }
  );

  yield takeLatest(RegisterActionTypes.Register_Member_Request, function* () {
    //call server
    try {
      const { register } = yield select();
      const formData = register.formData;
      let form = {
        phone: formData.phone,
        birthDate: formData.birthDate,
        password: formData.password,
        termsAndConditions: formData.termsAndConditions,
        smsCode: formData.smsCode,
      };
      let response = yield registerApi.RegisterMember(form);
      let data = response.data;

      switch (response.status) {
        case 1:
          let current = register.current + 1;
          let request = {
            username: response.data.userName,
            password: formData.password,
          };
          yield put({
            type: RegisterActionTypes.Register_Member_Success,
            current,
          });
          yield put({
            type: AuthActionTypes.Signin_Request,
            data: request,
            register: true,
          });
          break;
        case 3 || 4:
          yield put({
            type: RegisterActionTypes.Register_Member_Error,
            message: response.message,
          });
          break;
      }
    } catch (error) {
      window.log("client ==>>  could not set member");
    }
  });

  yield takeEvery(
    RegisterActionTypes.Validate_Email_Request,
    function* (memberData) {
      //call server
      try {
        let response = yield registerApi.ValidateEmail(memberData.data);
        let data = response.data;

        switch (response.status) {
          case 1:
            yield put({
              type: RegisterActionTypes.Validate_Email_Success,
              data,
            });
            break;
          case 3 || 4:
            yield put({
              type: RegisterActionTypes.Validate_Email_Error,
              message: response.message,
            });
            break;
        }
      } catch (error) {
        window.log("client ==>> could not validate email");
      }
    }
  );

  yield takeEvery(RegisterActionTypes.Handle_Register_signin, function* () {
    let user = Cookies.get("user");
    if (user && user.userName) {
      yield put({ type: AuthActionTypes.Signin_Success });
    }
  });
}
export default function* RegisterSaga() {
  yield all([fork(requests)]);
}
